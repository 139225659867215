import React,{useState,useEffect,createContext} from "react";
import { NavLink,useNavigate } from "react-router-dom";
import logo from "../Images/iHodl-3.png";
import avatar from "../Images/avatar.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import '../../index.css'
import {
  faCoins,
  faArrowDownUpAcrossLine,
  faLayerGroup,
  faShareNodes,
  faWallet,
  faArrowRightArrowLeft,
  faGrip,
  faArrowRightFromBracket,
  faSackDollar,
  faBars,
  faXmark,
  faGear,
  faTachometerAlt,
  faAddressBook,
  faTag,
  faCircle,
  faCircleQuestion,
  faHourglass,
} from "@fortawesome/free-solid-svg-icons";

const SetProfile = createContext();

const Menu = (props) => {
  
  let last_visit = localStorage.getItem('last_visit');
  const navigate = useNavigate()
  const[userData,setUserData]=useState(JSON.parse(localStorage.getItem('user_info')))

  const logout=() =>{
    localStorage.clear();
    window.location.href = '/login'; 

  }
  
  const Profile = () => {
    navigate('/profile')
  }

  useEffect(() => {
    setUserData(JSON.parse(localStorage.getItem('user_info')))
    props.setProfileUpdated(false)
  }, [props.profileUpdated])
  
  // useEffect(() => {
  //   const section = document.querySelector( '.active-nav' )
  //   section?.scrollIntoView({ behavior: 'smooth' }) 
  // }, [])
  



  return (
    <>
      <div className={(props.mode==='light'?'bg-orange ':'bg-gradient-to-r from-rad_grey to-rad_darkgrey ')+" h-full fixed w-w17 flex flex-col justify-between z-20 sm:w-wp250 md:w-wp250 scrollbar-hide overflow-y-auto"}>
        <div>
          <div className="relative flex justify-center items-center ">
            <img src={logo} className="w-2/4" />
            <button className="absolute top-3 right-3 text-2xl " onClick={()=>props.setMenu(false)}>
              <FontAwesomeIcon icon={faXmark} className={(props.mode==='light'?'text-darkblue':'text-white')+' hidden md:block text-white sm:block'} />
            </button>
          </div>
          <div className="flex justify-center overflow-y-auto md:h-auto custom-scroll">
            <ul className="w-w75 py-5 lg:w-w90">
              <li>
                <NavLink to="/dashboard"  className={({ isActive }) => isActive ? ((props.mode==='light'?' active-nav border-l-8 border-darkorange bg-activetabblue ':' active-nav bg-bgblue ')+'flex flex-row items-center w-full h-h13 justify-start pl-5 rounded-xl text-white xl:pl-2'): ((props.mode=='light'?'text-fontblue':'text-fontpurple')+' flex flex-row items-center w-full h-h13 justify-start pl-5 xl:pl-2')} >
                  <FontAwesomeIcon icon={faTachometerAlt} />
                  <p className={"ml-3 text-small xl:text-sm xl:ml-2"}>Dashboard</p>
                </NavLink>
              </li>
              <li className="">
                <NavLink to="/buy-coins" className={({ isActive }) => isActive ? ((props.mode==='light'?' active-nav border-l-8 border-darkorange bg-activetabblue ':' active-nav bg-bgblue ')+'flex flex-row items-center w-full h-h13 justify-start pl-5 rounded-xl text-white xl:pl-2'): ((props.mode=='light'?'text-fontblue':'text-fontpurple')+' flex flex-row items-center w-full h-h13 justify-start pl-5 xl:pl-2')} >
                  <FontAwesomeIcon icon={faCoins} />
                  <p className={"ml-3 text-small xl:text-sm x:ml-2"}>Buy Coins</p>
                </NavLink>
              </li>
              <li className="">
              <NavLink to="/send-receive-coin" className={({ isActive }) => isActive ? ((props.mode==='light'?' active-nav border-l-8 border-darkorange bg-activetabblue ':' active-nav bg-bgblue ')+'flex flex-row items-center w-full h-h13 justify-start pl-5 rounded-xl text-white xl:pl-2'): ((props.mode=='light'?'text-fontblue':'text-fontpurple')+' flex flex-row items-center w-full h-h13 justify-start pl-5 xl:pl-2')} >
                  <FontAwesomeIcon icon={faArrowRightArrowLeft}/>
                  <p className={"ml-3 text-small xl:text-sm xl:ml-2"}>Send / Receive</p>
                </NavLink>
              </li>
              <li className="">
              <NavLink to="/schedule-transactions" className={({ isActive }) => isActive ? ((props.mode==='light'?' active-nav border-l-8 border-darkorange bg-activetabblue ':' active-nav bg-bgblue ')+'flex flex-row items-center w-full h-h13 justify-start pl-5 rounded-xl text-white xl:pl-2'): ((props.mode=='light'?'text-fontblue':'text-fontpurple')+' flex flex-row items-center w-full h-h13 justify-start pl-5 xl:pl-2')} >
                  <FontAwesomeIcon icon={faHourglass}/>
                  <p className={"ml-3 text-small xl:text-sm xl:ml-2"}>Schedule Transactions</p>
                </NavLink>
              </li>
              <li className="">
              <NavLink to="/wallet" className={({ isActive }) => isActive ? ((props.mode==='light'?' active-nav border-l-8 border-darkorange bg-activetabblue ':' active-nav bg-bgblue ')+'flex flex-row items-center w-full h-h13 justify-start pl-5 rounded-xl text-white xl:pl-2'): ((props.mode=='light'?'text-fontblue':'text-fontpurple')+' flex flex-row items-center w-full h-h13 justify-start pl-5 xl:pl-2')} >
                  <FontAwesomeIcon icon={faWallet} />
                  <p className={"ml-3 text-small xl:text-sm xl:ml-2"}>Wallet</p>
                </NavLink>
              </li>
              <li className="">
              <NavLink to="/swap-coins" className={({ isActive }) => isActive ? ((props.mode==='light'?' active-nav border-l-8 border-darkorange bg-activetabblue ':' active-nav bg-bgblue ')+'flex flex-row items-center w-full h-h13 justify-start pl-5 rounded-xl text-white xl:pl-2'): ((props.mode=='light'?'text-fontblue':'text-fontpurple')+' flex flex-row items-center w-full h-h13 justify-start pl-5 xl:pl-2')} >
                  <FontAwesomeIcon icon={faArrowDownUpAcrossLine} />
                  <p className={"ml-3 text-small xl:text-sm xl:ml-2"}>Swap Coins</p>
                </NavLink>
              </li>
              <li className="">
              <NavLink to="/cold-storage" className={({ isActive }) => isActive ? ((props.mode==='light'?' active-nav border-l-8 border-darkorange bg-activetabblue ':' active-nav bg-bgblue ')+'flex flex-row items-center w-full h-h13 justify-start pl-5 rounded-xl text-white xl:pl-2'): ((props.mode=='light'?'text-fontblue':'text-fontpurple')+' flex flex-row items-center w-full h-h13 justify-start pl-5 xl:pl-2')} >
                  <FontAwesomeIcon icon={faLayerGroup} />
                  <p className={"ml-3 text-small xl:text-sm xl:ml-2"}>Cold Storage</p>
                </NavLink>
              </li>
              <li className="">
              <NavLink to="/transaction-history" className={({ isActive }) => isActive ? ((props.mode==='light'?' active-nav border-l-8 border-darkorange bg-activetabblue ':' active-nav bg-bgblue ')+'flex flex-row items-center w-full h-h13 justify-start pl-5 rounded-xl text-white xl:pl-2'): ((props.mode=='light'?'text-fontblue':'text-fontpurple')+' flex flex-row items-center w-full h-h13 justify-start pl-5 xl:pl-2')} >
                  <FontAwesomeIcon icon={faSackDollar} />
                  <p className={"ml-3 text-small xl:text-sm xl:ml-2"}>Transaction History</p>
                </NavLink>
              </li>
              <li className="">
              <NavLink to="/address-book" className={({ isActive }) => isActive ? ((props.mode==='light'?' active-nav border-l-8 border-darkorange bg-activetabblue ':' active-nav bg-bgblue ')+'flex flex-row items-center w-full h-h13 justify-start pl-5 rounded-xl text-white xl:pl-2'): ((props.mode=='light'?'text-fontblue':'text-fontpurple')+' flex flex-row items-center w-full h-h13 justify-start pl-5 xl:pl-2')} >
                  <FontAwesomeIcon icon={faAddressBook} />
                  <p className={"ml-3 text-small xl:text-sm xl:ml-2"}>Address Book</p>
                </NavLink>
              </li>
              <li className="">
              <NavLink to="/offers" className={({ isActive }) => isActive ? ((props.mode==='light'?' active-nav border-l-8 border-darkorange bg-activetabblue ':' active-nav bg-bgblue ')+'flex flex-row items-center w-full h-h13 justify-start pl-5 rounded-xl text-white xl:pl-2'): ((props.mode=='light'?'text-fontblue':'text-fontpurple')+' flex flex-row items-center w-full h-h13 justify-start pl-5 xl:pl-2')} >
                  <FontAwesomeIcon icon={faTag} />
                  <p className={"ml-3 text-small xl:text-sm xl:ml-2"}>Offers</p>
                </NavLink>
              </li>
              <li className="">
              <NavLink to="/faqs" className={({ isActive }) => isActive ? ((props.mode==='light'?' active-nav border-l-8 border-darkorange bg-activetabblue ':'active-nav bg-bgblue ')+'flex flex-row items-center w-full h-h13 justify-start pl-5 rounded-xl text-white xl:pl-2'): ((props.mode=='light'?'text-fontblue':'text-fontpurple')+' flex flex-row items-center w-full h-h13 justify-start pl-5 xl:pl-2')} >
                  <FontAwesomeIcon icon={faCircleQuestion} />
                  <p className={"ml-3 text-small xl:text-sm xl:ml-2"}>FAQs</p>
                </NavLink>
              </li>
              <li className="">
              <NavLink to="/settings"  className={({ isActive }) => isActive ? ((props.mode==='light'?' active-nav border-l-8 border-darkorange bg-activetabblue ':' active-nav bg-bgblue ')+'flex flex-row items-center w-full h-h13 justify-start pl-5 rounded-xl text-white xl:pl-2'): ((props.mode=='light'?'text-fontblue':'text-fontpurple')+' flex flex-row items-center w-full h-h13 justify-start pl-5 xl:pl-2')} >
                  <FontAwesomeIcon icon={faGear} />
                  <p className={"ml-3 text-small xl:text-sm xl:ml-2"}>Settings</p>
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
        <div className="w-full flex flex-col items-center xl:pt-5">
          {last_visit!=''&&last_visit!=null&&last_visit!='null'?
          <div className="w-w80">
            <h1 className="font-extrabold bg-clip-text text-trans bg-gradient-to-r from-purple to-pink text-small">
              Last Visit
            </h1>
            <p className={props.mode==='light'?"text-xsmall":"text-xsmall text-white"}>{last_visit}</p>
          </div>:null
          }
          <div className="border-t-2 h-1 border-trans w-w82 mt-3 bg-gradient-to-r from-purple to-pink">&nbsp;
          </div>
          <div className={"mb-4 mt-2 border-trans border-t-2 w-w75 px-5 xl:px-1"}>
            <div className="flex flex-row items-center justify-start mt-2 cursor-pointer" onClick={Profile}>
              <div className="w-10 h-10 border border-1 border-opacity-20 border-white rounded-full flex items-center justify-center">
                <img src={userData.photo} className="w-8 h-8 rounded-full" />
              </div>
              <p className={(props.mode==='light'?"ml-5":"ml-5 text-white")+" text-small xl:text-sm"} >{userData.first_name+" "+userData.last_name}</p>
            </div>
            <div className="flex flex-row items-center justify-start mt-2">
              <div className="w-10 h-10 flex items-center justify-center">
                <FontAwesomeIcon icon={faArrowRightFromBracket} className={(props.mode==='light'?"text-purple":'text-white')+" text-medium"} />
              </div>
              <p className={(props.mode==='light'?"ml-5":"ml-5 text-white")+" text-small cursor-pointer xl:text-sm"} onClick={logout}>Logout</p>
            </div>
            </div>
        </div>
      </div>
    </>
  );
};

export default Menu;
