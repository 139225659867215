import React,{useContext,useState,useEffect} from 'react'
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { APP_URLS } from "../../../../../api/url";
import API from "../../../../../api/axios";
import { toast, ToastContainer } from 'react-toastify';
import { NewEntries } from '../../..';
import dropdown from '../../../../../components/Images/dropdown.png';



const BankDeposit = (props) => {
  let coins = props.coinAmount
  const setNewEntries = useContext(NewEntries);
  let banks=props.data.banks
  const [loading, setLoading] = useState(false);
  const [coinAmount, setCoinAmount] = useState(props.coinAmount)

  useEffect(() => {
    setCoinAmount(props.coinAmount)
  },[props.coinAmount])

  useEffect(() => {
    {coinAmount<=0?props.setValidateAmount(true):props.setValidateAmount(false)}
  }, [coinAmount])


  const formik = useFormik({
    initialValues: {
      payment_type:'4',
      coin:coins,
      sleep:'',
      bank_id:'',
    },
    validationSchema: Yup.object({
        bank_id: Yup.string().required('Bank Name is Required'),
        sleep: Yup.mixed().required('File is Required'),
    }),
    onSubmit: values => {
      if(!loading) { 
      setLoading(true)
      let formValues = {}
      const form = new FormData();  
      var imagedata = document.querySelector('input[type="file"]').files[0];
      // formValues[`payment_type`] = values.payment_type
      // formValues[`coin`] = coins
      // formValues['sleep'] = imagedata
      // formValues[`bank_id`] = values.bank_id
      form.append('payment_type', values.payment_type);
      form.append('coin', coins);
      form.append('sleep', imagedata);
      form.append('bank_id', values.bank_id);
      if(props.phaseID!=null){
      // formValues[`phase_id`] = props.phaseID
        form.append("phase_id", props.phaseID)
      }

      API.post(APP_URLS.BUY_COIN_THROUGH_APP, form)
      .then(res => { 
          setLoading(false)
          props.setCoinAmount(0)
          if (res.data.success === true) {
            setNewEntries(true)
            formik.resetForm()
            toast.success('Transaction has been done successfully.', {
              position: toast.POSITION.TOP_RIGHT
            })
          } else {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT
            })        
          }
        })
        .catch((error) => {
          setLoading(false)
          const resp = error.response;
          if (resp) {
            
              let error_message = '';
              if (resp.data.errors !== undefined) {
                  {Object.keys(resp.data.errors).map((error, index) => (
                      error_message = resp.data.errors[error][0]
                  ))}
              } else if (resp.data.data.error !== undefined) {
                  error_message =  resp.data.data.error;
              } else {
                  error_message =  resp.data.error
              }
  
              toast.error(error_message, {
                  position: toast.POSITION.TOP_RIGHT
              })
          }
        })      
    }}
  });

  return (
    <>
    <ToastContainer />
    <form onSubmit={formik.handleSubmit}>
        <div className={(props.mode=='light'?'bg-orange':'bg-elemblue2')+' w-full flex-col w-full pb-5 rounded-lg'}>
        {/* Bank Deposit start */}
        <div className='w-full block gap-5 lg:block gap-3'>                  
                  <div className='w-full mt-4'>
                    <div className='flex flex-row items-center my-2'><p className={(props.mode==='light'?'text-darkblue':'text-white')+' text-small ml-2 xl:text-xs '}>Select Bank</p><p className='text-small text-red'>*</p></div>
                    <div className={(props.mode==='light'?'bg-white':'bg-inputblue1 text-white')+(formik.touched.bank_id && formik.errors.bank_id?' border-red border rounded-3xl':' border-btnblue border rounded-3xl')+" w-full outline-none h-10 text-small h-h45 px-4  shadow-lg"}>           
                      
                      <select 
                      style={{backgroundImage: `url(${dropdown})`}} 
                      className={(props.mode==='light'?'bg-white':'bg-inputblue1 text-white')+" w-full outline-none h-10 text-small h-h42  bg-no-repeat bg-right appearance-none"} 
                        value={formik.values.bank_id}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        name="bank_id">

                        <option hidden>Select Bank</option>
                        {banks.map((item,index)=>{
                        return <option value={item.id} key={index}>{item.bank_name}</option>
                        })}
                      </select>
                      {formik.touched.bank_id && formik.errors.bank_id ? (
                      <div className='text-left text-red text-xs pt-2 mb-1'>{formik.errors.bank_id}</div>):null}  
                    </div> 
                  </div> 
                  <div className='flex flex-row items-center my-2'><p className={(props.mode==='light'?'text-darkblue':'text-white')+' text-small ml-2 mt-4 xl:text-xs '}>Attach Payment Receipt</p><p className='text-small text-red mt-4'>*</p></div>
                  <div className={(props.mode==='light'?'bg-white':'bg-inputblue1')+(formik.touched.sleep && formik.errors.sleep?' border-red border rounded-3xl':' border-btnblue border rounded-3xl')+' w-full  rounded-md text-darkblue p-5 xs:py-5 xs:px-1 mt-3 text-center'}>
                  <input className= {(props.mode==='light'?'text-darkblue':'text-white')+' sm:w-w95'} type="file" name="sleep" 
                    value={formik.values.sleep}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    />
                  </div>                   
                    {formik.touched.sleep && formik.errors.sleep ? (
                      <div className='text-left text-red text-xs pt-2'>{formik.errors.sleep}</div>):null}  
                </div>
        </div>
        {/* Bank Deposit end */}
        {coinAmount<=0?
        <div className="w-full flex">
          <button type="submit" className={(props.mode==='light'?'bg-darkorange':'bg-gradient-to-r from-purple to-pink ')+" justify-start mt-3 rounded-3xl w-wp160 h-h13 text-white disabled:opacity-50"} disabled>
            Buy Now!
          </button>
        </div>:
        <div className="w-full flex">
          <button type="submit" className= {(props.mode==='light'?'bg-darkorange':'bg-gradient-to-r from-purple to-pink ')+" justify-start mt-3 rounded-3xl w-wp160 h-h13 text-white disabled:opacity-50"} disabled={loading}>
            {loading?'Please Wait':'Buy Now!'}
          </button>
        </div>
        }
    </form>
    
    </>
  )
}

export default BankDeposit