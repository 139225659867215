import React, { useState,useEffect } from 'react'
import BuyOurCoin from './BuyOurCoin';
import SaleCounter from './SaleCounter';

const SaleAvailable = (props) => {

  return (
    <>
    <div className="flex flex-row justify-between my-8 gap-6 md:block sm:block xxs:mt-6">
      <BuyOurCoin mode={props.mode} data={props.data}/>
      <SaleCounter mode={props.mode} data={props.data}/>
    </div>
  </>
  )
}

export default SaleAvailable