import React from "react";
import { AreaChart,Line, Area, XAxis,LineChart, YAxis, Tooltip,   CartesianGrid,ResponsiveContainer } from "recharts";


const rangeData = [
  {
    Day: "Mon",
    Coins: 0,
  },
  {
    Day: "Tues",
    Coins: 0,
  },
  {
    Day: "Wed",
    Coins: 0,
  },
  {
    Day: "Thu",
    Coins: 0,
  },
  {
    Day: "Fri",
    Coins: 0,
  },
  {
    Day: "Sat",
    Coins: 0,
  },
  {
    Day: "Sun",
    Coins: 0,
  }
];

const TotalBuyCoins = (props) => {
  let data = props.values
  let WeeklyBuyCoin = props.values.data.weekly_buy_coin
  rangeData.map((item,index)=>{
    item.Coins=parseFloat(WeeklyBuyCoin[index])
  })
  return (
    <div className={(props.mode==='light'?'bg-white':'bg-elemblue3')+" w-w94 xl:w-w100 2xl:mt-4"}>
      <div className="flex flex-row justify-between mt-8 mb-5 px-5">
        <div className={(props.mode==='light'?'text-darkblue':'text-white')}>Total Buy Coins</div>
        <div className="t-5">
          <p className={(props.mode==='light'?'text-darkblue':'text-lightblue')+" text-large"}> {data.data.total_buy_coin_usd}</p>
          <p className={(props.mode==='light'?'text-darkblue':'text-darkgrey')+" flex flex-col items-end"}>USD</p>
        </div>
      </div>
      <div className="mt-5">
        <ResponsiveContainer width="100%" height={130}>
        <AreaChart
           margin={{
            bottom: -28,
           }}
          data={rangeData}
        >
          <XAxis dataKey="Day" axisLine={false} tick={false}/>
          {/* <YAxis /> */}
          <Area dataKey="Coins" stroke={props.mode==='light'?"#003366":"#3e264a"} fill={props.mode==='light'?"#ede0cd":"#3e264a"} />
          <Tooltip />
        </AreaChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default TotalBuyCoins;
