import React, { useState,useContext,useEffect } from 'react'
import Switch from 'react-switch';
import {SetTheme} from "../../App"


const SwitchMode = (props) => {
    const {inline} = props;
    const setMode = useContext(SetTheme);
    let mode = localStorage.getItem('mode')
    let currMode = (mode === 'light') ? false : true;
  
  const [checked,setChecked] = useState(currMode)

    const handleChange =() =>{
        if(checked==true){
            setChecked(false)
            setMode('light')
            localStorage.setItem('mode','light')
          }
          else{
            setChecked(true)
            setMode('dark')
            localStorage.setItem('mode','dark')
          }
    }

    useEffect(() => {
        if(checked==true){
            setMode('dark')
            // console.log("true")
        }
        else{
            setMode('light')
            // console.log("false")
      }
    }, [checked])
  
    return (
    <>
        <div className={'flex bg-btnblue border border-white right-0 p-3 mt-28 rounded-tl-xl rounded-bl-xl z-10 '+ `${inline ? '' : 'fixed'}`}>    
          <span className='self-center mr-2 text-white'>Light Mode</span> 
            <Switch onChange={handleChange} checked={checked} onColor="#020241" 
            uncheckedIcon={
            <svg viewBox="0 0 10 10" height="100%" width="100%" fill="#ffffff">
                {/* <circle r={3} cx={5} cy={5} /> */}
            </svg>
                }
            checkedIcon={
            <svg viewBox="0 0 10 10" height="100%" width="100%" fill="#ffffff">
                {/* <circle r={3} cx={5} cy={5} /> */}
            </svg>
            }/> 
          <span className='self-center ml-2 text-white'>Dark Mode</span>     
        </div>
    </>
  )
}

export default SwitchMode;