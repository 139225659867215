import React from "react";
import Mode from "../../components/Mode/Mode";
import { MainLayout } from "../../components/Layouts/MainLayout";
import ActivityLog from "./Component/ActivityLog";



const ActivityLogPage = () => {

  return (
    <>
      <MainLayout>
        <Mode />
        <div className='min-h-screen w-full mt-5'>
        <ActivityLog />
        </div>
      </MainLayout>      
    </>
  );
};

export default ActivityLogPage;