import React, { useEffect, useState } from "react";
import API from "../../../api/axios";
import { APP_URLS } from "../../../api/url";
import * as Yup from 'yup';
import { useFormik } from "formik";
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert'; 
import GoogleAuth from "./Popups/GoogleAuth";
import dropdown from '../../../components/Images/dropdown.png';
import SendIndividualAmount from "./Popups/SendIndividualAmount";

let dates = [1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26,27,28,29,30]

const SendCoinGroups = (props) => {
    
    
    const [loading, setLoading] = useState(false);
    const [userWalletList, setUserWalletList] = useState([]);

    const [gauth,setGauth]=useState(false)
    const [popup,setPopup]=useState()
    const [amountPopup,setAmountPopup]=useState(false)
    const [individualAmountPopup,setIndividualAmountPopup] = useState()
    const [date,setDate] = useState()

    const [dropdownValues,setDropDownValues] = useState([])
    const [search,setSearch] = useState('')
    const [dataupdated,setDataupdated] = useState(false)
    const [walletDataupdated,setWalletDataupdated] = useState(false)

    const [coin,setCoin] = useState()
    const [coinData,setCoindata] = useState()
    const [walletID,setWalletID] = useState()
    const [groupID,setGroupID] = useState()
    const [minWithdrawal,setMinWithdrawal] = useState(1.00000000)
    const [maxWithdrawal,setmaxWithdrawal] = useState(9999999.00000000)

    const [groupedData,setGroupedData] = useState()
    const [modifyID,setModifyID] = useState()

    useEffect(() => {
        if(props.data){
            setModifyID(props.data.id)
            formik.setFieldValue('wallet_id',props.data.wallet_name)
            setCoinVal(props.data.coin_type)
            if(props.data.is_expanded===false){
            formik.setFieldValue('amount',props.data.amount)
            }
            else{
                setGroupedData(props.data.groupped_details)
            }
            formik.setFieldValue('date',props.data.date)
            formik.setFieldValue('group_id',props.data.group_id)
            setGroupID(props.data.group_id)
            setWalletID(props.data.wallet_id)
            setDate(props.data.date)
        }
    }, [])
    
    


    //API for Group ID
    const getAddressList =()=>{
        API.get(APP_URLS.GET_COIN_CATEGORIES+(coin!==undefined?`/${coin}`:'/'))
        .then(res => { 
          if (res.data.success === true) {
            setDropDownValues([])
            let items = res.data.data
            for (const item in items) {
                setDropDownValues((oldValues)=>{
                    return[...oldValues,{
                        'name':items[item],
                        'address':item,
                }]
                })
                setDataupdated(true)
              }  
            } else {
              toast.error(res.data.message, {
                position: toast.POSITION.TOP_RIGHT
              })        
            }
          })
          .catch((error) => {
            const resp = error.response;
            if (resp) {
                let error_message = '';
                if (resp.data.errors !== undefined) {
                    {Object.keys(resp.data.errors).map((error, index) => (
                        error_message = resp.data.errors[error][0]
                    ))}
                } else if (resp.data.data.error !== undefined) {
                    error_message =  resp.data.data.error;
                } else {
                    error_message =  resp.data.error
                }
  
                toast.error(error_message, {
                    position: toast.POSITION.TOP_RIGHT
                })
            }
          }) 
    }

    const updated =() =>{
        setDataupdated(false)
    }

    const walletUpdated =() =>{
        setWalletDataupdated(false)
    }

    //Listing User Email / Wallet Address if search or coin type changes
    useEffect(() => {
        if(coin){
        getAddressList()
        }
    }, [search,coin])

    //Listing wallets if searched
    useEffect(() => {
        fetchDefaultWallet();
    }, [coin]);
    
    //API for Primary Wallets
    const fetchDefaultWallet = async () => {
        await API.get(APP_URLS.PERSONAL_WALLET+`?limit=500&`+(coin?`search=${coin}`:`search=`))
          .then((result) => { 
            const resp = result.data.data;
            setUserWalletList(resp.data);
          })
          .catch((error) => console.log({error}))
    }


    //Setting coin value if Wallet is selected
    const setCoinVal =(coin)=>{
        let flag=false
        for(let i=0;i<userWalletList.length;i++){
            if(coin==userWalletList[i].coin_type){
                setCoin (userWalletList[i].coin_type)
                setCoindata(userWalletList[i])
                setWalletID(userWalletList[i].id)
                setMinWithdrawal(userWalletList[i].minimum_withdrawal)
                setmaxWithdrawal(userWalletList[i].maximum_withdrawal)
                flag = true
                break;
            }
        }
        if(flag===false){{
            setCoin(coin)
            setWalletID('')
        }}
    }

    const formik = useFormik({
        initialValues: {
            wallet_id: '',
            amount: '',
            group_id: '',
            date: '',
        },
        validationSchema: Yup.object({
            wallet_id: Yup.string().required('Wallet is Required'),
            amount: Yup.number().required('Amount is Required').positive().min(minWithdrawal, `Minimum amount should be greater than ${minWithdrawal}`).max(maxWithdrawal, `Maximum amount should be less than ${maxWithdrawal}`),
            group_id: Yup.string().required('Group is Required'),
            date: Yup.string().required("Date is Required"),
        }),
        onSubmit: (values, actions) => {
            setDropDownValues([])    
            let data ={
                "wallet_id": walletID,
                "amount": values.amount,
                "group_id": values.group_id,
                "date": values.date,
                "is_expanded":0
            }
            let gSecret = JSON.parse(localStorage.getItem('google2fa_secret'))
            let closePopup = true
            if(gSecret===true){
                setGauth(true)
                setPopup(<GoogleAuth setGauth={setGauth} modifyID={modifyID} data={data} mode={props.mode} actions={actions} rerender={rerender} setCoin={setCoin} closePopup={closePopup} setTransactionDone={props.setTransactionDone} setSchedulePopup={props.setSchedulePopup} setModifySchedulePopup={props.setModifySchedulePopup} setShowModifySchedulePopup={props.setShowModifySchedulePopup}/>)
            }
            else{
                toast.error("Please setup your Two-Factor Google Authentication", {
                    position: toast.POSITION.TOP_RIGHT
                  })
            }
            formik.resetForm()
            setGroupID('')   
            setCoinVal('')     
        },
    });

    const rerender = () => {
        props.rerender()
    }

    return (
        <>
            {gauth?popup:''}
            {amountPopup?individualAmountPopup:''}
            <div className={(props.mode == 'light' ? 'bg-orange' : 'bg-elemblue2') + ' w-full flex-col w-full px-4 py-10 mb-10 rounded-lg'}>
                <div className='flex flex-row justify-between mb-5 sm:block'>
                    <div className="flex flex-col mb-5">
                        <p className={(props.mode === 'light' ? "text-darkblue" : "text-white") + " text-lg mb-1"}>{props.data?'Modify Transaction':'Schedule New Transaction'}</p>
                        <p className={(props.mode === 'light' ? "text-lightblue" : "text-btnblue") + " text-sm mb-2"}>Note: Since February has 28 days, If you select recurring payment on 29,30, It will be made on 28 February.</p>
                    </div>
                    <div>
                        <button className={(props.mode === 'light' ? 'bg-darkorange' : 'bg-gradient-to-r from-purple to-pink')+" text-white mt-3 rounded-3xl w-wp160 h-[3rem] disabled:opacity-50 sm:mt-0" }
                        disabled={!groupID||props?.data?.is_expanded===false}
                        onClick={()=>{
                            setDropDownValues([])    
                            setAmountPopup(true)
                            setIndividualAmountPopup(<SendIndividualAmount modifyID={modifyID} setTransactionDone={props.setTransactionDone} data={props.data} setAmountPopup={setAmountPopup} groupedData={groupedData} date={date} groupID={groupID} walletID={walletID} mode={props.mode} rerender={rerender} setCoin={setCoin} minWithdrawal={minWithdrawal} maxWithdrawal={maxWithdrawal} setSchedulePopup={props.setSchedulePopup} setModifySchedulePopup={props.setModifySchedulePopup} setShowModifySchedulePopup={props.setShowModifySchedulePopup}/>)
                            formik.resetForm()
                            setGroupID('')
                            setDate('')
                            }}>
                            Expand Group
                        </button>
                    </div>
                </div>
                <form onSubmit={formik.handleSubmit} className="">
                    <div className='w-full flex flex-wrap flex-row gap-5 xl:gap-5 gap-3'>
                        <div className='w-w485 lg:w-w48  md:w-w100 inline-block align-top'>
                            <div className='flex flex-row items-center my-2'><p className={(props.mode === 'light' ? 'text-darkblue' : 'text-white') + ' text-small ml-2 xl:text-xs '}>Select Your Crypto Coins Wallet</p>
                            <p className='text-small text-red'>*</p>
                            </div>
                            <div className="relative">
                                <input
                                    type="text"
                                    name="wallet_id"
                                    onChange={(e)=>{
                                        formik.handleChange(e)
                                        setCoinVal(e.target.value)
                                        setGroupID('')
                                        formik.setFieldValue('group_id','')
                                    }}
                                    onBlur={(e)=>{
                                        formik.handleBlur(e)
                                        setTimeout(walletUpdated, 200);
                                    }}
                                    onFocus={()=>setWalletDataupdated(true)}
                                    value={formik.values.wallet_id}
                                    placeholder="Enter Wallet"
                                    autoComplete='off'
                                    className={(props.mode === 'light' ? 'bg-white' : 'bg-inputblue1 text-white') + " w-full outline-none h-10 text-small p-1 border-btnblue border rounded-3xl h-h45 px-4 shadow-lg " +  (formik.touched.wallet_id && formik.errors.wallet_id ? 'border-red': '')}></input>
                                    {walletDataupdated&&(
                                    <ul className={(props.mode === 'light' ? 'text-darkblue bg-white' : 'text-white bg-inputblue1') + ' absolute text-left left-[5%] border border-lightblue border-opacity-50 border-t-0 w-w90 text-xs mx-auto shadow-lg max-h-28 overflow-y-auto scrollbar-hide'} >
                                    {userWalletList && userWalletList.map((item, index) => {
                                        return <li className={(props.mode === 'light' ? 'border-b-darkblue' : 'border-b-white') + ' hover:bg-darkblue hover:bg-opacity-20 cursor-pointer border-b border-opacity-10 py-2 px-3'} key={index} onClick={()=>{
                                            formik.setFieldValue('wallet_id',item.name)
                                            setCoinVal(item.coin_type)
                                            }}>
                                            {item.name}
                                            </li>
                                    })} 
                                    </ul>
                                    )} 
                            </div>
                            {formik.touched.wallet_id && formik.errors.wallet_id ? (
                                    <div className='text-left text-red text-xs pt-2 pl-2'>{formik.errors.wallet_id}</div>
                                ) : null}
                        </div>
                        <div className='w-w485 lg:w-w48 md:w-w100 inline-block md:mt-2 align-top'>
                            <div className='flex flex-row items-center my-2'>
                                <p className={(props.mode === 'light' ? 'text-darkblue' : 'text-white') + ' text-small ml-2 xl:text-xs'}>Coin Amount Per User</p>
                                <p className='text-small text-red'>*</p>
                                <p className='text-xxsmall text-greylight ml-2'> (Min Amount : {coinData?coinData.minimum_withdrawal:' 0.00000010'} {coinData?coinData.coin_type:null})</p>
                            </div>
                            <div>
                                <input
                                    autoComplete="off"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    type="text" name="amount"
                                    value={formik.values.amount}
                                    placeholder="Enter Amount"
                                    className={(props.mode === 'light' ? 'bg-white' : 'bg-inputblue1 text-white') + " w-full outline-none h-10 text-small p-1 border-btnblue border rounded-3xl h-h45 px-4 shadow-lg " +  (formik.touched.amount && formik.errors.amount ? 'border-red': '')} />
                                
                                <p className='text-xxsmall text-greylight mr-2 mt-1 text-right'> (Max Amount : {coinData?coinData.maximum_withdrawal:'99999999.00000000'} {coinData?coinData.coin_type:null}) 
                                </p>
                            </div>
                        </div>                        
                        <div className='w-w485 lg:w-w48 md:w-w100 inline-block md:mt-2 align-top'>
                            <div className='flex flex-row items-center my-2'>
                                <p className={(props.mode === 'light' ? 'text-darkblue' : 'text-white') + ' text-small ml-2 xl:text-xs'}>Date</p>
                                <p className='text-small text-red'>*</p>
                            </div>
                            <div className={(props.mode === 'light' ? 'bg-white' : 'bg-inputblue1 text-white') + " w-full outline-none h-10 text-small border-btnblue border rounded-3xl h-h45 pr-4 shadow-lg bg-no-repeat bg-right overflow-hidden  " +  (formik.touched.date && formik.errors.date ? 'border-red': '')}>
                            <select
                                    style={{backgroundImage: `url(${dropdown})`}}
                                    onChange={(e)=>{
                                        formik.handleChange(e)
                                        setDate(e.target.value)
                                    }}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.date}
                                    name="date"         
                                    className={(props.mode === 'light' ? 'bg-white' : 'bg-inputblue1 text-white') + " w-full outline-none h-10 text-small p-1  h-h45 px-4 bg-no-repeat bg-right appearance-none " +  (formik.touched.date && formik.errors.date ? 'border-red': '')}>
                                        <option hidden>Select A Date</option>
                                        {dates.map((item,index)=>{
                                         return <option value={item} key={index} >{item}</option>
                                        })}

                                    
                                </select>
                                
                                <p className='text-xxsmall text-greylight mr-2 mt-1 text-right'> Choose a date for scheduling your transaction </p>
                            </div>
                        </div>
                        <div className='w-w485 lg:w-w48 md:w-w100 inline-block align-top'>
                            <div className='flex flex-row items-center my-2'>
                                <p className={(props.mode === 'light' ? 'text-darkblue' : 'text-white') + ' text-small ml-2 xl:text-xs'}>User Group Address</p>
                                <p className='text-small text-red'>*</p>
                            </div>
                            <div className={(props.mode === 'light' ? 'bg-white' : 'bg-inputblue1 text-white') + " w-full outline-none h-10 text-small border-btnblue border rounded-3xl h-h45 pr-4 shadow-lg bg-no-repeat bg-right overflow-hidden  " +  (formik.touched.group_id && formik.errors.group_id ? 'border-red': '')}>
                                <select
                                    style={{backgroundImage: `url(${dropdown})`}}
                                    onChange={(e)=>{
                                        formik.handleChange(e)
                                        setGroupID(e.target.value)
                                    }}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.group_id}
                                    name="group_id"         
                                    className={(props.mode === 'light' ? 'bg-white' : 'bg-inputblue1 text-white') + " w-full outline-none h-10 text-small p-1 rounded-3xl h-h45 px-4 bg-no-repeat bg-right appearance-none " +  (formik.touched.group_id && formik.errors.group_id ? 'border-red': '')}>
                                        <option hidden>Select Group Address</option>
                                        {dataupdated&&(dropdownValues.map((item,index)=>{
                                            return <option value={item.address} key={index}>{item.name}</option>
                                        }))
                                    }
                                </select>
                            </div>
                            <p className='text-xxsmall text-greylight mr-2 mt-1 text-right'>Input group address where you want to send. Coins will be sent to their crypto wallet. </p>
                        </div>
                    </div>
                    <div className='w-w33 xl:w-w50 '>
                        <button className={(props.mode==='light'?'text-white bg-darkorange':'text-white bg-gradient-to-r from-purple to-pink ')+' mt-3 rounded-3xl w-wp160 h-h13 disabled:opacity-50'} type="submit" disabled={loading||props?.data?.is_expanded===true}>{ loading ?props.data?'Updating...': 'Scheduling...' :props.data?'Update': 'Schedule' } </button>
                    </div>
                </form>
            </div>
        </>
    );
};

export default SendCoinGroups;

