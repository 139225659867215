import React,{useEffect,useState} from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDownLong,
  faCircle,
} from "@fortawesome/free-solid-svg-icons";
import ProgressBar from "@ramonak/react-progress-bar";

const SaleCounter = (props) => {

  const [endDate,setEndDate] = useState('YYYY-MM-DD')
  const [startDate,setStartDate] = useState('YYYY-MM-DD')

  const [raisedAmount,setRaisedAmount]=useState(parseFloat(props.data.activePhase.pahse_info.raised_amount))
  const [amount,setAmount]=useState(parseInt(props.data.activePhase.pahse_info.amount))
  const [percentage,setPercentage] = useState(0)

  const setDates = ()=>{
    let end_date = props.data.activePhase.pahse_info.end_date.split(" ")[0];
    setEndDate(end_date)
    let start_date = props.data.activePhase.pahse_info.start_date.split(" ")[0];
    setStartDate(start_date)
  }

  const getPercentage = () =>{
    let namount = parseInt(amount)
    let nramount = parseInt(raisedAmount)
    let value = (nramount/namount)*100
    setPercentage(parseInt(value))
  }
    

  //Sale Counter
  const [saleDays,setSaleDay] = useState(0);
  const [saleHours,setSaleHours] = useState(0);
  const [saleMinutes,setSaleMinutes] = useState(0);
  const [saleSeconds,setSaleSeconds] = useState(0);

  const saleCountdown = () =>{
    let countDownDate = new Date(props.data.activePhase.pahse_info.end_date.replace(/-/g, "/")).getTime();
    let x = setInterval(function() {

      // Get today's date and time
      let now = new Date().getTime();
    
      // Find the distance between now and the count down date
      let distance = countDownDate - now;
    
      // Time calculations for days, hours, minutes and seconds
      let days = Math.floor(distance / (1000 * 60 * 60 * 24));
      let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      let seconds = Math.floor((distance % (1000 * 60)) / 1000);
    
      // Setting state for counter
        setSaleDay(days<10?`0${days}`:days)
        setSaleHours(hours<10?`0${hours}`:hours)
        setSaleMinutes(minutes<10?`0${minutes}`:minutes)
        setSaleSeconds(seconds<10?`0${seconds}`:seconds)
    
      // If the count down is finished, exiting counter
      if (distance < 0) {
        clearInterval(x);
      }
    }, 1000);
  }

  useEffect(() => {
    getPercentage()
    saleCountdown()
    setDates()
    }, [])

  return (
    <>
          <div className={(props.mode==='light'?'bg-orange':'bg-elemblue2')+' shadow-lg flex flex-col p-7 rounded-lg w-w50 mt-2 md:w-full'}>
        <h2 className={(props.mode==='light'?'text-darkblue':'text-white')+' block text-left w-w100 text-medium mb-2'}>
          SALE ENDS IN
        </h2>
        <div className="flex justify-between w-full mt-3">
          <div className="block">
            <span className={(props.mode==='light'?'text-darkblue':'text-white')+' block text-center text-6xl font-light lg:text-4xl sm:text-2xl'}>
              {saleDays}
            </span>
            <span className={(props.mode==='light'?' border-lightblue':' border-white')+' block text-center text-lightblue text-xl border-t-2 pt-4 mt-3 tracking-widest font-light sm:mt-2 pt-2 lg:text-lg sm:text-xs border-t-2 lg:border-t lg:pt-2'}>
              DAYS
            </span>
          </div>
          <div className="block">
            <span className={(props.mode==='light'?'text-darkblue':'text-white')+' leading-10 text-7xl font-light   lg:text-3xl sm:text-2xl'}>
              :
            </span>
          </div>
          <div className="block">
          <span className={(props.mode==='light'?'text-darkblue':'text-white')+' block text-center text-6xl font-light lg:text-4xl sm:text-2xl'}>
              {saleHours}
            </span>
            <span className={(props.mode==='light'?' border-lightblue':' border-white')+' block text-center text-lightblue text-xl border-t-2 pt-4 mt-3 tracking-widest font-light lg:text-lg sm:mt-2 pt-2 sm:text-xs border-t-2 lg:border-t lg:pt-2'}>
              HOURS
            </span>
          </div>
          <div className="block">
            <span className={(props.mode==='light'?'text-darkblue':'text-white')+' leading-10 text-7xl font-light  lg:text-3xl sm:text-2xl'}>
              :
            </span>
          </div>
          <div className="block">
            <span className={(props.mode==='light'?'text-darkblue':'text-white')+' block text-center text-6xl font-light lg:text-4xl sm:text-2xl'}>
              {saleMinutes}
            </span>
            <span className={(props.mode==='light'?' border-lightblue':' border-white')+' block text-center text-lightblue text-xl border-t-2 pt-4 mt-3 tracking-widest font-light lg:text-lg sm:mt-2 pt-2 sm:text-xs border-t-2 lg:border-t lg:pt-2'}>
              MINUTES
            </span>
          </div>
          <div className="block">
            <span className={(props.mode==='light'?'text-darkblue':'text-white')+' leading-10 leading-10 text-7xl font-light lg:text-3xl sm:text-2xl'}>
              :
            </span>
          </div>
          <div className="block">
          <span className={(props.mode==='light'?'text-darkblue':'text-white')+' block text-center text-6xl font-light  lg:text-4xl sm:text-2xl'}>
              {saleSeconds}
            </span>
            <span className={(props.mode==='light'?' border-lightblue':' border-white')+' block text-center text-lightblue text-xl border-t-2 pt-4 mt-3 tracking-widest font-light lg:text-lg sm:mt-2 pt-2 sm:text-xs border-t-2 lg:border-t lg:pt-2'}>
              SECONDS
            </span>
          </div>
        </div>
        <div className="flex justify-between mt-10">
          <div className="text-left">
            <h3 className={(props.mode==='light'?'text-darkblue':'text-white')+' text-xl tracking-wider'}>STARTS AT</h3>
            <span className="block text-lightblue text-sm">{startDate}</span>
            
          </div>
          <div className="text-right">
            <h3 className={(props.mode==='light'?'text-darkblue':'text-white')+' text-right text-xl tracking-wider'}>
              ENDS AT
            </h3>
            <span className="block text-lightblue text-sm">{endDate}</span>
            
          </div>
        </div>
        <div className="block mt-0">
          <div className="block text-center">
            <span className={'block text-lightblue text-sm w-w75 text-left mx-auto mt-1 sm:w-full'} >
            {parseFloat(raisedAmount).toFixed(2)} HDL
            </span>
          </div>
          <div className="w-w75 bg-inputblue1 border border-btnblue h-4 w-full rounded-2xl mx-auto overflow-hidden sm:w-full">
            <div className="text-xs text-center text-white bg-lightblue" style={{width: `${percentage}%`,maxWidth:'100%'}}>  &nbsp;{percentage}%</div>
          </div>
          <span className={(props.mode==='light'?'text-darkblue':'text-white opacity-40')+" block text-sm w-w75 text-right mx-auto mt-1 sm:w-full"}>
          {parseFloat(amount).toFixed(2)} HDL
          </span>
        </div>
        <form className="block text-right mt-10 sm:text-left">
          <label className={(props.mode==='light'?'text-darkblue':'text-white')+' inline-block items-center space-x-2 mr-4'}>
            <FontAwesomeIcon className="text-lightblue border border-lightblue border-1 rounded-full" icon={faCircle}/>
            <span className="opacity-40 text-sm">Raised Amount</span>
          </label>
          <label className={(props.mode==='light'?'text-darkblue':'text-white')+' inline-block items-center space-x-2 mr-4'}>
            <FontAwesomeIcon className="text-inputblue1 border border-darkblue border-1 rounded-full" icon={faCircle}/>
            <span className="opacity-40 text-sm">Target Amount</span>
          </label>
        </form>
      </div>
    </>
  )
}

export default SaleCounter