import React, { useState, useContext, useEffect } from "react";
import ReactPaginate from 'react-paginate';
import { Theme } from "../../App";

const Pagination = (props) => {
  const mode = useContext(Theme);
  const handlePageClick = (event) => {
    props.onPageClick(event.selected)
  };

  
  return (
    <nav className='Page navigation mt-3 mb-3 float-right sm:block'>
      <ReactPaginate
              previousLabel="←PREV"
              nextLabel="NEXT→"
              pageClassName=" mx-1 leading-tight rounded-full border-btnblue border text-sm text-center"
              pageLinkClassName={(mode === 'light' ? 'text-darkblue' : 'text-white') + '  py-1 px-2 mt-2 align-middle leading-6'}
              previousClassName=""
              previousLinkClassName={(mode === 'light' ? 'text-darkblue' : 'text-white') + ' py-1 px-1 ml-0 leading-tight text-sm text-white disabled:opacity-50 text-center mx-1 xxs:text-xs'}
              nextClassName=""
              nextLinkClassName={(mode === 'light' ? 'text-darkblue' : 'text-white') + ' py-1 px-1 ml-0 leading-tight text-sm text-white disabled:opacity-50 xxs:text-xs'}
              // breakLabel="..."
              breakClassName=""
              breakLinkClassName={(mode === 'light' ? 'text-darkblue' : 'text-white') + ' py-1 px-1 ml-0 leading-tight text-sm text-white disabled:opacity-50'}
              pageCount={props.totalPages}
              marginPagesDisplayed={1}
              pageRangeDisplayed={1}
              containerClassName="inline-flex float-right mb-10"
              activeClassName="bg-lightblue border-btnblue"
              // forcePage={0}
              onPageChange={handlePageClick}
            />
      </nav>
  );
};

export default Pagination;