import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmarkCircle } from "@fortawesome/free-solid-svg-icons";
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import API from "../../../../api/axios";
import { APP_URLS } from "../../../../api/url";
import { useFormik } from "formik";

const ImportMultiSignatureWalletPopup = (props) => {
    const [loading, setLoading] = useState(false);
    const nameRegExp = /^([a-zA-Z0-9\_\-]+)$/;

    function onCloseClickHandle() {
        props.onClosePopup()
    }

    const formik = useFormik({
        initialValues: {
            key: ''
        },
        validationSchema: Yup.object({
            key: Yup.string().required('Wallet key is Required').matches(nameRegExp, 'Wallet key should be alphanumeric only'),
        }),
        onSubmit: values => {
            if(!loading) {
                setLoading(true)
                API.post(APP_URLS.IMPORT_WALLET_BY_KEY, values)
                .then(res => {
                    const resp = res.data;
                    setLoading(false)
    
                    if (resp.success === false) {
                      toast.error(resp.message, {
                        position: toast.POSITION.TOP_RIGHT
                      })
                    } else {
                        toast.success(resp.message, {
                            position: toast.POSITION.TOP_RIGHT
                        })
                        onCloseClickHandle();
                    }
                })
                .catch(function (error) {  
                    const resp = error.response;
                    setLoading(false)
                    if (resp) {
                      
                        let error_message = '';
                        if (resp.data.errors !== undefined) {
                            {Object.keys(resp.data.errors).map((error, index) => (
                                error_message = resp.data.errors[error][0]
                            ))}
                        } else if (resp.data.data.error !== undefined) {
                            error_message =  resp.data.data.error;
                        } else {
                            error_message =  resp.data.error
                        }
    
                        toast.error(error_message, {
                            position: toast.POSITION.TOP_RIGHT
                        })
                    }
                });         
            }
        },
    });
    
    return (
        <>
            <div className='mt-15'>
                <div className="flex flex-center bg-darkblue bg-opacity-80 w-full fixed z-20 grid place-items-center h-screen top-0 left-0 right-0">
                    <div className={(props.mode === 'light' ? 'bg-orange text-darkblue border-darktblue' : 'bg-bgblue border-lightblue') + ' relative block mx-auto border border-8 rounded-2xl text-center px-8 py-10 max-w-md sm:px-2  sm:w-w90 sm:mx-auto'}>
                        <button className='absolute -right-5 -top-5' onClick={() => onCloseClickHandle()}> <FontAwesomeIcon className='text-elemblue text-3xl bg-white rounded-full' icon={faXmarkCircle} /> </button>
                        <h2 className={(props.mode === 'light' ? 'text-darkblue' : 'text-white') + ' text-lg mb-2 text-2xl mb-5'} >Want To Import a Multi-Signature Wallet?</h2>

                        <form className='text-center' onSubmit={formik.handleSubmit}>
                            <div className='w-full mb-4'>
                                <div className='flex flex-row my-2'><p className={(props.mode === 'light' ? 'text-darkblue' : 'text-white') + ' text-small ml-2 xl:text-xs '}>Enter Wallet Key</p></div>
                                <div>
                                    <input
                                        type="text"
                                        autoComplete="off"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        name="key"
                                        value={formik.values.key}                                        placeholder='Enter Wallet key'
                                        className={(props.mode === 'light' ? 'bg-white' : 'bg-inputblue1 text-white') + " w-full outline-none h-10 text-xs p-1 border-btnblue border rounded-3xl h-h45 px-4 shadow-lg"} />
                                    
                                    {formik.touched.key && formik.errors.key ? (
                                        <div className='text-left text-red text-xs pt-2 pl-4'>{formik.errors.key}</div>
                                    ) : null}
                                </div>
                            </div>
                            <div className='w-full mt-5'>
                                <button type="submit" className={(props.mode==='light'?'bg-darkorange':'bg-lightblue')+' mt-3 rounded-md w-full h-h13 text-white text-center text-xl'}
                                disabled={loading}>{ loading ? 'Submitting....' : 'submit' }</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ImportMultiSignatureWalletPopup;