import React, { useContext } from "react";
import Mode from "../../components/Mode/Mode";
import {Theme} from "../../App"
import { MainLayout } from "../../components/Layouts/MainLayout";
import Book from "./Components/Book";


const AddressBookPage = () => {

  const mode = useContext(Theme);

  return (
    <>
      <MainLayout>
        <Mode />

        <div className="min-h-screen">
            <Book mode={mode}/>
        </div>

      </MainLayout>
    </>
  );
};

export default AddressBookPage;
