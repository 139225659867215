import React, { useState, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass} from "@fortawesome/free-solid-svg-icons";
import { Theme } from "../../App";

const SearchBar = (props) => {
    const [search, setSearch] = useState('')
    const mode = useContext(Theme);

    function handleChange(e) {
        setSearch(e.target.value)
    }

    function onSearch() {
        props.onSearchClick(search);
    }

    return (
        <>
            <div className="h-h40">
                <div className={"flex flex-row w-auto px-3 items-center shadow-lg rounded-full " + (mode === 'light' ? 'bg-white bg-white' : 'bg-inputblue')}>
                    <input
                        type="text"
                        placeholder="Search"
                        className={(mode === 'light' ? 'text-darkblue bg-trans' : 'bg-trans') + " w-w80 h-h40 outline-none text-white sm:text-sm"}
                        value={search}
                        onChange={handleChange} 
                    />
                    <button type="button" className="outline-none w-w20" onClick={onSearch}>
                        <FontAwesomeIcon icon={faMagnifyingGlass} className={(mode === 'light' ? '' : 'text-white') + ""} />
                    </button>
                </div>
            </div>
        </>
    );
};

export default SearchBar;