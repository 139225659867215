import React, { useState, useContext } from "react";
import {Theme} from "../../App"
import { MainLayout } from "../../components/Layouts/MainLayout";
import SwapCoin from "./Components/SwapCoin";
import SwapCoinHistory from './Components/SwapCoinHistory'
import { useParams } from 'react-router-dom';

const SwapCoinPage = (props) => {

  const mode = useContext(Theme);
  const selectedFromWallet = useParams();
  
  const [menu,]=useState(false);
  const [rerender, setRerender] = useState();

  const handleRerender = () => {
    setRerender(Math.random());
  }

  return (
    <>
      <MainLayout 
        setMenu={menu}
      >
        <div className='min-h-screen'>
          <h1 className={(mode==='light'?'text-darkblue':'text-white')+' justify-start text-medium mt-14 mb-10 sm:mt-7'} >Swap Coins from Coins Wallet</h1>
       
        
          <div className="mt-10 flex flex-col items-center lg:mt-5">
            <SwapCoin rerender={() => handleRerender()} selectedCoin={selectedFromWallet.coinType} />
            <hr className="mt-10 mb-7 border-white opacity-10" />
          </div>
          <SwapCoinHistory key={rerender} rerender={rerender} />
        </div>
      </MainLayout>
    </>
  );
};

export default SwapCoinPage;

