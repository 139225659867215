const APP_API_URL = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : 'https://admin.ihodl.com/api';

export const APP_URLS = {
  LOGIN: APP_API_URL + 'login',
  REGISTER: APP_API_URL + 'sign-up',
  EMAIL_VERIFICATION: APP_API_URL + 'email-verify',
  WATCH_LIST:  APP_API_URL + 'fetch-watch-list',
  UPDATE_WATCH_LIST: APP_API_URL + 'sync-coins-in-watch-list',
  FORGOT_PASSWORD:  APP_API_URL + 'forgot-password',
  RESET_PASSWORD:  APP_API_URL + 'reset-password',
  GOOGLE_AUTHENTICATE: APP_API_URL + 'g2f-verify-app',

  USER_DASHBOARD_DATA: APP_API_URL + 'user-dashboard-app',
  USER_WALLET_LIST:  APP_API_URL + 'my-pocket-list',
  USER_DEFAULT_WALLET:  APP_API_URL + 'request-coin-app',
  GET_NOTIFICATION: APP_API_URL + 'get-notification',
  SHOW_NOTIFICATION: APP_API_URL + 'show-notification',
  
  SEND_COIN:  APP_API_URL + 'give-coin-app',
  RECEIVE_COIN:  APP_API_URL + 'default-coin-request-app',
  COIN_HISTORY:  APP_API_URL + 'all-coin-history',
  BUY_COIN_THROUGH_APP: APP_API_URL + 'buy-coin-through-app',
  BUY_COIN_RATE_APP: APP_API_URL + 'buy-coin-rate-app',
  APPROVAL_ACTION_FOR_COIN_REQUEST: APP_API_URL + 'approval-action-for-coin-request',
  BUY_COIN_PHASE_INFORMATION: APP_API_URL + 'get-buy-coin-and-phase-information',

  PERSONAL_WALLET: APP_API_URL + 'my-pocket-list',
  MULTI_SINGATURE_WALLET:  APP_API_URL + 'my-multi-signature-pocket-list',
  CREATE_WALLET: APP_API_URL +'create-wallet',
  IMPORT_WALLET_BY_KEY: APP_API_URL +'import-wallet-by-key',
  WALLET_DETAILS_BY_ID: APP_API_URL +'wallet-details-by-id',
  SET_WALLET_PRIMARY:  APP_API_URL +'make-primary-account',
  CO_USER_LIST:  APP_API_URL +'co-user-list',
  WITHDRAW_PROCESS: APP_API_URL +'withdrawal-process',
  GET_WALLET_ADDRESS: APP_API_URL +'goto-address-app',
  GENERATE_NEW_WALLET_ADDRESS: APP_API_URL +'generate-new-address-app',
  ADDRESS_LIST: APP_API_URL +'show-pass-address',
  
  TRANSACTION_HISTORY: APP_API_URL +'transaction-histories',
  
  GET_COIN_SWAP: APP_API_URL + 'coin-swap-app',

  WITHDRAW_COIN_HISTORY:  APP_API_URL +'default-coin-withdrawal-history',
  WITHDRAW_COINS :APP_API_URL + 'withdrawal-coin-check-balance',

  GENERAL_SETTINGS: APP_API_URL + 'general-settings',
  GO_TO_SETTINGS: APP_API_URL + 'goto-setting-page',
  SAVE_LANGUAGE: APP_API_URL + 'save-language',
  GOOGLE_SECRET_SAVE : APP_API_URL + 'google-secret-save',
  TOGGLE_AUTHENTICATION : APP_API_URL + 'google-login-enable-or-disable',

  PROFILE_VIEW:APP_API_URL + 'profile-view',
  PROFILE_EDIT:APP_API_URL + 'profile-edit',
  SAVE_EDITED_PROFILE:APP_API_URL + 'save-edited-profile',
  SIGN_IN_ACTIVITY: APP_API_URL + 'activity-list',
  CHANGE_PASSWORD: APP_API_URL + 'change-password',
  SEND_VERIFICATION_CODE : APP_API_URL + 'phone-verification',
  VERIFY_VERIFICATION_CODE : APP_API_URL + 'send-phone-verification-code',
  ID_VERIFICATION : APP_API_URL + 'id-verification',
  SUBMIT_NID_PHOTO: APP_API_URL + 'submit-nid-photo',
  SUBMIT_PASSPORT_PHOTO: APP_API_URL + 'submit-passport-photo',
  SUBMIT_DL_PHOTO: APP_API_URL + 'submit-driving-license-photo',

  SWAP_COIN_HISTORY: APP_API_URL + 'show-swap-coin-history',
  COIN_SWAP_APP: APP_API_URL + 'coin-swap-app',
  SWAP_COIN_APP: APP_API_URL + 'swap-coin-app',
  FETCH_COIN_RATE: APP_API_URL + 'get-coin-rate',

  COIN_SINGLE_HISTORY: APP_API_URL + 'fetch-coin-history',

  ADD_ADDRESS: APP_API_URL + 'address-book',
  UPDATE_ADDRESS: APP_API_URL + 'address-book',
  GET_ALL_ADDRESS: APP_API_URL + 'address-book',
  GET_SINGLE_ADDRESS: APP_API_URL + 'address-book',
  REMOVE_ADDRESS: APP_API_URL + 'address-book',
  GET_WITHDRAWAL_ADDRESS_BOOK: APP_API_URL + 'get-withdrawl-address-book',
  GET_USER_CATEGORIES: APP_API_URL + 'get-all-address-book-categories',
  GET_COIN_CATEGORIES: APP_API_URL + 'get-coin-type-categories',
  GET_ADDRESS_BOOK_CATEGORIES: APP_API_URL + 'address-book-category',
  ADD_ADDRESS_BOOK_CATEGORY: APP_API_URL + 'address-book-category',
  UPDATE_ADDRESS_BOOK_CATEGORY: APP_API_URL + 'address-book-category',
  REMOVE_ADDRESS_BOOK_CATEGORY: APP_API_URL + 'address-book-category',
  GROUPED_ADDRESS_BOOK_LIST: APP_API_URL + 'address-book-grouped',

  DOWNLOAD_PDF: APP_API_URL + 'download-transactions-pdf',

  OFFERS: APP_API_URL + 'offers',

  FAQ : APP_API_URL +'faq-list',

  ALL_SCHEDULE_TRANSACTIONS : APP_API_URL + 'schedule-transactions',
  REMOVE_TRANSACTIONS : APP_API_URL + 'schedule-transactions',
  CREATE_TRANSACTIONS : APP_API_URL + 'schedule-transactions',
  MODIFY_TRANSACTIONS : APP_API_URL + 'schedule-transactions'
};

