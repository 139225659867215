import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong, faXmarkCircle } from "@fortawesome/free-solid-svg-icons";
import { Formik, useFormik } from "formik";
import * as Yup from 'yup';
import API from "../../../../api/axios";
import { APP_URLS } from "../../../../api/url";
import { toast } from 'react-toastify';
import GoogleAuth from './GoogleAuth.js'

const ini_Values = {}
const validate = {}
let arr = []

const SendIndividualAmount = (props) => {

    const [loading,setLoading] = useState(false)

    const [gauth,setGauth]=useState(false)
    const [popup,setPopup]=useState()
    
    const [dataLoaded,setDataLoaded] = useState(false)
    const [data,setData] = useState()

    const getAddresses =()=>{
        API.get(APP_URLS.GROUPED_ADDRESS_BOOK_LIST+`?length=500&sort_dir=desc&sort_by=id&category_id=${props.groupID}`)
        .then(res => { 
            if (res.data.success === true) {
                setData(res.data.data.addressbook.data)
                for(let i=0 ; i<res.data.data.addressbook.data.length;i++){
                    ini_Values[`amount${i}`] = ''
                    ini_Values[`address${i}`] = res.data.data.addressbook.data[i].address
                    ini_Values[`name${i}`] = res.data.data.addressbook.data[i].full_name
                    individualSendCoinForm.setFieldValue(`address${i}`, res.data.data.addressbook.data[i].address)
                    individualSendCoinForm.setFieldValue(`name${i}`, res.data.data.addressbook.data[i].full_name)
                    individualSendCoinForm.setFieldValue(`amount${i}`, '')
                    validate[`amount${i}`] = Yup.number().required('Amount is Required').positive().min(props.minWithdrawal).max(props.maxWithdrawal)

                }
                setDataLoaded(true)
            } else {
                toast.error(res.data.message, {
                position: toast.POSITION.TOP_RIGHT
                })        
            }
            })
            .catch((error) => {
            const resp = error.response;
            if (resp) {
                let error_message = '';
                if (resp.data.errors !== undefined) {
                    {Object.keys(resp.data.errors).map((error, index) => (
                        error_message = resp.data.errors[error][0]
                    ))}
                } else if (resp.data.data.error !== undefined) {
                    error_message =  resp.data.data.error;
                } else {
                    error_message =  resp.data.error
                }
    
                toast.error(error_message, {
                    position: toast.POSITION.TOP_RIGHT
                })
            }
            }) 
    }

    useEffect(() => {
        getAddresses()
    }, [])

    const individualSendCoinForm = useFormik({
        initialValues: {
            ini_Values
        },
        validationSchema: Yup.object(
            validate
        ),
        onSubmit: (values,actions) => {
            for(let i=0;i<data.length;i++){
                let newArr = {
                    'amount':parseFloat(values[`amount${i}`]),
                    'email':values[`address${i}`]
                }
                arr.push(newArr);
            }
            let respdata ={
                "wallet_id": props.walletID,
                "group_id": parseInt(props.groupID),
                "email": '',
                "amount":'',
                "is_expanded":1,
                "groupped":arr
            }
            let gSecret = JSON.parse(localStorage.getItem('google2fa_secret'))
            if(gSecret===true){
                setGauth(true)
                setPopup(<GoogleAuth setGauth={setGauth} data={respdata} mode={props.mode} actions={actions} rerender={props.rerender} setCoin={props.setCoin} setAmountPopup={props.setAmountPopup} arr={arr}/>)
                individualSendCoinForm.resetForm()
            }
            else{
                toast.error("Please setup your Two-Factor Google Authentication", {
                    position: toast.POSITION.TOP_RIGHT
                  })
            }   
        }
    });

  return (
            <>
                {gauth?popup:''}
                <div>
                    <div className="flex flex-center bg-darkblue bg-opacity-80 w-full fixed z-10 grid h-screen place-items-center top-0 left-0 right-0">
                    <div className="relative w-3/6 z-30 md:left-0 md:w-w50 sm:w-w90 sm:mx-auto">
                        <div className={(props.mode === "light"? "bg-orange text-darkblue border-darktblue"
                            : "bg-bgblue border-lightblue") +
                            " relative block mx-auto border border-8 rounded-2xl text-center px-3 py-5 max-w-xl max-h-hv90 h-auto sm:px-2 sm:py-5"
                        }
                        >
                            <button className="absolute -right-5 -top-5" onClick={()=> props.setAmountPopup(false)}>
                            {" "}
                            <FontAwesomeIcon
                                className="text-elemblue text-3xl bg-white rounded-full"
                                icon={faXmarkCircle}
                            />{" "}
                            </button>
                            <div className='max-h-hv70 h-auto overflow-y-auto scrollbar-hide'> 
                            <h2
                                className={(props.mode === "light" ? "text-darkblue" : "text-white") +
                                " text-lg mb-2 text-2xl mt-5"
                                }>
                                Enter Amount<span className="text-xs"> </span>
                            </h2>
                            <p
                                className={(props.mode === "light" ? "text-darkblue opacity-80": "text-white opacity-50") + " text-xs"
                                }>
                                (Address Count : {data?.length}) 
                            </p>
                            <p
                                className={(props.mode === "light" ? "text-darkblue opacity-80": "text-white opacity-50") + " text-xs"
                                }>
                                Min : {props.minWithdrawal} & Max : {props.maxWithdrawal} 
                            </p>
                            <form onSubmit={individualSendCoinForm.handleSubmit}>
                                <div className={(props.mode==='light'?'text-darkblue':'text-white')+" text-center flex flex-row justify-between sm:hidden my-2 text-sm gap-4 "}> 
                                    <p className="w-w33">Name</p>
                                    <p className="w-w33">Address</p>
                                    <p className="w-w33">Coin Amount</p>
                                </div>
                                {dataLoaded&&(
                                    data.map((item,index)=>{
                                        return <div className="flex flex-row justify-between my-2 gap-2 sm:gap-5 sm:block sm:w-full sm:bg-white sm:bg-opacity-10 sm:p-5 sm:mt-2 sm:rounded-lg" key={index}>                                                  

                                                    <div>
                                                        <label className="text-white text-left opacity-50 text-xs mt-1 mb-1 pl-2 hidden sm:block">Name</label>
                                                        <input
                                                            readOnly
                                                            autoComplete="off"
                                                            onChange={individualSendCoinForm.handleChange}
                                                            onBlur={individualSendCoinForm.handleBlur}
                                                            type="text" name={"name"+index}
                                                            value={individualSendCoinForm.values[`name${index}`]}
                                                            placeholder="Name"
                                                            className={(props.mode === 'light' ? 'bg-white' : 'bg-inputblue1 text-white') + " w-full outline-none h-10 text-small p-1 border-btnblue border rounded-3xl h-h40 px-2 shadow-lg opacity-70 cursor-not-allowed" +  (individualSendCoinForm.touched[`name${index}`] && individualSendCoinForm.errors[`name${index}`] ? 'border-red': '')} />
                                                    </div>
                                                    <div>
                                                    <label className="text-white text-left opacity-50 text-xs mt-3 mb-1 pl-2 hidden sm:block">Address</label>
                                                        
                                                        <input
                                                            readOnly
                                                            autoComplete="off"
                                                            onChange={individualSendCoinForm.handleChange}
                                                            onBlur={individualSendCoinForm.handleBlur}
                                                            type="text" name={"address"+index}
                                                            value={individualSendCoinForm.values[`address${index}`]}
                                                            placeholder="Address"
                                                            className={(props.mode === 'light' ? 'bg-white' : 'bg-inputblue1 text-white') + " w-full outline-none h-10 text-small p-1 border-btnblue border rounded-3xl h-h40 px-2 shadow-lg opacity-70 cursor-not-allowed" +  (individualSendCoinForm.touched[`address${index}`] && individualSendCoinForm.errors[`address${index}`] ? 'border-red': '')} />
                                                    </div>
                                                    <div>
                                                    <label className="text-white text-left opacity-50 text-xs mt-3 mb-1 pl-2 hidden sm:block">Amount</label>
                                                        <input
                                                            autoComplete="off"
                                                            onChange={individualSendCoinForm.handleChange}
                                                            onBlur={individualSendCoinForm.handleBlur}
                                                            type="text" name={"amount"+index}
                                                            value={individualSendCoinForm.values[`amount${index}`]}
                                                            placeholder="Amount"
                                                            className={(props.mode === 'light' ? 'bg-white' : 'bg-inputblue1 text-white') + " w-full outline-none h-10 text-small p-1 border-btnblue border rounded-3xl h-h40 px-2 shadow-lg " +  (individualSendCoinForm.touched[`amount${index}`] && individualSendCoinForm.errors[`amount${index}`] ? 'border-red': '')} />
                                                    </div> 
                                            </div>
                                       
                                    })
                            )}
                                <div className="w-full mt-5">
                                <button type="submit" className={(props.mode==='light'?'bg-darkorange':'bg-lightblue')+' mt-3 rounded-md w-full h-h13 text-white text-center text-xl'} disabled={loading}>
                                    { loading ? 'Sending...' : 'Send' }
                                    <FontAwesomeIcon className="ml-3" icon={faArrowRightLong} />
                                </button>


                                </div>
                            </form>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </>
  ) 
}

export default SendIndividualAmount