import React, { useEffect, useState } from "react";
import API from "../../../api/axios";
import { APP_URLS } from "../../../api/url";
import * as Yup from 'yup';
import { useFormik } from "formik";
import { toast } from 'react-toastify';
import { confirmAlert } from 'react-confirm-alert'; 
import GoogleAuth from "./Popups/GoogleAuth";

const SendCoin = (props) => {
    
    const [loading, setLoading] = useState(false);
    const [userWalletList, setUserWalletList] = useState([]);
    const emailRegExp = /^([a-z0-9\_\-]+)(\.[a-z0-9\+_\-]+)*@([a-z0-9\-]+\.)+[a-z]{2,6}$/;
    const defaultCoin = JSON.parse(localStorage.getItem('default_coin'));

    const [gauth,setGauth]=useState(false)
    const [popup,setPopup]=useState()

    const [dropdownValues,setDropDownValues] = useState([])
    const [search,setSearch] = useState('')
    const [dataupdated,setDataupdated] = useState(false)
    const [walletDataupdated,setWalletDataupdated] = useState(false)

    const [coin,setCoin] = useState()
    const [coinData,setCoindata] = useState()
    const [walletID,setWalletID] = useState()
    const [minWithdrawal,setMinWithdrawal] = useState(1.00000000)
    const [maxWithdrawal,setmaxWithdrawal] = useState(9999999.00000000)


    //API for User Email / Wallet Address
    const getAddressList =()=>{
        API.get(APP_URLS.GET_WITHDRAWAL_ADDRESS_BOOK+`?search=${search}`+(coin!==undefined?`&coin_type=${coin}`:`&coin_type`))
        .then(res => { 
          if (res.data.success === true) {
            setDropDownValues([])
            let items = res.data.data
            for (const item in items) {
                setDropDownValues((oldValues)=>{
                    return[...oldValues,{
                        'name':items[item],
                        'address':item,
                }]
                })
              }  
            } else {
              toast.error(res.data.message, {
                position: toast.POSITION.TOP_RIGHT
              })        
            }
          })
          .catch((error) => {
            const resp = error.response;
            if (resp) {
                let error_message = '';
                if (resp.data.errors !== undefined) {
                    {Object.keys(resp.data.errors).map((error, index) => (
                        error_message = resp.data.errors[error][0]
                    ))}
                } else if (resp.data.data.error !== undefined) {
                    error_message =  resp.data.data.error;
                } else {
                    error_message =  resp.data.error
                }
  
                toast.error(error_message, {
                    position: toast.POSITION.TOP_RIGHT
                })
            }
          }) 
    }

    const updated =() =>{
        setDataupdated(false)
    }

    const walletUpdated =() =>{
        setWalletDataupdated(false)
    }

    //Listing User Email / Wallet Address if search or coin type changes
    useEffect(() => {
        getAddressList()
    }, [search,coin])

    //Listing wallets if searched
    useEffect(() => {
        fetchDefaultWallet();
    }, [coin]);
    
    //API for Primary Wallets
    const fetchDefaultWallet = async () => {
        await API.get(APP_URLS.PERSONAL_WALLET+`?limit=500&`+(coin?`search=${coin}`:`search=`))
          .then((result) => { 
            const resp = result.data.data;
            setUserWalletList(resp.data);
          })
          .catch((error) => console.log({error}))
    }


    //Setting coin value if Wallet is selected
    const setCoinVal =(coin)=>{
        let flag=false
        for(let i=0;i<userWalletList.length;i++){
            if(coin==userWalletList[i].coin_type){
                setCoin (userWalletList[i].coin_type)
                setCoindata(userWalletList[i])
                setWalletID(userWalletList[i].id)
                setMinWithdrawal(userWalletList[i].minimum_withdrawal)
                setmaxWithdrawal(userWalletList[i].maximum_withdrawal)
                flag = true
                break;
            }
        }
        if(flag===false){{
            setCoin(coin)
            setWalletID('')
        }}
    }


    const formik = useFormik({
        initialValues: {
            wallet_id: '',
            amount: '',
            email: '',
        },
        validationSchema: Yup.object({
            wallet_id: Yup.string().required('Wallet is Required'),
            amount: Yup.number().required('Amount is Required').positive().min(minWithdrawal, `Minimum amount should be greater than ${minWithdrawal}`).max(maxWithdrawal, `Maximum amount should be less than ${maxWithdrawal}`),
            email: Yup.string().required('Address is Required'),
        }),
        onSubmit: (values, actions) => {
            let data ={
                "wallet_id": walletID,
                "amount": values.amount,
                "email": values.email,
            }
            let gSecret = JSON.parse(localStorage.getItem('google2fa_secret'))
            if(gSecret===true){
                setGauth(true)
                setPopup(<GoogleAuth setGauth={setGauth} data={data} mode={props.mode} actions={actions} rerender={rerender} setCoin={setCoin}/>)
                setCoin('')

            }
            else{
                toast.error("Please setup your Two-Factor Google Authentication", {
                    position: toast.POSITION.TOP_RIGHT
                  })
            }            
        },
    });

    const rerender = () => {
        props.rerender()
    }

    return (
        <>
            {gauth?popup:''}
            <div className={(props.mode == 'light' ? 'bg-orange' : 'bg-elemblue2') + ' w-full flex-col w-full px-4 py-10 mb-10 rounded-lg rounded-tl-none xs:rounded-tr-none'}>
                <div className='flex flex-col mb-5'>
                    <p className={(props.mode === 'light' ? "text-darkblue" : "text-white") + " text-lg mb-1"}>Send Coins</p>
                    <p className={(props.mode === 'light' ? "text-lightblue" : "text-btnblue") + " text-sm mb-2"}>Note: Send coins to other users wallets by email or crypto wallet address. You can add your contacts in the address book menu, and then select a pre-saved address from the address field when sending</p>
                </div>
                <form onSubmit={formik.handleSubmit} className="">
                    <div className='w-full flex flex-row gap-5 lg:block gap-3'>
                        <div className='w-w33 lg:w-w100 inline-block'>
                            <div className='flex flex-row items-center my-2'><p className={(props.mode === 'light' ? 'text-darkblue' : 'text-white') + ' text-small ml-2 xl:text-xs '}>Select Your Crypto Coins Wallet</p>
                            <p className='text-small text-red'>*</p>
                            </div>
                            <div className="relative">
                                <input
                                    type="text"
                                    name="wallet_id"
                                    onChange={(e)=>{
                                        formik.handleChange(e)
                                        setCoinVal(e.target.value)
                                    }}
                                    onBlur={(e)=>{
                                        formik.handleBlur(e)
                                        setTimeout(walletUpdated, 200);
                                    }}
                                    onFocus={()=>setWalletDataupdated(true)}
                                    value={formik.values.wallet_id}
                                    placeholder="Enter Wallet"
                                    autoComplete='off'
                                    className={(props.mode === 'light' ? 'bg-white' : 'bg-inputblue1 text-white') + " w-full outline-none h-10 text-small p-1 border-btnblue border rounded-3xl h-h45 px-4 shadow-lg " +  (formik.touched.wallet_id && formik.errors.wallet_id ? 'border-red': '')}></input>
                                    {walletDataupdated&&(
                                    <ul className={(props.mode === 'light' ? 'text-darkblue bg-white' : 'text-white bg-inputblue1') + ' absolute text-left left-[5%] border border-lightblue border-opacity-50 border-t-0 w-w90 text-xs mx-auto shadow-lg max-h-28 overflow-y-auto scrollbar-hide'} >
                                    {userWalletList && userWalletList.map((item, index) => {
                                        return <li className={(props.mode === 'light' ? 'border-b-darkblue' : 'border-b-white') + ' hover:bg-darkblue hover:bg-opacity-20 cursor-pointer border-b border-opacity-10 py-2 px-3'} key={index} onClick={()=>{
                                            formik.setFieldValue('wallet_id',item.name)
                                            setCoinVal(item.coin_type)
                                            }}>
                                            {item.name}
                                            </li>
                                    })} 
                                    </ul>
                                    )} 
                            </div>
                            {formik.touched.wallet_id && formik.errors.wallet_id ? (
                                    <div className='text-left text-red text-xs pt-2 pl-2'>{formik.errors.wallet_id}</div>
                                ) : null}
                        </div>
                        <div className='w-w33 lg:w-w100 inline-block md:mt-2'>
                            <div className='flex flex-row items-center my-2'>
                                <p className={(props.mode === 'light' ? 'text-darkblue' : 'text-white') + ' text-small ml-2 xl:text-xs'}>Coin Amount</p>
                                <p className='text-small text-red'>*</p>
                                <p className='text-xxsmall text-greylight ml-2'> (Min Amount : {coinData?coinData.minimum_withdrawal:' 0.00000010'} {coinData?coinData.coin_type:null})</p>
                            </div>
                            <div>
                                <input
                                    autoComplete="off"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    type="text" name="amount"
                                    value={formik.values.amount}
                                    placeholder="Enter Amount"
                                    className={(props.mode === 'light' ? 'bg-white' : 'bg-inputblue1 text-white') + " w-full outline-none h-10 text-small p-1 border-btnblue border rounded-3xl h-h45 px-4 shadow-lg " +  (formik.touched.amount && formik.errors.amount ? 'border-red': '')} />
                                
                                <p className='text-xxsmall text-greylight mr-2 mt-1 text-right'> (Max Amount : {coinData?coinData.maximum_withdrawal:'99999999.00000000'} {coinData?coinData.coin_type:null}) 
                                </p>
                            </div>
                        </div>
                        <div className='w-w33 lg:w-w100 inline-block '>
                            <div className='flex flex-row items-center my-2'>
                                <p className={(props.mode === 'light' ? 'text-darkblue' : 'text-white') + ' text-small ml-2 xl:text-xs'}>User Email / Wallet Address</p>
                                <p className='text-small text-red'>*</p>
                            </div>
                            <div className="relative">
                                <input
                                    type="text"
                                    name="email"
                                    onChange={(e)=>{
                                        formik.handleChange(e)
                                        setSearch(e.target.value)
                                    }}
                                    onBlur={(e)=>{
                                        formik.handleBlur(e)
                                        setTimeout(updated, 200);
                                    }}
                                    onFocus={()=>setDataupdated(true)}
                                    value={formik.values.email}
                                    placeholder="Enter Address"
                                    autoComplete='off'
                                    className={(props.mode === 'light' ? 'bg-white' : 'bg-inputblue1 text-white') + " w-full outline-none h-10 text-small p-1 border-btnblue border rounded-3xl h-h45 px-4 shadow-lg " +  (formik.touched.email && formik.errors.email ? 'border-red': '')}></input>
                                    {dataupdated&&(
                                    <ul className={(props.mode === 'light' ? 'text-darkblue bg-white' : 'text-white bg-inputblue1') + ' absolute text-left left-[5%] border border-lightblue border-opacity-50 border-t-0 w-w90 text-xs mx-auto shadow-lg max-h-28 overflow-y-auto scrollbar-hide'} >
                                        {dropdownValues.map((item,index)=>{
                                            return <li className={(props.mode === 'light' ? 'border-b-darkblue' : 'border-b-white') + ' hover:bg-darkblue hover:bg-opacity-20 cursor-pointer border-b border-opacity-10 py-2 px-3'} key={index} onClick={()=>{
                                                formik.setFieldValue('email',item.address)
                                                }}>
                                                {item.name} : {item.address}
                                                </li>
                                        }
                                        )}
                                    </ul>
                                    )} 
                            </div>
                            <p className='text-xxsmall text-greylight mr-2 mt-1 text-right'>Input a users email/wallet address or select from your pre-saved addresses in address box. Automatic Alphabetical letter selection process is enabled for fast searching.</p>
                        </div>
                    </div>
                    <div className='w-w33 xl:w-w50 '>
                        <button className={(props.mode==='light'?'text-white bg-darkorange':'text-white bg-gradient-to-r from-purple to-pink ')+' mt-3 rounded-3xl w-wp160 h-h13'} type="submit" disabled={loading}>{ loading ? 'Sending...' : 'Send Coins' } </button>
                    </div>
                </form>
            </div>
        </>
    );
};

export default SendCoin;

