import React, { useState } from "react";
import API from "../../../api/axios";
import { APP_URLS } from "../../../api/url";
import * as Yup from 'yup';
import { useFormik } from "formik";
import { toast } from 'react-toastify';

const RequestCoin = (props) => {

    const [loading, setLoading] = useState(false);
    const emailRegExp = /^([a-z0-9\_\-]+)(\.[a-z0-9\+_\-]+)*@([a-z0-9\-]+\.)+[a-z]{2,6}$/;
    const defaultCoin = JSON.parse(localStorage.getItem('default_coin'));

    const formik = useFormik({
        initialValues: {
            amount: '',
            email: '',
        },
        validationSchema: Yup.object({
            amount: Yup.number().required('Amount is Required').positive().min(defaultCoin.minimum_withdrawal, `Minimum amount should be greater than ${defaultCoin.minimum_withdrawal}`).max(defaultCoin.maximum_withdrawal, `Maximum amount should be less than ${defaultCoin.maximum_withdrawal}`),
            email: Yup.string().required('Email is Required').matches(emailRegExp, 'The "Email" field format is invalid'),
        }),
        onSubmit: (values, actions) => {
            if (!loading) {
                setLoading(true)
                API.post(APP_URLS.RECEIVE_COIN, values)
                    .then(res => {
                        const respMessage = res.data.message;
                        if (res.data.success === true) {
                            toast.success(respMessage, {
                                position: toast.POSITION.TOP_RIGHT
                            })
                        } else {
                            toast.error(respMessage, {
                                position: toast.POSITION.TOP_RIGHT
                            })
                        }
                        rerender()
                        actions.resetForm();
                        setLoading(false)
                    })
                    .catch(function (error) {
                        const resp = error.response;
                        setLoading(false)
                        if (resp) {

                            let error_message = '';
                            if (resp.data.errors !== undefined) {
                                {
                                    Object.keys(resp.data.errors).map((error, index) => (
                                        error_message = resp.data.errors[error][0]
                                    ))
                                }
                            } else if (resp.data.data.error !== undefined) {
                                error_message = resp.data.data.error;
                            } else {
                                error_message = resp.data.error
                            }

                            toast.error(error_message, {
                                position: toast.POSITION.TOP_RIGHT
                            })
                        }
                    });
            }
        },
    });

    const rerender = () => {
        props.rerender()
    }

    return (
        <>
            <div className={(props.mode == 'light' ? 'bg-orange' : 'bg-elemblue2') + ' w-full flex-col w-full px-4 py-10 mb-10 rounded-lg rounded-tl-none xs:rounded-tr-none'}>
                <div className='flex flex-col mb-5'>
                    <p className={(props.mode === 'light' ? "text-darkblue" : "text-white") + " text-lg mb-1"}>Send HDL Coins Request To User Using Email Address</p>
                    <p className={(props.mode === 'light' ? "text-lightblue" : "text-btnblue") + " text-sm mb-2"}>Note: Send HDL Coin Request To User Using Email Address</p>
                </div>
                <form onSubmit={formik.handleSubmit} className="">
                    <div className='w-full flex flex-row gap-5 lg:block gap-3'>
                        <div className='w-w50 lg:w-w100 inline-block '>
                            <div className='flex flex-row items-center my-2'>
                                <p className={(props.mode === 'light' ? 'text-darkblue' : 'text-white') + ' text-small ml-2 xl:text-xs'}>User Email</p>
                                <p className='text-small text-red'>*</p>
                            </div>
                            <div>
                                <input
                                    type="email"
                                    name="email"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.email}
                                    placeholder="Email Address"
                                    autoComplete='off'
                                    className={(props.mode === 'light' ? 'bg-white' : 'bg-inputblue1 text-white') + " w-full outline-none h-10 text-small p-1 border-btnblue border rounded-3xl h-h45 px-4 shadow-lg " +  (formik.touched.email && formik.errors.email ? 'border-red': '')}></input>
                                    <p className='text-xxsmall text-greylight mr-2 mt-1 text-right'> Input user email where you want to send request for coins </p>
                            </div>
                        </div>
                        <div className='w-w50 lg:w-w100 inline-block '>
                            <div className='flex flex-row items-center my-2 sm:mt-2'>
                                <p className={(props.mode === 'light' ? 'text-darkblue' : 'text-white') + ' text-small ml-2 xl:text-xs'}>Coin Amount</p>
                                <p className='text-small text-red'>*</p>
                                <p className='text-xxsmall text-greylight ml-2'> (Min Amount : {defaultCoin.minimum_withdrawal} {defaultCoin.name})</p>
                            </div>
                            <div>
                                <input
                                    autoComplete="off"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    type="text" name="amount"
                                    value={formik.values.amount}
                                    placeholder="Enter Amount"
                                    className={(props.mode === 'light' ? 'bg-white' : 'bg-inputblue1 text-white') + " w-full outline-none h-10 text-small p-1 border-btnblue border rounded-3xl h-h45 px-4 shadow-lg " +  (formik.touched.amount && formik.errors.amount ? 'border-red': '')} />
                                
                                <p className='text-xxsmall text-greylight mr-2 mt-1 text-right'> (Max Amount : {defaultCoin.maximum_withdrawal} {defaultCoin.name}) 
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='w-auto'>
                        <button className={(props.mode==='light'?'text-white bg-darkorange':' bg-gradient-to-r from-purple to-pink text-white')+' mt-3 rounded-3xl h-h13 px-5 text-white'} type="submit" disabled={loading}>{ loading ? 'Sending...' : 'Send Coins Request' } </button>
                    </div>
                </form>
            </div>
        </>
    );
};

export default RequestCoin;

