import React, { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlusCircle, faUserAlt, faFileLines } from "@fortawesome/free-solid-svg-icons";
import { Theme } from "../../../App";

const TopNavAndTabs = (props) => {
    const [show, setShow] = useState(props.displayTab);
    const mode = useContext(Theme);

    function selectTab(tab) {
        setShow(tab);
        props.onChangeTab(tab);
    }

    function hadleDisplayPop(popup) {
        props.onClickPopupButton(popup)
    }

    return (
        <>
            <div className='flex flex-row justify-between w-full items-center sm:block mt-14 sm:mt-7'>
                <h1 className={(mode == "light" ? 'text-darkblue' : 'text-white') + ' text-medium sm:mb-2'}>My Wallet</h1>
                <div className='xs:mt-2'>
                    <button className={(mode === 'light' ? 'text-darkblue' : 'text-white') + ' xs:mb-2'} onClick={() => { hadleDisplayPop('add-wallet') }}> <FontAwesomeIcon icon={faPlusCircle} className={(mode === 'light' ? 'text-darkblue' : 'text-elemblue align-middle') + " text-3xl mr-2 align-middle sm:md-2"} />Add Wallet</button> <span className='text-white opacity-30 mx-5 text-lg xs:hidden'>|</span>
                    <button  onClick={() => { hadleDisplayPop('import-multi-signature-wallet') }} className={(mode === 'light' ? 'text-darkblue' : 'text-white')}> <FontAwesomeIcon icon={faPlusCircle} className={(mode === 'light' ? 'text-darkblue' : 'text-darkpink align-middle') + " text-3xl mr-2 align-middle"} />Import Multi-Signature Wallet</button>
                </div>
            </div>
            <div>
                <div className={(mode === 'light' ? ' border-darkblue' : ' border-white') + ' flex flex-row justify-start mt-8 w-full items-center border-b-2 border-opacity-20 mb-7'}>
                    <button className={`text-md pb-3 text-center w-w25 mb-mb2 md:w-w50 xs:pl-1 xs:text-xs  border-b-2
                                ${show === 'personal-wallet' 
                                    ? (mode === 'light' ? 'border-darkblue text-darkblue' : 'border-lightblue text-lightblue') 
                                    : (mode === 'light' ? 'border-trans text-darkblue' : 'border-darkblue text-white') + ' border-b-0  border-transprent'}`}
                        onClick={() => { selectTab('personal-wallet') }}
                    >
                        <FontAwesomeIcon
                            icon={faUserAlt}
                            className={"text-xl mr-2 xs:mr-0 xs:hidden"} />
                        <span className={"text-xl mr-2 xl:text-sm md:text-sm xs:mr-0 xs:text-xs"}> Personal Wallet</span>
                    </button>

                    <button  className={`text-md pb-3 text-center w-w25 mb-mb2 md:w-w50 xs:pl-1 xs:text-xs  border-b-2
                                ${show === 'multi-signature-wallet' 
                                    ? (mode === 'light' ? 'border-darkblue text-darkblue' : 'border-lightblue text-lightblue') 
                                    : (mode === 'light' ? 'border-trans text-darkblue' : 'border-darkblue text-white') + ' border-b-0  border-transprent'}`}
                        onClick={() => { selectTab('multi-signature-wallet') }}
                    >
                        <FontAwesomeIcon icon={faFileLines} className={" text-xl mr-2 xs:mr-0 xs:hidden"} />
                        <span className={" text-xl mr-2 xl:text-sm md:text-sm xs:mr-0 xs:text-xs"}> Multi-Signature Wallet</span>
                    </button>
                </div>
            </div>
        </>
    );
};

export default TopNavAndTabs;