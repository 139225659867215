import { faCirclePlus} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState, useEffect, useContext } from 'react'
import AddressPopUp from './Popup/AddressPopUp';
import AddressHistory from './AddressHistory';
import AddressGroupsList from './AddressGroupsList ';
import AddCategory from './Popup/AddCategory';




const Book = (props) => {

    //Popups
    const [popup,setPopup] = useState(false)
    const [categorypopup,setCategoryPopup] = useState(false)

    //Display tabs
    const [show, setShow] = useState('addresses')
    
    //Record added
    const [dataAdded,setDataAdded] = useState()



    return (
    <>
        {popup===true?<AddressPopUp setPopup={setPopup} setDataAdded={setDataAdded} mode={props.mode}/>:null}
        {categorypopup===true?<AddCategory setCategoryPopup={setCategoryPopup} setDataAdded={setDataAdded} mode={props.mode}/>:null}
        <div className="flex flex-row w-w100 justify-between mt-14 md:mt-20 md:mt-20 sm:mt-7 mb-5 xs:block">
            <div className="flex flex-row items-center">
                <h2 className={(props.mode==='light'?'text-darkblue':'text-white')+' block text-left w-w100 text-medium mb-2 sm:mt-0'}>
                Address Book
                </h2>
            </div>
            <div className="w-auto flex flex-row justify-between mb-1 sm:mt-0">
            <button
                type="button" onClick={()=>setCategoryPopup(true)}
                className={(props.mode === "light"? "rounded-3xl w-auto px-3 mr-3 bg-darkblue text-white outline-none text-small": "bg-gradient-to-r from-purple to-pink text-white rounded-3xl w-auto px-3 mr-3 outline-none text-small")+" xxs:text-small xs:py-1"}>
                <FontAwesomeIcon icon={faCirclePlus} className={" text-white pr-2"} />
                Add Group
                </button>
                <button
                type="button" onClick={()=>setPopup(true)}
                className={(props.mode === "light"? "rounded-3xl w-auto px-3 bg-darkblue text-white outline-none text-small": "bg-gradient-to-r from-purple to-pink text-white rounded-3xl w-auto px-3 outline-none text-small")+" xxs:text-small xs:py-1"}>
                <FontAwesomeIcon icon={faCirclePlus} className={" text-white pr-2"} />
                Add Address
                </button> 
            </div>
        </div>

        <div>
            <div className={(props.mode === 'light' ? ' border-darkblue' : ' border-white') + ' flex flex-row justify-start mt-8 w-full items-center border-b-2 border-opacity-20 mb-7'}>
                <button className={`text-md pb-3 text-center w-w20 mb-mb2 md:w-w50 xs:pl-1 xs:text-xs  border-b-2
                            ${show === 'addresses' 
                                ? (props.mode === 'light' ? 'border-darkblue text-darkblue' : 'border-lightblue text-lightblue') 
                                : (props.mode === 'light' ? 'border-trans text-darkblue' : 'border-darkblue text-white') + ' border-b-0  border-transprent'}`}
                    onClick={() => { setShow('addresses') }}
                >
                    <span className={"text-xl mr-2 xl:text-sm md:text-sm xs:mr-0 xs:text-sm"}> Addresses </span>
                </button>

                <button  className={`text-md pb-3 text-center w-w20 mb-mb2 md:w-w50 xs:pl-1 xs:text-xs  border-b-2
                            ${show === 'groups' 
                                ? (props.mode === 'light' ? 'border-darkblue text-darkblue' : 'border-lightblue text-lightblue') 
                                : (props.mode === 'light' ? 'border-trans text-darkblue' : 'border-darkblue text-white') + ' border-b-0  border-transprent'}`}
                                onClick={() => { setShow('groups') }}
                >
                    <span className={" text-xl mr-2 xl:text-sm md:text-sm xs:mr-0 xs:text-sm"}> Groups</span>
                </button>
            </div>
            </div>

        {show === 'addresses'?
        <AddressHistory mode={props.mode} dataAdded={dataAdded} setDataAdded={setDataAdded}/>:
        <AddressGroupsList mode={props.mode} dataAdded={dataAdded} setDataAdded={setDataAdded}/>
        }
        
  </>
  )
}

export default Book