import React,{useState,useEffect} from "react";
import logo from "../components/Images/iHodl-3.png";
import { Link, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik } from "formik";
import { APP_URLS } from "../api/url";
import { toast, ToastContainer } from 'react-toastify';
import API from "../api/axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const ResetPassword = () => {
  const navigate = useNavigate()
  var url = new URL(document.URL);
  const params = url.searchParams; 
  const reset_email = params.get('reset_password_email');

  const [showPassword,setShowPassword] = useState(false)
  const [showConfirmPassword,setConfirmShowPassword] = useState(false)

  const handlePassword = () =>{
    var temp = document.getElementById("password");
    if(showPassword){
      setShowPassword(false)
      temp.type = "password"
    }
    else{
      setShowPassword(true)
      temp.type = "text"
    }
  }

  const handleConfirmPassword = () =>{
    var temp = document.getElementById("ConfirmPassword");
    if(showConfirmPassword){
      setConfirmShowPassword(false)
      temp.type = "password"
    }
    else{
      setConfirmShowPassword(true)
      temp.type = "text"
    }
  }

  const [loading, setLoading] = useState(false);
  const numberRegExp = /^\d+$/;
  const passwordRegExp = /^((?=.*\d)(?=.*[A-Z])(?=.*[a-z]).{8,})$/;

  useEffect(() => {
    const resetPasswordSuccessMessage = localStorage.getItem('reset_password_message');

    if(reset_email === '' || reset_email === null) {
      navigate('/login')
    }

    if (resetPasswordSuccessMessage !== null && resetPasswordSuccessMessage !== undefined && resetPasswordSuccessMessage !== '') {
      toast.success(resetPasswordSuccessMessage, {
        position: toast.POSITION.TOP_RIGHT
      })
      localStorage.removeItem('reset_password_message');
    }
  }, [navigate, reset_email]);

  const formik = useFormik({
    initialValues: {
      code: '',
      email: reset_email,
      password: '',
      password_confirmation: ''
    },
    validationSchema: Yup.object({
        code: Yup.string().matches(numberRegExp, 'Only Digits allowed').required('Verification Code is Required').max(6, 'The "Verification Code" field may not be greater than 6 digits'),
        password: Yup.string().required('Password is Required').matches(passwordRegExp, 'Password must be consist of one uppercase, one lowercase, one number and minimum length at least 8 characters.'),
        password_confirmation: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match'),
    }),
    onSubmit: values => {
        if(!loading) {
            setLoading(true)
            API.post(APP_URLS.RESET_PASSWORD, values)
            .then(res => {
                const resp = res.data;
                setLoading(false)
                if (resp.success === false) {
                  toast.error(resp.message, {
                    position: toast.POSITION.TOP_RIGHT
                  })
                } else {
                  toast.success(resp.message, {
                    position: toast.POSITION.TOP_RIGHT
                  })
  
                  localStorage.setItem('email_verified_message', resp.message);
                  navigate('/login');
                }

            })
            .catch(function (error) {  
                const resp = error.response;
                setLoading(false)
                if (resp) {                  
                    let error_message = '';
                    if (resp.data.errors !== undefined) {
                      {
                        Object.keys(resp.data.errors).map((error) => {
                          return error_message = resp.data.errors[error][0]
                        })
                      }
                    } else if (resp.data.data.error !== undefined) {
                        error_message =  resp.data.data.error;
                    } else {
                        error_message =  resp.data.error
                    }

                    toast.error(error_message, {
                        position: toast.POSITION.TOP_RIGHT
                    })
                }
            });         
        }
    },
  });

  return (
    <>
      <ToastContainer />
      <div className="w-full flex w-full bg-bgblue px-4 py-10 min-h-screen">
          <div className="shadow-lg self-center w-full max-w-lg m-auto bg-elemblue2 rounded p-7 border border-btnblue">   
            <header className="text-center">
            <img src={logo} alt="Logo" className="w-2/6 flex mx-auto" />
            <h1 className="text-center text-white text-bold text-lg mb-2">Reset Password</h1>
            </header>   
            <form onSubmit={formik.handleSubmit} className="">
              <div>
                <label className="block mb-1 text-white" htmlFor="code">Enter Code</label>
                <input 
                    className={"w-full p-2 text-darkblue border-b-2 border-indigo-500 outline-none bg-bluelight text-white rounded-3xl h-h50 px-4" + (formik.touched.code && formik.errors.code ? ' is-invalid' : '')} 
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    type="text" name="code"
                    value={formik.values.code}
                    placeholder="Enter Code" />
                    {formik.touched.code && formik.errors.code ? (
                          <div className='text-left text-red text-xs pt-2'>{formik.errors.code}</div>
                      ) : null}
              </div>       
              <div>
                <label className="block mb-1 mt-6 text-white" htmlFor="password">Password</label>
                <div className={"flex w-full p-2  text-darkblue border-b-2 border-indigo-500 outline-none bg-bluelight text-white rounded-3xl h-h50 px-4" + (formik.touched.password && formik.errors.password ? ' is-invalid' : '')}>
                <input type="password" name="password"  onChange={formik.handleChange} onBlur={formik.handleBlur}
                      className="w-w94 h-full focus:outline-0 bg-bluelight"
                      id="password"
                      value={formik.values.password} 
                      placeholder="Password"
                      autoComplete='off' />
                      <FontAwesomeIcon icon={showPassword?faEye:faEyeSlash} className={"text-small self-center cursor-pointer"} onClick={handlePassword}/>
                      </div>
                {formik.touched.password && formik.errors.password ? (
                      <div className='text-left text-red text-xs pt-2'>{formik.errors.password}</div>
                  ) : null}
              </div>
              <div>
                <label className="block mb-1 mt-6 text-white" htmlFor="password_confirmation">Password Confirmation</label>
                <div className={"flex w-full p-2 text-darkblue border-b-2 border-indigo-500 outline-none bg-bluelight text-white rounded-3xl h-h50 px-4" + (formik.touched.password && formik.errors.password ? ' is-invalid' : '')}>
                <input type="password" name="password_confirmation"  onChange={formik.handleChange} onBlur={formik.handleBlur}
                      id="ConfirmPassword"
                      value={formik.values.password_confirmation} 
                      className="w-w94 h-full focus:outline-0 bg-bluelight"
                      placeholder="Password Confirmation"
                      autoComplete='off' />
                      <FontAwesomeIcon icon={showConfirmPassword?faEye:faEyeSlash} className={"text-small self-center cursor-pointer"} onClick={handleConfirmPassword}/>
                      </div>
                  {formik.touched.password_confirmation && formik.errors.password_confirmation ? (
                        <div className='text-left text-red text-xs pt-2'>{formik.errors.password_confirmation}</div>
                    ) : null}
              </div>
              <div>          
                <button className="w-full mt-9 bg-btnblue hover:bg-pink-700 font-bold py-2 px-4 rounded-3xl h-h50 text-white" type="submit" disabled={loading}>{ loading ? 'Loading...' : 'Reset Password' }</button>
              </div>
            </form>  
            <footer className="float-right mt-5 text-white">
              Already have an account? <Link className="text-btnblue text-lg" to="/login">Login Here</Link>
            </footer>   
          </div>
        </div>
    </>
  )
}

export default ResetPassword