import React,{useState,useEffect} from "react";
import logo from "../components/Images/iHodl-3.png";
import { Link, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import * as Yup from 'yup';
import { useFormik } from "formik";
import { APP_URLS } from "../api/url";
import API from "../api/axios";

const ForgotPassword = () => {
  const navigate = useNavigate()
  const emailRegExp = /^([a-z0-9\_\-]+)(\.[a-z0-9\+_\-]+)*@([a-z0-9\-]+\.)+[a-z]{2,6}$/;
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const token = localStorage.getItem('token');
    
    if(token != '' && token !== null) {
      navigate('/dashboard')
    }
  }, []);

  const formik = useFormik({
    initialValues: {
        email: '',
        is_new: true
    },
    validationSchema: Yup.object({
        email: Yup.string().matches(emailRegExp, 'The "Email" field format is invalid').required('Email is Required'),
    }),
    onSubmit: (values) => {
        if(!loading) {
            setLoading(true)
            API.post(APP_URLS.FORGOT_PASSWORD, values)
            .then(res => {
                const resp = res.data;
                setLoading(false)

                if (resp.success === false && resp.email_verified === 0) {
                  localStorage.setItem('reset_password_error_message', resp.message)
                  navigate('/account-verification?verification_email='+values.email)
                } if (resp.success === false) {
                  toast.error(resp.message, {
                    position: toast.POSITION.TOP_RIGHT
                  })
                } else if (resp.success === true) {
                  toast.success(resp.message, {
                    position: toast.POSITION.TOP_RIGHT
                  })
                  localStorage.setItem('reset_password_message', resp.message);
                  navigate('/reset-password?reset_password_email='+values.email);
                }
            })
            .catch(function (error) {  
                const resp = error.response;
                setLoading(false)
                if (resp) {
                  
                    let error_message = '';
                    if (resp.data.errors !== undefined) {
                        {Object.keys(resp.data.errors).map((error, index) => (
                            error_message = resp.data.errors[error][0]
                        ))}
                    } else if (resp.data.data.error !== undefined) {
                        error_message =  resp.data.data.error;
                    } else {
                        error_message =  resp.data.error
                    }

                    toast.error(error_message, {
                        position: toast.POSITION.TOP_RIGHT
                    })
                }
            });         
        }
    },
  });

  return (
    <>
      <ToastContainer />
      <div className="w-full flex w-full bg-bgblue px-4 py-10 min-h-screen">
          <div className="shadow-lg self-center w-full max-w-md m-auto bg-elemblue2 rounded p-7 border border-btnblue">   
            <header className="text-center">
            <img src={logo} className="w-2/6 flex mx-auto" />
            <h2 className="text-center text-white text-basic mb-3">Forgot Your Password?</h2>
           <p className="text-lightblue text-xs mb-5">Enter your email address below to reset password.</p>
            </header>   
            <form onSubmit={formik.handleSubmit} className="">
            <div className="col-span-2">
                <input 
                  type="text" 
                   name="email" 
                  className={"w-full p-2 text-darkblue border-b-2 border-indigo-500 outline-none focus:bg-gray-300 bg-white text-white rounded-3xl h-h50 px-4" + (formik.touched.email && formik.errors.email ? ' is-invalid' : '')}   
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  placeholder="Email Address"
                  autoComplete='off' />

                  {formik.touched.email && formik.errors.email ? (
                        <div className='text-left text-red text-xs pl-2 mt-1'>{formik.errors.email}</div>
                    ) : null} 
              </div>              
              <div>          
                <button className="w-full mt-5 bg-btnblue hover:bg-pink-700 font-bold py-2 px-4 rounded-3xl h-h50 text-white" type="submit" disabled={loading}>{ loading ? 'Sending...' : 'Continue' }</button>
              </div>  
            </form>  
            <footer className="float-right mt-5 text-white">
              <Link className="text-btnblue text-lg" to="/login">Login</Link>
            </footer>
          </div>
        </div>
    </>
  )
}

export default ForgotPassword