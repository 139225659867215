import React, { useState, useEffect, useContext } from "react";
import {Theme} from "../../../App"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmarkCircle} from "@fortawesome/free-solid-svg-icons";
import { APP_URLS } from "../../../api/url";
import API from "../../../api/axios";
import { toast, ToastContainer } from "react-toastify";
import {SetNotificationCount} from '../../../App'

const Popup = (props) =>{

    const setNotification = useContext(SetNotificationCount);

    const mode = useContext(Theme);
    const [data,setData] =useState()
    const [dataLoaded,setDataLoaded] =useState(false)

    const showNotifications =()=>{
      API.get(APP_URLS.SHOW_NOTIFICATION+`?id=${props.id}`)
      .then(res => { 
          if (res.data) {
            props.setRead(true)
            setData(res.data.data)
            setDataLoaded(true)
            setNotification(res.data.data.notifications_count)
            localStorage.setItem('notification',res.data.data.notifications_count)
          }
        })
        .catch((error) => {
          const resp = error.response;
          if (resp) {
            
              let error_message = '';
              if (resp.data.errors !== undefined) {
                  {Object.keys(resp.data.errors).map((error, index) => (
                      error_message = resp.data.errors[error][0]
                  ))}
              } else if (resp.data.data.error !== undefined) {
                  error_message =  resp.data.data.error;
              } else {
                  error_message =  resp.data.error
              }
  
              toast.error(error_message, {
                  position: toast.POSITION.TOP_RIGHT
              })
          }
        })  
    }
  
    useEffect(() => {
      showNotifications()
    }, [])
    

    return(
      <div className={(mode==='light'?'bg-darkblue':'bg-darkblue')+" flex flex-center bg-opacity-80 w-full h-screen fixed z-20 grid place-items-center h-screen top-0 left-0"}>
        <div className="relative max-h-hv90 max-w-md z-30 right-0 md:left-0 md:w-w50 sm:w-w90">
          <div
              className={
                (props.mode === "light"
                  ? "bg-orange text-darkblue border-darktblue"
                  : "bg-bgblue border-lightblue") +
                " relative block mx-auto border border-8 rounded-2xl text-center px-8 py-10 w-full sm:p-2"
              }
            >
            <div className='max-h-hv70 h-auto overflow-y-auto scrollbar-hide'>
              <button
                className="absolute -right-5 -top-5"
                onClick={() => props.setOpen('')}
              >
                {" "}
                <FontAwesomeIcon
                  className="text-elemblue text-3xl bg-white rounded-full"
                  icon={faXmarkCircle}
                />{" "}
              </button>
                {dataLoaded&&(
                    <>
                  <p className={(mode==='light'?'text-darkblue':'text-white')+' mr-4 py-3 px-5'}>{data.title}</p>
                  <p className={(props.mode==='light'?'text-darkblue':'text-white')+' mr-4 py-3 px-5 text-xs'}>{data.date}</p>
                  <p className={(props.mode==='light'?'text-darkblue':'text-white')+' mr-4 py-3 px-5 text-xs'}>{data.notice}</p>
                  </>
                  )}
            </div>
          </div>
        </div>
      </div>
    )
  }

  export default Popup