import React, { useState, useContext } from "react";
import { MainLayout } from '../../components/Layouts/MainLayout';
import { Theme } from "../../App";
import PersonalWallet from "./Components/PersonalWallet";
import TopNavAndTabs from "./Components/TopNavAndTabs";
import MultiSignatureWallet from "./Components/MultiSignatureWallet";
import AddWalletPopUp from "./Components/Popup/AddWalletPopUp";
import ImportMultiSignatureWalletPopup from "./Components/Popup/ImportMultiSignatureWalletPopup";
import WithdrawlPopUp from "./Components/Popup/WithdrawlPopUp";
import ActivityLog from "./Components/ActivityLog";
import Deposit from "./Components/Deposit";
import CoUser from "./Components/CoUser";

const WalletPage = (props) => {
    const mode = useContext(Theme);

    const [displayTab, setDisplayTab] = useState('personal-wallet');
    const [popup, setDisplayPopup] = useState('');
    const [displayActivity, setDisplayActivity] = useState(false);
    const [displayDeposit, setDisplayDeposit] = useState(false);
    const [displayCoUser, setDisplayCoUser] = useState(false);
    const [wallet, setWallet] = useState([]);
    const [updated,setUpdated] = useState(false)

    const handleWithdrawDisplayPopup = (wallet) => {
        setWallet(wallet)
        setDisplayPopup('withdrawl')
    }

    const showActivityLog = (wallet) => {
        setWallet(wallet)
        setDisplayActivity(true)
    }

    const showCoUser = (wallet) => {
        setWallet(wallet)
        setDisplayCoUser(true)
    }

    const showDeposit = (wallet) => {
        setWallet(wallet)
        setDisplayDeposit(true)
    }

    const backToWallet = () => {
        setDisplayActivity(false)
        setDisplayDeposit(false)
        setDisplayCoUser(false)
        setWallet('')
    }

    return (
        <>
            <MainLayout>
                <div className="">
                    { (displayActivity === false && displayDeposit === false && displayCoUser === false)  && 
                            <>
                                <TopNavAndTabs 
                                    displayTab={displayTab}
                                    onChangeTab={(tab) => setDisplayTab(tab)}
                                    onClickPopupButton={(popup) => setDisplayPopup(popup)}
                                />
                                {(() => {
                                    switch (displayTab) {
                                        case 'personal-wallet': 
                                            return <PersonalWallet  
                                                        onPopupChange={(wallet) => handleWithdrawDisplayPopup(wallet) }
                                                        onActivityLog={(wallet) => showActivityLog(wallet) }
                                                        onClickDeposit={(wallet) => showDeposit(wallet) } 
                                                        updated={updated}
                                                        setUpdated={setUpdated}/> 
                                        case 'multi-signature-wallet': 
                                            return <MultiSignatureWallet 
                                                        onClickCoUser={(wallet) => showCoUser(wallet) } 
                                                        onPopupChange={(wallet) => handleWithdrawDisplayPopup(wallet) }
                                                        onActivityLog={(wallet) => showActivityLog(wallet) }
                                                        onClickDeposit={(wallet) => showDeposit(wallet) } 
                                                        updated={updated}
                                                        setUpdated={setUpdated}/> 
                                        default: 
                                            return <PersonalWallet 
                                                        onPopupChange={(wallet) => handleWithdrawDisplayPopup(wallet) }
                                                        onActivityLog={(wallet) => showActivityLog(wallet) }
                                                        onClickDeposit={(wallet) => showDeposit(wallet) } 
                                                        updated={updated}
                                                        setUpdated={setUpdated}/> 
                                }})()}

                                {(() => {
                                    switch (popup) {
                                        case 'add-wallet': 
                                            return <AddWalletPopUp  mode={mode} onClosePopup={ () => setDisplayPopup('') } setUpdated={setUpdated}/>
                                        case 'import-multi-signature-wallet': 
                                            return <ImportMultiSignatureWalletPopup  mode={mode} onClosePopup={ () => setDisplayPopup('') } />
                                        case 'withdrawl': 
                                            return <WithdrawlPopUp wallet={wallet} mode={mode} onClosePopup={ () => setDisplayPopup('') } setUpdated={setUpdated}/>
                                        default: return null
                                }})()}
                            </>
                    }
                    
                    { displayActivity === true &&   <ActivityLog wallet={wallet} backToWallet={() => backToWallet()} /> }
                    
                    { displayDeposit === true && <Deposit wallet={wallet} backToWallet={() => backToWallet()} /> }
                    
                    { displayCoUser === true && <CoUser wallet={wallet} backToWallet={() => backToWallet()} /> }
                </div>
            </MainLayout>
        </>
    )
};

export default WalletPage