import React, { useEffect, useState } from "react";
import { PieChart, Pie, Legend, Cell, Label, ResponsiveContainer } from "recharts";

const data = [
  {
    name: "Ethereum",
    currency:'ETH',
    value: 0,
    fill: "#654598",
    stroke:"#654598"
  },
  {
    name: "Bitcoin",
    currency:'BTC',
    value: 0,
    fill: "#0066ff",
    stroke: "#0066ff",
  },
  {
    name: "Bitcoin Cash",
    currency:'BCH',
    value: 0,
    fill: "#03fce3",
    stroke: "#03fce3",
  },
  {
    name: "Dash",
    currency:'DASH',
    value: 0,
    fill: "#e283a5",
    stroke: "#e283a5",
  },
  {
    name: "Tether",
    currency:'USDT',
    value: 0,
    fill: "#fb991f",
    stroke: "#fb991f",
  },
  {
    name: "Litecoin",
    currency:'LTC',
    value: 0,
    fill: "#00ff00",
    stroke: "#00ff00",
  },
  {
    name: "Headline",
    currency:'HDL',
    value: 0,
    fill: "#03d3fc",
    stroke: "#03d3fc",
  },
  {
    name: "Headline",
    currency:'LTCT',
    value: 0,
    fill: "#fcf003",
    stroke: "#fcf003",
  },

];

const BalanceChart = (props) => {
  let values = props.values
  // console.log(values)
  let balance = values.data.total_wallet_balance
  let coins = props.values.data.coins_percentage
  const keys = Object.keys(coins);
  for(let i=0;i<data.length;i++){
    for(let j=0;j<keys.length;j++){
      if(data[i].currency==keys[j]){
        data[i].value = parseFloat(coins[keys[j]])
      }
    }
  }
  // console.log(data)

  const [flag,setFlag]=useState(false)
  const [display,setdisplay] = useState(false)

  const showPopup = (index) =>{
    let a = document.getElementById('popup'+index)
    a.style.display = 'block'
  }

  const hidePopup = (index) =>{
    let a = document.getElementById('popup'+index)
    a.style.display = 'none'
  }

  const setBalanceChart = ()=>{
    for(let i =0;i<data.length;i++){
      if((data[i].value)>0){
        setFlag(true)
      }
    }
  }

  useEffect(() => {
    setBalanceChart()
  }, [])
  

  return (
    <>
      <div className= {(props.mode==='light'?'bg-white':'bg-elemblue2')+" h-full rounded sm:mb-5"}>
        <div className="">
          <h2 className={props.mode==='light'?"p-5":"p-5 pt-7 text-white md:px-5 py-3"}>Total Coins</h2>
        </div>
        
          {balance?
          <>
          <div className="w-full flex justify-center">
            <ResponsiveContainer width="100%" height={270} className='font-light text-sm'>
            <PieChart >
              <Pie
                data={data}
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                innerRadius="60%"
                outerRadius="75%"
                fill="#82ca9d" 
              >
            <Label value={parseFloat(balance).toFixed(2)} position="center" style={{fontSize: '90%'}} stroke={props.mode=="light"?'#0066ff':'#ffffff'}/>
            </Pie>
            </PieChart>
            </ResponsiveContainer>
          </div>
          <div className="justify-between h-32 overflow-y-auto scrollbar-hide lg:justify-start">
            {data.map((item,index)=>{
              return item.value>0?
              <div className="relative" onMouseEnter={()=>showPopup(index)} onMouseLeave={()=>hidePopup(index)} key={index}>
                <div className="flex flex-row justify-between my-2 px-5 uppercase sm:justify-between lg:px-2 xl:text-xs sm:text-sm sm:px-5" key={index} >
                  <div className={(props.mode==='light'?'text-darkblue':'text-lightblue')+' flex flex-row'}>
                    <p className={(props.mode==='light'?'bg-darkblue':'bg-lightblue')+' w-2 h-2 rounded-full self-center mr-2'} style={{backgroundColor: item.fill}}>
                    </p>
                    <p className={(props.mode==='light'?'text-darkblue':'text-lightblue')+' w-w50 sm:mr-5'} style={{color: item.fill}}>{item.name}</p>
                  </div>
                  <div className="">
                    <p className={(props.mode==='light'?'text-darkblue':'text-lightblue')+' lg:ml-2 sm:mr-5'} style={{color: item.fill}}>{item.value.toFixed(4)}%</p>
                  </div>
                </div>
                <div className={(props.mode==='light'?'border-borderGrey bg-bgLight':'border-white bg-white')+' border w-auto absolute top-5 right-1 z-10 px-2 lg:text-xxsmall hidden'} id={'popup'+index} style={{color: item.fill}}>{coins[item.currency]}%</div>
              </div>
              :''
            })}
        </div>
        </>:
          <div className="flex h-hp75 items-center ">
            <p className={(props.mode==='light'?'text-darkblue':'text-white')+'  w-full block p-5 text-sm opacity-50 sm:pt-0 self-center'}>
              <span className="block self-center text-center">You don't have any coins</span></p>
          </div>
          }
      </div>
    </>
  );
};

export default BalanceChart;
