import React, { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeftLong, faArrowRightLong, faClone } from "@fortawesome/free-solid-svg-icons";
import API from "../../../api/axios";
import { APP_URLS } from "../../../api/url";
import PageLength from "../../../components/Shared/PageLength";
import SearchBar from "../../../components/Shared/SearchBar";
import { Theme } from "../../../App";
import Pagination from "../../../components/Shared/Pagination";
import { NavLink, useParams } from "react-router-dom";

const ActivityLog = (props) => {
    const walletID = useParams();
    const [loading, setLoading] = useState(false);
    const [activityLog, setActivityLog] = useState([]);
    const mode = useContext(Theme);

    const[fetchData, setFetchData] = useState('deposite');

    //Setting Pagination and Search
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [entries, setEntries] = useState(10)
    const [search, setSearch] = useState('');

    useEffect(() => {
        fetchActivityLog();
    }, [search, page, entries, fetchData]);

    const fetchActivityLog = async () => {
        setLoading(true);
        await API.get(APP_URLS.TRANSACTION_HISTORY + `?type=${fetchData}&length=${entries}&search=${search}&page=${page}&wallet_id=${walletID.coin}`)
            .then((result) => {
                const resp = result.data.data;
                setTotalPages(result.data.data.last_page)
                setActivityLog(resp.data);
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
            })
    }

    function handleClick() {
        props.backToWallet()
    }

    const cloneAddress = (index) =>{
        let copyText  = document.getElementById("copyText"+index).previousSibling.innerText;
        navigator.clipboard.writeText(copyText)
        let textCopied = document.getElementById('textCopied'+index);
        textCopied.style.display = 'block';
        setTimeout(() => {
          const textCopied = document.getElementById('textCopied'+index);
          textCopied.style.display = 'none';
        }, 1000);
      }
    
    const cloneHash = (index) =>{
        let copyText  = document.getElementById("Hash"+index).innerText;
        navigator.clipboard.writeText(copyText)
        let textCopied = document.getElementById('HashCopied'+index);
        textCopied.style.display = 'block';
        setTimeout(() => {
          const textCopied = document.getElementById('HashCopied'+index);
          textCopied.style.display = 'none';
        }, 1000);
    }

    const handleLengthChange = (length) => {
        setEntries(length)
        setPage(0)
    }

    const handleSearchChange = (search) => {
        setSearch(search)
        setPage(0)
    }
    
    return (
        <>
            <div className='pb-5 mt-14 sm:mt-7'>
                <div className='flex flex-row w-full justify-between mb-3'>
                    <h1 className={(mode==='light'?'text-darkblue':'text-white')+' justify-start text-medium'}>Activity Log</h1>
                    <NavLink to="/wallet"><button className={(mode === 'light' ? 'text-darkblue' : 'text-white text-md') + " xs:mt-2"} ><FontAwesomeIcon className='pr-2' icon={faArrowLeftLong} /> Back to Wallet </button></NavLink>
                </div>
                <div className="flex flex-row w-full justify-between sm:block">
                <PageLength onPageLengthChange={(length) => handleLengthChange(length)} />
                    
                    <div className="flex flex-row">
                    <SearchBar onSearchClick={(search) => handleSearchChange(search)} />
                        <button
                            type="button"
                            className={(mode === 'light' ? 'bg-white' : 'bg-inputblue text-white') + " text-xsmall shadow-lg w-auto mx-3 px-3 rounded-lg outline-none " + (fetchData == 'deposite' ? (mode=='light'?'border-darkorange ':'border-blue ')+"bg-darkbuttonblue border-b-4 text-white" : ' sm:px-1')}
                            onClick={() => setFetchData('deposite')}>
                            Deposit
                        </button>
                        <button type="button" className={(mode === 'light' ? 'bg-white ' : 'bg-inputblue text-white') + " text-xsmall shadow-lg w-auto px-3 mr-3 rounded-lg outline-none" + (fetchData == 'withdraw' ? (mode=='light'?' border-darkorange ':' border-blue ')+ " bg-darkbuttonblue border-b-4 text-white" : ' sm:px-1 sm:mr-0')} onClick={() => setFetchData('withdraw')}>Withdraw</button>
                    </div>
                </div>
            </div>

            <div className="w-w100 table-auto overflow-x-auto">
                <table className="w-full mt-2 table-auto md:w-maxWidth">
                    <thead>
                        <tr className={(mode === 'light' ? 'bg-white' : 'bg-tableblue text-white') + " flex flex-row justify-around mt-4 py-8 rounded-xl shadow-lg"}>
                            <th className="flex justify-start w-w16 px-5">Address</th>
                            <th className="flex justify-start w-w16">Coin Amount</th>
                            <th className="flex justify-start w-w16 ">Transaction Hash</th>
                            <th className="flex justify-start w-w16">Created At</th>
                            <th className="flex justify-center w-w16">Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {activityLog.length > 0 ?
                            activityLog.map((item, index) => {
                                const icon = item.coin_type;
                                return (
                                    <tr key={index} className={(mode === 'light' ? 'bg-white text-darkblue' : 'bg-tableblue text-white') + " flex flex-row justify-around items-center my-4 py-4 rounded-xl shadow-lg"}>
                                        <td className="flex flex-col justify-start w-w16 px-5 break-all">
                                            <div className="flex justify-start w-full break-all">
                                                {item.address?
                                                <span className="overflow-hidden whitespace-nowrap overflow-ellipsis w-52 ">{item.address}</span>:
                                                <span className="overflow-hidden whitespace-nowrap overflow-ellipsis w-52 ">{item.email}</span>}
                                                <button className="cursor-pointer" onClick={() => cloneAddress(index)} id={"copyText" + index}> <FontAwesomeIcon icon={faClone} className={(mode === 'light' ? 'text-darkblue' : 'text-white') + " text-medium"} /> </button>
                                            </div>
                                            <p id={"textCopied" + index} className={"ml-1 text-green text-xsmall self-center hidden"}>Text Copied To Clipboard</p>

                                        </td>
                                        <td className="flex justify-start w-w16 xl:px-0">{parseFloat(item.amount).toFixed(2)} {item.coin_type}</td>
                                        {item.transaction_type == 'deposite' ?
                                            <td className="flex flex-col justify-start w-w16">
                                                <div className="w-4/5 flex flex-row break-all">
                                                    {item.transaction_id?
                                                    <>
                                                    <span className="overflow-hidden whitespace-nowrap overflow-ellipsis w-52" id={"Hash" + index}>{item.transaction_id}</span>
                                                    <button className="cursor-pointer ml-3" onClick={() => cloneHash(index)} id={"copyHash" + index}>
                                                        <FontAwesomeIcon icon={faClone} className={(mode === 'light' ? 'text-darkblue' : 'text-white') + " text-medium"} />
                                                    </button>
                                                    </>:
                                                    <span className="overflow-hidden whitespace-nowrap overflow-ellipsis w-52" id={"Hash" + index}>NA</span>}
                                                </div>
                                                <p id={"HashCopied" + index} className={"ml-1 text-green text-xsmall hidden"}>Text Copied To Clipboard</p>
                                            </td> :
                                            <td className="flex flex-col justify-left w-w16">
                                                <div className="w-4/5 flex flex-row justify-between break-all">
                                                    {item.transaction_hash?
                                                    <>
                                                    <span className="overflow-hidden whitespace-nowrap overflow-ellipsis w-52" id={"Hash" + index}>{item.transaction_hash}</span>
                                                    <button className="cursor-pointer" onClick={() => cloneHash(index)} id={"copyHash" + index}>
                                                        <FontAwesomeIcon icon={faClone} className={(mode === 'light' ? 'text-darkblue' : 'text-white') + " text-medium"} />
                                                    </button>
                                                    </>:
                                                    <span className="overflow-hidden whitespace-nowrap overflow-ellipsis w-52" id={"Hash" + index}>NA</span>}
                                                </div>
                                                <p id={"HashCopied" + index} className={"ml-1 text-green text-xsmall hidden"}>Text Copied To Clipboard</p>
                                            </td>
                                        }
                                        <td className="flex justify-start w-w16">{item.created_at}</td>
                                        <td className="flex justify-center w-w16 text-white text-sm">
                                            {item.status == 1 ?
                                                <div className="px-3 h-8 self-center flex justify-center items-center bg-greenlight rounded-lg">Completed</div> :
                                                <div className="px-3 h-8 self-center flex justify-center items-center bg-darkgrey2fade rounded-lg">Waiting</div>
                                            }
                                        </td>
                                    </tr>
                                )
                            })
                            :
                            <>
                                <tr className={(mode === 'light' ? 'bg-white' : 'bg-tableblue text-fontblue') + " flex flex-row justify-around items-center my-5 py-4 rounded-xl shadow-lg"}>
                                    <td colSpan="5"> { loading ? 'Loading...' : 'No Records Found' }</td>
                                </tr>
                            </>
                        }
                    </tbody>
                </table>
            </div>
            {(activityLog.length > 0) && 
                <>
                    <Pagination 
                        totalPages={totalPages}
                        onPageClick={(page) => setPage(page + 1)}
                        key={entries}
                    />
                </>
            }
        </>
    );
};

export default ActivityLog;