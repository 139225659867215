import React, { useState, useEffect,createContext,useContext } from "react";
import Mode from "../../components/Mode/Mode";
import {Theme} from "../../App"
import { MainLayout } from "../../components/Layouts/MainLayout";
import BuyCoinsHistory from "./Components/BuyCoinsHistory";
import ICOPhase from "./Components/ICOPhase";
import Pagination from "../../components/Shared/Pagination";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmarkCircle } from "@fortawesome/free-solid-svg-icons";
import QRCode from "react-qr-code";

const NewEntries = createContext();
const PayableCoins = createContext();
const PayableCoinsPopup = createContext();

const BuyCoinPage = (props) => {

  const mode = useContext(Theme);

  //Popup
  const [open,setOpen] = useState(false)
  const [payableData,setPayableData] = useState()
  
  
  //Seting data for coin history API
  const[entries,setEntries] =useState(10)
  const[newEntries,setNewEntries] =useState(false)
  const[updateSearch,setUpdateSearch] = useState('');
  const[search,setSearch] = useState('');
  const[data,setData] = useState([]);
  const[dataLoaded,setDataLoaded] = useState(false);
  const [loading, setLoading] = useState(false);

  
  //Setting Pagination
  const[page,setPage] = useState(1);
  const[totalPages,setTotalPages] = useState(0);

  
  //Coin History API function
  const getCoinHistory =()=>{
    setLoading(true);
    let authToken = localStorage.getItem("token");
    let access_type = localStorage.getItem("access_type");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", access_type + " " + authToken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(process.env.REACT_APP_API_URL+`buy-coin-history-app?limit=${entries}&search=${search}&page=${page}`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        let response = JSON.parse(result)
        setData(response.data.data);
        setDataLoaded(true)
        setTotalPages(response.data.last_page)
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log("error", error)
      });
  } 


  useEffect(() => {
    getCoinHistory()
    setNewEntries(false)
  }, [search,page,entries,newEntries])

  const handleLengthChange = (length) => {
    setEntries(length)
    setPage(0)
  }

  const handleSearchChange = (search) => {
    setSearch(search)
    setPage(0)
  }

  return (
    <>
      <MainLayout>
        <Mode />

        <div className="min-h-screen">
          {open&&(
              <div className={(mode==='light'?'bg-darkblue':'bg-darkblue')+" flex flex-center bg-opacity-80 w-full h-screen fixed z-20 grid place-items-center h-screen top-0 left-0"}>
                <div className="relative max-h-hv90 max-w-3xl z-30 right-0 md:left-0 md:w-w50 sm:w-w90 ">
                  <div
                      className={
                        (mode === "light"
                          ? "bg-orange text-darkblue border-darktblue"
                          : "bg-bgblue border-lightblue") +
                        " relative block mx-auto border border-8 rounded-2xl text-center px-5 py-10 w-full sm:px-1 sm:py-10 sm:border-4"
                      }
                    >
                    <div className='max-h-hv70 h-auto overflow-y-auto scrollbar-hide'>
                      <button
                        className="absolute -right-5 -top-5"
                        onClick={() => setOpen(false)}
                      >
                        {" "}
                        <FontAwesomeIcon
                          className="text-elemblue text-3xl bg-white rounded-full"
                          icon={faXmarkCircle}
                        />{" "}
                      </button>
                    </div>
                    {payableData!==undefined?
                    <div className="max-h-hv70 h-auto overflow-y-auto scrollbar-hide">
                      <h2 className={(mode === "light" ? "text-darkblue" : "text-white") +
                        "  text-md text-left mb-10 text-center sm:mb-5 xs:px-2"}>Request submitted successfully, please send <b>{payableData.payable_coin}</b> with this address</h2>
                      
                      <div className="flex gap-5 md:block">
                      <div className="flex bg-white self-center w-[265px] h-[265px] md:mx-auto md:mb-4">
                      <QRCode className="m-1" value={payableData.address} />
                      </div>
                      
                      <div className={(mode === "light" ? "text-darkblue" : "text-white") +
                        " text-sm text-left self-center md:text-center px-2"}>
                        <p className="mb-5"><b className="pr-2">Address :</b><span className="text-sm break-all">{payableData.address}</span></p>
                        <p><b className="pr-2">Payable Coin :</b><span className="text-sm">{payableData.payable_coin}</span></p>
                      </div>

                      </div>
                    </div>
                    :null}
                  </div>
                </div>
            </div>
            )}
            <PayableCoinsPopup.Provider value={setOpen}>
              <PayableCoins.Provider value={setPayableData}>
                <NewEntries.Provider value={setNewEntries}>
                    <ICOPhase mode={mode}/>
                </NewEntries.Provider>
              </PayableCoins.Provider>
            </PayableCoinsPopup.Provider>
            <hr className="border-white opacity-10 my-10" />
            <BuyCoinsHistory mode={mode} setSearch={(search) => handleSearchChange(search)} setPage={setPage} entries={entries} setEntries={(length) => handleLengthChange(length)} data={data} page={page} totalPages={totalPages} loading={loading}/>
            {(data.length > 0) && 
              <Pagination 
                  totalPages={totalPages}
                  onPageClick={(page) => setPage(page + 1)}
                  key={entries}
              />
            }
        </div>

      </MainLayout>
    </>
  );
};

export default BuyCoinPage;
export {NewEntries,PayableCoins,PayableCoinsPopup};