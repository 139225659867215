import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmarkCircle } from "@fortawesome/free-solid-svg-icons";
import { useFormik } from "formik";
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import API from "../../../../api/axios";
import { APP_URLS } from "../../../../api/url";
import { useNavigate } from "react-router-dom";
import dropdown from '../../../../components/Images/dropdown.png';

const AddWalletPopUp = (props) => {
    const [loading, setLoading] = useState(false);
    const [coins, setCoins] = useState([]);
    const navigate = useNavigate()

    useEffect(() => {
        fetchCoinList();
    }, []); 

    const formik = useFormik({
        initialValues: {
            type: '',
            wallet_name: '',
            coin_type: ''
        },
        validationSchema: Yup.object({
            type: Yup.string().required('Wallet Type is Required'),
            wallet_name: Yup.string().required('Wallet Name is Required'),
            coin_type: Yup.string().required('Coint Type is Required'),
        }),
        onSubmit: values => {
            if(!loading) {
                setLoading(true)
                API.post(APP_URLS.CREATE_WALLET, values)
                .then(res => {
                    const resp = res.data;
                    setLoading(false)
                    props.setUpdated(true)
                    if (resp.success === false) {
                      toast.error(resp.message, {
                        position: toast.POSITION.TOP_RIGHT
                      })
                    } else {
                        toast.success(resp.message, {
                            position: toast.POSITION.TOP_RIGHT
                        })
                        onCloseClickHandle();
                    }
                })
                .catch(function (error) {  
                    const resp = error.response;
                    setLoading(false)
                    if (resp) {
                      
                        let error_message = '';
                        if (resp.data.errors !== undefined) {
                            {Object.keys(resp.data.errors).map((error, index) => (
                                error_message = resp.data.errors[error][0]
                            ))}
                        } else if (resp.data.data.error !== undefined) {
                            error_message =  resp.data.data.error;
                        } else {
                            error_message =  resp.data.error
                        }
    
                        toast.error(error_message, {
                            position: toast.POSITION.TOP_RIGHT
                        })
                    }
                });         
            }
        },
    });

    function fetchCoinList() {
        console.log(APP_URLS.GENERAL_SETTINGS);
        API.get(APP_URLS.GENERAL_SETTINGS)
            .then(res => {
                const resp = res.data.data;
                setCoins(resp.coins);
            })
    }

    function onCloseClickHandle() {
        props.onClosePopup()
    }
    
    return (
        <>
            <div className='mt-15'>
                <div className="flex flex-center bg-darkblue bg-opacity-80 w-full fixed z-20 grid h-screen place-items-center top-0 left-0 right-0">
                    <div className={(props.mode === 'light' ? 'bg-orange text-darkblue border-darktblue' : 'bg-bgblue border-lightblue') + '  relative block mx-auto border border-8 rounded-2xl text-center px-8 py-10 max-w-md max-h-hv90 h-auto sm:px-2 sm:w-w90 sm:mx-auto'}>
                        <button className='absolute -right-5 -top-5' onClick={() => onCloseClickHandle()}> <FontAwesomeIcon className='text-elemblue text-3xl bg-white rounded-full' icon={faXmarkCircle} /> </button>
                        <div className='max-h-hv70 h-auto overflow-y-auto scrollbar-hide'>
                            <h2 className={(props.mode === 'light' ? 'text-darkblue' : 'text-white') + ' text-lg mb-2 text-2xl mb-5'} >Want To Add New Wallet?</h2>
                            <form className='text-center' onSubmit={formik.handleSubmit} >
                                <div className='w-full mb-4'>
                                    <div className='flex flex-row my-2'><p className={(props.mode === 'light' ? 'text-darkblue' : 'text-white') + ' text-small ml-2 xl:text-xs '}>Wallet Type</p></div>
                                    <div className={(props.mode === 'light' ? 'bg-white' : 'bg-inputblue1 text-white') + " w-full outline-none h-10 text-xs  border-btnblue border rounded-3xl h-h45 pr-4 shadow-lg appearance-none"}>
                                        <select 
                                         style={{backgroundImage: `url(${dropdown})`}} 
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            name="type"
                                            value={formik.values.type}
                                            className={(props.mode === 'light' ? 'bg-white' : 'bg-inputblue1 text-white') + " w-full outline-none h-10 text-xs p-1 rounded-3xl h-h42 px-4 bg-no-repeat bg-right appearance-none"}>
                                            <option value="">Select Wallet type</option>
                                            <option value="1">Personal Wallet</option>
                                            <option value="2">Multi-Signature Wallet</option>
                                        </select>
                                        {formik.touched.type && formik.errors.type ? (
                                            <div className='text-left text-red text-xs pt-2 pl-4'>{formik.errors.type}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className='w-full mb-4'>
                                    <div className='flex flex-row my-2'><p className={(props.mode === 'light' ? 'text-darkblue' : 'text-white') + ' text-small ml-2 xl:text-xs '}>Wallet Name</p></div>
                                    <div>
                                        <input
                                            type="text"
                                            name="wallet_name"
                                            autoComplete="off"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.wallet_name}
                                            placeholder='Your Wallet Name'
                                            className={(props.mode === 'light' ? 'bg-white' : 'bg-inputblue1 text-white') + " w-full outline-none h-10 text-xs p-1 border-btnblue border rounded-3xl h-h45 px-4 shadow-lg"} />
                                            {formik.touched.wallet_name && formik.errors.wallet_name ? (
                                                <div className='text-left text-red text-xs pt-2 pl-4'>{formik.errors.wallet_name}</div>
                                            ) : null}
                                    </div>
                                </div>
                                <div className='w-full'>
                                    <div className='flex flex-row my-2'><p className={(props.mode === 'light' ? 'text-darkblue' : 'text-white') + ' text-small ml-2 xl:text-xs '}>Coin Type</p></div>
                                    <div className={(props.mode === 'light' ? 'bg-white' : 'bg-inputblue1 text-white') + " w-full outline-none h-10 text-xs  border-btnblue border rounded-3xl h-h45 pr-4 shadow-lg"}>
                                        <select 
                                        style={{backgroundImage: `url(${dropdown})`}}
                                            className={(props.mode === 'light' ? 'bg-white' : 'bg-inputblue1 text-white') + " w-full outline-none h-10 text-xs p-1 rounded-3xl h-h42 px-4 bg-no-repeat bg-right appearance-none"}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            name="coin_type"
                                            value={formik.values.coin_type}
                                            >
                                            <option hidden>Select Coin Type</option>
                                            {
                                                coins.length > 0 && coins.map((coin, index) => {
                                                    return <option key={index} value={coin.type}> {coin.type}</option>
                                                })
                                            }
                                        </select>
                                        {formik.touched.coin_type && formik.errors.coin_type ? (
                                            <div className='text-left text-red text-xs pt-2 pl-4'>{formik.errors.coin_type}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className='w-full mt-5'>
                                    <button 
                                        type="submit"
                                        className={(props.mode==='light'?'bg-darkorange':'bg-lightblue')+' mt-3 rounded-md w-full h-h13 text-white text-center text-xl'}
                                        disabled={loading}
                                    >{ loading ? 'Adding Wallet....' : 'Add Wallet' }</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AddWalletPopUp;