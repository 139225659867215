import React, { useState, useContext } from "react";
import {Theme} from "../../App"
import { MainLayout } from "../../components/Layouts/MainLayout";
import Offers from "./Components/Offers";

const OffersPage = (props) => {

  const mode = useContext(Theme);
  
  const [menu,]=useState(false);

  return (
    <>
      <MainLayout 
        setMenu={menu}
      >
        <div className='min-h-screen'>
          <h1 className={(mode==='light'?'text-darkblue':'text-white')+' justify-start text-medium mt-14 sm:mt-7'} >Offers</h1> 
          <div className="mt-5 mb-10">
              <Offers mode={mode}/>
          </div>
        </div>
      </MainLayout>
    </>
  );
};

export default OffersPage;