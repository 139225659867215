import React, { useContext, useEffect, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import API from '../../api/axios';
import { APP_URLS } from '../../api/url';
import { SetNotificationCount, Theme } from '../../App';
import Menu from '../Menu/Menu';
import Mode from '../Mode/Mode';
import Navbar from '../Navbar/Navbar';

export const MainLayout = (props) => {
    const mode = useContext(Theme);
    const setNotification = useContext(SetNotificationCount);

    const [values, setValues] = useState([]);
    const [loading, setLoading] = useState(false);
    const [menu, setMenu] = useState(false) 

    const [profileUpdated,setProfileUpdated]=useState(false);


    useEffect(() => {
        fetchUserAccountDetails();
        setNotification(localStorage.getItem('notification'))
    }, []);
    
    async function fetchUserAccountDetails() {
        if(!loading) {
            setLoading(true)
            API.get(APP_URLS.USER_DASHBOARD_DATA)
            .then(res => {
                const resp = res.data;
                setLoading(false)
                setValues(resp)
                localStorage.setItem('notification',resp.data.notification_count)
            })
            .catch(function (error) {  
                const resp = error.response;
                setLoading(false)
                if (resp) {
                    let error_message = '';
                    if (resp.data.errors !== undefined) {
                        {Object.keys(resp.data.errors).map((error, index) => (
                            error_message = resp.data.errors[error][0]
                        ))}
                    } else if (resp.data.data.error !== undefined) {
                        error_message =  resp.data.data.error;
                    } else {
                        error_message =  resp.data.error
                    }

                    toast.error(error_message, {
                        position: toast.POSITION.TOP_RIGHT
                    })
                }
            });         
        }
    }
   

    return (
        <>
            <ToastContainer />
            <div className={"flex flex-row " + (mode === 'light' ? 'bg-grey' : ' bg-bgblue')}>
                <div className={"w-w17 " +(menu === true ?'md:absolute sm:absolute ':'md:hidden sm:hidden')}>
                    <Menu mode={mode} setMenu={setMenu} menu={menu} setProfileUpdated={setProfileUpdated}/>
                </div>
                <div className="w-w83 min-h-screen md:w-w100 sm:w-w100 sm:overflow-x-hidden px-3">
                    <Navbar mode={mode} setMenu={setMenu} data={values} />
                    <div className="mt-10 flex flex-col items-center lg:lg-5"></div>
                    <div className="flex flex-col w-w95 mx-auto mt-10">
                        <Mode />
                        {props.children}
                    </div>
                </div>
            </div>
        </>
    );
};