import React,{useEffect, useState} from 'react'
import {
    faArrowRightLong, faCheck, faCheckCircle, 
  } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import identity from "../../Images/identity-icon.png";
import licence from "../../Images/licence-icon.png";
import passport from "../../Images/passport-icon.png";
import PhoneVerification from './forms/PhoneVerification';
import Popup from './forms/Popup';
import { APP_URLS } from "../../../api/url";
import API from "../../../api/axios";
import { toast, ToastContainer } from 'react-toastify';

const SecurityVerification = (props) => {
    
  const [open, setOpen] = useState();
  const [idUpdated,setIdUpdated]=useState(false)

  const [dlStatus,setdlStatus] = useState('sdaas')
  const [nidStatus,setNidStatus] = useState()
  const [passportStatus,setPassportStatus] = useState()


  const getIdStatus =() =>{
      API.get(APP_URLS.ID_VERIFICATION)
      .then(res => { 
          if (res.data.success === true) {
            setdlStatus(res.data.data.driving_license.status)
            setNidStatus(res.data.data.nid.status)
            setPassportStatus(res.data.data.passport.status)
          } else {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT
            })        
          }
        })
        .catch((error) => {
          const resp = error.response;
          if (resp) {
            
              let error_message = '';
              if (resp.data.errors !== undefined) {
                  {Object.keys(resp.data.errors).map((error, index) => (
                      error_message = resp.data.errors[error][0]
                  ))}
              } else if (resp.data.data.error !== undefined) {
                  error_message =  resp.data.data.error;
              } else {
                  error_message =  resp.data.error
              }
  
              toast.error(error_message, {
                  position: toast.POSITION.TOP_RIGHT
              })
          }
        })   
  }

  useEffect(() => {
    getIdStatus()
    setIdUpdated(false)
  }, [idUpdated])
  


  return (
    <>
      <div className="flex flex-row justify-between mt-10 w-full items-center xs:block">
        <h1 className={(props.mode==='light'?'text-darkblue':'text-white')+' text-medium mb-1 xxs:mt-4'}>Security</h1>
      </div>

      <PhoneVerification mode={props.mode} userDetails={props.userData} setUpdated={props.setUpdated}/>

      <div className={(props.mode==='light'?'bg-orange':'bg-elemblue2')+' w-full w-full px-10 pt-10 pb-2 mt-5 rounded-lg mb-10 md:px-3'}>
        <div className="mb-5">
          <p className={(props.mode==='light'?'text-darkblue':'text-white')+' text-lg mb-10'}>Select Identity Type </p>
          <div className="flex justify-between border-b border-1 border-blue border-opacity-20 py-5">
            <div className="block w-w50 self-center md:w-w80">
              <img className={(props.mode==='light'?'bg-darkorange w-11 p-1 rounded-md':'text-white')+' inline-block align-bottom sm:w-8'} src={identity}/>
              <div className="inline-block px-5">
                <p className={(props.mode === "light" ? "text-darkblue" : "text-white") +" text-xl sm:text-base"}>
                  National ID Card
                </p>
                {nidStatus==='Approved'?
                  <p className={(props.mode === "light" ? "text-greenlight" : "text-greenlight") +" text-sm"}>Verified</p>:
                  nidStatus==="Not Submitted"?
                  <p className={(props.mode === "light" ? "text-lightblue" : "text-lightblue") +" text-sm"}>Not Submitted yet</p>:
                  nidStatus==="Pending"?
                  <p className={(props.mode === "light" ? "text-lightyellow" : "text-lightyellow") +" text-sm"}>Waiting For Approval</p>:
                  nidStatus==="Rejected"?
                  <p className={(props.mode === "light" ? "text-red" : "text-red") +" text-sm"}>Rejected</p>:null}
              </div>
            </div>
            {nidStatus=='Approved'?
            <button className="self-center bg-greenlight border-greenlight border rounded-full h-16 w-16 text-white b-0 text-3xl sm:w-8 sm:h-8 sm:text-base"  disabled>
              <FontAwesomeIcon icon={faCheck} />
            </button>:
            nidStatus=='Pending'?
            <button className="self-center bg-lightyellow border-lightyellow border rounded-full h-16 w-16 text-white b-0 text-3xl sm:w-8 sm:h-8 sm:text-base"  disabled>
              <FontAwesomeIcon icon={faArrowRightLong} />
            </button>:
            nidStatus=='Not Submitted'?
            <button className={(props.mode==='light'?'bg-darkorange border-darkorange':'bg-bgblue border-btnblue')+' self-center border rounded-full h-16 w-16 text-white b-0 text-3xl sm:w-8 sm:h-8 sm:text-base'}  onClick={()=> {setOpen(1)}} >
              <FontAwesomeIcon icon={faArrowRightLong} />
            </button>:
            nidStatus=='Rejected'?
            <button className={(props.mode==='light'?'bg-red border-red':'bg-red border-red')+' self-center border rounded-full h-16 w-16 text-white b-0 text-3xl sm:w-8 sm:h-8 sm:text-base'}  onClick={()=> {setOpen(1)}} >
              <FontAwesomeIcon icon={faArrowRightLong} />
            </button>:null}
          </div>

          <div className="flex justify-between border-b border-1 border-blue border-opacity-20 py-5">
            <div className="block w-w50 self-center md:w-w80">
              <img
               className={(props.mode==='light'?'bg-darkorange w-11 p-1 rounded-md':'text-white')+' inline-block align-bottom sm:w-8'}
                src={passport}
              />
              <div className="inline-block px-5 ">
              <p className={(props.mode === "light" ? "text-darkblue" : "text-white") +" text-xl sm:text-base"}>Passport</p>
              {passportStatus==='Approved'?
                  <p className={(props.mode === "light" ? "text-greenlight" : "text-greenlight") +" text-sm"}>Verified</p>:
                  passportStatus==="Not Submitted"?
                  <p className={(props.mode === "light" ? "text-lightblue" : "text-lightblue") +" text-sm"}>Not Submitted yet</p>:
                  passportStatus==="Pending"?
                  <p className={(props.mode === "light" ? "text-lightyellow" : "text-lightyellow") +" text-sm"}>Waiting For Approval</p>:
                  passportStatus==="Rejected"?
                  <p className={(props.mode === "light" ? "text-red" : "text-red") +" text-sm"}>Rejected</p>:null}
              </div>
            </div>
            {passportStatus=='Approved'?
            <button className="self-center bg-greenlight border-greenlight border rounded-full h-16 w-16 text-white b-0 text-3xl sm:w-8 sm:h-8 sm:text-base"  disabled>
              <FontAwesomeIcon icon={faCheck} />
            </button>:
            passportStatus=='Pending'?
            <button className="self-center bg-lightyellow border-lightyellow border rounded-full h-16 w-16 text-white b-0 text-3xl sm:w-8 sm:h-8 sm:text-base"  disabled>
              <FontAwesomeIcon icon={faArrowRightLong} />
            </button>:
            passportStatus=='Not Submitted'?
            <button className={(props.mode==='light'?'bg-darkorange border-darkorange':'bg-bgblue border-btnblue')+' self-center border rounded-full h-16 w-16 text-white b-0 text-3xl sm:w-8 sm:h-8 sm:text-base'}  onClick={()=> {setOpen(2)}} >
              <FontAwesomeIcon icon={faArrowRightLong} />
            </button>:
            passportStatus=='Rejected'?
            <button className={(props.mode==='light'?'bg-red border-red':'bg-red border-red')+' self-center border rounded-full h-16 w-16 text-white b-0 text-3xl sm:w-8 sm:h-8 sm:text-base'}  onClick={()=> {setOpen(2)}} >
              <FontAwesomeIcon icon={faArrowRightLong} />
            </button>:null}
          </div>

          <div className="flex justify-between py-5">
            <div className="block w-w50 self-center md:w-w80">
              <img 
              className={(props.mode==='light'?'bg-darkorange w-11 p-1 rounded-md':'text-white')+' inline-block align-bottom sm:w-8'}
               src={licence} />
              <div className="inline-block px-5">
                <p className={(props.mode === "light" ? "text-darkblue" : "text-white") +" text-xl sm:text-base"}>Driving Licence</p>
                {dlStatus==='Approved'?
                  <p className={(props.mode === "light" ? "text-greenlight" : "text-greenlight") +" text-sm"}>Verified</p>:
                  dlStatus==="Not Submitted"?
                  <p className={(props.mode === "light" ? "text-lightblue" : "text-lightblue") +" text-sm"}>Not Submitted yet</p>:
                  dlStatus==="Pending"?
                  <p className={(props.mode === "light" ? "text-lightyellow" : "text-lightyellow") +" text-sm"}>Waiting For Approval</p>:
                  dlStatus==="Rejected"?
                  <p className={(props.mode === "light" ? "text-red" : "text-red") +" text-sm"}>Rejected</p>:null}
              </div>
            </div>
            {dlStatus=='Approved'?
            <button className="self-center bg-greenlight border-greenlight border rounded-full h-16 w-16 text-white b-0 text-3xl sm:w-8 sm:h-8 sm:text-base"  disabled>
              <FontAwesomeIcon icon={faCheck} />
            </button>:
            dlStatus=='Pending'?
            <button className="self-center bg-lightyellow border-lightyellow border rounded-full h-16 w-16 text-white b-0 text-3xl sm:w-8 sm:h-8 sm:text-base"  disabled>
              <FontAwesomeIcon icon={faArrowRightLong} />
            </button>:
            dlStatus=='Not Submitted'?
            <button className={(props.mode==='light'?'bg-darkorange border-darkorange':'bg-bgblue border-btnblue')+' self-center border rounded-full h-16 w-16 text-white b-0 text-3xl sm:w-8 sm:h-8 sm:text-base'}  onClick={()=> {setOpen(3)}} >
              <FontAwesomeIcon icon={faArrowRightLong} />
            </button>:
            dlStatus=='Rejected'?
            <button className={(props.mode==='light'?'bg-red border-red':'bg-red border-red')+' self-center border rounded-full h-16 w-16 text-white b-0 text-3xl sm:w-8 sm:h-8 sm:text-base'}  onClick={()=> {setOpen(3)}} >
              <FontAwesomeIcon icon={faArrowRightLong} />
            </button>:null}
          </div>
          {open === 1 ? <Popup setOpen={setOpen} mode={props.mode} idType={'NID'} setIdUpdated={setIdUpdated}/> : open === 2 ? <Popup setOpen={setOpen} mode={props.mode} idType={'Passport'} setIdUpdated={setIdUpdated}/> : open === 3 ? <Popup setOpen={setOpen} mode={props.mode} idType={'Dl'} setIdUpdated={setIdUpdated}/> : null}
        </div>
      </div>
    </>
  )
}

export default SecurityVerification