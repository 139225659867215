import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmarkCircle } from "@fortawesome/free-solid-svg-icons";
import { useFormik } from "formik";
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { APP_URLS } from "../../../../api/url";
import API from "../../../../api/axios";
import dropdown from '../../../../components/Images/dropdown.png';



const DownloadPDF = (props) => {

    const [loading,setLoading] = useState(false)

      const formik = useFormik({
        initialValues: {
            start_date:'',
            end_date:'',
            file_type:'',
        },
        validationSchema: Yup.object({
            start_date: Yup.date().max(Yup.ref('end_date'),'Start Date should be smaller than end date'),
            end_date: Yup.date().max(new Date(),"End Date can't be greater than current date"),
            file_type: Yup.string().required("Choose a File type"),
        }),
        onSubmit: values => {
            setLoading(true)
            API.post(APP_URLS.DOWNLOAD_PDF,values)
            .then(res => { 
                setLoading(false)
                if (res.data.success === true) {
                  window.open(res.data.data.file_url, '_blank');
                  props.setPopup(false)
                  toast.success(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                  })
                }
                else{
                    toast.error(res.data.message, {
                        position: toast.POSITION.TOP_RIGHT
                      })
                }
              })
              .catch((error) => {
                setLoading(false)
                const resp = error.response;
                if (resp) {
                  
                    let error_message = '';
                    if (resp.data.errors !== undefined) {
                        {Object.keys(resp.data.errors).map((error, index) => (
                            error_message = resp.data.errors[error][0]
                        ))}
                    } else if (resp.data.data.error !== undefined) {
                        error_message =  resp.data.data.error;
                    } else {
                        error_message =  resp.data.error
                    }
        
                    toast.error(error_message, {
                        position: toast.POSITION.TOP_RIGHT
                    })
                }
              }) 
        }
    });

  return (
    <>
        <div className="flex flex-center bg-darkblue bg-opacity-80 w-full fixed z-20 grid h-screen place-items-center top-0 left-0 right-0">
            <div className="relative w-w50 z-30 md:left-0 md:w-w50 sm:w-w90 sm:mx-auto">
                <div className={(props.mode === 'light' ? 'bg-orange text-darkblue' : 'bg-bgblue border-lightblue ') + '  relative block mx-auto border border-8 rounded-2xl text-center px-8 py-10 max-w-md max-h-hv90 h-auto sm:px-2 sm:py-5'} >
                        <form className='max-h-hv70 h-auto overflow-y-auto scrollbar-hide' onSubmit={formik.handleSubmit}>
                            <h2 className={(props.mode === 'light' ? 'text-darkblue' : 'text-white') + ' pl-2 text-lg mb-2 tracking-wider'} >Export Your Transaction History into PDF or CSV</h2>
                            <button className='absolute -right-5 -top-5' onClick={() => props.setPopup(false)}> <FontAwesomeIcon className='text-elemblue text-3xl bg-white rounded-full' icon={faXmarkCircle} /> </button>
                            <p className='text-btnblue text-xs pl-2'>
                                If you want to download complete transactions, just click on download button.
                            </p>

                            <div className='w-full pt-4'>
                                <div className='flex flex-row items-center my-2'>
                                    <p className={(props.mode === 'light' ? 'text-darkblue' : 'text-white') + ' pl-2 text-small xl:text-xs'}>From</p>
                                </div>
                                <div>
                                    <input
                                        type="date"
                                        name="start_date"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.start_date}
                                        className={(props.mode === 'light' ? 'bg-white' : 'bg-inputblue1 text-white') + " w-full outline-none text-xsmall p-1 border-btnblue border rounded-3xl h-h45 px-4 "} />
                                    {formik.touched.start_date && formik.errors.start_date ? (
                                        <div className='text-left text-red text-xs pt-2 pl-2'>{formik.errors.start_date}</div>
                                    ) : null}
                                </div>
                            </div>

                            <div className='w-full pt-4'>
                                <div className='flex flex-row items-center my-2'>
                                    <p className={(props.mode === 'light' ? 'text-darkblue' : 'text-white') + ' pl-2 text-small xl:text-xs'}>To</p>
                                </div>
                                <div>
                                    <input
                                        type="date"
                                        name="end_date"
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.end_date}
                                        className={(props.mode === 'light' ? 'bg-white' : 'bg-inputblue1 text-white') + " w-full outline-none text-xsmall p-1 border-btnblue border rounded-3xl h-h45 px-4 "} />
                                    {formik.touched.end_date && formik.errors.end_date ? (
                                        <div className='text-left text-red text-xs pt-2 pl-2'>{formik.errors.end_date}</div>
                                    ) : null}
                                </div>
                            </div>

                            <div className='w-full pt-4'>
                                <div className='flex flex-row items-center my-2'>
                                    <p className={(props.mode === 'light' ? 'text-darkblue' : 'text-white') + ' pl-2 text-small xl:text-xs'}>File Type</p>
                                </div>
                                <div className={(props.mode==='light'?'bg-white border-btnblue':'bg-inputblue1 text-white border-btnblue')+" border rounded-3xl w-full outline-none h-10 text-xsmall h-h45 pr-4 shadow-lg"}>
                                <select    
                                    style={{backgroundImage: `url(${dropdown})`}}      
                                    className={(props.mode==='light'?'bg-white':'bg-inputblue1 text-white')+"  rounded-3xl w-full outline-none h-10 text-xsmall p-1 h-h42 px-4 bg-no-repeat bg-right appearance-none"}
                                    name="file_type"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.file_type}
                                    >
                                        <option hidden>Choose a type</option>
                                        <option value="pdf">PDF</option>
                                        <option value="csv">CSV</option>
                                </select>
                                    {formik.touched.file_type && formik.errors.file_type ? (
                                        <div className='text-left text-red text-xs pt-2 pl-2'>{formik.errors.file_type}</div>
                                    ) : null}
                                </div>
                            </div>




                            <div className='mt-5 pl-2 '>
                                <button type="submit" className={(props.mode==='light'?'bg-darkorange':'bg-gradient-to-r from-purple to-pink ')+' mt-3 rounded-3xl w-wp160 h-h13 text-white disabled:opacity-50'} 
                                    disabled={loading}
                                >
                                Download
                                </button>
                            </div>
                        </form>
                    {/* </form> */}
                </div>
            </div>
        </div>
    </>
  )
}

export default DownloadPDF
