import React,{useEffect,useContext,useState} from 'react'
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { APP_URLS } from "../../../../../api/url";
import API from "../../../../../api/axios";
import { toast, ToastContainer } from 'react-toastify';
import { NewEntries } from '../../../../BuyCoins';
import { PayableCoins } from '../../../../BuyCoins';
import { PayableCoinsPopup } from '../../../../BuyCoins';
import dropdown from '../../../../../components/Images/dropdown.png';


const CoinPayment = (props) => {
  let coins=props.data.coins
  const setNewEntries = useContext(NewEntries);
  const setPayableData = useContext(PayableCoins);
  const setOpen = useContext(PayableCoinsPopup);
  const [loading, setLoading] = useState(false);
  const [convertedAmount, setConvertedAmount] = useState(props.convertedAmount);
  const [coinAmount, setCoinAmount] = useState(props.coinAmount);


  useEffect(() => {
    setConvertedAmount(props.convertedAmount)
    setCoinAmount(props.coinAmount)
    formik.setFieldValue('coin', coinAmount);
  },[props.convertedAmount,props.coinAmount])
  
  const formik = useFormik({
    initialValues: {
      payment_type:'1',
      coin:coinAmount,
      payment_coin_type:'',
    },
    validationSchema: Yup.object({
      payment_coin_type: Yup.string().required('Coin Type is Required'),
    }),
    onSubmit: values => {
      if(!loading) { 
        setLoading(true)
        let formValues = {}
        formValues[`payment_type`] = '1'
        formValues[`coin`] = values.coin
        formValues[`payment_coin_type`] = values.payment_coin_type
        if(props.phaseID!=null){
          formValues[`phase_id`] = props.phaseID
        }
        API.post(APP_URLS.BUY_COIN_THROUGH_APP, formValues)
        .then(res => { 
            setLoading(false)
            props.setCoinAmount('')
            if (res.data.success === true) {
              // props.SetDisplayPaymentMethods('')
              props.setConvertedAmount('')
              setPayableData(res.data.data)
              setOpen(true)
              setNewEntries(true)
              formik.resetForm()
              toast.success('Transaction has been done successfully.', {
                position: toast.POSITION.TOP_RIGHT
              })
            } else {
              toast.error(res.data.message, {
                position: toast.POSITION.TOP_RIGHT
              })        
            }
          })
          .catch((error) => {
            setLoading(false)
            const resp = error.response;
            if (resp) {
              
                let error_message = '';
                if (resp.data.errors !== undefined) {
                    {Object.keys(resp.data.errors).map((error, index) => (
                        error_message = resp.data.errors[error][0]
                    ))}
                } else if (resp.data.data.error !== undefined) {
                    error_message =  resp.data.data.error;
                } else {
                    error_message =  resp.data.error
                }
    
                toast.error(error_message, {
                    position: toast.POSITION.TOP_RIGHT
                })
            }
          })     
    }
  }
  });

  useEffect(() => {
    {coinAmount<=0?props.setValidateAmount(true):props.setValidateAmount(false)}
  }, [coinAmount])
  
  
  return (
    <>
      <ToastContainer />
      <form onSubmit={formik.handleSubmit}>
        <div className={(props.mode=='light'?'bg-orange':'bg-elemblue2')+' w-full flex-col w-full pb-5 rounded-lg'}>
        {/* coin payment start */}
            <div className='w-full flex flex-row gap-5 lg:block gap-3'>
                  <div className=' mt-4 w-w50 lg:w-w100 inline-block '>
                    <div className='flex flex-row items-center my-2'>
                      <p className={(props.mode==='light'?'text-darkblue':'text-white')+' text-small ml-2 xl:text-xs'}>Payable Coin</p>
                      <p className='text-small text-red'>*</p>
                    </div>
                    {/* <div>                             
                    <input 
                    type="number" 
                    placeholder='Enter payable coin'
                    name="converted_coin"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.converted_coin}
                    className={(props.mode==='light'?'bg-white':'bg-inputblue1 text-white')+(formik.touched.converted_coin && formik.errors.converted_coin?' border-red border rounded-3xl':' border-btnblue border rounded-3xl')+" w-full outline-none h-10 text-small p-1 h-h45 px-4 shadow-lg"} />                       
                    {formik.touched.converted_coin && formik.errors.converted_coin ? (
                      <div className='text-left text-red text-xs pt-2'>{formik.errors.converted_coin}</div>):null}
                    </div> */}

                    <div className={(props.mode==='light'?'bg-white':'bg-inputblue1 text-white')+" border-btnblue border rounded-3xl w-full outline-none h-10 text-small p-1 h-h45 px-4 shadow-lg leading-9"}>
                      {convertedAmount}                             
                    </div>
                    
                    <input 
                    type="hidden" 
                    placeholder='Enter payable coin'
                    name="coin"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.coin}
                    className={(props.mode==='light'?'bg-white':'bg-inputblue1 text-white')+(formik.touched.coin && formik.errors.coin?' border-red border rounded-3xl':' border-btnblue border rounded-3xl')+" w-full outline-none h-10 text-small p-1 h-h45 px-4 shadow-lg"} /> 
                  </div> 
                  <div className='mt-4 w-w50 lg:w-w100 inline-block'>
                    <div className='flex flex-row my-2'><p className={(props.mode==='light'?'text-darkblue':'text-white')+' text-small ml-2 self-center xl:text-xs '}>Select</p><p className='text-small text-red'>*</p></div>
                    <div className={(props.mode==='light'?'bg-white':'bg-inputblue1 text-white')+(formik.touched.payment_coin_type && formik.errors.payment_coin_type?' border-red border rounded-3xl':' border-btnblue border rounded-3xl')+" w-full outline-none h-10 text-small h-h45 pr-4 shadow-lg appearance-none"}>           
                     
                      <select
                       style={{backgroundImage: `url(${dropdown})`}} 
                       className={(props.mode==='light'?'bg-white':'bg-inputblue1 text-white')+" w-full outline-none text-small h-h42 px-4 ml-1 rounded-3xl bg-no-repeat bg-right appearance-none"}
                        value={formik.values.payment_coin_type}
                        name="payment_coin_type"
                        onBlur={formik.handleBlur}
                        onChange={(e) => {
                          formik.handleChange("payment_coin_type")(e);
                          props.setCoinType(e.currentTarget.value);
                        }}>
                          <option hidden>Select a Coin</option>
                          {coins.map((items,index)=>{
                            return <option value={items.type} key={index}>{items.type}</option>
                          })}
                      </select>
                      {formik.touched.payment_coin_type && formik.errors.payment_coin_type ? (
                      <div className='text-left text-red text-xs pt-2 pl-2'>{formik.errors.payment_coin_type}</div>):null}
                    </div> 
                  </div>                   
            </div>
        </div>
         {/* coin payment end */}
         {coinAmount<=0?
        <div className="w-full flex">
          <button type="submit" className={(props.mode==='light'?'bg-darkorange':'bg-gradient-to-r from-purple to-pink ')+" justify-start mt-3 rounded-3xl w-wp160 h-h13 text-white disabled:opacity-50"} disabled>
            Buy Now!
          </button>
        </div>:
        <div className="w-full flex">
          <button type="submit" className= {(props.mode==='light'?'bg-darkorange':'bg-gradient-to-r from-purple to-pink ')+" justify-start mt-3 rounded-3xl w-wp160 h-h13 text-white disabled:opacity-50"} disabled={loading}>
            {loading?'Please Wait':'Buy Now!'}
          </button>
        </div>
        }
    </form>
    </>
  )
}

export default CoinPayment