import axios from 'axios';
const API_APP_URL = process.env.REACT_APP_API_URL ? process.env.REACT_APP_API_URL : 'https://admin.ihodl.com/api';

const API = axios.create({
  baseURL: API_APP_URL,
});

API.interceptors.request.use(
  config => {
    const authToken = localStorage.getItem('token');
    const accessType = localStorage.getItem("access_type");

    if (authToken) {
      config.headers = {
        'Authorization': accessType + " " + authToken,
        'Accept': 'application/json',
        'content-type': 'application/json'
      };
    }
    return config;
  },
  error => {
    return Promise.reject(error);
  },
);

API.interceptors.response.use(
  response => response, 
  error => {
    if (error.response.status === 401) {
      localStorage.clear();
      window.location.href = '/login';
    }
    return Promise.reject(error);
  }
)

export default API;
