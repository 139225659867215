import React, { useEffect, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";
import API from "../../api/axios";
import { APP_URLS } from "../../api/url";


const WatchlistLineChart = (props) => {
  const sixtyDays = 60;
  const currentTime = new Date();
  const weekAgoTime = new Date(currentTime.getTime() - (sixtyDays * 24 * 60 * 60 * 1000))
  const [coinHistory, setCoinHistory] = useState()

  useEffect(() => {
    coinRateHistory(props.watchList)
  },[props.watchList]) 

  const toTimeStamp = (date) => {
    var datum = Date.parse(date);
    return datum;
  }

  const blockBackgroundColor = "transparent"

  const currentDateTimeStamp = toTimeStamp(currentTime);
  const weekAgoTimeStamp = toTimeStamp(weekAgoTime);
  
  const coinRateHistory = async () => {

    const params = {
      'currency': 'USD',
      'code': props.coinType,
      'start': weekAgoTimeStamp,
      'end':  currentDateTimeStamp,
    };

    await API.post(APP_URLS.COIN_SINGLE_HISTORY, params)
      .then((result) => {
        const resp = result.data;
        setCoinHistory(resp.data);        
      })
      .catch((error) => {
      })
  }

  return (
    <>
          <ResponsiveContainer width="100%" height={80} className="pt-10 -mt-5">
          <LineChart 
            data={coinHistory}
          >
            <Tooltip 
            wrapperStyle={{top: "25px", zIndex: "999" }}
            labelStyle={{ color: "#000000", marginTop: "-5px", fontSize: "14px"}}
            itemStyle={{ color: "Green", marginTop: "0px", marginBottom: "-5px", fontSize: "14px" }}
            />
            <XAxis dataKey="name" dot={false} stroke={blockBackgroundColor} tick={false} />
            <Line type="monotone" dataKey="Amount" stroke={props.color} dot={false}  />
            

          </LineChart>
          </ResponsiveContainer>
    </>
  );
};

export default WatchlistLineChart;
