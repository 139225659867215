import React, { useState, useEffect } from "react";
import {faArrowLeftLong, faArrowRightLong, faFileLines, faRectangleList, faUserAlt} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Mode from "../Mode/Mode";
import ProfileInfo from "../childComponents/profileComponents/ProfileInfo";
import SignInActivity from "../childComponents/profileComponents/SignInActivity";
import SecurityVerification from "../childComponents/profileComponents/SecurityVerification";
import { APP_URLS } from "../../api/url";
import API from "../../api/axios";
import { toast, ToastContainer } from 'react-toastify';

const ProfileUser = (props) => {

  const [tab,setTab] = useState(1);

  //Phone Number Updation
  const [updated,setUpdated] = useState(false)

  //UserDetails
  const [userData,setUserData] = useState()
  const [dataloaded,setDataLOaded] = useState(false)
  

  const getProfileData=()=>{
    API.get(APP_URLS.PROFILE_EDIT)
          .then(res => { 
            if (res.data.success === true) {
                // props.setUserDetails(res.data.data)
                setUserData(res.data.data)
                setDataLOaded(true)
              }
            })
            .catch((error) => {
              const resp = error.response;
              if (resp) {
                
                  let error_message = '';
                  if (resp.data.errors !== undefined) {
                      {Object.keys(resp.data.errors).map((error, index) => (
                          error_message = resp.data.errors[error][0]
                      ))}
                  } else if (resp.data.data.error !== undefined) {
                      error_message =  resp.data.data.error;
                  } else {
                      error_message =  resp.data.error
                  }
      
                  toast.error(error_message, {
                      position: toast.POSITION.TOP_RIGHT
                  })
              }
            })
  }

  useEffect(() => {
    getProfileData()
    setUpdated(false)
  }, [updated])


  return (
    <>
      <Mode />

      <div className="min-h-screen w-w95 mt-14 sm:mt-7">
        <div className={(props.mode==='light'?' border-darkblue':' border-white')+' flex flex-row justify-start mt-14 w-full items-center border-b-2 border-opacity-20 mb-7 xs:block xs:text-left xs:border-0 sm:mt-5'}>
          <button className={`text-md pb-3 px-4 mb-mb2 lg:px-2 xs:w-full xs:leading-4 border-b-2 xs:py-2
                  ${tab === 1 
                      ? (props.mode === 'light' ? ' border-darkblue text-darkblue' : 'border-lightblue text-lightblue') 
                      : (props.mode === 'light' ? ' border-darkblue border-opacity-20 text-darkblue' : 'border-darkblue text-white') }`
                }
            onClick={()=> {setTab(1)}}
          >
            <FontAwesomeIcon
              icon={faUserAlt}
              className={" text-xl mr-2 xs:mr-0 lg:hidden"} />
           <span className={" text-lg mr-2 md:text-sm xs:mr-0 xs:text-lg"}> Profile Information</span>
          </button>

          <button className={`text-md pb-3 px-4 mb-mb2 lg:px-2 xs:w-full xs:leading-4 border-b-2 xs:py-2
                  ${tab === 2 
                      ? (props.mode === 'light' ? ' border-darkblue text-darkblue' : 'border-lightblue text-lightblue') 
                      : (props.mode === 'light' ? ' border-darkblue border-opacity-20 text-darkblue' : 'border-darkblue text-white') }`
                }
                onClick={()=> {setTab(2)}}
            
          >
            <FontAwesomeIcon icon={faRectangleList} className={" text-xl mr-2 xs:mr-0 lg:hidden"}/>
            <span className={" text-lg mr-2 md:text-sm xs:mr-0 xs:text-lg"}> Security Verification</span>
          </button>

          <button className={`text-md pb-3 px-4 mb-mb2 lg:px-2 xs:w-full xs:leading-4 border-b-2 xs:py-2  
                  ${tab === 3 
                      ? (props.mode === 'light' ? ' border-darkblue text-darkblue' : 'border-lightblue text-lightblue') 
                      : (props.mode === 'light' ? '  border-darkblue border-opacity-20 text-darkblue' : 'border-darkblue text-white') }`
                }
            onClick={()=> {setTab(3)}}
          >
            <FontAwesomeIcon
              icon={faFileLines}
              className={" text-xl mr-2 xs:mr-0 lg:hidden"}/>
           <span className={" text-lg mr-2 md:text-sm xs:mr-0 xs:text-lg"}> Signin Activity</span>
          </button>
        </div>
        {dataloaded&&(
        tab === 1 ? <ProfileInfo mode={props.mode} data={props.data} userData={userData} setUpdated={setUpdated}/> : tab === 2 ? <SecurityVerification mode={props.mode} userData={userData} 
        setUpdated={setUpdated} /> : tab === 3 ? <SignInActivity mode={props.mode}/> : <ProfileInfo mode={props.mode}/>
        )}
        <div className="Flex justify-center mb-10">
          {tab===1?
            <button className={(props.mode==='light'?'text-darkorange':'text-white')+' text-2xl sm:text-sm disabled:opacity-0'} disabled>
              <FontAwesomeIcon
                className="mr-5 sm:mr-2 sm:text-sm"
                icon={faArrowLeftLong}
              />
              Back
            </button>:
            <button className={(props.mode==='light'?'text-darkorange':'text-white')+' text-2xl sm:text-sm'} onClick={()=>setTab(tab-1)}>
               <FontAwesomeIcon
                className="mr-5 sm:mr-2 sm:text-sm"
                icon={faArrowLeftLong}
               />
              Back
            </button>}
            {tab===3?
            <button className={(props.mode==='light'?'bg-darkorange border-darkorange':'bg-bgblue border-btnblue')+' text-white text-xl float-right border rounded-full b-0 text-xl px-5 py-3 sm:text-sm sm:py-1 sm:px-2 disabled:opacity-0'} disabled>
              Next Step
              <FontAwesomeIcon className="ml-5 sm:ml-2" icon={faArrowRightLong} />
            </button>:
            <button className={(props.mode==='light'?'bg-darkorange border-darkorange':'bg-bgblue border-btnblue')+' text-white text-xl float-right border rounded-full b-0 text-xl px-5 py-3 sm:text-sm sm:py-1 sm:px-2'} onClick={()=>setTab(tab+1)}>
              Next Step
              <FontAwesomeIcon className="ml-5 sm:ml-2" icon={faArrowRightLong} />
            </button>}
          </div>
      </div>
    </>
  );
};

export default ProfileUser;
