import React from 'react'

const Card = (props) => {
  return (
    <>
    <div className={(props.mode==='light'?'border border-darkorange':'border border-elemblue')+' text-center rounded-2xl overflow-hidden'}>
        <div className='h-auto overflow-hidden object-cover rounded-t-2xl'>
            <img className='h-auto w-full object-cover' src={props.data?.image_url} />
        </div>
        <div className={(props.mode==='light'?'bg-orange text-darkblue':'bg-tableblue')+' px-2 py-3  h-[100%]'}>
            <div>
                <p className={(props.mode==='light'?'text-darkblue':'text-white')+' text-center px-2'}>{props.data?.title}</p>
                <p className={(props.mode==='light'?'text-darkblue':'text-white')+' text-center px-2 text-xs'}>{props.data?.description}</p>
            </div>
            <div>
                <button className={(props.mode==='light'?'bg-darkblue':'bg-gradient-to-r from-purple to-pink')+' outline-none text-white text-md rounded-3xl py-1 px-5 shadow-2xl mt-2'}
                onClick={()=>{window.open(`${props.data?.url}`, '_blank');}}
                >Go To Offer</button>
            </div>
        </div>
    </div>
    </>
  )
}

export default Card