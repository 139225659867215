import React,{useState,useEffect} from 'react'
import {
    faArrowLeftLong, 
    faArrowRightLong, 
    faMagnifyingGlass
  } from "@fortawesome/free-solid-svg-icons";
  import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
  import { APP_URLS } from "../../../api/url";
import API from "../../../api/axios";
import { toast, ToastContainer } from "react-toastify";
import PageLength from '../../Shared/PageLength';
import SearchBar from '../../Shared/SearchBar';
import Pagination from '../../Shared/Pagination';

const SignInActivity = (props) => {

    //Seting data for coin history API
    const[entries,setEntries] =useState(10)
    const[updateSearch,setUpdateSearch] = useState('');
    const[search,setSearch] = useState('');
    const[data,setData] = useState([]);
    const[dataLoaded,setDataLoaded] = useState(false);
    const [loading, setLoading] = useState(false);


    
    //Setting Pagination
    const[page,setPage] = useState(1);
    const[totalPages,setTotalPages] = useState(0);
  
  const fetchSignIn=()=>{
    setLoading(true);
    API.get(APP_URLS.SIGN_IN_ACTIVITY+`?search=${search}&limit=${entries}&page=${page}`)
    .then(res => { 
        if (res.data.success === true) {
          setData(res.data.data.data);
          setDataLoaded(true)
          setTotalPages(res.data.data.last_page)
          setLoading(false);
        }
      })
      .catch((error) => {
        const resp = error.response;
        if (resp) {
          
            let error_message = '';
            if (resp.data.errors !== undefined) {
                {Object.keys(resp.data.errors).map((error, index) => (
                    error_message = resp.data.errors[error][0]
                ))}
            } else if (resp.data.data.error !== undefined) {
                error_message =  resp.data.data.error;
            } else {
                error_message =  resp.data.error
            }

            toast.error(error_message, {
                position: toast.POSITION.TOP_RIGHT
            })
        }
      })
  }

  useEffect(() => {
    fetchSignIn()
  }, [search,page,entries])

  const handleLengthChange = (length) => {
    setEntries(length)
    setPage(0)
  }

  const handleSearchChange = (search) => {
    setSearch(search)
    setPage(0)
  }
  
  return (
    <>
          <div className="flex flex-row justify-between mt-10 w-full items-center xs:block">
            <h1 className={(props.mode==='light'?'text-darkblue':'text-white')+' text-medium mb-1 xxs:mt-4'}>Activity</h1>
          </div>
          <div className="flex flex-row w-w100 justify-between h-h40">
            <PageLength onPageLengthChange={(length) => handleLengthChange(length)} />
            <div className="sm:w-w40">
                <SearchBar onSearchClick={(search) => handleSearchChange(search)} />
            </div>
          </div>
          <div className="w-full table-auto overflow-x-auto">
            <table className="w-full mt-2 table-auto relative md:w-maxWidth">
              <thead>
              <tr
                className={
                  (props.mode === "light"
                    ? "bg-white"
                    : "bg-tableblue text-white") +
                  " flex flex-row justify-around mt-4 py-8 rounded-xl shadow-lg mt-4"
                }
              >
                <th className="flex justify-left w-w20 pl-8">Action</th>
                <th className="flex justify-left w-w20">Source</th>
                <th className="flex justify-left w-w20">IP Address</th>
                <th className="flex justify-left w-w20">Updated At</th>
                <th className="flex justify-left w-w20">When</th>
              </tr>
              </thead>

              <tbody>
                {data.length>0?
                data.map((item,index)=>{
                  return <tr className={(props.mode === "light"? "bg-white": "bg-tableblue text-fontblue") +" flex flex-row justify-around items-center my-5 py-4 rounded-xl shadow-lg"} key={index}>
                  <td className={(props.mode==='light'?'text-darkblue':'text-white')+' flex justify-left w-w20 pl-8'}>
                    <span>{item.action}</span>
                  </td>
                  <td className={(props.mode==='light'?'text-darkblue':'text-white')+' flex justify-left w-w20'}>
                    <span>{item.source}</span>
                  </td>
                  <td className={(props.mode==='light'?'text-darkblue':'text-white')+' flex justify-left w-w20'}>
                    <span>{item.ip_address}</span>
                  </td>
                  <td className={(props.mode==='light'?'text-darkblue':'text-white')+' flex justify-left w-w20'}>
                    <span>{item.updated_at}</span>
                  </td>
                  <td className={(props.mode==='light'?'text-darkblue':'text-white')+' flex justify-left w-w20'}>
                    <span>{item.when}</span>
                  </td>
                </tr>
                }):
                <tr className={(props.mode === 'light' ? 'bg-white' : 'bg-tableblue text-fontblue') + " flex flex-row justify-around items-center my-5 py-4 rounded-xl shadow-lg"}>
                    <td colSpan="5"> { loading ? 'Loading...' : 'No Records Found' }</td>
                </tr>
              }

              </tbody>

            </table>
          </div>
          {(data.length > 0) &&
            <Pagination 
              totalPages={totalPages}
              onPageClick={(page) => setPage(page + 1)}
              key={entries}
            />
          }
    </>
  )
}

export default SignInActivity