import React, { useEffect, useState } from 'react'
import SaleAvailable from "./ChildComponents/SaleAvailable";
import SaleNotAvailable from "./ChildComponents/SaleNotAvailable";
import Loader from "react-js-loader";


const ICOPhase = (props) => {

  //Setting icoPhase component
  const[icoPhase,setIcoPhase] = useState(false);
  const[icoData,setIcoData] = useState([]);
  const[icoPhaseLoaded,setIcoPhaseLoaded] = useState(false);

  //ICO Phase API Call
  const getICOphase = () =>{
    let authToken = localStorage.getItem("token");
    let access_type = localStorage.getItem("access_type");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Accept", "application/json");
    myHeaders.append("Authorization", access_type + " " + authToken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(process.env.REACT_APP_API_URL+`get-buy-coin-and-phase-information`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        let response = JSON.parse(result)
        setIcoData(response.data)
        setIcoPhase(response.data.activePhase.status)
        setIcoPhaseLoaded(true)
      })
      .catch((error) => console.log("error", error));
  }

  useEffect(() => {
    getICOphase()
  }, [])

  return (
    <>
        <div className="flex flex-col w-w95">
            <div className="flex flex-row justify-start w-full items-center mt-14 sm:mt-7">
                <h1 className={(props.mode === "light" ? "text-darkblue" : "text-white") + " text-medium"}>Buy Coins</h1>
            </div>
            </div>
            {/* <NewEntries.Provider value={setNewEntries}> */}
            {/* Rendering Sale Component*/}
            {icoPhaseLoaded?(
            icoPhase? <SaleAvailable mode={props.mode} data={icoData}/> : <SaleNotAvailable mode={props.mode} data={icoData}/>
            ):
            <div className="flex flex-row justify-between my-8 gap-6 md:block sm:block xxs:mt-6 h-[423px]">
              <div className="absolute top-[35%] left-[55%] md:top-[50%] md:left-[45%] xs:left-[38%]">
                  {/* <Loader type="spinner-default" bgColor={props.mode==='light'?"#bb9b5e":"#FFFFFF"} color={'#FFFFFF'} size={100} /> */}
                  <span className='text-fontblue '>Loading...</span>
              </div>
            </div>
            }
            {/* </NewEntries.Provider> */}
    </>
  )
}

export default ICOPhase