import React,{useState,useEffect} from "react";
import logo from "../components/Images/iHodl-3.png";
import { Link, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik } from "formik";
import { APP_URLS } from "../api/url";
import { toast, ToastContainer } from 'react-toastify';
import API from "../api/axios";

const VerifyAccount = () => {
  const navigate = useNavigate()
  var url = new URL(document.URL);
  const params = url.searchParams; 
  const verification_email = params.get('verification_email');

  const [loading, setLoading] = useState(false);
  const numberRegExp = /^\d+$/;

  useEffect(() => {

  const registerSuccessMessage = localStorage.getItem('register_success_message');
  const emailVerificationErrorMessage = localStorage.getItem('email_verification_error_message');
    if(verification_email === '' || verification_email === null) {
      navigate('/login')
    }

    if (registerSuccessMessage !== null && registerSuccessMessage !== undefined && registerSuccessMessage !== '') {
      toast.success(registerSuccessMessage, {
        position: toast.POSITION.TOP_RIGHT
      })
      localStorage.removeItem('register_success_message');
    }

    if (emailVerificationErrorMessage !== null && emailVerificationErrorMessage !== undefined && emailVerificationErrorMessage !== '') {
      toast.error(emailVerificationErrorMessage, {
        position: toast.POSITION.TOP_RIGHT
      })
      localStorage.removeItem('email_verification_error_message');
    }
  }, [navigate, verification_email]);

  const formik = useFormik({
    initialValues: {
      access_code: '',
      email: verification_email
    },
    validationSchema: Yup.object({
        access_code: Yup.string().matches(numberRegExp, 'Only Digits allowed').required('Verification Code is Required').max(6, 'The "Verification Code" field may not be greater than 6 digits'),
    }),
    onSubmit: values => {
        if(!loading) {
            setLoading(true)
            API.post(APP_URLS.EMAIL_VERIFICATION, values)
            .then(res => {
                const resp = res.data;
                setLoading(false)

                toast.success(resp.message, {
                  position: toast.POSITION.TOP_RIGHT
                })

                localStorage.setItem('email_verified_message', resp.message);
                navigate('/login');
            })
            .catch(function (error) {  
                const resp = error.response;
                setLoading(false)
                if (resp) {                  
                    let error_message = '';
                    if (resp.data.errors !== undefined) {
                      {
                        Object.keys(resp.data.errors).map((error) => {
                          return error_message = resp.data.errors[error][0]
                        })
                      }
                    } else if (resp.data.data.error !== undefined) {
                        error_message =  resp.data.data.error;
                    } else {
                        error_message =  resp.data.error
                    }

                    toast.error(error_message, {
                        position: toast.POSITION.TOP_RIGHT
                    })
                }
            });         
        }
    },
  });

  return (
    <>
      <ToastContainer />
      <div className="w-full flex w-full bg-bgblue px-4 py-10 min-h-screen">
          <div className="shadow-lg self-center w-full max-w-lg m-auto bg-elemblue2 rounded p-7 border border-btnblue">   
            <header className="text-center">
            <img src={logo} alt="Logo" className="w-2/6 flex mx-auto" />
            <h1 className="text-center text-white text-bold text-lg mb-2">Verify Account</h1>
            </header>   
            <form onSubmit={formik.handleSubmit} className="">
              <div>
                <label className="block mb-1 text-white" htmlFor="access_code">Enter Code</label>
                <input 
                    className={"w-full p-2 text-darkblue border-b-2 border-indigo-500 outline-none focus:bg-gray-300 bg-white text-white rounded-3xl h-h50 px-4" + (formik.touched.access_code && formik.errors.access_code ? ' is-invalid' : '')} 
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    type="text" name="access_code"
                    value={formik.values.access_code}
                    placeholder="Enter Code" />
                    {formik.touched.access_code && formik.errors.access_code ? (
                          <div className='text-left text-red text-xs pt-2'>{formik.errors.access_code}</div>
                      ) : null}
          
                <button className="w-full mt-9 bg-btnblue hover:bg-pink-700 font-bold py-2 px-4 rounded-3xl h-h50 text-white" type="submit" disabled={loading}>{ loading ? 'Verifying Up...' : 'Verify Account' }</button>
              </div>       
            </form>  
            <footer className="float-right mt-5 text-white">
              Already have an account? <Link className="text-btnblue text-lg" to="/login">Login Here</Link>
            </footer>   
          </div>
        </div>
    </>
  )
}

export default VerifyAccount