import React, { useState } from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faGear} from "@fortawesome/free-solid-svg-icons";
import SwitchMode from './SwitchMode';


const Mode = () => {
  const [display,setDisplay] = useState(false)
  const handleClick = () =>{
    if(display==true){
      setDisplay(false)
    }
    else{
      setDisplay(true)
    }
  }
   return ( 
    <>
        {/* <span className='bg-darkpink fixed right-0 p-3 mt-14 rounded-tl-xl rounded-bl-xl cursor-pointer z-10' onClick={handleClick}>
          <FontAwesomeIcon icon={faGear} className="text-white text-medium text-center text-2xl mx-1"/>
        </span> */}
        {
          display?<SwitchMode />:''
        }
        
        
    </>
  )
}

export default Mode;