import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus, faCircleXmark, faXmarkCircle } from "@fortawesome/free-solid-svg-icons";
import { useFormik } from "formik";
import * as Yup from 'yup';
import Gauth from "./Gauth";
import { toast } from 'react-toastify';
import API from "../../../../api/axios";
import { APP_URLS } from "../../../../api/url";



// let dropdownValues= []
const WithdrawlPopUp = (props) => {
    const [wallet,] = useState(props.wallet);
    const [loading, setLoading] = useState(false);
    const [gauth,setGauth]=useState(false)
    const [popup,setPopup]=useState()

    const [dropdownValues,setDropDownValues] = useState([])
    const [address,setAddress] = useState('')
    const [search,setSearch] = useState('')
    const [dataupdated,setDataupdated] = useState(false)

    const [textField,settextField] = useState('')
    function onCloseClickHandle() {
        props.onClosePopup()
    }

    const getAddressList =()=>{
        API.get(APP_URLS.GET_WITHDRAWAL_ADDRESS_BOOK+`?search=${search}&coin_type=${wallet.coin_type}`)
        .then(res => { 
          if (res.data.success === true) {
            setDropDownValues([])
            let items = res.data.data
            for (const item in items) {
                setDropDownValues((oldValues)=>{
                    return[...oldValues,{
                        'name':items[item],
                        'address':item,
                }]
                })
              }  
            } else {
              toast.error(res.data.message, {
                position: toast.POSITION.TOP_RIGHT
              })        
            }
          })
          .catch((error) => {
            const resp = error.response;
            if (resp) {
                let error_message = '';
                if (resp.data.errors !== undefined) {
                    {Object.keys(resp.data.errors).map((error, index) => (
                        error_message = resp.data.errors[error][0]
                    ))}
                } else if (resp.data.data.error !== undefined) {
                    error_message =  resp.data.data.error;
                } else {
                    error_message =  resp.data.error
                }
  
                toast.error(error_message, {
                    position: toast.POSITION.TOP_RIGHT
                })
            }
          }) 
    }

    const updated =() =>{
        setDataupdated(false)
    }

    useEffect(() => {
        getAddressList()
    }, [search])

    const formik = useFormik({
        initialValues: {
            address: address,
            amount: '',
            code: wallet.coin_type,
            wallet_id: wallet.id
        },
        validationSchema: Yup.object({
            address: Yup.string().required('Address is Required'),
            amount: Yup.number().required('Amount is Required').positive(0.00000010, 'Minimum amount should be greater than 0.00000010').max(99999999.00000000, 'Maximum amount should be less than 99999999.00000000'),
        }),
        onSubmit: values => {
            let gSecret = JSON.parse(localStorage.getItem('google2fa_secret'))
            if(gSecret===true){
                setGauth(true)
                setPopup(<Gauth setGauth={setGauth} values={values} onCloseClickHandle={onCloseClickHandle} mode={props.mode} setUpdated={props.setUpdated} textField={textField}/>)
            }
            else{
                toast.error("Please setup your Two-Factor Google Authentication", {
                    position: toast.POSITION.TOP_RIGHT
                  })
            }
        },
    });
    
    return (
        <>
        {gauth?
            popup:
            <div className="flex flex-center bg-darkblue bg-opacity-80 w-full fixed z-20 grid h-screen place-items-center top-0 left-0 right-0">
                <div className="relative w-50 z-30 md:left-0 md:w-w50 sm:w-w90 sm:mx-auto">
                    <div className={(props.mode === 'light' ? 'bg-orange text-darkblue' : 'bg-bgblue border-lightblue ') + '  relative block mx-auto border border-8 rounded-2xl text-center px-8 py-10 max-w-md max-h-hv90 h-auto sm:p-2'} >
                        <form className='text-center' onSubmit={formik.handleSubmit} >
                            <div className='max-h-hv70 h-auto overflow-y-auto scrollbar-hide'>
                                <h2 className={(props.mode === 'light' ? 'text-darkblue' : 'text-white') + ' pl-2 text-lg mb-2 tracking-wider'} >Withdrawal</h2>
                                <button className='absolute -right-5 -top-5' onClick={() => onCloseClickHandle()}> <FontAwesomeIcon className='text-elemblue text-3xl bg-white rounded-full' icon={faXmarkCircle} /> </button>
                                <p className='text-red text-xs pl-2'>
                                    <em>
                                        Please input your {wallet.coin_type} Coin address carefully. Because of wrong address if coin is lost, we will not be responsible for the Amount.
                                    </em>
                                </p>

                                <div className='w-full pt-4'>
                                    <div className='flex flex-row items-center my-2'>
                                        <p className={(props.mode === 'light' ? 'text-darkblue' : 'text-white') + ' pl-2 text-small xl:text-xs'}>Address</p>
                                    </div>
                                    <div className="relative">
                                        <input
                                            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}                                       
                                            type="text"
                                            autoComplete="off"
                                            onChange={(e)=>{
                                                formik.handleChange(e)
                                                setSearch(e.target.value)
                                            }}
                                            onBlur={(e)=>{
                                                formik.handleBlur(e)
                                                setTimeout(updated, 200);
                                            }}
                                            onFocus={()=>setDataupdated(true)}
                                            name="address"
                                            value={formik.values.address}
                                            placeholder='Enter your address' className={(props.mode === 'light' ? 'bg-white' : 'bg-inputblue1 text-white') + " w-full outline-none text-xsmall p-1 border-btnblue border rounded-3xl h-h45 px-4 "} />
                                            {dataupdated&&(
                                            <ul className={(props.mode === 'light' ? 'text-darkblue bg-white' : 'text-white bg-inputblue1') + ' absolute text-left left-[5%] border border-lightblue border-opacity-50 border-t-0 w-w90 text-xs mx-auto shadow-lg max-h-28 overflow-y-auto scrollbar-hide'} >
                                                {dropdownValues.map((item,index)=>{
                                                    return <li className={(props.mode === 'light' ? 'border-b-darkblue' : 'border-b-white') + ' hover:bg-darkblue hover:bg-opacity-20 cursor-pointer border-b border-opacity-10 py-2 px-3'} key={index} onClick={()=>{
                                                        formik.setFieldValue('address',item.address)
                                                        }}>
                                                        {item.name} : {item.address}
                                                        </li>
                                                }
                                                )}
                                            </ul>
                                            )} 
                                        </div>
                                        <p className='text-xxsmall text-greylight mr-2 pl-2 mt-1 text-right'> Please input here your {wallet.coin_type} Coin address for Withdrawal</p>
                                        {formik.touched.address && formik.errors.address ? (
                                            <div className='text-left text-red text-xs pt-2 pl-2'>{formik.errors.address}</div>
                                        ) : null}
                                </div>

                                <div className='w-full py-4'>
                                    <div className='flex flex-row items-center my-2'>
                                        <p className={(props.mode === 'light' ? 'text-darkblue' : 'text-white') + ' text-small text-white pl-2 xl:text-xs'}>Amount</p>
                                        <p className='text-xxsmall text-greylight ml-2'> (Minimum Amount : 0.00000010 {wallet.coin_type})</p>
                                        <p className='text-small text-red'>*</p>
                                        
                                    </div>
                                    <div>
                                        <input
                                            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}                                       
                                            type="text"
                                            autoComplete="off"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            name="amount"
                                            value={formik.values.amount}
                                            placeholder='Enter amount'
                                            className={(props.mode === 'light' ? 'bg-white' : 'bg-inputblue1 text-white') + " w-full outline-none text-xsmall p-1 border-btnblue border rounded-3xl h-h45 px-4"} />
                                        <p className='text-xxsmall text-greylight mr-2 mt-1 text-right'> Maximum Withdrawal Amount : 99999999.00000000 {wallet.coin_type}</p>
                                        {formik.touched.amount && formik.errors.amount ? (
                                            <div className='text-left text-red text-xs pt-2 pl-2'>{formik.errors.amount}</div>
                                        ) : null}
                                    </div>
                                </div>
                                <div className='w-full'>
                                    <div className='flex flex-row items-center my-2'>
                                        <p className={(props.mode === 'light' ? 'text-darkblue' : 'text-white') + ' text-small pl-2 xl:text-xs'} >Note</p>
                                    </div>
                                    <div>
                                        <textarea placeholder='Enter note' onChange={e=>settextField(e.target.value)}
                                            className={(props.mode === 'light' ? 'bg-white' : 'bg-inputblue1 text-white') + " w-full outline-none text-xsmall p-1 border-btnblue border rounded-3xl  h-20 px-4 py-4"}>
                                        </textarea>
                                    </div>
                                </div>
                                <div className='mt-5 pl-2 '>
                                    <button type="submit" className={(props.mode==='light'?'bg-darkorange':'bg-gradient-to-r from-purple to-pink ')+' mt-3 rounded-3xl w-wp160 h-h13 text-white'} disabled={loading}>{loading ? 'Submitting...' : 'Submit'}</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        }
        </>
    );
};

export default WithdrawlPopUp;