import React, { useState, useEffect, useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRightLong,
  faCircle,
} from "@fortawesome/free-solid-svg-icons";
import verification from "../../../components/Images/verification-icon.png";
import Verify from "./Popups/Verify";
import Authentication from "./Popups/Authentication";
import { APP_URLS } from "../../../api/url";
import API from "../../../api/axios";
import { toast, ToastContainer } from "react-toastify";

const GoogleVerification = (props) => {

  //Google Key response
  const [data,setData] = useState()
  const [qrcode,setQrcode] = useState(null)
  const [activeKey,setActiveKey] =useState(null)
  const [inactiveKey,setInactiveKey] =useState(null)
  const [g2fEnabled,setg2fEnabled] = useState()
  const [dataLoaded,setDataLoaded] = useState(false)
  
  //Popups and conditional rendering
  const [pop, _pop] = useState();
  const [show, setShow] = useState();
  const [display, _display] = useState();

  //Authentication activated and deactivated state
  const [activated,setActivated] = useState(false)
  const [deactivated,setDeactivated] = useState(false)

  const getUserSetting = () =>{
    API.get(APP_URLS.GO_TO_SETTINGS)
    .then(res => { 
      if (res.data.success === true) {
          setDataLoaded(true)
          setData(res.data.data.user)
          setQrcode(res.data.data.qrcode)
          setInactiveKey(res.data.data.google2fa_secret)
          setActiveKey(res.data.data.user.google2fa_secret)
          setg2fEnabled(res.data.data.user.g2f_enabled)
          setShow(res.data.data.user.g2f_enabled==1?true:false)
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT
          })        
        }
      })
      .catch((error) => {
        const resp = error.response;
        if (resp) {
          
            let error_message = '';
            if (resp.data.errors !== undefined) {
                {Object.keys(resp.data.errors).map((error, index) => (
                    error_message = resp.data.errors[error][0]
                ))}
            } else if (resp.data.data.error !== undefined) {
                error_message =  resp.data.data.error;
            } else {
                error_message =  resp.data.error
            }

            toast.error(error_message, {
                position: toast.POSITION.TOP_RIGHT
            })
        }
      })
  }

  useEffect(() => {
    getUserSetting()
    setActivated(false)
    setDeactivated(false)
  }, [g2fEnabled,activated,deactivated])


  const toggleg2a = () =>{
    API.post(APP_URLS.TOGGLE_AUTHENTICATION)
    .then(res => { 
        if (res.data.success === true) {
          if(g2fEnabled=='1'){
            setg2fEnabled('0')
            toast.success('Google Two Factor Authentication has been disabled successfully.', {
              position: toast.POSITION.TOP_RIGHT
            })
          }
          else{
            setg2fEnabled('1')
            toast.success('Google Two Factor Authentication has been enabled successfully.', {
              position: toast.POSITION.TOP_RIGHT
            })
          }
          {show==true?setShow(false):setShow(true)}
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT
          })        
        }
      })
      .catch((error) => {
        const resp = error.response;
        if (resp) {
          
            let error_message = '';
            if (resp.data.errors !== undefined) {
                {Object.keys(resp.data.errors).map((error, index) => (
                    error_message = resp.data.errors[error][0]
                ))}
            } else if (resp.data.data.error !== undefined) {
                error_message =  resp.data.data.error;
            } else {
                error_message =  resp.data.error
            }

            toast.error(error_message, {
                position: toast.POSITION.TOP_RIGHT
            })
        }
      })
  }


  return (
    <>
    <ToastContainer />
        <div className="flex flex-row justify-start mb-5 w-full items-center mt-14 sm:mt-7">
          <h1 className={(props.mode === "light" ? "text-darkblue" : "text-white") +" text-medium"}>
            Settings
          </h1>
        </div>

        <div
          className={(props.mode === "light"? "bg-orange text-darkblue": "bg-bgdarkblue") +
            " w-full flex-col w-full px-10 py-10 rounded-lg md:px-3"
          }>
          <div className="flex flex-col mb-5">
            <p className={(props.mode === "light" ? "text-darkblue" : "text-white") +
                " text-lg mb-1"
              }>
              Use 2-step verification{" "}
            </p>
          </div>
          {dataLoaded?
          <div>
            <div className="w-full flex flex-row gap-5 lg:block gap-7 border border-y-1 border-white border-x-0 border-opacity-10 py-5">
              <div className="w-w70 lg:w-w100 flex self-center">
                <div className="">
                  <img src={verification} />
                </div>
                <div className="self-center px-5">
                  <h6 className={(props.mode === "light" ? "text-darkblue" : "text-white") +
                      " text-xl text-base"
                    }>
                    *************
                  </h6>
                  <p
                    className={(props.mode === "light" ? "text-darkblue" : "text-white") + " text-xs opacity-50"}>
                    Please on this option to enable two factor authentication at
                    log in
                  </p>
                </div>
              </div>
              <div className="w-w30 lg:w-w100 mt-2 self-center text-right pr-5">
                {show == true ? (
                  <button className={(props.mode==='light'?'text-darkblue border-darkblue':' border-blue')+' text-sm border border-1 rounded-3xl py-1 px-3'}
                    onClick={() => {
                      toggleg2a()
                      }} disabled={activeKey===undefined||activeKey===null}>
                    {" "}
                    <FontAwesomeIcon className={(props.mode==='light'?'text-darkblue':'text-blue')+' mr-1'} icon={faCircle} />
                    <span className={(props.mode==='light'?'text-darkblue':'text-blue')+' '}>Enabled</span>{" "}
                  </button>
                ) : (
                  <button className={(props.mode==='light'?'border-darkblue border-opacity-50':' border-blue')+' text-sm border border-1 rounded-3xl py-1 ml-2 px-3'}
                    onClick={() => {
                      toggleg2a()
                      }} disabled={activeKey===undefined||activeKey===null}>
                    <FontAwesomeIcon className={(props.mode==='light'?'text-darkblue text-opacity-50':'text-blue')+' mr-1'} icon={faCircle} />{" "}
                    <span className={(props.mode==='light'?'text-darkblue text-opacity-50':'text-elemblue')+''}>Disabled</span>
                  </button>
                )}
              </div>
            </div>
            {data !== undefined?
            <div className="w-full flex flex-row mt-10 mb-20">
              <button className="text-sm text-blue underline" 
              onClick={()=> {
                _display(!display)
                if(g2fEnabled=='1'){
                  toggleg2a()
                  setg2fEnabled('0')
                }
                }}>
                Remove google secret key
              </button>
            </div>:
            <div className="w-full flex flex-row mt-10 mb-10">
              <button className={(props.mode === "light"? "bg-darkorange": "bg-gradient-to-r from-lightblue to-blue") +
                  " text-lg text-center text-white rounded-md p-3 w-w30 sm:w-full"} onClick={()=> _pop(!pop)}>
                Setup <FontAwesomeIcon className="ml-3" icon={faArrowRightLong} />{" "}
              </button>
            </div>}
          </div>
          :
          <div className="flex flex-row justify-between my-8 gap-6 md:block sm:block xxs:mt-6 h-[190px]">
            <div className="absolute top-[35%] left-[55%] md:top-[50%] md:left-[45%] xs:left-[38%]">
                <span className='text-fontblue '>Loading...</span>
            </div>
          </div>
          }
        </div>
        {pop && <Verify  _pop={ _pop} mode={props.mode} inactiveKey={inactiveKey} qrcode={qrcode} setActivated={setActivated}/>}
        {display && <Authentication  _display={ _display} mode={props.mode} activeKey={activeKey} setDeactivated={setDeactivated}/>}    
    </>
  )
}

export default GoogleVerification