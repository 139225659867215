import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmarkCircle } from "@fortawesome/free-solid-svg-icons";
import { useFormik } from "formik";
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { APP_URLS } from "../../../../api/url";
import API from "../../../../api/axios";
import dropdown from '../../../../components/Images/dropdown.png';


const ModifyAddressPopUp = (props) => {

    const nameRegExp = /^[0-9-_]*[a-zA-Z-_][a-zA-Z0-9-_ ]*$/
    const addresregex = /^[a-zA-Z0-9]*$/gm

    const [loading,setLoading] = useState(false)
    const [coins,setCoins] = useState(false)
    const [dataLoaded,setDataLoaded] = useState(false)

    const [coinName,setCoinName] = useState(props.coin)
    const [categories,setcategories] = useState([])
    const [categoriesLoaded,setcategoriesLoaded] = useState(false)

    const categoryList =()=>{
        API.get(APP_URLS.GET_COIN_CATEGORIES+`/${coinName}`)
        .then(res => { 
        if (res.data.success === true) {
            for (const item in res.data.data) {
                setcategories((oldValues)=>{
                    return[...oldValues,{
                        'id':item,
                        'name':res.data.data[item],
                }]
                })
              }
              setcategoriesLoaded(true) 
            } else {
            toast.error(res.data.message, {
                position: toast.POSITION.TOP_RIGHT
            })        
            }
        })
        .catch((error) => {
            const resp = error.response;
            if (resp) {
                let error_message = '';
                if (resp.data.errors !== undefined) {
                    {Object.keys(resp.data.errors).map((error, index) => (
                        error_message = resp.data.errors[error][0]
                    ))}
                } else if (resp.data.data.error !== undefined) {
                    error_message =  resp.data.data.error;
                } else {
                    error_message =  resp.data.error
                }

                toast.error(error_message, {
                    position: toast.POSITION.TOP_RIGHT
                })
            }
        }) 

    }

    const getCoinList =()=>{
        API.get(APP_URLS.BUY_COIN_PHASE_INFORMATION)
        .then(res => { 
          if (res.data.success === true) {
                setCoins(res.data.data.coins)
                setDataLoaded(true)
            } else {
              toast.error(res.data.message, {
                position: toast.POSITION.TOP_RIGHT
              })        
            }
          })
          .catch((error) => {
            const resp = error.response;
            if (resp) {
                let error_message = '';
                if (resp.data.errors !== undefined) {
                    {Object.keys(resp.data.errors).map((error, index) => (
                        error_message = resp.data.errors[error][0]
                    ))}
                } else if (resp.data.data.error !== undefined) {
                    error_message =  resp.data.data.error;
                } else {
                    error_message =  resp.data.error
                }
  
                toast.error(error_message, {
                    position: toast.POSITION.TOP_RIGHT
                })
            }
          }) 
    }

    useEffect(() => {
        getCoinList()
        categoryList()
    }, [coinName])
    

    const formik = useFormik({
        initialValues: {
            full_name: props.name,
            address: props.address,
            coin_type: props.coin,
            category_id:props.categoryID
        },
        validationSchema: Yup.object({
            full_name: Yup.string().matches(nameRegExp, 'Name is not valid').matches(/^(.*)?\S+(.*)?$/, 'Blank spaces are not allowed').required('Name is Required').min(1).trim().max(20, 'The "Name" field can not be greater than 20 characters'),
            address: Yup.string().matches(addresregex,"Enter Valid address").required('Address is Required').min(20,"Address should be of minimum 20 alphanumeric characters").max(60,"Address should be of maximum 60 alphanumeric characters"),
        }),
        onSubmit: values => {
            setLoading(true)
            props.setmodifyPopup('')
            API.post(APP_URLS.UPDATE_ADDRESS+`/${props.id}?_method=PATCH`,values)
            .then(res => { 
                setLoading(false)
                if (res.data.success === true) {
                    props.setDataUpdated(true)
                    formik.resetForm()
                    toast.success('Address has been updated successfully.', {
                    position: toast.POSITION.TOP_RIGHT
                    })
                } else {
                    toast.error(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                    })        
                }
                })
                .catch((error) => {
                setLoading(false)
                const resp = error.response;
                if (resp) {
                    
                    let error_message = '';
                    if (resp.data.errors !== undefined) {
                        {Object.keys(resp.data.errors).map((error, index) => (
                            error_message = resp.data.errors[error][0]
                        ))}
                    } else if (resp.data.data.error !== undefined) {
                        error_message =  resp.data.data.error;
                    } else {
                        error_message =  resp.data.error
                    }
        
                    toast.error(error_message, {
                        position: toast.POSITION.TOP_RIGHT
                    })
                }
                })
        },
    });
    
    return (
        <>
            <div className="flex flex-center bg-darkblue bg-opacity-80 w-full fixed z-20 grid h-screen place-items-center top-0 left-0 right-0">
                <div className="relative w-w50 z-30 md:left-0 md:w-w50 sm:w-w90 sm:mx-auto">
                    <div className={(props.mode === 'light' ? 'bg-orange text-darkblue' : 'bg-bgblue border-lightblue ') + '  relative block mx-auto border border-8 rounded-2xl text-center px-8 py-10 max-w-md max-h-hv90 h-auto sm:px-2 sm:py-5'} >
                        <form className='text-center' onSubmit={formik.handleSubmit} >
                            <div className='max-h-hv70 h-auto overflow-y-auto scrollbar-hide'>
                                <h2 className={(props.mode === 'light' ? 'text-darkblue' : 'text-white') + ' pl-2 text-lg mb-2 tracking-wider'} >Modify Address Book</h2>
                                <button className='absolute -right-5 -top-5' onClick={() => props.setmodifyPopup('')}> <FontAwesomeIcon className='text-elemblue text-3xl bg-white rounded-full' icon={faXmarkCircle} /> </button>
                                <p className='text-red text-xs pl-2'>
                                    <em>
                                    Please verify the wallet address carefully. Funds sent to the wrong address can not be retrieved, and we hold no responsibility for lost transactions.
                                    </em>
                                </p>
                                <div className='w-full pt-4'>
                                    <div className='flex flex-row items-center my-2'>
                                        <p className={(props.mode === 'light' ? 'text-darkblue' : 'text-white') + ' pl-2 text-small xl:text-xs'}>Name</p>
                                    </div>
                                    <div>
                                        <input
                                            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}                                       
                                            type="text"
                                            autoComplete="off"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            name="full_name"
                                            value={formik.values.full_name}
                                            placeholder='Enter your name' className={(props.mode === 'light' ? 'bg-white' : 'bg-inputblue1 text-white') + " w-full outline-none text-xsmall p-1 border-btnblue border rounded-3xl h-h45 px-4 "} />
                                        {formik.touched.full_name && formik.errors.full_name ? (
                                            <div className='text-left text-red text-xs pt-2 pl-2'>{formik.errors.full_name}</div>
                                        ) : null}
                                    </div>
                                </div>

                                <div className='w-full pt-4'>
                                    <div className='flex flex-row items-center my-2'>
                                        <p className={(props.mode === 'light' ? 'text-darkblue' : 'text-white') + ' pl-2 text-small xl:text-xs'}>Address</p>
                                    </div>
                                    <div>
                                        <input
                                            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}                                       
                                            type="text"
                                            autoComplete="off"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            name="address"
                                            value={formik.values.address}
                                            placeholder='Enter your address' className={(props.mode === 'light' ? 'bg-white' : 'bg-inputblue1 text-white') + " w-full outline-none text-xsmall p-1 border-btnblue border rounded-3xl h-h45 px-4 "} />
                                        {formik.touched.address && formik.errors.address ? (
                                            <div className='text-left text-red text-xs pt-2 pl-2'>{formik.errors.address}</div>
                                        ) : null}
                                    </div>
                                </div>

                                <div className='w-full pt-4'>
                                    <div className='flex flex-row items-center my-2'>
                                        <p className={(props.mode === 'light' ? 'text-darkblue' : 'text-white') + ' pl-2 text-small xl:text-xs'}>Coin</p>
                                    </div>
                                    <div className={(props.mode==='light'?'bg-white':'bg-inputblue1 text-white')+" border-btnblue border rounded-3xl w-full outline-none h-10 text-xsmall h-h45 pr-4 shadow-lg"}>
                                    <select 
                                    style={{backgroundImage: `url(${dropdown})`}} 
                                    className={(props.mode==='light'?'bg-white':'bg-inputblue1 text-white')+"  rounded-3xl w-full outline-none h-10 text-xsmall p-1 h-h42 px-4 bg-no-repeat bg-right appearance-none"}
                                        value={formik.values.coin_type}
                                        name="coin_type"
                                        onBlur={formik.handleBlur}
                                        onChange={(e)=>{
                                            formik.handleChange(e)
                                            setCoinName(e.target.value)
                                            setcategories([])
                                            formik.setFieldValue('category_id', null)
                                        }
                                        }>
                                        <option hidden>Select a Coin</option>
                                        <option value="HDL">HDL</option>
                                        {dataLoaded&&(
                                            coins.map((item,index)=>{
                                                return <option value={item.type} key={index}>{item.type}</option>
                                            })
                                        )}
                                    </select>
                                        {formik.touched.coin_type && formik.errors.coin_type ? (
                                            <div className='text-left text-red text-xs pt-2 pl-2'>{formik.errors.coin_type}</div>
                                        ) : null}
                                    </div>
                                </div>

                                <div className='w-full pt-4'>
                                    <div className='flex flex-row items-center my-2'>
                                        <p className={(props.mode === 'light' ? 'text-darkblue' : 'text-white') + ' pl-2 text-small xl:text-xs'}>Group</p>
                                    </div>
                                    <div className={(props.mode==='light'?'bg-white':'bg-inputblue1 text-white')+" border-btnblue border rounded-3xl w-full outline-none h-10 text-xsmall h-h45 pr-4 shadow-lg"}>
                                    <select 
                                    style={{backgroundImage: `url(${dropdown})`}} 
                                    className={(props.mode==='light'?'bg-white':'bg-inputblue1 text-white')+"  rounded-3xl w-full outline-none h-10 text-xsmall p-1 h-h42 px-4 bg-no-repeat bg-right appearance-none"}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            value={formik.values.category_id}
                                            name="category_id"
                                            >
                                                <option hidden>Select a Group</option>
                                                {categoriesLoaded&&(categories.map((item,index)=>{
                                                    return <option key={index} value={item.id}>{item.name}</option>
                                                    })
                                                )}
                                        </select>
                                    </div>
                                </div>

                                <div className='mt-5 pl-2 '>
                                    <button type="submit" className={(props.mode==='light'?'bg-darkorange':'bg-gradient-to-r from-purple to-pink ')+' mt-3 rounded-3xl w-wp160 h-h13 text-white'} disabled={loading}>{loading ? 'Modifying...' : 'Modify'}</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ModifyAddressPopUp;