import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCoins
} from "@fortawesome/free-solid-svg-icons";

const AvailableCoins = (props) => {
  let values = props.values
  let balance = values.data.balance_usd

    return (
        <div className={(props.mode==='light'?'bg-white':'bg-elemblue3')+" border-b-4 px-5 border-green shadow-lg rounded h-44 w-w94 lg:w-w100 h-40 lg:mt-0 md:w-w100 sm:mt-2 md:p-5 sm:w-w100 2xl:mr-2"}>
          <h2 className={(props.mode==='light'?'text-darkblue':'text-white')+" mt-6 lg:mt-0 lg:pt-4 md:mt-0 "}>Available Balance in USD</h2>
            <div className="flex flex-row justify-between my-7 md:my-2">
            <div className={(props.mode==='light'?'bg-darkorange2':'bg-elempink1')+" w-14 h-14 rounded-full flex items-center justify-center"}>
              <div className={(props.mode==='light'?'bg-darkorange':'bg-elempink')+" w-11 h-11 rounded-full flex items-center justify-center"}>
              <FontAwesomeIcon icon={faCoins} className="text-white text-medium"/>
              </div>
            </div>
            <div>
              <p className={(props.mode==='light'?'text-darkblue':'text-lightblue')+' text-large'}>{balance}</p>
              <p className={(props.mode==="light"?'text-darkblue':'text-darkgrey')+" flex flex-col items-end"}>USD</p>
            </div>
          </div>
        </div>
      );
};

export default AvailableCoins;
