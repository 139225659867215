import React, { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeftLong, faArrowRightLong, faCheckCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import send from '../../../components/Images/send-icon.png';
import receive from '../../../components/Images/receive-icon.png';
import request from '../../../components/Images/request-icon.png';
import API from "../../../api/axios";
import { APP_URLS } from "../../../api/url";
import * as Constant from "../../../config/constant";
import PageLength from "../../../components/Shared/PageLength";
import SearchBar from "../../../components/Shared/SearchBar";
import { confirmAlert } from 'react-confirm-alert'; // Import
import { toast } from "react-toastify";
import { Theme } from "../../../App";
import Pagination from "../../../components/Shared/Pagination";

const CoinHistory = (props) => {
    const userInfo = JSON.parse(localStorage.getItem('user_info'))
    const [loading, setLoading] = useState(false);
    const [rerender,] = useState(props.rerender);
    const [accept,setAccept] = useState(false)
    const [userCoinHistory, setUserCoinHistory] = useState([]);
    const [defaultCurrency, setDefaultCurrency] = useState([]);
    const [wallets,setWallets] = useState(JSON.parse(localStorage.getItem('wallet_list')))
    const mode = useContext(Theme);

    //Setting Pagination and Search
    const [page, setPage] = useState(1);
    const [entries, setEntries] = useState(10)
    const [search, setSearch] = useState('');
    const [totalPages, setTotalPages] = useState(0);

    useEffect(() => {
        fetchUserCoinHistory();
    }, [search, page, entries, rerender]);

    const fetchUserCoinHistory = async () => {
        setLoading(true);
        await API.get(APP_URLS.COIN_HISTORY + `?limit=${entries}&search=${search}&page=${page}`)
            .then((result) => {
                const resp = result.data.data.coin_list.data;
                setTotalPages(result.data.data.coin_list.last_page)
                setDefaultCurrency(result.data.data.coin_name);
                setUserCoinHistory(resp);
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
            })
    }

    const confirm = (paymentId, action) => {
        confirmAlert({
            title: 'Confirm to submit',
            message: `Are you sure to ${action} this payment.`,
            closeOnEscape: true,
            buttons: [
              {
                label: 'Yes',
                onClick: () => acceptOrRejectPayment(paymentId, action)
              },
              {
                label: 'No'
              }
            ]
        });
    }

    const acceptOrRejectPayment = async (paymentId, action) => {
        setLoading(true);
        await API.post(APP_URLS.APPROVAL_ACTION_FOR_COIN_REQUEST, {'request_id': paymentId, 'action_flag': action})
            .then((result) => {
                setLoading(false);
                const message = result.data.message;
                if (result.data.success === true) {
                    toast.success(message, {
                        position: toast.POSITION.TOP_RIGHT
                    })
                    setTimeout(() => {
                        fetchUserCoinHistory();
                    }, 5000);
                                
                } else {
                    toast.error(message, {
                        position: toast.POSITION.TOP_RIGHT
                    })
                }
            })
            .catch((error) => {
                setLoading(false);
            })
    }

    const handleLengthChange = (length) => {
        setEntries(length)
        setPage(0)
    }

    const handleSearchChange = (search) => {
        setSearch(search)
        setPage(0)
    }

    return (
        <>
            <div>
                <h2 className={(mode == 'light' ? 'text-darkblue' : 'text-white') + ' text-white text-xl mb-1'}>Coins History</h2>
            </div>
                <span className="text-sm text-lightblue mt-2 mb-6">Note: Only transactions through email address will be visible here. To check all transactions go to Transaction History.</span>
            <div className="flex flex-row w-full justify-between">
                <PageLength onPageLengthChange={(length) => handleLengthChange(length)} />
                <div className="sm:w-w40">
                    <SearchBar onSearchClick={(search) => handleSearchChange(search)} />
                </div>
            </div>
            <div className="w-full table-auto overflow-x-auto">
                <table className="w-full mt-2 table-auto relative md:w-maxWidth">
                    <thead>
                        <tr className={(mode === 'light' ? 'bg-white' : 'bg-tableblue text-white') + " flex flex-row justify-around py-8 mt-3 rounded-xl shadow-lg"}>
                            <th className="flex justify-left w-w16 pl-8">Sent/Receive</th>
                            <th className="flex justify-start w-w24">Email</th>
                            <th className="flex justify-start w-w16">Coin Name</th>
                            <th className="flex justify-center w-w16">Coin Amount</th>
                            <th className="flex justify-start w-w16">Created At</th>
                            <th className="flex justify-center w-w16">Status</th>
                        </tr>
                    </thead>
                    <tbody>
                        {userCoinHistory.length > 0 ?
                            userCoinHistory.map((item, index) => {
                                let wallet
                                (userInfo.email !== item.receiver_email?wallet = item.sender_wallet_id:wallet = item.receiver_wallet_id)
                                let currency
                                for(let i=0;i<wallets.length;i++){
                                    if(wallets[i].id==wallet){
                                        currency =wallets[i].coin_type
                                    }
                                }
                                return (
                                    <tr key={index} className={(mode === 'light' ? 'bg-white' : 'bg-tableblue text-fontblue') + " flex flex-row justify-around items-center my-5 py-4 rounded-xl shadow-lg"}>
                                        <td className="flex justify-left w-w16 pl-8">
                                            <div className="w-11 h-11 flex items-center justify-center w-w20">
                                                {(Constant.COIN_SEND_RECEIVE_STATUS[item.status] === 'Pending') ? <img title="Requested" alt="Requested" src={request} /> : (userInfo.email !== item.receiver_email ?  <img alt="Sent" title="Sent" src={send} /> : <img title="Received" alt="Received" src={receive} />) }
                                            </div>
                                        </td>
                                        <td className={(mode === 'light' ? 'text-darkblue' : 'text-white') + ' flex justify-start w-w24'}>
                                            {userInfo.email === item.receiver_email ? item.sender_email : item.receiver_email}
                                        </td>
                                        <td className={(mode === 'light' ? 'text-darkblue' : 'text-white') + ' flex justify-left w-w16'}>
                                            {currency}
                                        </td>
                                        <td className={(mode === 'light' ? 'text-darkblue' : 'text-white') + ' flex justify-center break-all w-w16'}>
                                            {item.amount} 
                                        </td>
                                        <td className={(mode === 'light' ? 'text-darkblue' : 'text-white') + ' block justify-center w-w16'}>
                                            <span className='block'>{item.created_at}</span>
                                        </td>
                                        <td className="flex justify-center w-w16 text-white">
                                            <div className={`min-w-w45 px-2 h-8 self-center flex justify-center items-center rounded-lg text-sm 
                                                    ${Constant.COIN_SEND_RECEIVE_STATUS[item.status] === 'Accepted' ? 'bg-greenlight' : (Constant.COIN_SEND_RECEIVE_STATUS[item.status] === 'Pending' ? 'bg-darkgrey2fade' : 'bg-redLight') }`}>{Constant.COIN_SEND_RECEIVE_STATUS[item.status]}</div>
                                            { (userInfo.email !== item.receiver_email && Constant.COIN_SEND_RECEIVE_STATUS[item.status] == 'Pending') && 
                                                <>
                                                    <div className="flex self-center">
                                                        <FontAwesomeIcon icon={faCheckCircle} title='Accept' onClick={() => confirm(item.id, 'accept')} className={(mode === 'light' ? '' : '') + ' text-green cursor-pointer text-center mx-1'} /> 
                                                        <FontAwesomeIcon icon={faTimesCircle} title='Reject' onClick={() => confirm(item.id, 'reject')}  className={(mode === 'light' ? '' : '') + ' text-red  cursor-pointer text-center'} /> 
                                                    </div>
                                                </>}
                                        </td>
                                    </tr>
                                )
                            })
                            :
                            <>
                                <tr className={(mode === 'light' ? 'bg-white' : 'bg-tableblue text-fontblue') + " flex flex-row justify-around items-center my-5 py-4 rounded-xl shadow-lg"}>
                                    <td colSpan="5"> { loading ? 'Loading...' : 'No Records Found' }</td>
                                </tr>
                            </>
                        }
                    </tbody>
                </table>
            </div>

            
            {(userCoinHistory.length > 0) && 
                <Pagination 
                    totalPages={totalPages}
                    onPageClick={(page) => setPage(page + 1)}
                    key={entries}
                />
            }
          
        </>
    );
};

export default CoinHistory;

