import React,{useState,useEffect,useContext} from "react";
import logo from "../components/Images/iHodl-3.png";
import { Link, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import * as Yup from 'yup';
import { useFormik } from "formik";
import { APP_URLS } from "../api/url";
import API from "../api/axios";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Login = () => {

  const navigate = useNavigate()
  const emailRegExp = /^([a-z0-9\_\-]+)(\.[a-z0-9\+_\-]+)*@([a-z0-9\-]+\.)+[a-z]{2,6}$/;
  const [loading, setLoading] = useState(false);
  const emailVerifiedSuccessMessage = localStorage.getItem('email_verified_message');

  const [showPassword,setShowPassword] = useState(false)

  const handlePassword = () =>{
    var temp = document.getElementById("password");
    if(showPassword){
      setShowPassword(false)
      temp.type = "password"
    }
    else{
      setShowPassword(true)
      temp.type = "text"
    }
  }

  useEffect(() => {
    const token = localStorage.getItem('token');
    
    if(token != '' && token !== null) {
      navigate('/dashboard')
    }

    if (emailVerifiedSuccessMessage !== null && emailVerifiedSuccessMessage !== undefined && emailVerifiedSuccessMessage !== '') {
      toast.success(emailVerifiedSuccessMessage, {
        position: toast.POSITION.TOP_RIGHT
      })
      localStorage.removeItem('email_verified_message');
    }
  }, []);

  const formik = useFormik({
    initialValues: {
        email: '',
        password: '',
    },
    validationSchema: Yup.object({
        email: Yup.string().matches(emailRegExp, 'The "Email" field format is invalid').required('Email is Required'),
        password: Yup.string().required('Password is Required'),
    }),
    onSubmit: values => {
        if(!loading) {
            setLoading(true)
            API.post(APP_URLS.LOGIN, values)
            .then(res => {
                const resp = res.data;
                setLoading(false)

                if (resp.success === false && resp.email_verified === 0) {
                  localStorage.setItem('email_verification_error_message', resp.message)
                  navigate('/account-verification?verification_email='+values.email)
                } if (resp.success === false) {
                  toast.error(resp.message, {
                    position: toast.POSITION.TOP_RIGHT
                  })
                } else if (resp.success === true) {
                  const data = resp.data
                  localStorage.setItem('notification',data.notification_count)
                  localStorage.setItem('token',data.access_token)
                  localStorage.setItem('access_type', data.access_type)
                  localStorage.setItem('last_visit', data.last_visit)
                  localStorage.setItem('default_coin', JSON.stringify(data.default_coin))
                  localStorage.setItem('coins',JSON.stringify([]))
                  localStorage.setItem('user_info',JSON.stringify(data.user_info))
                  localStorage.setItem('mode','dark')
                  localStorage.setItem('g2f',true)
                  localStorage.setItem('google2fa_secret',data.user_info.google2fa_secret!=null&&data.user_info.google2fa_secret!==''?true:false)
                  if(data.user_info==null||data.user_info.g2f_enabled=='1'){
                    localStorage.setItem('g2f', false)
                    navigate('/g2f-checked')
                  } else{
                    window.location.reload(); 
                  }
                }
            })
            .catch(function (error) {  
                const resp = error.response;
                setLoading(false)
                if (resp) {
                  
                    let error_message = '';
                    if (resp.data.errors !== undefined) {
                        {Object.keys(resp.data.errors).map((error, index) => (
                            error_message = resp.data.errors[error][0]
                        ))}
                    } else if (resp.data.data.error !== undefined) {
                        error_message =  resp.data.data.error;
                    } else {
                        error_message =  resp.data.error
                    }

                    toast.error(error_message, {
                        position: toast.POSITION.TOP_RIGHT
                    })
                }
            });         
        }
    },
  });

  return (
    <>
      <ToastContainer />
      <div className="w-full flex w-full bg-bgblue px-4 py-10 min-h-screen">
          <div className="shadow-lg self-center w-full max-w-md m-auto bg-elemblue2 rounded p-7 border border-btnblue">   
            <header className="text-center">
            <img src={logo} className="w-2/6 flex mx-auto" />
            <h2 className="text-center text-white text-basic mb-5">Log into your account</h2>
            {/* <FontAwesomeIcon icon={faUserCircle} className='text-white text-8xl text-center mb-7' /> */}
            </header>   
            <form onSubmit={formik.handleSubmit} className="">
            <div className="col-span-2">
                <label className="block mb-1 mt-6 text-white" htmlFor="username">Email address</label>
                <input 
                  type="text" 
                   name="email" 
                  className={"w-full  p-2 text-darkblue border-b-2 border-indigo-500 outline-none focus:bg-gray-300 bg-white text-white rounded-3xl h-h50 px-4" + (formik.touched.email && formik.errors.email ? ' is-invalid' : '')}   
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  placeholder="Email Address"
                  autoComplete='off' />

                  {formik.touched.email && formik.errors.email ? (
                        <div className='text-left text-red text-xs pt-2'>{formik.errors.email}</div>
                    ) : null}
              </div>
              <div>
                <label className="block mb-1 mt-6 text-white" htmlFor="password">Password</label>
                <div className={" flex w-full p-2 text-darkblue border-b-2 border-indigo-500 outline-none bg-bluelight text-white rounded-3xl h-h50 px-4" + (formik.touched.password && formik.errors.password ? ' is-invalid' : '')}>
                <input type="password" name="password"  id="password" onChange={formik.handleChange} onBlur={formik.handleBlur}
                      // value={formik.values.password} 
                      // value="" 
                      className="w-w94 h-full focus:outline-0 bg-bluelight"
                      placeholder="Password"
                      autoComplete='off' />
                      <FontAwesomeIcon icon={showPassword?faEye:faEyeSlash} className={"text-small self-center cursor-pointer"} onClick={handlePassword}/>
                </div>
                {formik.touched.password && formik.errors.password ? (
                      <div className='text-left text-red text-xs pt-2'>{formik.errors.password}</div>
                  ) : null}
              </div>
              <div>          
                <button className="w-full mt-9 bg-btnblue hover:bg-pink-700 font-bold py-2 px-4 rounded-3xl h-h50 text-white" type="submit" disabled={loading}>{ loading ? 'Logging In....' : 'Log In' }</button>
              </div>  
            </form>  
            <footer className="mt-4">
              <Link className="text-btnblue text-sm float-left"  to="/forget-password">Forgot Password?</Link>
              <Link className="text-btnblue text-sm float-right"  to="/register">Create an Account</Link>
            </footer>   
          </div>
        </div>
    </>
  )
}

export default Login