import React, { useState, useEffect,useContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faArrowTrendDown, faArrowTrendUp } from "@fortawesome/free-solid-svg-icons";
import WatchlistLineChart from "./WatchlistLinechart";
import { SetPopUp } from "../MainContent/Dashboard";

const Watchlist = (props) => {
  const [watchlist, updateWatchList] = useState(props.watchList)

  let values = props.values
  const setdisplayPopUp = useContext(SetPopUp);

  const showPopup = (index) =>{
    let a = document.getElementById('percentage'+index)
    a.style.display = 'block'
  }

  const hidePopup = (index) =>{
    let a = document.getElementById('percentage'+index)
    a.style.display = 'none'
  }

  function deleteCoin(event) {
    props.onDeleteCoin(event);
  }

  useEffect(() => {
    updateWatchList(props.watchList)
  },[props.watchList])

  return (
    <>
      <div className={(props.mode==='light'?'bg-orange':'bg-elemblue3')+" px-5 h-full flex flex-col rounded sm:mt-6 sm:px-2"}>
        <div className="flex flex-row justify-between my-6 ">
          <h1 className={(props.mode==='light'?'':'text-white')+" text-medium"}>Watch List</h1>
          
        <button
          type="button" onClick={()=>setdisplayPopUp(true)}
          disabled={props.loading}
          className={(props.mode === "light"? "rounded-3xl w-auto px-3 bg-darkblue text-white outline-none text-small": "bg-gradient-to-r from-purple to-pink text-white rounded-3xl w-auto px-3 outline-none text-small")+" xxs:text-small"}>
          Add Coins
        </button> 
        
        </div>
        <div className="h-hr23 overflow-y-auto scrollbar-hide">
        {watchlist && watchlist.map((item,index)=>{
          let price = parseFloat(item.price).toFixed(2);
          let priceChange = item["1d"]?.price_change?parseFloat(item["1d"]?.price_change).toFixed(2):'00.00';
          let priceChangePct = item["1d"]?.price_change_pct?parseFloat(item["1d"]?.price_change_pct):'00.00';
          let currency = parseFloat(values.data.coins_amount[item.currency])
          let logo = item.logo_url?item.logo_url:require('../Images/icons/hdl-icon.png')

          return <div className="flex flex-row justify-between my-4 md:mx-0" key={index}>
            <div className="w-30 flex">
              <div className="w-12 h-12 bg-purple2 self-center rounded-full flex items-center overflow-hidden justify-center lg:h-9 lg:w-9 xxs:w-7 xxs:h-7">
                <img className="w-full h-full mx-auto" src={logo} alt="Logo" />
              </div>
              <div className="ml-5 self-center self-center xl:ml-3 lg:ml-2">
                <p className={(props.mode==='light'?'':'text-white')+" text-medium w-16 xl:text-sm xxs:text-small"}>{item.name}</p>
                <p title={currency} className={(props.mode==='light'?'':'text-darkgrey')+" text-xsmall xxs:text-xsmall2 cursor-pointer"}>{currency>0?currency.toFixed(2):'0'} {item.currency}</p>
              </div>
            </div>
            <div className="w-w16 self-center lg:hidden">
              <WatchlistLineChart coinType={item.currency} color="#2eb82e"/>
            </div>
              <div className="relative flex flex-col w-w40 self-center xs:w-w50 lg:w-w60">
                <div className="flex flex-row justify-between self-end flex-end">
                  <span>
                  {priceChange>0?<FontAwesomeIcon icon={faArrowTrendUp} className="text-green text-medium mr-2 sm:hidden" />:
                  <FontAwesomeIcon icon={faArrowTrendDown} className="text-red text-medium mr-3 lg:mr-2 sm:hidden" />} 
                  </span>               
                  <h2 className={(props.mode==='light'?'':'text-white')+" text-small lg:text-sm lg:text-left"}>${price}</h2>
                  <button disabled={props.loading} className="cursor-pointer h-6 w-6 ml-2 bg-white rounded-full shadow-lg text-center justify-between" title='Delete'  onClick={() => deleteCoin(item.currency)}><FontAwesomeIcon className="text-red text-xs item-center text-center align-baseline" icon={faTrash} /></button >
                </div>
                <div className="flex flex-row items-center justify-end pr-8 xl:pr-2 xs:pr-2 xs:pt-2">
                  <p className={(props.mode==='light'?'':'text-white')+' text-xsmall'}> $ {priceChange}</p>
                  <p className={(priceChange>0?'text-green':'text-red')+" text-xsmall pl-2"} onMouseEnter={()=>showPopup(index)} onMouseLeave={()=>hidePopup(index)}>{priceChangePct}%</p>
                </div>

                <div className={(props.mode==='light'?'border-borderGrey bg-bgLight':'border-white bg-white')+' border w-auto absolute top-10 right-1 z-10 px-2 lg:text-xxsmall lg:pt-1 hidden'} id={'percentage'+index}>{priceChangePct}%</div>
              </div>
          </div>
        })}
        </div>
      </div>
    </>
  );
};

export default Watchlist;
