import React, { useState, useContext } from "react";
import { PAGE_LENGTH } from '../../config/constant'
import { Theme } from "../../App";

const PageLength = (props) => {
    const [entries, setEntries] = useState(10)
    const mode = useContext(Theme);

    function handleChange(e) {
        setEntries(e.target.value)
        props.onPageLengthChange(e.target.value);
    }

    return (
        <>
            <div className="flex flex-row w-wp170 items-center sm:py-3">
                <p className={(mode === 'light' ? '' : 'text-white')}>Show</p>
                <select 
                    value={entries}
                    onChange={handleChange}
                    className={(mode === 'light' ? 'bg-white' : 'bg-elemblue2 text-white') + " w-14 text-xsmall p-1 shadow-lg rounded-lg outline-none mx-2"}>
                    {PAGE_LENGTH.map((length, index) => {
                        return <option key={index} value={length}> { length }</option>
                    })}
                </select>
                <p className={(mode === 'light' ? '' : 'text-white')}>entries</p>
            </div>
        </>
    );
};

export default PageLength;