import React, { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeftLong, faArrowRightLong } from "@fortawesome/free-solid-svg-icons";
import API from "../../../api/axios";
import { APP_URLS } from "../../../api/url";
import { NavLink } from "react-router-dom";
import primary from '../../../components/Images/primary.png';
import wallet from '../../../components/Images/wallet.png';
import withdrawal from '../../../components/Images/withdrawal.png';
import log from '../../../components/Images/log.png';
import swap from '../../../components/Images/swap.png';
import PageLength from "../../../components/Shared/PageLength";
import SearchBar from "../../../components/Shared/SearchBar";
import { toast } from 'react-toastify';
import { Theme } from "../../../App";
import Pagination from "../../../components/Shared/Pagination";
import { click } from "@testing-library/user-event/dist/click";

const PersonalWallet = (props) => {
    const [loading, setLoading] = useState(false);
    const [personalWalletList, setPersoanlWalletList] = useState([]);
    const mode = useContext(Theme);

    //Setting Pagination and Search
    const [page, setPage] = useState(1);
    const [entries, setEntries] = useState(10)
    const [search, setSearch] = useState('');
    const [totalPages, setTotalPages] = useState(0);

    const [selectedWallet,setSelectedWallet] = useState()
    useEffect(() => {
        if(props.updated===true){
            setTimeout(personalWallet, 7000);
            props.setUpdated(false)
        }
        else{
            personalWallet()
        }
    }, [search, page, entries,props.updated]);

    const personalWallet = async () => {
        setLoading(true);
        await API.get(APP_URLS.PERSONAL_WALLET + `?limit=${entries}&search=${search}&page=${page}`)
          .then((result) => { 
            const resp = result.data.data;
            setPersoanlWalletList(resp.data);
            setTotalPages(result.data.data.last_page)
            setLoading(false);
          })
          .catch((error) => {
            setLoading(false);
          })
    }

    function handleWithdrawClick(wallet) {
        props.onPopupChange(wallet)
    }
    
    function handleActivityLog(wallet) {
        props.onActivityLog(wallet)
    }

    function handleDeposit(wallet) {
        props.onClickDeposit(wallet)
    }

    const setAsPrimary = async (walletId) => {
        await API.post(APP_URLS.SET_WALLET_PRIMARY, {'wallet_id': walletId})
          .then((result) => { 
            setLoading(false);
            const message = result.data.message;
            if (result.data.success === true) {
                toast.success(message, {
                    position: toast.POSITION.TOP_RIGHT
                })
                personalWallet()
            } else {
                toast.error(message, {
                    position: toast.POSITION.TOP_RIGHT
                })
            }
          })
          .catch((error) => {
            setLoading(false);
          })
    }

    const handleLengthChange = (length) => {
        setEntries(length)
        setPage(0)
    }

    const handleSearchChange = (search) => {
        setSearch(search)
        setPage(0)
    }
    
    return (
        <>
            
            <div className="flex flex-row w-full justify-between">               
                <PageLength onPageLengthChange={(length) => handleLengthChange(length)} />
                <div className="sm:w-w40">
                    <SearchBar onSearchClick={(search) => handleSearchChange(search)} />
                </div>
            </div>
            <div className='w-full table-auto overflow-x-auto'>
                <table className="w-full mt-2 table-auto relative md:w-maxWidth">
                    <thead>
                        <tr className={(mode === 'light' ? 'bg-white' : 'bg-tableblue text-white') + " flex flex-row justify-around mt-4 py-8 rounded-xl shadow-lg"}>
                            <th className="flex justify-left w-w20 pl-8">Name</th>
                            <th className="flex justify-center w-w20">Coin Type</th>
                            <th className="flex justify-left w-w20 pl-8">Balance</th>
                            <th className="flex justify-left w-w20">Updated At</th>
                            <th className="flex justify-left w-w20">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {personalWalletList.length > 0 ? 
                            personalWalletList.map((item, index) => {
                                return (
                                    <tr key={index} className={(selectedWallet==index?(mode==='light'?'bg-selectedLight bg-opacity-30':'bg-selectedDark bg-opacity-30 text-fontblue'):(mode === 'light' ? 'bg-white' : 'bg-tableblue text-fontblue'))+" flex flex-row justify-around items-center my-5 py-4 rounded-xl shadow-lg"}>
                                        <td className={(mode === 'light' ? 'text-darkblue' : 'text-white') + ' flex justify-left w-w20 pl-8'}>
                                            {item.name}
                                        </td>
                                        <td className={(mode === 'light' ? 'text-darkblue' : 'text-white') + ' flex justify-center w-w20'} >
                                            {item.coin_type}
                                        </td>
                                        <td className={(mode === 'light' ? 'text-darkblue' : 'text-white') + ' flex justify-left  w-w20 pl-8'} >
                                            {item.balance}
                                        </td>
                                        <td className={(mode === 'light' ? 'text-darkblue' : 'text-white') + ' block justify-left w-w20'}>
                                            <span className='block'>{item.updated_at}</span>
                                            {/* <span className='text-xs opacity-50 block'>05:34</span> */}
                                        </td>
                                        <td className="flex justify-left w-w20 text-white" onClick={()=>setSelectedWallet(index)} >
                                            {/* <button className={'mr-4 self-center xl:mr-2 ' + (item.is_primary === 1 ? 'hidden' : '')} onClick={() => setAsPrimary(item.id)}><img src={primary} title='Make Primary' /></button> */}
                                            {item.coin_type==='Default'?null:
                                                <NavLink className='mr-4 xl:mr-2' to={`/wallet/deposit/${item.id}`}><img src={wallet} title='Deposit' /></NavLink>
                                            }
                                            {item.coin_type==='Default'?
                                            <NavLink to={`/withdraw-coins/`} className='mr-4 self-center xl:mr-2'><img src={withdrawal} alt='Withdrawal' title='Withdrawal' /></NavLink>:
                                            <button className='mr-4 self-center xl:mr-2' onClick={() => handleWithdrawClick(item)}><img src={withdrawal} alt='Withdrawal' title='Withdrawal' /></button>}
                                            <NavLink className='mr-4 xl:mr-2' to={`/wallet/activity-log/${item.id}`}><img src={log} title='Log' /></NavLink>
                                            {/* <button className='mr-4 self-center xl:mr-2' onClick={() => handleActivityLog(item)}><img src={log} alt='Log' title='Log' /></button> */}
                                            <NavLink to={`/swap-coins/${item.id}`} className='mr-4 self-center xl:mr-2'><img src={swap} alt='Swap' title='Swap' /></NavLink>
                                        </td>
                                    </tr>
                                )
                            })
                            : 
                            <>
                                <tr className={(mode === 'light' ? 'bg-white' : 'bg-tableblue text-fontblue') + " flex flex-row justify-around items-center my-5 py-4 rounded-xl shadow-lg"}>
                                    <td colSpan="6">{ loading ? 'Loading...' : 'No Records Found' }</td>
                                </tr>
                            </>
                        }
                    </tbody>
                </table>
            </div>
            {(personalWalletList.length > 0) && 
                <>
                    <Pagination 
                        totalPages={totalPages}
                        onPageClick={(page) => setPage(page + 1)}
                        key={entries}
                    />
                </>
            }
            
        </>
    );
};

export default PersonalWallet;