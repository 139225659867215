import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'

const Card = ({data,mode,dataFetched,setDataFetched}) => {

    const [displayAnswer,setDisplayAnswer] = useState(false)
    
    const display =()=>{
        {displayAnswer===true?setDisplayAnswer(false):setDisplayAnswer(true)}
    }

    useEffect(() => {
        setDisplayAnswer(false)
        setDataFetched(false)
    }, [dataFetched])
    
  return (
    <>
    <div className='' id={data.id}>
        <div className={(mode==='light'?'bg-darkorange bg-opacity-40 text-darkblue':'bg-tableblue ')+' flex justify-between text-white p-4 border border-white border-opacity-10 cursor-pointer'} onClick={display}>
        <h1>
            <b>Question :</b> {data.question}
        </h1>
        <span className={(mode==='light'?'text-darkblue':'text-white ')+' self-center ml-5'}>
            {displayAnswer?
                    <FontAwesomeIcon icon={faMinus} className={"text-lg self-center mr-1"} />:
                    <FontAwesomeIcon icon={faPlus} className={"text-lg self-center mr-1"} />}
        </span>
        </div>
        {displayAnswer===true?
        <div className={(mode==='light'?'bg-orange bg-opacity-2 text-darkblue':'bg-tableblue text-white ')+' p-4 text-sm bg-opacity-50'}>
            <p className=''>
                <b>Answer :</b> {data.answer}
            </p>
        </div>
        :
        ''}
    </div>
    </>
  )
}

export default Card