import React,{useState,useEffect,useContext} from 'react'
import check from "../../../Images/check.png";
import warning from "../../../Images/warning.png";
import displaypic from "../../../Images/dp.png";
import {
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { APP_URLS } from "../../../../api/url";
import API from "../../../../api/axios";
import { toast, ToastContainer } from 'react-toastify';
import { Update } from '../../../../Pages/ProfilePage';
import dropdown from '../../../../components/Images/dropdown.png';


const ProfileInformationForm = (props) => {
  
  const emailRegExp = /^([a-z0-9\_\-]+)(\.[a-z0-9\+_\-]+)*@([a-z0-9\-]+\.)+[a-z]{2,6}$/;
  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
  const nameRegExp = /^[_A-zA-Z]*((-|\s)*[_A-zA-Z])*$/g

  
  const FILE_SIZE = 1024 * 1024;
  const SUPPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/png"
  ];
  let fname = props.data.user.first_name
  let lname = props.data.user.last_name
  let email = props.data.user.email!=null?props.data.user.email:''
  let phone = props.data.user.phone!=null?props.data.user.phone:''
  let gender = props.data.user.gender
  let country = props.data.user.country_code!=null?props.data.user.country_code:''
  
  const [image,setImage] = useState(props.data.user.photo)

  const setProfileUpdated = useContext(Update);
  
  const [dropdownValues, setDropdownValues] = useState([]);

  const setCountries =()=>{
      let countries = props.data.countries
      let newDropdownValues = []
      for (const key in countries) {
        newDropdownValues.push(<option value={key} key={key}>{countries[key]}</option>)
    }
      setDropdownValues(newDropdownValues)
    }
    useEffect(() => {
      setCountries()
    }, [])
      

    const getUserProfile = () =>{
      API.get(APP_URLS.PROFILE_VIEW,)
      .then(res => { 
          if (res.data.success === true) {
            localStorage.setItem('user_info',JSON.stringify(res.data.data.user))
            setProfileUpdated(true)
          } else {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT
            })        
          }
        })
        .catch((error) => {
          const resp = error.response;
          if (resp) {
            
              let error_message = '';
              if (resp.data.errors !== undefined) {
                  {Object.keys(resp.data.errors).map((error, index) => (
                      error_message = resp.data.errors[error][0]
                  ))}
              } else if (resp.data.data.error !== undefined) {
                  error_message =  resp.data.data.error;
              } else {
                  error_message =  resp.data.error
              }
  
              toast.error(error_message, {
                  position: toast.POSITION.TOP_RIGHT
              })
          }
        })
    }

    const formik = useFormik({
        initialValues: {
          first_name:fname,
          last_name:lname,
          email:email,
          residence:country,   
          phonenumber:phone,
          gender:gender,
          photo:null
        },
        validationSchema: Yup.object({
          first_name: Yup.string().matches(nameRegExp, 'First Name is not valid').matches(/^(.*)?\S+(.*)?$/, 'Blank spaces are not allowed').required('First Name is Required').min(1).trim().max(20, 'The "First Name" field can not be greater than 20 characters'),
          last_name: Yup.string().matches(nameRegExp, 'Last Name is not valid').matches(/^(.*)?\S+(.*)?$/, 'Blank spaces are not allowed').required('Last Name is Required').min(1).trim().max(20, 'The "Last Name" field can not be greater than 20 characters'),
          email: Yup.string().matches(emailRegExp, 'The "Email" field format is invalid').required('Email is Required'),
          phonenumber: Yup.string().matches(phoneRegExp, 'Phone number is not valid').min(10,"Phone number should be min of 10 digits").max(13,"Phone number should be max of 13 digits"),
          photo: Yup.mixed().nullable()
          .test(
            'fileFormat',
            'Unsupported file type',
            (value) => value === null || (value && SUPPORTED_FORMATS.includes(document.querySelector('input[type="file"]').files[0].type))
          )
          .test('fileSize', 'File too large, Max Size: 1 MB', (value) => value === null || (value && document.querySelector('input[type="file"]').files[0].size <= FILE_SIZE))
        }),
        onSubmit: values => {
          var imagedata = document.querySelector('input[type="file"]').files[0];
          var formdata = new FormData();
          formdata.append("first_name", values.first_name);
          formdata.append("last_name", values.last_name);
          formdata.append("country_code", values.residence);
          formdata.append("phone", values.phonenumber);
          formdata.append("country", values.residence);
          formdata.append("gender", values.gender);
          formdata.append("photo", (imagedata?imagedata:''));
          API.post(APP_URLS.SAVE_EDITED_PROFILE, formdata)
          .then(res => { 
              if (res.data.success === true) {
                props.setUpdated(true)
                getUserProfile()
                toast.success('Profile has been Updated successfully.', {
                  position: toast.POSITION.TOP_RIGHT
                })
              } else {
                toast.error(res.data.message, {
                  position: toast.POSITION.TOP_RIGHT
                })        
              }
            })
            .catch((error) => {
              const resp = error.response;
              if (resp) {
                
                  let error_message = '';
                  if (resp.data.errors !== undefined) {
                      {Object.keys(resp.data.errors).map((error, index) => (
                          error_message = resp.data.errors[error][0]
                      ))}
                  } else if (resp.data.data.error !== undefined) {
                      error_message =  resp.data.data.error;
                  } else {
                      error_message =  resp.data.error
                  }
      
                  toast.error(error_message, {
                      position: toast.POSITION.TOP_RIGHT
                  })
              }
            })      
        },
      });

    const loadFile = function(e) {
      let image = document.getElementById('profileImage');
      if(typeof(formik.errors.photo) == "undefined"){
        image.src = URL.createObjectURL(e.target.files[0]);
      }  
    };

    return (
        <>
            <form className="flex flex-row mb-8 gap-8 md:gap-5 sm:block md:mb-5" onSubmit={formik.handleSubmit}>
            <div className={(props.mode==='light'?'bg-orange':'bg-elemblue2 ')+' w-w30 py-7 px-5 rounded-lg md:w-w40 sm:w-full sm:mb-5'} >
              <div className='block'>
                <div className="flex ml-2 border border-2 border-opacity-20 border-white rounded-full h-16 w-16 overflow-hidden mb-1">
                  <img className='object-cover mx-auto' id='profileImage' src={image} />                
                </div>                
                <input className='self-center ml-2 mb-4 text-xs text-lightblue form-control' accept="image/*" type="file" value={formik.values.photo}
                      onChange={formik.handleChange}
                      onBlur={e=>{
                          formik.handleBlur(e)
                          loadFile(e)
                      }}
                     name="photo"/>
                      {formik.touched.photo && formik.errors.photo ? (<div className='text-left text-red text-xs mt-2 pl-2'>{formik.errors.photo}</div>) : null}
              </div>

              <h2 className={(props.mode==='light'?'text-darkblue':'text-white')+' text-xl mb-2 pl-2'}>
                Welcome, {props.data.user.first_name} !
              </h2>
              <div>
                <p className="text-sm text-lightblue mr-2 my-2 pl-2">
                  {" "}
                  {props.data.user.email!=null?email:''}
                </p>
              </div>
              <hr className="opacity-20 my-5 border-white"></hr>
              {props.data.user.is_verified===1?
              <div className="flex">
                <img className="mr-2" src={check} />
                <span className={(props.mode==='light'?'text-darkblue':'text-white')+' text-md self-center'} >
                  {" "}
                  Account Status Verified
                </span>
              </div>:
              <div className="flex">
              <img className="mr-2" src={warning} />
              <span className={(props.mode==='light'?'text-darkblue':'text-white')+' text-md self-center'} >
                {" "}
                Verification Pending
              </span>
            </div>}
            </div>
            <div className={(props.mode==='light'?'bg-orange text-darkblue':'bg-elemblue3')+' w-w70 py-7 px-5 rounded-lg md:w-w60 sm:w-full'}>
              <div className='flex justify-between'>
                <h2 className={(props.mode==='light'?'text-darkblue':'text-white')+' text-medium mb-1 sm:text-sm'}>
                  Profile Information
                </h2>
                <button type="submit" className="float-right text-lightblue text-md font-light sm:text-sm">
                  {" "}
                  <FontAwesomeIcon className="mr-2" icon={faCheck} />
                  Save Changes
                </button>
              </div>
              <div>
                <div className="flex flex-row w-w80 lg:w-w100 inline-block gap-5 pl-10 mt-5 md:pl-0 md:gap-5 md:block">
                  
                  <div className="w-w50 md:mt-5 md:w-full">
                    <div className="flex flex-row w-w100 inline-block gap-5 md:pl-0 md:gap-5 md:block">
                      <div className="w-w50 md:w-full lg:mr-2 md:mr-0">
                        <p className={(props.mode==='light'?'text-darkblue':'text-white')+' text-small ml-2 mb-1 xl:text-xs'}>
                        First Name
                        </p>
                        <input
                          type="text"
                          name="first_name"
                          autoComplete='off'
                          value={formik.values.first_name}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          placeholder="First name"
                          className={
                            (props.mode === "light"
                              ? "bg-white"
                              : "bg-inputblue1 text-white") +
                            " w-full outline-none h-10 text-sm p-1 border-btnblue border rounded-3xl h-h45 px-4 shadow-lg"
                          }
                        />
                        {formik.touched.first_name && formik.errors.first_name ? (<div className='text-left text-red text-xs mt-2 pl-2'>{formik.errors.first_name}</div>) : null}
                        <hr className="border-white border-opacity-10 mt-6 mb-1 md:hidden "></hr>
                      </div>
                      <div className="w-w50 md:mt-5 md:w-full lg:mr-2 md:mr-0">
                        <p className={(props.mode==='light'?'text-darkblue':'text-white')+' text-small ml-2 mb-1 xl:text-xs'}>
                        Last Name
                        </p>
                        <input
                          type="text"
                          name="last_name"
                          autoComplete='off'
                          value={formik.values.last_name}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          placeholder="Last name"
                          className={
                            (props.mode === "light"
                              ? "bg-white"
                              : "bg-inputblue1 text-white") +
                            " w-full outline-none h-10 text-sm p-1 border-btnblue border rounded-3xl h-h45 px-4 shadow-lg"
                          }
                        />
                        {formik.touched.last_name && formik.errors.last_name ? (<div className='text-left text-red text-xs mt-2 pl-2'>{formik.errors.last_name}</div>) : null}
                        <hr className="border-white border-opacity-10 mt-6 mb-1 md:hidden "></hr>
                      </div>
                    </div>
                  </div>
                  <div className="w-w50 md:w-full md:mt-5">
                  <p className={(props.mode==='light'?'text-darkblue':'text-white')+' text-small ml-2 mb-1 xl:text-xs'}>
                      Email address
                    </p>
                    <input
                      type="email"
                      name="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder="Email address"
                      disabled
                      className={
                        (props.mode === "light"
                          ? "bg-white"
                          : "bg-inputblue1 text-white") +
                        " w-full outline-none h-10 text-sm p-1 border-btnblue border rounded-3xl h-h45 px-4 shadow-lg opacity-40 cursor-not-allowed"
                      }
                    />
                    {formik.touched.email && formik.errors.email ? (<div className='text-left text-red text-xs mt-2 pl-2'>{formik.errors.email}</div>) : null}
                    <hr className="border-white border-opacity-10 mt-6 mb-1 md:hidden"></hr>
                  </div>
                </div>
                <div className="flex flex-row w-w80 lg:w-w100 inline-block gap-5 pl-10 mt-5 md:pl-0 md:gap-5 md:block">
                  <div className="w-w50 md:w-full md:mb-5 lg:mr-2 md:mr-0">
                  <p className={(props.mode==='light'?'text-darkblue':'text-white')+' text-small ml-2 mb-1 xl:text-xs'}>
                      Country of Residence
                    </p>
                  <div className={(props.mode==='light'?'bg-white':'bg-inputblue1 text-white')+" w-full outline-none h-10 text-small border-btnblue border rounded-3xl h-h45 px-4 shadow-lg"}>
                    <select 
                     style={{backgroundImage: `url(${dropdown})`}}
                    className={(props.mode==='light'?'bg-white':'bg-inputblue1 text-white')+" w-full outline-none h-10 text-small p-1 rounded-3xl h-h42 px-4 bg-no-repeat bg-right appearance-none"}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.residence}
                          name="residence"
                          >
                            <option value="none">Select a Country</option>
                            {dropdownValues}
                        </select>
                    </div>
                  </div>
                  {/* {formik.touched.residence && formik.errors.residence ? (<div className='text-left text-red text-xs mt-2 pl-2'>{formik.errors.residence}</div>) : null} */}
                  <div className="w-w50 md:w-full">
                  <p className={(props.mode==='light'?'text-darkblue':'text-white')+' text-small ml-2 mb-1 xl:text-xs'}>
                      Phone Number
                    </p>
                    <input
                      type="number"
                      name="phonenumber"
                      value={formik.values.phonenumber}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder="Enter phone no."
                      className={
                        (props.mode === "light"
                          ? "bg-white"
                          : "bg-inputblue1 text-white") +
                        " w-full outline-none h-10 text-sm p-1 border-btnblue border rounded-3xl h-h45 px-4 shadow-lg"
                      }
                    />
                    {formik.touched.phonenumber && formik.errors.phonenumber ? (<div className='text-left text-red text-xs mt-2 pl-2'>{formik.errors.phonenumber}</div>) : null}
                  </div>

                  {/* <div className="w-w50 md:w-full">
                    <p className={(props.mode==='light'?'text-darkblue':'text-white')+' text-small ml-2 mb-1 xl:text-xs'}>
                      Phone Number
                    </p>
                    <div className={
                        (props.mode === "light"
                          ? "bg-white"
                          : "bg-inputblue1 text-white") +
                        " flex w-full outline-none h-10 text-sm p-1 border-btnblue border rounded-3xl h-h45 px-4 shadow-lg input-group" }>
                      <select className={(props.mode==='light'?'text-darkblue bg-trans':'text-white bg-inputblue1')+" self-center mr-2 outline-none "}>
                            <option value="10">+10</option>
                            <option value="20">+20</option>
                            <option value="50">+50</option>
                            <option value="100">+100</option>
                      </select>
                      <input 
                      type="number"
                      name="phonenumber"
                      value={formik.values.phonenumber}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      placeholder="Enter phone no."
                      className={
                        (props.mode === "light"
                          ? "bg-white"
                          : "bg-inputblue1 text-white") +
                        " w-full outline-none "
                      }/>
                    </div>                    
                    {formik.touched.phonenumber && formik.errors.phonenumber ? (<div className='text-left text-red text-xs mt-2 pl-2'>{formik.errors.phonenumber}</div>) : null}
                  </div> */}

                </div>
              </div>
            </div>
          </form>
    </>
  )
}

export default ProfileInformationForm