import React, { useState, useContext } from "react";
import {Theme} from "../../App"
import { MainLayout } from "../../components/Layouts/MainLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoins } from "@fortawesome/free-solid-svg-icons";
import SendCoin from './Components/SendCoin'
import RequestCoin from './Components/RequestCoin'
import SendCoinGroups from "./Components/SendCoinGroups";
import CoinHistory from './Components/CoinHistory'

const SendReceiveCoinPage = () => {

  const mode = useContext(Theme);

  const [menu,]=useState(false);
  const [display, setDisplay] = useState(1)
  const [rerender, setRerender] = useState();

  const handleRerender = () => {
    setRerender(Math.random());
  }

  return (
    <>
      <MainLayout 
        setMenu={menu}
      >
        <div className="flex flex-row justify-start w-full items-center mt-14 sm:mt-7">
          <h1 className={(mode === 'light' ? "text-darkblue" : "text-white") + " text-medium"}>Send/ Receive </h1>
        </div>
        <span className="text-sm text-lightblue mt-2 mb-5">Note: Here you can {display?'send or receive crypto':'request HDL'} coins only.</span>
        <div className="flex flex-col justify-center mt-5">
          <div className='flex flex-row'>
            <div className={(mode === 'light' ? (display == 1 ? 'bg-orange border-darkorange border text-darkblue ' : ' bg-white text-darkblue border-darkorange') : (display == 1 ? 'bg-btnblue text-white border-btnblue' : ' bg-bgblue border-btnblue border text-white')) + ' flex flex-row justify-center items-center h-h50 px-5 rounded-t-xl cursor-pointer border border-b-0 xs:px-1 xs:text-xs xs:h-h40 xs:font-semibold'} onClick={() => setDisplay(1)}>
              <FontAwesomeIcon icon={faCoins} className={(mode === 'light' ? '' : '') + ' mr-3 text-medium sm:hidden'} />
              <p className={(mode === 'light' ? '' : '')}>Send Coins</p>
            </div>

            <div className={(mode === 'light' ? (display == 3 ? 'bg-orange border-darkorange border text-darkblue' : ' bg-white text-darkblue border-darkorange') : (display == 3 ? 'bg-btnblue text-white border-btnblue' : ' bg-bgblue border-btnblue border text-white')) + ' flex flex-row justify-center items-center h-h50 px-5 rounded-t-xl cursor-pointer pt-2 pb-2 border border-b-0 xs:px-1 xs:text-xs xs:h-h40 xs:font-semibold'} onClick={() => setDisplay(3)}>
              <FontAwesomeIcon icon={faCoins} className={(mode === 'light' ? '' : '') + ' mr-3 text-medium sm:hidden'} />
              <p className=''>Send Coins to Group</p>
            </div>
            
            <div className={(mode === 'light' ? (display == 2 ? 'bg-orange border-darkorange border text-darkblue' : ' bg-white text-darkblue border-darkorange') : (display == 2 ? 'bg-btnblue text-white border-btnblue' : ' bg-bgblue border-btnblue border text-white')) + ' flex flex-row justify-center items-center h-h50 px-5 rounded-t-xl cursor-pointer pt-2 pb-2 border border-b-0 xs:px-1 xs:text-xs xs:h-h40 xs:font-semibold'} onClick={() => setDisplay(2)}>
              <FontAwesomeIcon icon={faCoins} className={(mode === 'light' ? '' : '') + ' mr-3 text-medium sm:hidden'} />
              <p className=''>Request Coins</p>
            </div>


          </div>
        </div>

        { display === 1 
          ? <SendCoin mode={mode} rerender={() => handleRerender()} /> : 
          display === 2 ?
          <RequestCoin mode={mode} rerender={() => handleRerender()} /> :
          display === 3 ?
          <SendCoinGroups mode={mode} rerender={() => handleRerender()} /> : 
          <SendCoin mode={mode} rerender={() => handleRerender()} />
        }

        <CoinHistory mode={mode} key={rerender} rerender={rerender} />

        {/* <SendRecieveCoin mode={mode}/> */}
      </MainLayout>
    </>
  );
};

export default SendReceiveCoinPage;

