import React, { useContext, useEffect, useState } from "react";
import API from "../../../api/axios";
import { APP_URLS } from "../../../api/url";
import * as Yup from 'yup';
import { useFormik } from "formik";
import { toast } from 'react-toastify';
import { Theme } from "../../../App";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDownLong } from "@fortawesome/free-solid-svg-icons";
import rightArrow from '../../../components/Images/arrow-right-long.png';
import rightArrowDark from '../../../components/Images/arrow-right-long-dark.png';
import dropdown from '../../../components/Images/dropdown.png';
import { env } from "process";


const SwapCoin = (props) => {
    const mode = useContext(Theme);

    const [loading, setLoading] = useState(false);
    const [converting, setConverting] = useState(false);
    const decimalRegExp = /^\d*\.?\d*$/;
    const walletList = JSON.parse(localStorage.getItem('wallet_list'));
    const [sameWallet, setSameWallet] = useState(false);
    const [wallets, setWallets] = useState(walletList);
 
    const [fromWallet, setFromWallet] = useState();
    const [toWallet, setToWallet] = useState();

    const [toConvertRate, setToConvertRate] = useState(0)
    const [toTotalConvertedAmount, setToTotalConvertedAmount] = useState(0)

    const [enterAmount, setEnterAmount] = useState(0)

    useEffect(() => {
        if (wallets === null) { fetchWalletList();}
        fetchCoinRate();
        formik.setFieldValue('customerId', (fromWallet !== undefined && fromWallet !== '') ? fromWallet.coin_type : '');
    }, [enterAmount, fromWallet, toWallet, wallets]);

    const fetchWalletList = async () => {
        await API.get(APP_URLS.COIN_SWAP_APP)
            .then((result) => {
                const resp = result.data;
                setWallets(resp.data)
                localStorage.setItem('wallet_list', JSON.stringify(resp.data));
            })
            .catch((error) => console.log({ error }))
    }

    const formik = useFormik({
        initialValues: {
            from_coin_id: '',
            to_coin_id: '',
            amount: '',
        },
        validationSchema: Yup.object({  
            from_coin_id: Yup.string().required('From Wallet is Required'),
            to_coin_id: Yup.string().required('To Wallet is Required'),
            amount: Yup.string().required('Amount is Required').matches(decimalRegExp, 'Please enter valid amount'),
        }),
        onSubmit: (values, actions) => {
            const swapValues = {
                'from_coin_id':  fromWallet?.id,
                'to_coin_id':  toWallet?.id,
                'amount': values.amount
            }

            if (!loading) {
                setLoading(true)
                API.post(APP_URLS.SWAP_COIN_APP, swapValues)
                    .then(res => {
                        const respMessage = res.data.message;
                        if (res.data.success === true) {
                            toast.success(respMessage, {
                                position: toast.POSITION.TOP_RIGHT
                            })
                            actions.resetForm();
                            setToTotalConvertedAmount(0)
                            setToConvertRate(0)
                            setFromWallet('')
                            setToWallet('')
                            setEnterAmount(0)
                            rerender();
                        } else {
                            toast.error(respMessage, {
                                position: toast.POSITION.TOP_RIGHT
                            })
                        }

                        setLoading(false);
                    })
                    .catch(function (error) {
                        const resp = error.response;
                        setLoading(false)
                        if (resp) {

                            let error_message = '';
                            if (resp.data.errors !== undefined) {
                                {
                                    Object.keys(resp.data.errors).map((error, index) => (
                                        error_message = resp.data.errors[error][0]
                                    ))
                                }
                            } else if (resp.data.data.error !== undefined) {
                                error_message = resp.data.data.error;
                            } else {
                                error_message = resp.data.error
                            }

                            toast.error(error_message, {
                                position: toast.POSITION.TOP_RIGHT
                            })
                        }
                    });
            }
        },
    });

    const rerender = () => {
        props.rerender()
    }

    const fetchCoinRate = () => {

        if ((props.selectedCoin !== '' && props.selectedCoin !== undefined) && fromWallet === undefined) { 
            handleFromWalletChangeNew(props.selectedCoin) 
        }

        const toWalletId = toWallet?.id
        const fromWalletId = fromWallet?.id

        setSameWallet(false)
        if (fromWalletId !== undefined && toWalletId !== undefined && enterAmount !== undefined && enterAmount > 0 && fromWalletId !== toWalletId) {
            const values = {
                'from_coin_id': fromWalletId,
                'to_coin_id' : toWalletId,
                'amount': enterAmount
            }

            setConverting(true)
            API.post(APP_URLS.FETCH_COIN_RATE, values)
            .then(res => {
                setConverting(false)
                const respMessage = res.data.message;

                if (res.data.success === true) {
                    setToConvertRate(res.data.data.rate)
                    setToTotalConvertedAmount(res.data.data.convert_rate)
                } else {
                    toast.error(respMessage, {
                        position: toast.POSITION.TOP_RIGHT
                    })
                }

                rerender();
                setLoading(false);
            })
            .catch(function (error) {
                setConverting(false)
                const resp = error.response;
                setLoading(false)
                if (resp) {

                    let error_message = '';
                    if (resp.data.errors !== undefined) {
                        {
                            Object.keys(resp.data.errors).map((error, index) => (
                                error_message = resp.data.errors[error][0]
                            ))
                        }
                    } else if (resp.data.data.error !== undefined) {
                        error_message = resp.data.data.error;
                    } else {
                        error_message = resp.data.error
                    }

                    toast.error(error_message, {
                        position: toast.POSITION.TOP_RIGHT
                    })
                }
            });
        } else if (fromWalletId !== undefined && toWalletId !== undefined && fromWalletId === toWalletId) {
            const converRateValue = enterAmount > 0 ? 1 : 0;
            setToConvertRate(converRateValue)
            setToTotalConvertedAmount(enterAmount)
            setSameWallet(true)
            toast.error('Cannot swap to same wallet.', {
                position: toast.POSITION.TOP_RIGHT
            })
        }
    }

    const handleToWalletChange = (e) => {
        const wallet = getWalletId(e.target.value) !== null ? getWalletId(e.target.value)[0] : []
        setToWallet(wallet)
    }

    const handleFromWalletChange = (coinID) => {
        const wallet = getWalletId(coinID) !== null ?  getWalletId(coinID)[0] : []
        setFromWallet(wallet)
    }

    //If params are available
    const handleFromWalletChangeNew = (coinID) => {
        const wallet = getWalletId(coinID) !== null ?  getWalletId(coinID)[0] : []
        formik.setFieldValue('from_coin_id',wallet.id)
        setFromWallet(wallet)

    }

    const getWalletId = (walletCoinId) => {
        if (wallets?.length > 0) {
            return wallets.filter((wallet) => wallet.id == walletCoinId)
        }
    }

    return (
        <>
              <div className={(mode==='light'?'bg-orange text-darkblue':'bg-elemblue2')+' w-full flex-col w-full px-4 py-10 rounded-lg sm:px-2'} >      
                <div className='w-full px-8 gap-5 lg:block gap-3 md:px-2'>
                    <form onSubmit={formik.handleSubmit} className="">
                        <div className='w-w40 lg:w-w100 inline-block '>
                            <div className='flex flex-row items-center my-2'>
                                <p className={(mode === 'light' ? 'text-darkblue' : 'text-white') + ' text-small xl:text-xs'}>Select FROM Wallet <span className="text-xxsmall text-greylight ml-2 2xl:hidden">(Amount : {formik.values.amount})</span></p>
                            </div>
                            <div className={(mode === 'light' ? 'bg-white text-darkblue' : 'bg-inputblue1 text-white') + ' shadow-lg mt-1 relative w-full outline-none text-sm rounded-md h-h45 px-2 sm:px-1'}>
                                <div className='absolute inset-y-0 left-0 pl-2 flex items-center pointer-events-none py-2 sm:pl-1'>
                                    <span>
                                        {   
                                            (fromWallet && fromWallet.coin && fromWallet.coin.coin_icon !== null)? 
                                                <img src={process.env.REACT_APP_API_IMG+'uploaded_file/sleep/'+fromWallet.coin.coin_icon} alt="Default-light" className="w-10 h-10 rounded-full"/>
                                            :<img src = {require(`../../../components/Images/icons/Default-light.png`)} alt="Default-light" className="w-10 h-10 rounded-full"/>
                                        }
                                    </span>
                                    
                                </div>
                                <input 
                                    type='text' 
                                    autoComplete="off"
                                    name='amount' 
                                    value={formik.values.amount}
                                    disabled={(loading || converting) ? true : false}
                                    onChange={e => {
                                        var timeout;
                                        var delay = 2000;   // 2 seconds
                                        var enterAmount = e.currentTarget.value;
                                        // call the built-in handleBur
                                        formik.handleChange(e)
                                        if(timeout) {
                                            clearTimeout(timeout);
                                        }
                                        timeout = setTimeout(function() {
                                            setEnterAmount(enterAmount)
                                        }, delay);
                                    }}
                                    onBlur={formik.handleBlur}
                                    className={(mode === 'light' ? 'bg-white text-darkblue' : 'bg-inputblue1') + ' focus:outline-0 w-w60 h-h45 block w-full pl-12 pr-12 py-1 sm:text-sm sm:pr-2 sm:pl-11 focus:outline-none'} placeholder='0.00' />
                                    {formik.touched.amount && formik.errors.amount ? (
                                    <div className='text-left absolute text-red text-xs pt-2 sm:pl-0'>{formik.errors.amount}</div>
                                ) : null}
                                   {formik.touched.from_coin_id && formik.errors.from_coin_id ? (
                                        <div className='float-right text-right text-red text-xs pt-2 sm:pl-0'>{formik.errors.from_coin_id}</div>
                                    ) : null}
                                <div className="absolute inset-y-0 right-4 flex items-center sm:right-0">
                                    <label htmlFor='currency' className='sr-only'>Currency</label>
                                    <select 
                                        disabled={(loading || converting) ? true : false}
                                        onChange={e => {
                                            formik.handleChange(e)
                                            handleFromWalletChange(e.currentTarget.value)
                                        }}
                                        // onFocus={() => {
                                        //     console.log({fromWallet})
                                        //     setTimeout(() => formik.setFieldTouched('from_coin_id', true), 100)}
                                        // }
                                        // value={(fromWallet !== undefined && fromWallet !== '') ? fromWallet.coin_type : ''}
                                        value={formik.values.from_coin_id}
                                        name='from_coin_id' 
                                        style={{backgroundImage: `url(${dropdown})`}}
                                        className={(mode === 'light' ? 'bg-white text-darkblue' : 'bg-inputblue1 ') + ' focus:outline-none border-1 h-full py-0 pl-2 py-1 rounded-md mr-0 text-sm sm:text-sm pl-0 xl:w-32 xl:overflow-hidden xl:whitespace-nowrap xl:overflow-ellipsis bg-no-repeat bg-right appearance-none pr-5 sm:pr-5'}>
                                        <option hidden>Select Coin</option>
                                        {wallets && wallets.map((item, index) => {
                                            return (item.type===1?<option  key={index} value={item.id}>{item.name} ({item.coin_type})</option>:'');
                                        })} 
                                    </select>                                    
                                </div>                             
                            </div>
                        </div>

                        <div className={(mode === 'light' ? 'text-darkblue' : ' text-lightblue') + ' w-w20 lg:w-w100 inline-block text-center self-center py-5 px-3'}>
                            <span className='text-lg font-light self-center flex justify-center sm:text-sm sm:mt-5'>
                                {converting === true ? 'Converting...' : `Rate of ${formik.values.amount > 0 ? 1 : 0} = ${toConvertRate}`}
                            </span>
                            {mode === 'dark'
                                ? <img className='block mx-auto lg:hidden' src={rightArrow} />
                                : <img className='block mx-auto lg:hidden' src={rightArrowDark} />
                            }

                            <FontAwesomeIcon icon={faArrowDownLong} className={(mode === 'light' ? 'xlmin:hidden text-darkblue' : ' xlmin:hidden text-lightblue') + " text-medium"} />
                        </div>

                        <div className='w-w40 lg:w-w100 inline-block '>
                            <div className='flex flex-row items-center my-2'>
                                <p className={(mode === 'light' ? 'text-darkblue' : 'text-white') + ' text-small xl:text-xs'}>Select TO Wallet <span className="text-xxsmall text-greylight ml-2 2xl:hidden">(Amount : {toTotalConvertedAmount})</span></p>
                            </div>
                            <div className={(mode === 'light' ? 'bg-white text-darkblue' : 'bg-inputblue1 text-white') + ' shadow-lg mt-1 relative w-full outline-none text-sm rounded-md h-h45 px-2 sm:px-1'}>
                                <div className='absolute inset-y-0 left-0 pl-2 flex items-center pointer-events-none py-2 sm:pl-1'>
                                    <span>
                                        {   
                                            (toWallet && toWallet.coin && toWallet.coin.coin_icon !== null)? 
                                                <img src={process.env.REACT_APP_API_IMG+'uploaded_file/sleep/'+toWallet.coin.coin_icon} alt="Default-light" className="w-10 h-10 rounded-full"/>
                                            :<img src = {require(`../../../components/Images/icons/Default-light.png`)} alt="Default-light" className="w-10 h-10 rounded-full"/>
                                        }
                                    </span>
                                    
                                </div>
                                <input type='text' disabled name='price' value={toTotalConvertedAmount} className={(mode === 'light' ? 'bg-white text-darkblue' : 'bg-inputblue1') + ' focus:outline-0 h-h45 block w-full pl-12 pr-12 py-1 sm:text-sm sm:pr-2 sm:pl-11'} placeholder='0.00' />
                                <div className="absolute inset-y-0 right-4 flex items-center sm:right-0">
                                    <label htmlFor='currency' className='sr-only'>Currency</label>
                                    <select 
                                        onChange={e => {
                                            formik.handleChange(e)
                                            handleToWalletChange(e)
                                        }}
                                        value={formik.values.to_coin_id}
                                        name='to_coin_id' 
                                        disabled={(loading || converting) ? true : false}
                                        style={{backgroundImage: `url(${dropdown})`}}
                                        className={(mode === 'light' ? 'bg-white text-darkblue' : 'bg-inputblue1 ') + ' focus:outline-none border-1 h-full py-5 pl-2 pr-5 py-1 rounded-md mr-0 sm:text-xs text-sm sm:pl-0 xl:w-32 xl:overflow-hidden xl:whitespace-nowrap xl:overflow-ellipsis bg-no-repeat bg-right appearance-none'}>
                                        <option hidden >Select Coin</option>
                                        {wallets && wallets.map((item, index) => {
                                            return <option key={index} value={item.id}>{item.name} ({item.coin_type})</option>;
                                        })} 
                                    </select>
                                </div>
                            </div>
                        {formik.touched.to_coin_id && formik.errors.to_coin_id ? (
                                <div className='float-right text-right text-red text-xs pt-2 sm:pl-0'>{formik.errors.to_coin_id}</div>
                            ) : null}
                        </div>

                        <div className='w-full text-center'>
                            <button type="submit" 
                                className={(mode === 'light' ? 'bg-darkorange' : 'bg-gradient-to-r from-lightblue to-blue ') + ' outline-none mt-3 rounded-3xl w-wp160 h-h13 text-white mx-auto'} 
                                disabled={(loading || converting || sameWallet) ? true : false}> { loading ? 'Swapping...' : 'Swap Coins'}</button>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
};

export default SwapCoin;

