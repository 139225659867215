import React, { useContext, useEffect, useState } from "react";
import API from "../../../api/axios";
import { APP_URLS } from "../../../api/url";
import PageLength from "../../../components/Shared/PageLength";
import SearchBar from "../../../components/Shared/SearchBar";
import { confirmAlert } from 'react-confirm-alert'; // Import
import { toast } from "react-toastify";
import { Theme } from "../../../App";
import Pagination from "../../../components/Shared/Pagination";
import SendCoinGroups from "./SendCoinGroups";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus, faCross, faL, faXmarkCircle } from "@fortawesome/free-solid-svg-icons";
import AmountTable from "./Popups/AmountTable";

const CoinHistory = (props) => {

    const userInfo = JSON.parse(localStorage.getItem('user_info'))
    const [loading, setLoading] = useState(false);
    const [userTransactionHistory, setUserTransactionHistory] = useState([]);
    const mode = useContext(Theme);

    const [dataDeleted,setDataDeleted] = useState(false)
    const [transactionDone,setTransactionDone] = useState(false)


    const [rerender, setRerender] = useState();


    //Setting Pagination and Search
    const [page, setPage] = useState(1);
    const [entries, setEntries] = useState(10)
    const [search, setSearch] = useState('');
    const [totalPages, setTotalPages] = useState(0);

    const [schedulePopup,setSchedulePopup] = useState(false)
    const [showModifySchedulePopup,setShowModifySchedulePopup] = useState(false)
    const [modifySchedulePopup,setModifySchedulePopup] = useState()

    const [expand,setExpand] = useState()


    useEffect(() => {
        setSchedulePopup(false)
        setShowModifySchedulePopup(false)
        setModifySchedulePopup('')
        setDataDeleted(false)
        setTransactionDone(false)
        fetchUserCoinHistory();
    }, [search, page, entries, rerender,dataDeleted,transactionDone]);

    const fetchUserCoinHistory = async () => {
        setLoading(true);
        await API.get(APP_URLS.ALL_SCHEDULE_TRANSACTIONS + `?length=${entries}&search=${search}&page=${page}&sort_dir=desc&sort_by=id`)
            .then((result) => {
                const resp = result.data.data.data;
                setTotalPages(result.data.data.last_page)
                setUserTransactionHistory(resp);
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
            })
    }



    const handleLengthChange = (length) => {
        setEntries(length)
        setPage(0)
    }

    const handleSearchChange = (search) => {
        setSearch(search)
        setPage(0)
    }

    const handleRerender = () => {
        setRerender(Math.random());
      }

    const handleModification = (item) =>{
        setSchedulePopup(false)
        setShowModifySchedulePopup(true)
        setModifySchedulePopup(<SendCoinGroups mode={mode} setTransactionDone={setTransactionDone} rerender={() => handleRerender()} data={item} setSchedulePopup={setSchedulePopup} setModifySchedulePopup={setModifySchedulePopup} setShowModifySchedulePopup={setShowModifySchedulePopup}/>)
    }

    const removeAddress =(index)=>{
        confirmAlert({
            title: 'Confirm to remove',
            message: `Are you sure you want to remove.`,
            closeOnEscape: true,
            buttons: [
                {
                label: 'Yes',
                onClick: () => {
                    API.delete(APP_URLS.REMOVE_TRANSACTIONS+`/${index}`)
                    .then(res => { 
                        if (res.data.success === true) {
                        setDataDeleted(true)
                        toast.success(res.data.message, {
                            position: toast.POSITION.TOP_RIGHT
                            })   
                        } else {
                            toast.error(res.data.message, {
                            position: toast.POSITION.TOP_RIGHT
                            })        
                        }
                        })
                        .catch((error) => {
                        const resp = error.response;
                        if (resp) {
                            let error_message = '';
                            if (resp.data.errors !== undefined) {
                                {Object.keys(resp.data.errors).map((error, index) => (
                                    error_message = resp.data.errors[error][0]
                                ))}
                            } else if (resp.data.data.error !== undefined) {
                                error_message =  resp.data.data.error;
                            } else {
                                error_message =  resp.data.error
                            }
                
                            toast.error(error_message, {
                                position: toast.POSITION.TOP_RIGHT
                            })
                        }
                        }) 
                }
                },
                {
                label: 'No'
                }
            ]
        });
    }

    return (
        <>
            <div className="flex flex-row w-w100 justify-between mt-14 md:mt-20 md:mt-20 sm:mt-7 mb-5 xs:block">
            <div className="flex flex-row items-center">
                <h2 className={(props.mode==='light'?'text-darkblue':'text-white')+' block text-left w-w100 text-medium mb-2 sm:mt-0'}>
                Scheduled Transactions
                </h2>
            </div>
            <div className="w-auto flex flex-row justify-between mb-1 sm:mt-0"> 
            {!showModifySchedulePopup?
                    !schedulePopup?<button
                        type="button" 
                        onClick={()=>{
                            setShowModifySchedulePopup(false)
                            setModifySchedulePopup('')
                            setSchedulePopup(true)
                        }}
                        className={(props.mode === "light"? "rounded-3xl w-auto px-3 mr-3 bg-darkblue text-white outline-none text-small": "bg-gradient-to-r from-purple to-pink text-white rounded-3xl w-auto px-3 mr-3 outline-none text-small")+" xxs:text-small xs:py-1"}>
                        <FontAwesomeIcon icon={faCirclePlus} className={" text-white pr-2"} />
                        Schedule
                    </button>:
                    <button
                        type="button" 
                        onClick={()=>setSchedulePopup(false)}
                        className={(props.mode === "light"? "rounded-3xl w-auto px-3 mr-3 bg-darkblue text-white outline-none text-small": "bg-gradient-to-r from-purple to-pink text-white rounded-3xl w-auto px-3 mr-3 outline-none text-small")+" xxs:text-small xs:py-1"}>
                        <FontAwesomeIcon icon={faXmarkCircle} className={" text-white pr-2"} />
                        Cancel
                    </button>:
                    <button
                    type="button" 
                    onClick={()=>{
                        setShowModifySchedulePopup(false)
                        setModifySchedulePopup('')
                    }}
                    className={(props.mode === "light"? "rounded-3xl w-auto px-3 mr-3 bg-darkblue text-white outline-none text-small": "bg-gradient-to-r from-purple to-pink text-white rounded-3xl w-auto px-3 mr-3 outline-none text-small")+" xxs:text-small xs:py-1"}>
                    <FontAwesomeIcon icon={faXmarkCircle} className={" text-white pr-2"} />
                    Cancel
                </button>}
            </div>
        </div>
        
            {/* <div className="flex flex-row justify-start w-full items-center mt-14 sm:mt-7">
                <h1 className={(mode === 'light' ? "text-darkblue" : "text-white") + " text-medium"}>Schedule Transactions </h1>
            </div> */}
            {showModifySchedulePopup?modifySchedulePopup:''}
            {schedulePopup?<SendCoinGroups mode={mode} setTransactionDone={setTransactionDone} rerender={() => handleRerender()} setSchedulePopup={setSchedulePopup} setModifySchedulePopup={setModifySchedulePopup} setShowModifySchedulePopup={setShowModifySchedulePopup}/>:''}
            {/* <div className="mt-14">
                <h2 className={(mode == 'light' ? 'text-darkblue' : 'text-white') + ' text-white text-xl mb-1'}>Scheduled Transactions List</h2>
            </div> */}
                {/* <span className="text-sm text-lightblue mt-2 mb-6">Note: Only transactions through email address will be visible here. To check all transactions go to Transaction History.</span> */}
            <div className="flex flex-row w-full justify-between">
                <PageLength onPageLengthChange={(length) => handleLengthChange(length)} />
                <div className="sm:w-w40">
                    <SearchBar onSearchClick={(search) => handleSearchChange(search)} />
                </div>
            </div>
            <div className="w-full table-auto overflow-x-auto">
                <table className="w-full mt-2 table-auto relative md:w-maxWidth">
                    <thead>
                        <tr className={(mode === 'light' ? 'bg-white' : 'bg-tableblue text-white') + " flex flex-row justify-around py-8 mt-3 rounded-xl shadow-lg"}>
                            <th className="flex justify-start w-w16 pl-8">Group Name</th>
                            <th className="flex justify-center w-w16">Wallet Name</th>
                            <th className="flex justify-center w-w16">Amount</th>
                            <th className="flex justify-center w-w16">Scheduled For</th>
                            <th className="flex justify-center w-w16">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                         {userTransactionHistory.length > 0 ?
                            userTransactionHistory.map((item, index) => {
                                return ( <div>
                                <tr  key={index} className={(mode === 'light' ?expand===item.id?'bg-orange ':'bg-white ' :expand===item.id?' bg-elemblue bg-opacity-60':' bg-tableblue text-fontblue') + " flex flex-row justify-around items-center my-5 py-4 rounded-xl shadow-lg"}>
                                        <td className="flex justify-start w-w16 pl-8">
                                            <div className={(mode === 'light' ? 'text-darkblue' : 'text-white') + ' flex justify-center'}>
                                                {item.group_name}
                                            </div>
                                        </td>
                                        <td className={(mode === 'light' ? 'text-darkblue' : 'text-white') + ' flex justify-center w-w16'}>
                                            {item.wallet_name}
                                        </td>
                                        <td className={(mode === 'light' ? 'text-darkblue' : 'text-white') + ' flex justify-center break-all w-w16'}>
                                            {
                                            item.is_expanded===false?parseFloat(item.amount).toFixed(2):
                                            expand!==item.id?
                                            <span className="cursor-pointer underline" onClick={()=>setExpand(item.id)}>Expand</span>:
                                            <span className="cursor-pointer underline" onClick={()=>setExpand()}>Hide</span>
                                            }
                                        </td>
                                        <td className={(mode === 'light' ? 'text-darkblue' : 'text-white') + ' flex justify-center w-w16'}>
                                            {item.date} of Every Month
                                        </td>
                                        <td className="flex justify-center w-w16 text-white">
                                            <div className="px-3 h-8 self-center flex justify-center items-center bg-darkgrey2fade rounded-lg cursor-pointer" onClick={()=>handleModification(item)}>
                                                Modify
                                            </div>
                                            {/* <div className="px-3 ml-2 h-8 self-center flex justify-center items-center bg-redLight rounded-lg cursor-pointer" onClick={()=>removeAddress(item.id)}> */}
                                            <div className="px-3 ml-2 h-8 self-center flex justify-center items-center bg-redLight rounded-lg cursor-pointer" onClick={()=>removeAddress(item.id)}>
                                                Remove
                                            </div>
                                        </td>
                                    </tr>
                                    {expand===item.id?<AmountTable mode={mode} data={item.groupped_details}/>:null}
                                    </div>
                                    
                                )
                            })
                            :
                            <>
                                <tr className={(mode === 'light' ? 'bg-white' : 'bg-tableblue text-fontblue') + " flex flex-row justify-around items-center my-5 py-4 rounded-xl shadow-lg"}>
                                    <td colSpan="5"> { loading ? 'Loading...' : 'No Records Found' }</td>
                                </tr>
                            </>
                        }

                    </tbody>
                </table>
            </div>

            
            {(userTransactionHistory.length > 0) && 
                <Pagination 
                    totalPages={totalPages}
                    onPageClick={(page) => setPage(page + 1)}
                    key={entries}
                />
            }
          
        </>
    );
};

export default CoinHistory;

