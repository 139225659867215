import React, { useContext, useEffect, useState } from 'react'
import { Theme } from '../../../../App';

const AmountTable = (props) => {

    const mode = useContext(Theme);
    const [data,setData] = useState([])

    useEffect(() => {
    if(props.data){
      setData(props.data)
    }
    }, [props.data])
    


  return (
    <div className={(mode === 'light' ? 'bg-orange' : 'bg-elemblue bg-opacity-60')+ " table-auto px-10 pt-2 pb-6 -mt-3 rounded-bl-lg rounded-br-lg"}>
    <table className="w-full mt-2 table-auto relative md:w-maxWidth">
        <thead>
            <tr className={(mode === 'light' ? 'bg-white' : 'bg-tableblue text-white') + " flex flex-row justify-around py-5 mt-2 rounded-xl shadow-lg"}>
                <th className="flex justify-start w-w25 text-center pl-10">Name</th>
                <th className="flex justify-center w-w50 text-center">Address</th>
                <th className="flex justify-center w-w25 text-center">Amount</th>
            </tr>
        </thead>
        <tbody>
                {data.length>0 && data.map((item, index) => {
                    return ( <>
                    <tr  key={index} className={(mode === 'light' ? 'bg-white' : 'bg-tableblue text-fontblue') + " flex flex-row justify-around items-center my-4 py-4 rounded-xl shadow-lg"}>
                            <td className={(mode === 'light' ? 'text-darkblue' : 'text-white') + ' flex justify-start text-center w-w25  pl-10'}>
                                    {item.name}
                            </td>
                            <td className="flex pl-8 w-w50 justify-center text-center">
                                <div className={(mode === 'light' ? 'bg-white text-darkblue' : 'bg-tableblue text-white') + " w-auto flex"}>
                                    {item.email}
                                </div>
                            </td>
                            <td className={(mode === 'light' ? 'text-darkblue' : 'text-white') + ' flex justify-center text-center w-w25'}>
                                    {item.amount}
                            </td>
                        </tr>
                        </>
                        
                    )
                })
            }

        </tbody>
    </table>
</div>
  )
}

export default AmountTable