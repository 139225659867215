import React, { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeftLong, faArrowRightLong, faCheckCircle, faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import send from '../../../components/Images/send-icon.png';
import receive from '../../../components/Images/receive-icon.png';
import request from '../../../components/Images/request-icon.png';
import API from "../../../api/axios";
import { APP_URLS } from "../../../api/url";
import * as Constant from "../../../config/constant";
import PageLength from "../../../components/Shared/PageLength";
import SearchBar from "../../../components/Shared/SearchBar";
import { confirmAlert } from 'react-confirm-alert'; // Import
import { toast } from "react-toastify";
import { Theme } from "../../../App";
import Pagination from "../../../components/Shared/Pagination";

const SwapCoinHistory = (props) => {
    const userInfo = JSON.parse(localStorage.getItem('user_info'))
    const [loading, setLoading] = useState(false);
    const [rerender,] = useState(props.rerender);
    const [swapCoinHistory, setSwapCoinHistory] = useState([]);
    const [defaultCurrency, setDefaultCurrency] = useState([]);
    const mode = useContext(Theme);

    //Setting Pagination and Search
    const [page, setPage] = useState(1);
    const [entries, setEntries] = useState(10)
    const [search, setSearch] = useState('');
    const [totalPages, setTotalPages] = useState(0);

    useEffect(() => {
        fetchswapCoinHistory();
    }, [search, page, entries, rerender]);

    const fetchswapCoinHistory = async () => {
        setLoading(true);
        await API.get(APP_URLS.SWAP_COIN_HISTORY + `?limit=${entries}&search=${search}&page=${page}`)
            .then((result) => {
                const resp = result.data.data.data;
                setSwapCoinHistory(resp);
                setTotalPages(result.data.data.last_page)
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
            })
    }

    const handleLengthChange = (length) => {
        setEntries(length)
        setPage(0)
    }

    const handleSearchChange = (search) => {
        setSearch(search)
        setPage(0)
    }

 return (
        <>
            <div className="lex flex-row w-full justify-between mb-3">
                <h2 className={(mode == 'light' ? 'text-darkblue' : 'text-white') + ' text-white text-xl mb-3'}>Coins Swap History</h2>
            </div>
            <div className="flex flex-row w-full justify-between sm:block">
                <PageLength onPageLengthChange={(length) => handleLengthChange(length)} />
                <div className="sm:w-w40">
                    <SearchBar onSearchClick={(search) => handleSearchChange(search)} />
                </div>
            </div>
            <div className="w-full table-auto overflow-x-auto">
                <table className="w-full mt-2 table-auto relative md:w-maxWidth">
                    <thead>
                        <tr className={(mode === 'light' ? 'bg-white' : 'bg-tableblue text-white') + " flex flex-row justify-around mt-2 py-8 rounded-xl shadow-lg"}>
                            <th className="flex justify-start w-w16 pl-8">From Wallet</th>
                            <th className="flex justify-start w-w16">To Wallet</th>
                            <th className="flex justify-start w-w16">Requested Amount</th>
                            <th className="flex justify-start w-w16">Converted Amount</th>
                            <th className="flex justify-start w-w16">Created At</th>
                            <th className="flex justify-start w-w16">Rate</th>
                        </tr>
                    </thead>
                    <tbody>
                        {swapCoinHistory.length > 0 ?
                            swapCoinHistory.map((item, index) => {
                                return (
                                    <tr key={index} className={(mode === 'light' ? 'bg-white' : 'bg-tableblue text-fontblue') + " flex flex-row justify-around items-center my-5 py-4 rounded-xl shadow-lg"}>
                                        <td className={(mode === 'light' ? 'text-darkblue' : 'text-white') + ' flex justify-left w-w16 pl-8'}>{item.from_wallet_name}</td>
                                        <td className={(mode === 'light' ? 'text-darkblue' : 'text-white') + ' flex justify-start w-w16'}>{item.to_wallet_name}</td>
                                        <td className={(mode === 'light' ? 'text-darkblue' : 'text-white') + ' flex justify-start w-w16'}>{item.requested_amount_string}</td>
                                        <td className={(mode === 'light' ? 'text-darkblue' : 'text-white') + ' flex justify-start w-w16'}>{item.converted_amount_string}</td>
                                        <td className={(mode === 'light' ? 'text-darkblue' : 'text-white') + ' flex justify-start w-w16'}>{item.created_at}</td>
                                        <td className={(mode === 'light' ? 'text-darkblue' : 'text-white') + ' flex justify-start w-w16'}>{item.rate}</td>
                                    </tr>
                                )
                            })
                            :
                            <>
                                <tr className={(mode === 'light' ? 'bg-white' : 'bg-tableblue text-fontblue') + " flex flex-row justify-around items-center my-5 py-4 rounded-xl shadow-lg"}>
                                    <td colSpan="6"> {loading ? 'Loading...' : 'No Records Found'}</td>
                                </tr>
                            </>
                        }
                    </tbody>
                </table>
            </div>
            {(swapCoinHistory.length) > 0 && 
                <>
                   <Pagination 
                    totalPages={totalPages}
                    onPageClick={(page) => setPage(page + 1)}
                    key={entries}
                />
                </>
            }
        </>
    );
};

export default SwapCoinHistory;

