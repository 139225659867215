import React, { useState, useEffect } from "react";
// import {
//   AreaChart,
//   Area,
//   XAxis,
//   YAxis,
//   Tooltip,
//   CartesianGrid,
//   ResponsiveContainer,
// } from "recharts";
import {
  LineChart,
  Line,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer
} from "recharts";
const monthly_data = [
  {
    name: "Jan",
    Deposit: 0,
    Withdrawal: 0,
  },
  {
    name: "Feb",
    Deposit: 0,
    Withdrawal: 0,
  },
  {
    name: "Mar",
    Deposit: 0,
    Withdrawal: 0,
  },
  {
    name: "Apr",
    Deposit: 0,
    Withdrawal: 0,
  },
  {
    name: "May",
    Deposit: 0,
    Withdrawal: 0,
  },
  {
    name: "Jun",
    Deposit: 0,
    Withdrawal: 0,
  },
  {
    name: "Jul",
    Deposit: 0,
    Withdrawal: 0,
  },
  {
    name: "Aug",
    Deposit: 0,
    Withdrawal: 0,
  },
  {
    name: "Sep",
    Deposit: 0,
    Withdrawal: 0,
  },
  {
    name: "Oct",
    Deposit: 0,
    Withdrawal: 0,
  },
  {
    name: "Nov",
    Deposit: 0,
    Withdrawal: 0,
  },
  {
    name: "Dec",
    Deposit: 0,
    Withdrawal: 0,
  },
];
const weekly_data = [
  {
    name: "Mon",
    Deposit: 0,
    Withdrawal: 0,
  },
  {
    name: "Tue",
    Deposit: 0,
    Withdrawal: 0,
  },
  {
    name: "Wed",
    Deposit: 0,
    Withdrawal: 0,
  },
  {
    name: "Thu",
    Deposit: 0,
    Withdrawal: 0,
  },
  {
    name: "Fri",
    Deposit: 0,
    Withdrawal: 0,
  },
  {
    name: "Sat",
    Deposit: 0,
    Withdrawal: 0,
  },
  {
    name: "Sun",
    Deposit: 0,
    Withdrawal: 0,
  },
];

const DepositWithdraw = (props) => {

  const [range,setRange] = useState('monthly')
  const [weeklyvalues,setWeeklyValues] = useState({})
  const [monthlyvalues,setMontlhyValues] = useState({})
  const [weeklyDataLoaded,setWeeklyDataLoaded] = useState(false)
  const [monthlyDataLoaded,setMonthlyDataLoaded] = useState(false)

  useEffect(() => {
      let authToken = localStorage.getItem("token");
      let access_type = localStorage.getItem("access_type");
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
      myHeaders.append("Authorization", access_type + " " + authToken);

      var requestOptions = {
        method: "GET",
        headers: myHeaders,
      };
      fetch(process.env.REACT_APP_API_URL+`user-dashboard-app?withdraw_type=monthly&deposite_type=monthly`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        let data = JSON.parse(result);
        setMontlhyValues(data);
        setMonthlyDataLoaded(true);
      })
      .catch((error) => console.log("error", error));
      fetch(process.env.REACT_APP_API_URL+`user-dashboard-app?withdraw_type=weekly&deposite_type=weekly`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        let data = JSON.parse(result);
        setWeeklyValues(data);
        setWeeklyDataLoaded(true);
      })
      .catch((error) => console.log("error", error));
  }, []);

  if(range=='monthly'&&monthlyDataLoaded){
    
    let values_withdrawal = monthlyvalues.data.monthly_withdrawal;
    let values_deposit = monthlyvalues.data.monthly_deposit;

    monthly_data.map((element,index) => {
        element.Withdrawal=parseFloat(values_withdrawal[index])
        element.Deposit=parseFloat(values_deposit[index])
     });
  }
  if(range=='weekly'&&weeklyDataLoaded){

    let values_withdrawal = weeklyvalues.data.weekly_withdrawal;
    let values_deposit = weeklyvalues.data.weekly_deposit;

    weekly_data.map((element,index) => {
        element.Withdrawal=parseFloat(values_withdrawal[index])
        element.Deposit=parseFloat(values_deposit[index])
     });
  }
  return (
    <>
        <div
          className={(props.mode === "light" ? "bg-white" : "bg-elemblue2") +" shadow-lg flex flex-col justify-between items-center w-w485 mt-2 sm:w-w100"}>
          <div className="flex flex-row w-full justify-between">
            <div>
              <div className="flex flex-row items-center justify-between w-2/5 p-5 lg:block">
                <h1 className={(props.mode === "light" ? "" : "text-white") +" text-medium"}>Deposit/Withdraw</h1>
                <div className="flex flex-row justify-between ml-5 lg:ml-0 md:mt-2">
                  <div className="bg-darkorange w-2 h-2 rounded-full self-center">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
                  <p className={(props.mode === "light" ? "" : "text-white") +" text-xsmall mx-5 xl:mx-2"}>Deposit</p>
                  <div className="bg-purple w-2 h-2 rounded-full self-center">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
                  <p className={(props.mode === "light" ? "" : "text-white") +" text-xsmall ml-5 xl:mx-2"}>Withdraw</p>
                </div>
              </div>
            </div>
            <div className="py-5 pr-5 text-xsmall xxs:px-0 mr-4">
              <select className={(props.mode === "light"? "bg-white": "bg-elemblue2 text-white") +" w-full outline-none text-xsmall p-1 rounded-lg "}onChange={(e) => setRange(e.target.value)}>
                <option value="monthly">Monthly</option>
                <option value="weekly">Weekly</option>
              </select>
            </div>
          </div>
          <div className="w-11/12 -ml-14">
              {range=='monthly'&&monthlyDataLoaded?
              <ResponsiveContainer width="100%" height={250}>
              <AreaChart
                data={monthly_data}
                margin={{
                  top: 2,
                  right: 20,
                  bottom: 20,
                  left: 20,
                }}
              >
                <YAxis strokeWidth={2.5} />
                <XAxis strokeWidth={2.5} dataKey="name" />
                <Area dataKey="Withdrawal" stroke="#654598" fill="#654598" />
                <Area dataKey="Deposit" stroke="#b38600" fill="#fff4e9" />
                <CartesianGrid strokeDasharray="3 3" strokeWidth={0.3} />
                <Tooltip />
              </AreaChart>
            </ResponsiveContainer>:
              // {/* //Weekly Data */}
              <ResponsiveContainer width="100%" height={250}>
                <AreaChart
                  data={weekly_data}
                  margin={{
                    top: 2,
                    right: 20,
                    bottom: 20,
                    left: 20,
                  }}
                >
                  <YAxis strokeWidth={2.5} />
                  <XAxis strokeWidth={2.5} dataKey="name" />
                  <Area dataKey="Withdrawal" stroke="#654598" fill="#654598" />
                  <Area dataKey="Deposit" stroke="#b38600" fill="#fff4e9" />
                  <CartesianGrid strokeDasharray="3 3" strokeWidth={0.3} />
                  <Tooltip />
                </AreaChart>
              </ResponsiveContainer>
            }
          </div>
        </div>
    </>
  );
};

export default DepositWithdraw;
