export const Stripe = {
    getCreditCardToken: ({number, cvv, exp_month, exp_year, name,key}) => {
      const card = {
        'card[number]': number,
        'card[exp_month]': exp_month,
        'card[exp_year]': exp_year,
        'card[cvc]': cvv,
        'card[name]': name,
      };
      return fetch('https://api.stripe.com/v1/tokens', {
        headers: {
          // Use the correct MIME type for your server
          Accept: 'application/json',
          // Use the correct Content Type to send data to Stripe
          'Content-Type': 'application/x-www-form-urlencoded',
          // Use the Stripe publishable key as Bearer
          Authorization: `Bearer ${key}`,
        },
        // Use a proper HTTP method
        method: 'post',
        // Format the credit card data to a string of key-value pairs
        // divided by &
        body: Object.keys(card)
          .map((key)=> key + '=' + card[key])
          .join('&'),
      }).then((response) => response.json());
    },
  };