import React, { useState, useContext } from "react";
import {Theme} from "../../App"
import { MainLayout } from "../../components/Layouts/MainLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoins } from "@fortawesome/free-solid-svg-icons";
import SendCoin from './Components/SendCoin'
import SendCoinGroups from "./Components/SendCoinGroups";
import CoinHistory from './Components/CoinHistory'

const ScheduleTransactionsPage = () => {

  const mode = useContext(Theme);

  const [menu,]=useState(false);
  const [display, setDisplay] = useState(1)
  const [rerender, setRerender] = useState();

  const handleRerender = () => {
    setRerender(Math.random());
  }

  return (
    <>
      <MainLayout 
        setMenu={menu}
      >
        <CoinHistory mode={mode} key={rerender} rerender={rerender} />
      </MainLayout>
    </>
  );
};

export default ScheduleTransactionsPage;

