import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmarkCircle } from "@fortawesome/free-solid-svg-icons";
import { useFormik } from "formik";
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { APP_URLS } from "../../../../api/url";
import API from "../../../../api/axios";
import { confirmAlert } from "react-confirm-alert";
import dropdown from '../../../../components/Images/dropdown.png';




const ModifyCategory = (props) => {

    const nameRegExp = /^[_A-zA-Z]*((-|\s)*[_A-zA-Z])*$/g

    const [loading,setLoading] = useState(false)

    const [coins,setCoins] = useState(false)
    const [dataLoaded,setDataLoaded] = useState(false)
    console.log(props.coin_type)

    const getCoinList =()=>{
        API.get(APP_URLS.BUY_COIN_PHASE_INFORMATION)
        .then(res => { 
        if (res.data.success === true) {
                setCoins(res.data.data.coins)
                setDataLoaded(true)
            } else {
            toast.error(res.data.message, {
                position: toast.POSITION.TOP_RIGHT
            })        
            }
        })
        .catch((error) => {
            const resp = error.response;
            if (resp) {
                let error_message = '';
                if (resp.data.errors !== undefined) {
                    {Object.keys(resp.data.errors).map((error, index) => (
                        error_message = resp.data.errors[error][0]
                    ))}
                } else if (resp.data.data.error !== undefined) {
                    error_message =  resp.data.data.error;
                } else {
                    error_message =  resp.data.error
                }

                toast.error(error_message, {
                    position: toast.POSITION.TOP_RIGHT
                })
            }
        }) 
    }

    useEffect(() => {
        getCoinList()
    }, [])

    const formik = useFormik({
        initialValues: {
            title: props.name,
            coin_type:props.coin_type,
        },
        validationSchema: Yup.object({
            title: Yup.string().matches(nameRegExp, 'Name is not valid').matches(/^(.*)?\S+(.*)?$/, 'Blank spaces are not allowed').required('Name is Required').min(1).trim().max(20, 'The "Name" field can not be greater than 20 characters'),
            coin_type:Yup.string().required('Coin type is required')
        }),
        onSubmit: values => {
                    props.setmodifyPopup('')
                    API.post(APP_URLS.UPDATE_ADDRESS_BOOK_CATEGORY+`/${props.id}?_method=PATCH`,values)
                    .then(res => { 
                        if (res.data.success === true) {
                            props.setDataUpdated(true)
                            formik.resetForm()
                            toast.success('Group has been modified successfully.', {
                            position: toast.POSITION.TOP_RIGHT
                            })
                        } else {
                            toast.error(res.data.message, {
                            position: toast.POSITION.TOP_RIGHT
                            })        
                        }
                        })
                        .catch((error) => {
                        const resp = error.response;
                        if (resp) {
                            
                            let error_message = '';
                            if (resp.data.errors !== undefined) {
                                {Object.keys(resp.data.errors).map((error, index) => (
                                    error_message = resp.data.errors[error][0]
                                ))}
                            } else if (resp.data.data.error !== undefined) {
                                error_message =  resp.data.data.error;
                            } else {
                                error_message =  resp.data.error
                            }
                
                            toast.error(error_message, {
                                position: toast.POSITION.TOP_RIGHT
                            })
                        }
                        })
                    }
          });
    
    return (
        <>
            <div className="flex flex-center bg-darkblue bg-opacity-80 w-full fixed z-20 grid h-screen place-items-center top-0 left-0 right-0">
                <div className="relative w-w50 z-30 md:left-0 md:w-w50 sm:w-w90 sm:mx-auto">
                    <div className={(props.mode === 'light' ? 'bg-orange text-darkblue' : 'bg-bgblue border-lightblue ') + '  relative block mx-auto border border-8 rounded-2xl text-center px-8 py-10 max-w-md max-h-hv90 h-auto sm:px-2 sm:py-5'} >
                        <form className='text-center' onSubmit={formik.handleSubmit} >
                            <div className='max-h-hv70 h-auto overflow-y-auto scrollbar-hide'>
                                <h2 className={(props.mode === 'light' ? 'text-darkblue' : 'text-white') + ' pl-2 text-lg mb-2 tracking-wider'} >Modify Group</h2>
                                <button className='absolute -right-5 -top-5' onClick={() => props.setmodifyPopup('')}> <FontAwesomeIcon className='text-elemblue text-3xl bg-white rounded-full' icon={faXmarkCircle} /> </button>
                                <p className='text-red text-xs pl-2'>
                                    <em>
                                    Once created coin type can't be changed.
                                    </em>
                                </p>

                                <div className='w-full pt-4'>
                                    <div className='flex flex-row items-center my-2'>
                                        <p className={(props.mode === 'light' ? 'text-darkblue' : 'text-white') + ' pl-2 text-small xl:text-xs'}>Title</p>
                                    </div>
                                    <div>
                                        <input    
                                            onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}                                       
                                            type="text"
                                            autoComplete="off"
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            name="title"
                                            value={formik.values.title}
                                            placeholder='Enter Username' className={(props.mode === 'light' ? 'bg-white' : 'bg-inputblue1 text-white') + " w-full outline-none text-xsmall p-1 border-btnblue border rounded-3xl h-h45 px-4 "} />
                                        {formik.touched.title && formik.errors.title ? (
                                            <div className='text-left text-red text-xs pt-2 pl-2'>{formik.errors.title}</div>
                                        ) : null}
                                    </div>
                                </div>

                                <div className='w-full pt-4'>
                                    <div className='flex flex-row items-center my-2'>
                                        <p className={(props.mode === 'light' ? 'text-darkblue' : 'text-white') + ' pl-2 text-small xl:text-xs'}>Coin</p>
                                    </div>
                                    <div className={(props.mode==='light'?'bg-white':'bg-inputblue1 text-white')+" border-btnblue border rounded-3xl w-full outline-none h-10 text-xsmall h-h45 pr-4 shadow-lg opacity-70"}>
                                    
                                    <select 
                                    style={{backgroundImage: `url(${dropdown})`}} 
                                    className={(props.mode==='light'?'bg-white':'bg-inputblue1 text-white')+"  rounded-3xl w-full outline-none h-10 text-xsmall p-1 h-h42 px-4 bg-no-repeat bg-right appearance-none opacity-40 cursor-not-allowed"}
                                        value={formik.values.coin_type}
                                        name="coin_type"
                                        onBlur={formik.handleBlur}
                                        onChange={formik.handleChange}
                                        disabled>
                                        <option hidden>Select a Coin</option>
                                        <option value="HDL">HDL</option>
                                        {dataLoaded&&(
                                            coins.map((item,index)=>{
                                                return <option value={item.type} key={index}>{item.type}</option>
                                            })
                                        )}
                                    </select>
                                        {formik.touched.coin_type && formik.errors.coin_type ? (
                                            <div className='text-left text-red text-xs pt-2 pl-2'>{formik.errors.coin_type}</div>
                                        ) : null}
                                    </div>
                                </div>

                                <div className='mt-5 pl-2 '>
                                    <button type="submit" className={(props.mode==='light'?'bg-darkorange':'bg-gradient-to-r from-purple to-pink ')+' mt-3 rounded-3xl w-wp160 h-h13 text-white mb-2 focus:outline-none'} disabled={loading}  autoFocus>{loading ? 'Modifying...' : 'Modify'}</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default ModifyCategory;