import { faClone, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react'
import { confirmAlert } from 'react-confirm-alert';
import API from '../../../api/axios';
import { APP_URLS } from '../../../api/url';
import Pagination from '../../../components/Shared/Pagination';
import ModifyAddressPopUp from './Popup/ModifyAddressPopUp';
import { toast } from 'react-toastify';

const AddressHistory = (props) => {

    //Popups
    const [modifyPopup,setmodifyPopup] = useState(false)

    const [data,setData] = useState()
    const [dataUpdated,setDataUpdated] = useState(false)

    //Seting data for address history API
    const[entries,setEntries] =useState(10)
    const[updateSearch,setUpdateSearch] = useState('');
    const[search,setSearch] = useState('');
    const[dataLoaded,setDataLoaded] = useState(false);
    const [datadeleted,setDataDeleted] = useState(false)
    const [loading, setLoading] = useState(false);

    //Setting Pagination
    const[page,setPage] = useState(1);
    const[totalPages,setTotalPages] = useState(0);

    const copyAddress=(index)=>{
        let copyText  = document.getElementById("address"+index).innerText;
        navigator.clipboard.writeText(copyText)
        let textCopied = document.getElementById('addressCopied'+index);
        textCopied.style.display = 'block';
        setTimeout(() => {
            const textCopied = document.getElementById('addressCopied'+index);
            textCopied.style.display = 'none';
        }, 1000);
        }

    const searchRecord =() =>{
        setSearch(updateSearch)
    }
    
    const getAddresses =()=>{
        API.get(APP_URLS.GET_ALL_ADDRESS+`?length=${entries}&page=${page}&search=${search}&sort_dir=desc&sort_by=id`)
        .then(res => { 
            if (res.data.success === true) {
                setData(res.data.data)
                setTotalPages(res.data.data.last_page)
                setDataLoaded(true)
            } else {
                toast.error(res.data.message, {
                position: toast.POSITION.TOP_RIGHT
                })        
            }
            })
            .catch((error) => {
            const resp = error.response;
            if (resp) {
                let error_message = '';
                if (resp.data.errors !== undefined) {
                    {Object.keys(resp.data.errors).map((error, index) => (
                        error_message = resp.data.errors[error][0]
                    ))}
                } else if (resp.data.data.error !== undefined) {
                    error_message =  resp.data.data.error;
                } else {
                    error_message =  resp.data.error
                }
    
                toast.error(error_message, {
                    position: toast.POSITION.TOP_RIGHT
                })
            }
            }) 
    }

    const removeAddress =(index)=>{
        confirmAlert({
            title: 'Confirm to remove',
            message: `Are you sure you want to remove.`,
            closeOnEscape: true,
            buttons: [
                {
                label: 'Yes',
                onClick: () => {
                    API.delete(APP_URLS.REMOVE_ADDRESS+`/${index}`)
                    .then(res => { 
                        if (res.data.success === true) {
                        setDataDeleted(true)
                        toast.success(res.data.message, {
                            position: toast.POSITION.TOP_RIGHT
                            })   
                        } else {
                            toast.error(res.data.message, {
                            position: toast.POSITION.TOP_RIGHT
                            })        
                        }
                        })
                        .catch((error) => {
                        const resp = error.response;
                        if (resp) {
                            let error_message = '';
                            if (resp.data.errors !== undefined) {
                                {Object.keys(resp.data.errors).map((error, index) => (
                                    error_message = resp.data.errors[error][0]
                                ))}
                            } else if (resp.data.data.error !== undefined) {
                                error_message =  resp.data.data.error;
                            } else {
                                error_message =  resp.data.error
                            }
                
                            toast.error(error_message, {
                                position: toast.POSITION.TOP_RIGHT
                            })
                        }
                        }) 
                }
                },
                {
                label: 'No'
                }
            ]
        });
    }
    useEffect(() => {
        setDataDeleted(false)
        setDataUpdated(false)
        props.setDataAdded(false)
        getAddresses()
    }, [entries,search,page,datadeleted,props.dataAdded,dataUpdated])

  return (
    <>
    {modifyPopup}
        <div className="flex flex-row w-w100 justify-between mt-8">
        <div className="flex flex-row w-wp170 items-center sm:py-3">
            <p className={props.mode === "light" ? "" : "text-white"}>Show</p>
            <select className={(props.mode === "light"? "bg-white": "bg-elemblue2 text-white") +" w-14 text-xsmall p-1 shadow-lg rounded-lg outline-none mx-2"} value={props.entries}
            onChange={(e) => {
                setPage(1)
                setEntries(parseInt(e.target.value))}}
            >
            <option value="10">10</option>
            <option value="20">20</option>
            <option value="50">50</option>
            <option value="100">100</option>
            </select>
            <p className={props.mode === "light" ? "" : "text-white"}>
            entries
            </p>
        </div>
        <div className="w-auto flex flex-row justify-between sm:w-w40">
            <div
            className={
                "flex flex-row w-auto px-3 items-center shadow-lg rounded-full h-h40 " +
                (props.mode === "light"
                ? "bg-trans"
                : "bg-inputblue")
            }
            >
            <input
                type="text"
                placeholder="search"
                className={
                (props.mode === "light" ? "bg-trans text-darkblue" : "bg-trans text-white text-opacity-60") +
                " w-w80 outline-none text-white"
                }
                value={updateSearch}
                onChange={(e)=>setUpdateSearch(e.target.value)}
            />
            <button type="button" className="outline-none w-w20" onClick={searchRecord}>
                <FontAwesomeIcon
                icon={faMagnifyingGlass}
                className={(props.mode === "light" ? "" : "text-white") + ""}
                />
            </button>
            </div>
        </div>
        </div>

        <div className="w-w100 table-auto overflow-x-auto mt-2">
            <table className="w-full mt-2 table-auto md:w-maxWidth">
            <thead>
                <tr
                className={
                    (props.mode === "light"
                    ? "bg-white"
                    : "bg-tableblue text-white") +
                    " flex flex-row justify-around mt-2 py-8 rounded-xl shadow-lg"
                }
                >
                <th className="flex justify-start w-w20 pl-10 pr-2">Name</th>
                <th className="flex justify-start w-w20 px-2">Address</th>
                <th className="flex justify-start w-w20 px-2">Coin Type</th>
                <th className="flex justify-start w-w20 px-2">Group</th>
                <th className="flex justify-center w-w20 px-2">Action</th>
                </tr>
            </thead>
            <tbody>
                {dataLoaded===true?
                (data.data.length >0?
                 data.data.map((item,index)=>{
                    return <tr className={(props.mode === "light"? "bg-white": "bg-tableblue text-fontblue") +" flex flex-row justify-around items-center my-4 py-4 rounded-xl shadow-lg"} key={index}>
                            <td className={(props.mode==='light'?'text-darkblue':'text-white')+' capitalize flex justify-start w-w20 pl-10 pr-2'}>
                            {item.full_name}
                            </td>
                            <td className="flex flex-col justify-start text-white w-w20 px-2 break-all">
                                <div className="flex flex-row">
                                <span className={(props.mode==='light'?'text-darkblue':'text-white')+' overflow-hidden whitespace-nowrap overflow-ellipsis mr-1 w-52'} id={"address"+index}>
                                {item.address}
                                </span>
                                <span className="cursor-pointer" onClick={()=>copyAddress(index)}>
                                    {" "}
                                    <FontAwesomeIcon
                                    icon={faClone}
                                    className={
                                        (props.mode === "light"
                                        ? "text-darkblue"
                                        : "text-white") + " text-medium"
                                    }
                                    />{" "}
                                </span>
                                </div>
                                <p id={"addressCopied"+index} className={"ml-1 text-green text-xsmall hidden"}>Text Copied To Clipboard</p>
                            </td>
                            <td className={(props.mode==='light'?'text-darkblue':'text-white')+' block justify-start w-w20 px-2'}>
                            {item.coin_type}
                            </td>
                            <td className={(props.mode==='light'?'text-darkblue':'text-white')+' block justify-start w-w20 px-2'}>
                            {(item.category_name!==""&&item.category_name!=null)?item.category_name:'-'}
                            </td>
                            <td className="flex justify-center w-w20 text-white text-sm px-2">
                                <div className="px-3 h-8 self-center flex justify-center items-center bg-darkgrey2fade rounded-lg cursor-pointer"  onClick={()=>setmodifyPopup(<ModifyAddressPopUp id={item.id} setmodifyPopup={setmodifyPopup} setDataUpdated={setDataUpdated} name={item.full_name} address={item.address} coin={item.coin_type} category={item.category_name} categoryID={item.category_id} mode={props.mode}/>)}>
                                    Modify
                                </div>
                                <div className="px-3 ml-2 h-8 self-center flex justify-center items-center bg-redLight rounded-lg cursor-pointer" onClick={()=>removeAddress(item.id)}>
                                    Remove
                                </div>
                            </td>
                        </tr>
                }):
                <tr className={(props.mode === 'light' ? 'bg-white' : 'bg-tableblue text-fontblue') + " flex flex-row justify-around items-center my-5 py-4 rounded-xl shadow-lg"}>
                    <td colSpan="5"> { props.loading ? 'Loading...' : 'No Records Found' }</td>
                </tr>):
                <tr className={(props.mode === 'light' ? 'bg-white' : 'bg-tableblue text-fontblue') + " flex flex-row justify-around items-center my-5 py-4 rounded-xl shadow-lg"}>
                    <td colSpan="5">Loading...</td>
                </tr>
            }
            </tbody>
            </table>
        </div>
        <Pagination 
              totalPages={totalPages}
              onPageClick={(page) => setPage(page + 1)}
              key={entries}
            />
    </>
  )
}

export default AddressHistory