import React, { useState, useContext } from "react";
import { MainLayout } from '../../components/Layouts/MainLayout'
import {Theme} from "../../App"
import Mode from '../../components/Mode/Mode'
import GoogleVerification from './Components/GoogleVerification'
import PreferenceSettings from './Components/PreferenceSettings'


const SettingPage = (props) => {

    const mode = useContext(Theme);

    return (
        <MainLayout>
            <Mode />
            <div className="min-h-screen">
            <GoogleVerification mode={mode}/>
            <PreferenceSettings mode={mode}/>
            </div>
        </MainLayout>
    )
}

export default SettingPage