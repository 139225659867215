import React,{useEffect, useState} from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeftLong, faMagnifyingGlass, faClone,faArrowRightLong, faCheck, faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import Pagination from '../Shared/Pagination';


const AllDeposit = (props) => {

  const[fetchData,SetfetchData] = useState('all');
  const[depositList,setDespositList] = useState([]);
  
  //Seting data for coin history API
  const[entries,setEntries] =useState(10)
  const[updateSearch,setUpdateSearch] = useState('');
  const[search,setSearch] = useState('');
  const[dataLoaded,setDataLoaded] = useState(false);
  const [loading, setLoading] = useState(false);

  
  //Setting Pagination
  const[page,setPage] = useState(1);
  const[totalPages,setTotalPages] = useState(0);

  //USERID
  const [id,setId] = useState(JSON.parse(localStorage.getItem('user_info')).id)
  
  useEffect(() => {
    callAPI()
  }, [entries, fetchData, page, search])

  const handleEntries = (e) => setEntries(parseInt(e.target.value))

  const cloneAddress = (index) =>{
    let copyText  = document.getElementById("copyText"+index).previousSibling.innerText;
    navigator.clipboard.writeText(copyText)
    let textCopied = document.getElementById('textCopied'+index);
    textCopied.style.display = 'block';
    setTimeout(() => {
      const textCopied = document.getElementById('textCopied'+index);
      textCopied.style.display = 'none';
    }, 1000);
  }

  const cloneHash = (index) =>{
    let copyText  = document.getElementById("Hash"+index).innerText;
    navigator.clipboard.writeText(copyText)
    let textCopied = document.getElementById('HashCopied'+index);
    textCopied.style.display = 'block';
    setTimeout(() => {
      const textCopied = document.getElementById('HashCopied'+index);
      textCopied.style.display = 'none';
    }, 1000);
  }

  const callAPI = async () =>{
    setLoading(true);
    let authToken = localStorage.getItem("token");
    let access_type = localStorage.getItem("access_type");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", access_type + " " + authToken);
    
    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
    };

    await fetch(process.env.REACT_APP_API_URL+`transaction-histories?type=${fetchData}&search=${search}&length=${entries}&page=${page}`, requestOptions)
      .then(response => response.text())
      .then(result => {
        setLoading(false);
        let respData = JSON.parse(result);
        setDespositList(respData.data.data)
        setDataLoaded(true);
        // if(respData.data.data.length>=entries){
        //   setPagination(true)
        // }

        setTotalPages(respData.data.last_page)
        
      })
      .catch(error => console.log('error', error));
  }

  const searchRecord =() =>{
    setSearch(updateSearch)
  }

  return (
    <>
      <div className="flex flex-col justify-evenly w-w100">
        <div className="flex flex-row my-5 justify-start sm:mb-0">
        <h1 className={(props.mode==='light'?'':'text-white')+" text-medium xxs:text-small"}>
          {fetchData==='all'?'All Deposit/Withdrawal List':
          fetchData==='deposite'?'All Deposit List':
          fetchData==='withdraw'?'All Withdrawal List':''}
          </h1>
          <button
            type="button"
            className={(props.mode === "light"? "bg-darkblue": "bg-gradient-to-r from-purple to-pink")+" text-white text-small w-auto px-3 h-8 rounded-3xl ml-2 outline-none xxs:text-xsmall hidden"}
          >
            Export History
          </button>
        </div>
        <div className="flex flex-row w-full justify-between sm:block">
          <div className="flex flex-row w-wp170 items-center sm:py-3 sm:mb-2">
          <p className={(props.mode==='light'?'':'text-white')}>Show</p>
            <select className={(props.mode==='light'?'bg-white':'bg-elemblue2 text-white')+" w-14 text-xsmall px-1 py-2 shadow-lg rounded-lg outline-none mx-2"}
            onChange={e => {
              handleEntries(e)
              setPage(1)
              }}>
              <option value="10">10</option>
              <option value="20">20</option>
              <option value="50">50</option>
              <option value="100">100</option>
            </select>
            <p className={(props.mode==='light'?'':'text-white')}>entries</p>
          </div>
          <div className="w-auto flex flex-row justify-between sm:w-w100">
            <div className={"flex flex-row w-auto px-3 items-center shadow-lg rounded-full h-h40 "+(props.mode==='light'?'bg-white':'bg-inputblue')}>
              <input
                type="text"
                placeholder="search"
                className={(props.mode==='light'?'text-darkblue bg-trans':'bg-trans text-white text-opacity-60')+" w-w80 outline-none text-white lg:text-sm"}
                value={updateSearch}
                onChange={(e)=>setUpdateSearch(e.target.value)}
              />
              <button type="button" className="outline-none w-w20" >
              <FontAwesomeIcon icon={faMagnifyingGlass} className={(props.mode==='light'?'':'text-white')+""} onClick={searchRecord}/>
              </button>
            </div>
            <div className="flex flex-row sm:justify-between">
              <button type="button" className={(props.mode==='light'?'bg-white':'text-white bg-inputblue')+" text-xsmall shadow-lg w-auto px-3 rounded-lg ml-2 outline-none "+(fetchData=='all' ? (props.mode==='light'?"bg-darkbuttonblue border-b-4 border-darkorange text-white":'bg-darkbuttonblue border-b-4 border-blue text-white'):' sm:px-1')} onClick={()=>SetfetchData('all')}>All</button>
              <button
                type="button"
                className={(props.mode==='light'?'bg-white':'bg-inputblue text-white')+" text-xsmall shadow-lg w-auto mx-3 px-3 rounded-lg outline-none "+(fetchData=='deposite' ? (props.mode==='light'?"bg-darkbuttonblue border-b-4 border-darkorange text-white":'bg-darkbuttonblue border-b-4 border-blue text-white'):' sm:px-1')}
              onClick={()=>SetfetchData('deposite')}>
                Deposit
              </button>
              <button type="button" 
              className={(props.mode==='light'?'bg-white ':'bg-inputblue text-white')+" text-xsmall shadow-lg w-auto px-3 mr-3 rounded-lg outline-none"+(fetchData=='withdraw' ? (props.mode==='light'?" bg-darkbuttonblue border-b-4 border-darkorange text-white":' bg-darkbuttonblue border-b-4 border-blue text-white'):' sm:px-1 sm:mr-0')} onClick={()=>SetfetchData('withdraw')}>Withdraw</button>
            </div>
          </div>
        </div>
        {dataLoaded && (
        <div className="w-full table-auto overflow-x-auto">
          <table className="w-full mt-2 table-auto md:w-maxWidth">
            <thead>
              <tr className={(props.mode==='light'?'bg-white':'bg-tableblue text-white')+" flex flex-row justify-around mt-4 py-8 rounded-xl shadow-lg"}>
                <th className="flex justify-start w-w16 px-5">Address</th>
                <th className="flex justify-start w-w16 px-10 xl:px-0">Coin Amount</th>
                <th className="flex justify-center w-w16">Coin Type</th>
                <th className="flex justify-left w-w16">Transaction</th>
                <th className="flex justify-start w-w16 px-10">Date</th>
                <th className="flex justify-center w-w16">Status</th>
              </tr>
            </thead>
            <tbody>
          {depositList.length>0?
            depositList.map((item,index) => {
              let icon = item.coin_type
              return(
                <tr key={index} className={(props.mode==='light'?'bg-white text-darkblue':'bg-tableblue text-white')+" flex flex-row justify-around items-center my-4 py-4 rounded-xl shadow-lg"}>
                  <td className="flex flex-col justify-start w-w16 px-5 break-all">
                    <div className="flex justify-start w-full break-all">
                    {item.address?
                    <span className="overflow-hidden whitespace-nowrap overflow-ellipsis w-52 ">{item.address}</span>:
                    <span className="overflow-hidden whitespace-nowrap overflow-ellipsis w-52 ">{item.email}</span>}
                    {item.address==="iHodl Bonus"?'':<button className="cursor-pointer" onClick={()=>cloneAddress(index)} id={"copyText"+index}> <FontAwesomeIcon icon={faClone} className={(props.mode==='light'?'text-darkblue':'text-white')+" text-medium"} /> </button>}
                    </div>
                    <p id={"textCopied"+index} className={"ml-1 text-green text-xsmall self-center hidden"}>Text Copied To Clipboard</p>

                  </td>
                  <td className="flex justify-start px-10 w-w16 xl:px-0">
                    {fetchData==='all'?
                    (item.transaction_type==='withdraw'||id===item.sender_user_id)?
                    <FontAwesomeIcon icon={faMinus} className={"text-red text-xsmall self-center mr-1"} />:
                    <FontAwesomeIcon icon={faPlus} className={"text-green text-xsmall self-center mr-1"} />
                    :null}
                    {parseFloat(item.amount).toFixed(2)} {item.coin_type}
                    </td>
                    {console.log("=======================>",item)}
                  <td className="flex justify-center  w-w16">
                    {   
                        (item && item.coin_icon !== null)? 
                            <img src={process.env.REACT_APP_API_IMG+'uploaded_file/sleep/'+item.coin_icon} alt="Default-light" className="w-10 h-10 rounded-full"/>
                        :<img src = {require(`../Images/icons/Default-light.png`)} alt="Default-light" className="w-10 h-10 rounded-full"/>
                    }
                  </td>
                  {item.transaction_type=='deposite'?
                  <td className="flex flex-col justify-left w-w16">
                    <div className="w-4/5 flex flex-row break-all">
                    {item.transaction_id?
                    <>
                    <span className="overflow-hidden whitespace-nowrap overflow-ellipsis w-52" id={"Hash"+index}>{item.transaction_id}</span>
                    <button className="cursor-pointer ml-3" onClick={()=>cloneHash(index)} id={"copyHash"+index}> 
                      <FontAwesomeIcon icon={faClone} className={(props.mode==='light'?'text-darkblue':'text-white')+" text-medium"} /> 
                    </button>
                    </>:
                    <span className="overflow-hidden whitespace-nowrap overflow-ellipsis w-52" id={"Hash"+index}>NA</span>}
                    </div>
                    <p id={"HashCopied"+index} className={"ml-1 text-green text-xsmall hidden"}>Text Copied To Clipboard</p>
                  </td>:
                  <td className="flex flex-col justify-left w-w16">
                    <div className="w-4/5 flex flex-row justify-between break-all">
                    {item.transaction_hash?
                    <>
                    <span className="overflow-hidden whitespace-nowrap overflow-ellipsis w-52" id={"Hash"+index}>{item.transaction_hash}</span>
                    <button className="cursor-pointer" onClick={()=>cloneHash(index)} id={"copyHash"+index}> 
                      <FontAwesomeIcon icon={faClone} className={(props.mode==='light'?'text-darkblue':'text-white')+" text-medium"} /> 
                    </button>
                    </>:
                    <span className="overflow-hidden whitespace-nowrap overflow-ellipsis w-52" id={"Hash"+index}>NA</span>}
                    </div>
                    <p id={"HashCopied"+index} className={"ml-1 text-green text-xsmall hidden"}>Text Copied To Clipboard</p>
                  </td>
                  }
                  <td className="flex justify-center w-w16">{item.created_at}</td>
                  <td className="flex justify-center w-w16 text-white text-sm">
                    {item.status===1||item.status==='1'?
                    <div className="px-3 h-8 self-center flex justify-center items-center bg-greenlight rounded-lg">Active</div>:
                    item.status===0||item.status==='0'?
                    <div className="px-3 h-8 self-center flex justify-center items-center bg-darkgrey2fade rounded-lg">Pending</div>:
                    <div className="px-3 h-8 self-center flex justify-center items-center bg-redLight rounded-lg">Rejected</div>
                    }
                  </td>
                </tr>
              )
            }):
            <tr className={(props.mode === 'light' ? 'bg-white' : 'bg-tableblue text-fontblue') + " flex flex-row justify-around items-center my-5 py-4 rounded-xl shadow-lg"}>
                <td colSpan="5"> { loading ? 'Loading...' : 'No Records Found' }</td>
            </tr>
            }
          </tbody>

          </table>
        </div>
        )}
        {(depositList.length>0) &&
            <Pagination 
              totalPages={totalPages}
              onPageClick={(page) => setPage(page + 1)}
              key={entries}
            />
          }
      
      </div>
    </>
  );
};

export default AllDeposit;
