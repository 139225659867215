import React from 'react'
import ProfileInformationForm from './forms/ProfileInformationForm';
import ChangePasswordForm from './forms/ChangePasswordForm';

const ProfileInfo = (props) => {
  

  return (
    <>
          <div className="flex flex-row justify-between mt-10 w-full items-center xs:block rounded-lg">
            <h1 className={(props.mode==='light'?'text-darkblue':'text-white')+' text-medium mb-5 xxs:mt-4'}>
              Edit profile
            </h1>
          </div>
          <ProfileInformationForm mode={props.mode} data={props.userData} setUpdated={props.setUpdated}/>
          <div className="flex flex-row mb-8 gap-8 md:gap-5 sm:block">
            <ChangePasswordForm mode={props.mode}/>
          </div>


    </>
  )
}

export default ProfileInfo