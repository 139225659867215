import React,{useState,useEffect} from "react";
import logo from "../components/Images/iHodl-3.png";
import { Link, useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import * as Yup from 'yup';
import { useFormik } from "formik";
import { APP_URLS } from "../api/url";
import API from "../api/axios";

const GoogleAuthentication = () => {

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate()

  useEffect(() => {
    const token = localStorage.getItem('token');
    
    if(!token) {
      navigate('/login')
    }
  }, []);

  const formik = useFormik({
    initialValues: {
        code: '',
    },
    validationSchema: Yup.object({
        code: Yup.string().required('Code is Required').min(6,'Code should be of minimum 6 characters').max(6,'Code should be of maximum 6 characters'),
    }),
    onSubmit: (values) => {
        if(!loading) {
            setLoading(true)
            var formdata = new FormData();
            formdata.append("code", values.code);
            API.post(APP_URLS.GOOGLE_AUTHENTICATE, formdata)
            .then(res => {
                setLoading(false)
                if (res.data.success === true) {
                    localStorage.setItem('g2f',true)
                    toast.success('Google Two Factor Authentication Code has been verified successfully.', {
                        position: toast.POSITION.TOP_RIGHT
                      })
                      window.location.reload();
                  } else {
                    toast.error(res.data.message, {
                      position: toast.POSITION.TOP_RIGHT
                    })        
                  }
            })
            .catch(function (error) {  
                const resp = error.response;
                setLoading(false)
                if (resp) {
                  
                    let error_message = '';
                    if (resp.data.errors !== undefined) {
                        {Object.keys(resp.data.errors).map((error, index) => (
                            error_message = resp.data.errors[error][0]
                        ))}
                    } else if (resp.data.data.error !== undefined) {
                        error_message =  resp.data.data.error;
                    } else {
                        error_message =  resp.data.error
                    }

                    toast.error(error_message, {
                        position: toast.POSITION.TOP_RIGHT
                    })
                }
            });         
        }
    },
  });

  return (
    <>
      <ToastContainer />
      <div className="w-full flex w-full bg-bgblue px-4 py-10 min-h-screen">
          <div className="shadow-lg self-center w-full max-w-md m-auto bg-elemblue2 rounded p-7 border border-btnblue pb-10">   
            <header className="text-center">
            <img src={logo} className="w-2/6 flex mx-auto" />
            <h2 className="text-center text-white text-basic mb-3">Two Factor Authentication</h2>
           <p className="text-lightblue text-xs mb-5">Open your authentication app and enter the code for iHodl</p>
            </header>   
            <form onSubmit={formik.handleSubmit} className="">
            <div className="col-span-2">
                <input 
                  type="text" 
                   name="code" 
                  className={"w-full p-2 text-darkblue border-b-2 border-indigo-500 outline-none focus:bg-gray-300 bg-white text-white rounded-3xl h-h50 px-4" + (formik.touched.code && formik.errors.code ? ' is-invalid' : '')}   
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.code}
                  placeholder="Authentication Code"
                  autoComplete='off' />

                  {formik.touched.code && formik.errors.code ? (
                        <div className='text-left text-red text-xs pl-2 mt-1'>{formik.errors.code}</div>
                    ) : null} 
              </div>              
              <div>          
                <button className="w-full mt-5 bg-btnblue hover:bg-pink-700 font-bold py-2 px-4 rounded-3xl h-h50 text-white" type="submit" disabled={loading}>{ loading ? 'Verifying...' : 'Verify' }</button>
              </div>  
            </form>  
          </div>
        </div>
    </>
  )
}

export default GoogleAuthentication