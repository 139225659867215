import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightLong, faXmarkCircle } from "@fortawesome/free-solid-svg-icons";
import { useFormik } from "formik";
import * as Yup from 'yup';
import API from "../../../../api/axios";
import { APP_URLS } from "../../../../api/url";
import { toast } from 'react-toastify';

let formData ={}
const GoogleAuth = (props) => {

    const [loading,setLoading] = useState(false)
    var container = document.getElementById("inputBox");
    {container&&(
      container.onkeyup = function(e) {
        var target = e.target;
        var maxLength = parseInt(target.attributes["maxlength"].value, 10);
        var myLength = target.value.length;
        if (myLength >= maxLength) {
            var next = target;
            while (next = next.nextElementSibling) {
              if (next == null)
                break;
              if (next.tagName.toLowerCase() === "input") {
                next.focus();
                break;
              }
            }
      }
    
      // Move to previous field if empty (user pressed backspace)
     else if (myLength === 0) {
        var previous = target;
        while (previous = previous.previousElementSibling) {
          if (previous == null)
             break;
          if (previous.tagName.toLowerCase() === "input") {
             previous.focus();
             break;
             }
            }
          }
        }
        )}

        const formik = useFormik({
            initialValues: {
                char1:'',
                char2:'',
                char3:'',
                char4:'',
                char5:'',
                char6:'',
            },
            validationSchema: Yup.object({
                char1: Yup.string().required(),
                char2: Yup.string().required(),
                char3: Yup.string().required(),
                char4: Yup.string().required(),
                char5: Yup.string().required(),
                char6: Yup.string().required(),
            }),
            onSubmit: values => {
                formData = props.data                
                if(!loading) {
                setLoading(true)
                let code = []
                for (const key in values) {
                    code.push(values[key])
                }
                let strCode = code.join("")
                formData[`code`] = strCode
                if(props.modifyID){
                    API.post(APP_URLS.MODIFY_TRANSACTIONS+`/${props.modifyID}?_method=PATCH`, formData)
                    .then(res => {
                        const respMessage = res.data.message;
                        if (res.data.success === true) {
                            toast.success(respMessage, {
                                position: toast.POSITION.TOP_RIGHT
                            })
                        } else {
                            toast.error(respMessage, {
                                position: toast.POSITION.TOP_RIGHT
                            })
                        }
                        props.actions.resetForm();
                        props.setGauth(false)
                        props.setCoin('')
                        setLoading(false);
                        formik.resetForm()
                        setTimeout(props.setTransactionDone(true),1000)
                        props.arr?.splice(0, props.arr.length)
                        props.setAmountPopup(false)
                    })
                    .catch(function (error) {
                        console.log("entered")

                        const resp = error.response;
                        setLoading(false)
                        if (resp) {

                            let error_message = '';
                            if (resp.data.errors !== undefined) {
                                {
                                    Object.keys(resp.data.errors).map((error, index) => (
                                        error_message = resp.data.errors[error][0]
                                    ))
                                }
                            } else if (resp.data.data.error !== undefined) {
                                error_message = resp.data.data.error;
                            } else if(resp.data.error !== undefined){
                                error_message = resp.data.error
                            } else{
                                error_message = resp.data.message
                            }
                            toast.error(error_message, {
                                position: toast.POSITION.TOP_RIGHT
                            })
                        }
                    }); 
                }
                else{
                    API.post(APP_URLS.CREATE_TRANSACTIONS, formData)
                    .then(res => {
                        const respMessage = res.data.message;
                        if (res.data.success === true) {
                            toast.success(respMessage, {
                                position: toast.POSITION.TOP_RIGHT
                            })
                        } else {
                            toast.error(respMessage, {
                                position: toast.POSITION.TOP_RIGHT
                            })
                        }
                        props.actions.resetForm();
                        props.setGauth(false)
                        props.setCoin('')
                        setLoading(false);
                        formik.resetForm()
                        setTimeout(props.setTransactionDone(true),1000)
                        props.arr?.splice(0, props.arr.length)
                        props.setAmountPopup(false)
                    })
                    .catch(function (error) {
                        const resp = error.response;
                        toast.error(resp.data.message, {
                            position: toast.POSITION.TOP_RIGHT
                        })
                        setLoading(false)
                        if (resp) {

                            let error_message = '';
                            if (resp.data.errors !== undefined) {
                                {
                                    Object.keys(resp.data.errors).map((error, index) => (
                                        error_message = resp.data.errors[error][0]
                                    ))
                                }
                            } else if (resp.data.data.error !== undefined) {
                                error_message = resp.data.data.error;
                            } else {
                                error_message = resp.data.error
                            }

                            toast.error(error_message, {
                                position: toast.POSITION.TOP_RIGHT
                            })
                        }
                    }); 
                }    
            }
            },
        });


  return (
            <>
                <div>
                    <div className="flex flex-center bg-darkblue bg-opacity-80 w-full fixed z-20 grid h-screen place-items-center top-0 left-0 right-0">
                    <div className="relative w-50 z-30 md:left-0 md:w-w50 sm:w-w90 sm:mx-auto">
                        <div className={(props.mode === "light"? "bg-orange text-darkblue border-darktblue"
                            : "bg-bgblue border-lightblue") +
                            " relative block mx-auto border border-8 rounded-2xl text-center px-8 py-10 max-w-md max-h-hv90 h-auto sm:p-2"
                        }
                        >
                            <button className="absolute -right-5 -top-5" onClick={()=> {
                                props.setGauth(false)
                                props.arr?.splice(0, props.arr.length)
                                if(props.closePopup){
                                props.setSchedulePopup(false) 
                                props.setModifySchedulePopup('') 
                                props.setShowModifySchedulePopup(false)
                                }
                                }}>
                            {" "}
                            <FontAwesomeIcon
                                className="text-elemblue text-3xl bg-white rounded-full"
                                icon={faXmarkCircle}
                            />{" "}
                            </button>
                            <div className='max-h-hv70 h-auto overflow-y-auto scrollbar-hide'> 
                            <h2
                                className={(props.mode === "light" ? "text-darkblue" : "text-white") +
                                " text-lg mb-2 text-2xl mt-5"
                                }>
                                Google Authentication
                            </h2>
                            <p
                                className={(props.mode === "light" ? "text-darkblue opacity-80": "text-white opacity-50") + " text-xs"
                                }>
                                Open your Google Authenticator app and enter the 6-digit code from the app into the input field to withdraw
                            </p>
                            <p className="text-elemblue text-xl mt-5 mb-4">6-digit code</p>
                            <form onSubmit={formik.handleSubmit}>
                                <div className='flex justify-between text-center' id="inputBox">
                                    <input
                                        type="text"  maxLength="1" name="char1" id="1" value={formik.values.char1} onChange={formik.handleChange} onBlur={formik.handleBlur} 
                                        className={(props.mode === "light"? "bg-white": "bg-bgblue text-white") +(formik.touched.char1 && formik.errors.char1?' border-2 border-red rounded-md':' border-elemblue border rounded-md')+
                                        " focus:border-btnblue w-full outline-none text-lg p-1 h-h45 w-wp45 p-2 text-center sm:w-wp40"
                                        }/>
                                    <input
                                        type="text"  maxLength="1" name="char2" id="2" value={formik.values.char2} onChange={formik.handleChange} onBlur={formik.handleBlur} 
                                        className={(props.mode === "light"? "bg-white": "bg-bgblue text-white") +(formik.touched.char2 && formik.errors.char2?' border-2 border-red rounded-md':' border-elemblue border rounded-md')+
                                        " focus:border-btnblue w-full outline-none text-lg p-1 h-h45 w-wp45 p-2 text-center sm:w-wp40"
                                        }/>
                                    <input
                                        type="text"  maxLength="1" name="char3" id="3" value={formik.values.char3} onChange={formik.handleChange} onBlur={formik.handleBlur} 
                                        className={(props.mode === "light"? "bg-white": "bg-bgblue text-white") +(formik.touched.char3 && formik.errors.char3?' border-2 border-red rounded-md':' border-elemblue border rounded-md')+
                                        " focus:border-btnblue w-full outline-none text-lg p-1 h-h45 w-wp45 p-2 text-center sm:w-wp40"
                                        }/>
                                    <input
                                        type="text"  maxLength="1" name="char4" id="4" value={formik.values.char4} onChange={formik.handleChange} onBlur={formik.handleBlur} 
                                        className={(props.mode === "light"? "bg-white": "bg-bgblue text-white") +(formik.touched.char4 && formik.errors.char4?' border-2 border-red rounded-md':' border-elemblue border rounded-md')+
                                        " focus:border-btnblue w-full outline-none text-lg p-1 h-h45 w-wp45 p-2 text-center sm:w-wp40"
                                        }/>
                                    <input
                                        type="text"  maxLength="1" name="char5" id="5" value={formik.values.char5} onChange={formik.handleChange} onBlur={formik.handleBlur} 
                                        className={(props.mode === "light"? "bg-white": "bg-bgblue text-white") +(formik.touched.char5 && formik.errors.char5?' border-2 border-red rounded-md':' border-elemblue border rounded-md')+
                                        " focus:border-btnblue w-full outline-none text-lg p-1 h-h45 w-wp45 p-2 text-center sm:w-wp40"
                                        }/>
                                    <input
                                        type="text"  maxLength="1" name="char6" id="6" value={formik.values.char6} onChange={formik.handleChange} onBlur={formik.handleBlur} 
                                        className={(props.mode === "light"? "bg-white": "bg-bgblue text-white") +(formik.touched.char6 && formik.errors.char6?' border-2 border-red rounded-md':' border-elemblue border rounded-md')+
                                        " focus:border-btnblue w-full outline-none text-lg p-1 h-h45 w-wp45 p-2 text-center sm:w-wp40"
                                        }/>
                                </div>
                                <div className="w-full my-5 ">
                                {props.data&&(
                                <button type="submit" className={(props.mode==='light'?'bg-darkorange':'bg-lightblue')+' mt-3 rounded-md w-full h-h13 text-white text-center text-xl'} disabled={loading}>
                                    { loading ? 'Confirming...' : 'Confirm' }
                                    <FontAwesomeIcon className="ml-3" icon={faArrowRightLong} />
                                </button>
                                )}

                                </div>
                            </form>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </>
  ) 
}

export default GoogleAuth