import React, { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeftLong } from "@fortawesome/free-solid-svg-icons";
import API from "../../../api/axios";
import { APP_URLS } from "../../../api/url";
import PageLength from "../../../components/Shared/PageLength";
import SearchBar from "../../../components/Shared/SearchBar";
import { Theme } from "../../../App";
import { toast } from "react-toastify";
import Pagination from "../../../components/Shared/Pagination";
import { NavLink, useParams } from "react-router-dom";

const CoUser = (props) => {
    const walletID = useParams();
    const mode = useContext(Theme);
    const [loading, setLoading] = useState(false);
    const [wallet,] = useState(walletID.coin)
    const [coUserList, setCoUserList] = useState([]);
    const [name,setName] = useState()

    //Setting Pagination and Search
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [entries, setEntries] = useState(10)
    const [search, setSearch] = useState('');

    useEffect(() => {
        personalWallet();
    }, [search, page, entries]);


    const personalWallet = async () => {
        setLoading(true);
        await API.get(APP_URLS.CO_USER_LIST + `/${wallet}?limit=${entries}&search=${search}&page=${page}`)
          .then((result) => { 
            const resp = result.data.data;
            if (result.data.success === true) {
                setCoUserList(resp.data);
                setTotalPages(resp.last_page)
                setName(resp.data[0].wallet_name)

            } else {
                toast.error(resp.message, {
                    position: toast.POSITION.TOP_RIGHT
                }) 
            }
            setLoading(false);
          })
          .catch((error) => {
            setLoading(false);
            setCoUserList([]);
          })
    }

    const handleLengthChange = (length) => {
        setEntries(length)
        setPage(0)
    }

    const handleSearchChange = (search) => {
        setSearch(search)
        setPage(0)
    }
    
    return (
        <>
            <div className='min-h-screen mt-10 pb-5 sm:mt-7'>
                <div className='flex flex-row w-full justify-between mb-3 xs:block'>
                    <h1 className={(mode === 'light' ? 'text-darkblue ' : ' text-white text-md ') + ""}>Co User of Wallet ( {name} )</h1>
                    <NavLink to="/wallet"><button className={(mode === 'light' ? 'text-darkblue' : 'text-white text-md') + " xs:mt-2"} ><FontAwesomeIcon className='pr-2' icon={faArrowLeftLong} /> Back to Wallet </button></NavLink>
                </div>
                <div className="flex flex-row w-full justify-between sm:block">
                    <PageLength onPageLengthChange={(length) => handleLengthChange(length)} />
                    <SearchBar onSearchClick={(search) => handleSearchChange(search)} />
                </div>

                <div className="w-w100 table-auto overflow-x-auto">
                    <table className="w-full mt-2 table-auto md:w-maxWidth">
                        <thead>
                            <tr className={(mode === 'light' ? 'bg-white' : 'bg-tableblue text-white') + " flex flex-row justify-around mt-2 py-8 rounded-xl shadow-lg"}>
                                <th className="flex justify-start w-w25 px-10">Name</th>
                                <th className="flex justify-start w-w25 px-10">Email</th>
                                <th className="flex justify-start w-w25 px-10">Phone</th>
                                <th className="flex justify-start w-w25 px-10">Wallet Imported At</th>
                            </tr>
                        </thead>
                        <tbody>
                            {coUserList.length > 0 ?
                                coUserList.map((item, index) => {
                                    return (
                                        <tr key={index} className={(mode === 'light' ? 'bg-white' : 'bg-tableblue text-fontblue') + " flex flex-row justify-around items-center my-4 py-4 rounded-xl shadow-lg"}>
                                            <td className={(mode === 'light' ? 'text-darkblue' : 'text-white') + '' + ' w-w25 px-10'}>{item.first_name} {item.last_name} 
                                            {item.owner_user_id===item.user_id?<span className="bg-yellow text-xsmall text-black px-2 py-1 ml-2 border rounded-2xl">Creator</span>:''}
                                            </td>
                                            <td className={(mode === 'light' ? 'text-darkblue' : 'text-white') + ' flex justify-start px-10 w-w25'}>{item.email}</td>
                                            <td className={(mode === 'light' ? 'text-darkblue' : 'text-white') + ' flex justify-start px-10 w-w25'} >{item.phone}</td>
                                            <td className={(mode === 'light' ? 'text-darkblue' : 'text-white') + ' block justify-start w-w25 px-10'} >
                                                {item.created_at}
                                            </td>
                                        </tr>
                                    )
                            })
                            :
                            <>
                                <tr className={(mode === 'light' ? 'bg-white' : 'bg-tableblue text-fontblue') + " flex flex-row justify-around items-center my-5 py-4 rounded-xl shadow-lg"}>
                                    <td colSpan="6">{ loading ? 'Loading...' : 'No Records Found' }</td>
                                </tr>
                            </>
                            }
                        </tbody>
                    </table>                   
                </div>
                {( coUserList.length > 0) && 
                        <>
                            <Pagination 
                                totalPages={totalPages}
                                onPageClick={(page) => setPage(page + 1)}
                                key={entries}
                            />
                        </>
                    }
            </div>
        </>
    );
};

export default CoUser;