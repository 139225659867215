import React, { useEffect, useState } from 'react'
import Card from './Card'
import API from '../../../api/axios'
import { APP_URLS } from '../../../api/url'
import { toast } from 'react-toastify';



const Offers = (props) => {

  const [data,setData] = useState()
  const [dataLoaded,setDataLoaded] = useState(false)

  const getOffers =()=>{
    API.get(APP_URLS.OFFERS)
    .then(res => { 
        if (res.data.success === true) {
            setData(res.data.data)
            setDataLoaded(true)
        } else {
            toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT
            })        
        }
        })
        .catch((error) => {
        const resp = error.response;
        if (resp) {
            let error_message = '';
            if (resp.data.errors !== undefined) {
                {Object.keys(resp.data.errors).map((error, index) => (
                    error_message = resp.data.errors[error][0]
                ))}
            } else if (resp.data.data.error !== undefined) {
                error_message =  resp.data.data.error;
            } else {
                error_message =  resp.data.error
            }

            toast.error(error_message, {
                position: toast.POSITION.TOP_RIGHT
            })
        }
        }) 
  }

  useEffect(() => {
    getOffers()
  }, [])
  
  return (
    <div className='grid grid-cols-4 gap-16 lg:grid-cols-2 sm:grid-cols-2 xs:grid-cols-1'>
      {dataLoaded?(
        data.length>0?data.map((item,index)=>{
          return <Card data={item} mode={props.mode} key={index}/>
        }):
        <div className="flex flex-row justify-between my-8 gap-6 md:block sm:block xxs:mt-6 h-screen">
            <div className="absolute top-[50%] left-[55%] md:top-[50%] md:left-[43%] sm:left-[40%] xs:left-[36%] xxs:left-[32%]">
                <span className='text-fontblue '>No Offers Available</span>
            </div>
        </div>
          )
        :
        <div className="grid grid-cols-2 gap-4 lg:grid-cols-2 sm:grid-cols-2 xs:grid-cols-1">
        <div className="flex flex-row justify-between gap-6 md:block sm:block xxs:mt-6 h-screen">
          <div className="absolute top-[50%] left-[55%] md:top-[50%] md:left-[43%] sm:left-[40%] xs:left-[36%] xxs:left-[32%]">
            <span className="text-fontblue ">Loading</span>
          </div>
        </div>
      </div>
        } 
    </div>
  )
}

export default Offers