import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMagnifyingGlass,
  faClone,
  faArrowRightLong,
  faArrowLeftLong,
} from "@fortawesome/free-solid-svg-icons";

const BuyCoinsHistory = (props) => {


  //Seting data for coin history API
  const[updateSearch,setUpdateSearch] = useState('');

  const copyAddress=(index)=>{
    let copyText  = document.getElementById("address"+index).innerText;
    navigator.clipboard.writeText(copyText)
    let textCopied = document.getElementById('addressCopied'+index);
    textCopied.style.display = 'block';
    setTimeout(() => {
      const textCopied = document.getElementById('addressCopied'+index);
      textCopied.style.display = 'none';
    }, 1000);
  }

  const searchRecord =() =>{
    props.setSearch(updateSearch)
  }

  return (
      <>
        <h2 className={(props.mode==='light'?'text-darkblue':'text-white')+' block text-left w-w100 text-medium mb-2 mt-8'}>
            Purchase History
            </h2>
            <div className="flex flex-row w-w100 justify-between sm:mt-5">
            <div className="flex flex-row w-wp170 items-center sm:py-3">
                <p className={props.mode === "light" ? "" : "text-white"}>Show</p>
                <select className={(props.mode === "light"? "bg-white": "bg-elemblue2 text-white") +" w-14 text-xsmall p-1 shadow-lg rounded-lg outline-none mx-2"} value={props.entries}
                onChange={(e) => {
                    props.setPage(1)
                    props.setEntries(parseInt(e.target.value))}}
                >
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
                </select>
                <p className={props.mode === "light" ? "" : "text-white"}>
                entries
                </p>
            </div>
            <div className="w-auto flex flex-row justify-between sm:w-w40">
                <div
                className={
                    "flex flex-row w-auto px-3 items-center shadow-lg rounded-full h-h40 " +
                    (props.mode === "light"
                    ? "bg-trans"
                    : "bg-inputblue")
                }
                >
                <input
                    type="text"
                    placeholder="search"
                    className={
                    (props.mode === "light" ? "bg-trans text-darkblue" : "bg-trans text-white text-opacity-60") +
                    " w-w80 outline-none text-white"
                    }
                    value={updateSearch}
                    onChange={(e)=>setUpdateSearch(e.target.value)}
                />
                <button type="button" className="outline-none w-w20" onClick={searchRecord}>
                    <FontAwesomeIcon
                    icon={faMagnifyingGlass}
                    className={(props.mode === "light" ? "" : "text-white") + ""}
                    />
                </button>
                </div>
            </div>
            </div>
            <div className="w-w100 table-auto overflow-x-auto mt-2">
                <table className="w-full mt-2 table-auto md:w-maxWidth">
                <thead>
                    <tr
                    className={
                        (props.mode === "light"
                        ? "bg-white"
                        : "bg-tableblue text-white") +
                        " flex flex-row justify-around mt-2 py-8 rounded-xl shadow-lg"
                    }
                    >
                    <th className="flex justify-start w-w16 px-5">Address</th>
                    <th className="flex justify-center w-w16">Coin Name</th>
                    <th className="flex justify-start w-w16">
                        Coin Amount
                    </th>
                    <th className="flex justify-start w-w16">
                        Payment Type
                    </th>
                    <th className="flex justify-start w-w16">Created At</th>
                    <th className="flex justify-center w-w16">Status</th>
                    </tr>
                </thead>
                <tbody>
                {props.data.length>0?
                    props.data.map((item,index)=>{
                    let icon = item.coin_type
                    let status =item.status
                        return(
                        <tr className={(props.mode === "light"? "bg-white": "bg-tableblue text-fontblue") +" flex flex-row justify-around items-center my-4 py-4 rounded-xl shadow-lg"} key={index}>
                        <td className="flex flex-col justify-start text-white w-w16 px-5 break-all">
                        <div className="flex flex-row">
                        <span className={(props.mode==='light'?'text-darkblue':'text-white')+' overflow-hidden whitespace-nowrap overflow-ellipsis mr-1 w-52'} id={"address"+index}>
                            {item.address}
                        </span>
                        {item.address!=="N/A"?
                        <span className="cursor-pointer" onClick={()=>copyAddress(index)}>
                            {" "}
                            <FontAwesomeIcon
                            icon={faClone}
                            className={
                                (props.mode === "light"
                                ? "text-darkblue"
                                : "text-white") + " text-medium"
                            }
                            />{" "}
                        </span>:''}
                        </div>
                        <p id={"addressCopied"+index} className={"ml-1 text-green text-xsmall hidden"}>Text Copied To Clipboard</p>
                        </td>
                        <td className="flex justify-center  w-w16">
                            {   
                                (item && item.coin_data && item.coin_data.coin_icon)? 
                                
                                    <img src={process.env.REACT_APP_API_IMG+'uploaded_file/sleep/'+item.coin_data.coin_icon} alt="Default-light" className="w-10 h-10 rounded-full"/>
                                    :""
                            }
                        </td>
                        
                        <td className={(props.mode==='light'?'text-darkblue':'text-white')+' capitalize flex justify-start w-w16'}>
                        {parseFloat(item.coin).toFixed(2)}
                        </td>
                        {item.type==='BANK DEPOSIT'?
                        <td className="capitalize flex justify-start text-green w-w16">
                        {item.type.toLowerCase()} 
                        </td>:
                        item.type==='Coin Payment'?<td className="capitalize flex justify-start text-lightyellow w-w16">
                        {item.type.toLowerCase()} 
                        </td>:
                        <td className="capitalize flex justify-start text-blue w-w16">
                        {item.type.toLowerCase()} 
                        </td>}
                        <td className={(props.mode==='light'?'text-darkblue':'text-white')+' block justify-start w-w16'}>
                        <span className="block">{item.created_at}</span>
                        <span className="text-xs opacity-50 block">
                        
                        </span>
                        </td>
                        <td className="flex justify-center w-w16 text-white text-sm">
                        {status==="Accepted"?
                        <div className="px-3 h-8 self-center flex justify-center items-center bg-greenlight rounded-lg">
                            {status}
                        </div>:
                        status==="Rejected"? 
                            <div className="px-3 h-8 self-center flex justify-center items-center bg-redLight rounded-lg">
                                {status}
                            </div>:
                            <div className="px-3 h-8 self-center flex justify-center items-center bg-darkgrey2fade rounded-lg">
                            {status}
                            </div>
                        }
                        </td>
                    </tr>)
                    }):
                    <tr className={(props.mode === 'light' ? 'bg-white' : 'bg-tableblue text-fontblue') + " flex flex-row justify-around items-center my-5 py-4 rounded-xl shadow-lg"}>
                        <td colSpan="5"> { props.loading ? 'Loading...' : 'No Records Found' }</td>
                    </tr>
                    }
                </tbody>
                </table>
            </div>
            
      </>
    
  )
}

export default BuyCoinsHistory