import React, { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBitcoinSign, faClone, faArrowRightLong, faArrowLeftLong } from "@fortawesome/free-solid-svg-icons";
import API from "../../../api/axios";
import { APP_URLS } from "../../../api/url";
import SearchBar from "../../../components/Shared/SearchBar";
import PageLength from "../../../components/Shared/PageLength";
import wallet from '../../../components/Images/wallet.png';
import withdrawal from '../../../components/Images/withdrawal.png';
import log from '../../../components/Images/log.png';
import swap from '../../../components/Images/swap.png';
import couser from '../../../components/Images/co-user.png';
import { NavLink } from 'react-router-dom';
import { Theme } from "../../../App";
import Pagination from "../../../components/Shared/Pagination";


const   MultiSignatureWallet = (props) => {
    const [loading, setLoading] = useState(false);
    const [multiSignatureWalletList, setMultiSignatureWalletList] = useState([]);
    const mode = useContext(Theme);

    //Setting Pagination and Search
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [entries, setEntries] = useState(10)
    const [search, setSearch] = useState('');

    const [selectedWallet,setSelectedWallet] = useState()

    useEffect(() => {
        mutliSignatureWallet()
    }, [search, page, entries,props.updated]);

    const mutliSignatureWallet = async () => {
        setLoading(true);
        await API.get(APP_URLS.MULTI_SINGATURE_WALLET+`?search=${search}&limit=${entries}&page=${page}`)
            .then((result) => {
                const resp = result.data.data;
                setTotalPages(result.data.data.last_page)
                setMultiSignatureWalletList(resp.data);
                setLoading(false);
            })
            .catch((error) => {
                setLoading(false);
                setMultiSignatureWalletList([]);
            })
    }

    function handleCoUser(wallet) {
        props.onClickCoUser(wallet)
    }

    function handleWithdrawClick(wallet) {
        props.onPopupChange(wallet)
    }
    
    function handleActivityLog(wallet) {
        props.onActivityLog(wallet)
    }

    function handleDeposit(wallet) {
        props.onClickDeposit(wallet)
    }

    const cloneHash = (index) =>{
        let copyText  = document.getElementById("copyHash"+index).innerText;
        navigator.clipboard.writeText(copyText)
        let textCopied = document.getElementById('HashCopied'+index);
        textCopied.style.display = 'block';
        setTimeout(() => {
          const textCopied = document.getElementById('HashCopied'+index);
          textCopied.style.display = 'none';
        }, 1000);
    }

    const handleLengthChange = (length) => {
        setEntries(length)
        setPage(0)
    }

    const handleSearchChange = (search) => {
        setSearch(search)
        setPage(0)
    }


    return (
        <>
            <div className="flex flex-row w-full justify-between">
                <PageLength onPageLengthChange={(length) => handleLengthChange(length)} />
                <div className="sm:w-w40">
                    <SearchBar onSearchClick={(search) => handleSearchChange(search)} />
                </div>
            </div>
            <div className='w-full table-auto overflow-x-auto'>
                <table className="w-full mt-2 table-auto relative md:w-maxWidth">
                    <thead>
                        <tr className={(mode === 'light' ? 'bg-white' : 'bg-tableblue text-white') + " flex flex-row justify-around mt-4 py-8 rounded-xl shadow-lg"}>
                            <th className="flex justify-left w-w15 pl-8">Name</th>
                            <th className="flex justify-left w-w15">Key</th>
                            <th className="flex justify-center w-w15">Coin Type</th>
                            <th className="flex justify-left w-w15">Balance</th>
                            <th className="flex justify-left w-w20">Updated At</th>
                            <th className="flex justify-left w-w20">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {multiSignatureWalletList.length > 0 ?
                            multiSignatureWalletList.map((item, index) => {
                                return (
                                    <tr key={index} className={(selectedWallet==index?(mode==='light'?'bg-selectedLight bg-opacity-30':'bg-selectedDark bg-opacity-30 text-fontblue'):(mode === 'light' ? 'bg-white' : 'bg-tableblue text-fontblue'))+" flex flex-row justify-around items-center my-5 py-4 rounded-xl shadow-lg"}>
                                        <td className={(mode === 'light' ? 'text-darkblue' : 'text-white') + ' flex justify-left w-w15 pl-8'}>
                                            {item.name}
                                        </td>
                                        <td className="justify-left w-w15">
                                            <div className="flex justify-start w-full break-all">
                                                <span className={(mode === 'light' ? 'text-darkblue' : 'text-white') + ' overflow-hidden whitespace-nowrap overflow-ellipsis w-52'}  id={"copyHash" + index}>{item.key}</span>
                                                <span className="cursor-pointer" onClick={() => cloneHash(index)}> <FontAwesomeIcon icon={faClone} className={(mode === 'light' ? 'text-darkblue' : 'text-white') + " text-medium ml-1"} /> </span>
                                            </div>
                                            <p id={"HashCopied"+index} className={"ml-1 text-green text-xsmall self-center hidden"}>Text Copied To Clipboard</p>


                                        </td>
                                        <td className={(mode === 'light' ? 'text-darkblue' : 'text-white') + ' flex justify-center w-w15'}>{item.coin_type}</td>
                                        <td className={(mode === 'light' ? 'text-darkblue' : 'text-white') + ' flex justify-left w-w15'} >{item.balance}</td>
                                        <td className={(mode === 'light' ? 'text-darkblue' : 'text-white') + ' block justify-left w-w20'}>{item.updated_at}</td>
                                        <td className="flex justify-left w-w20 text-white" onClick={()=>setSelectedWallet(index)}>
                                             {/* <button className='mr-4 xl:mr-2' onClick={() => handleCoUser(item)}><img src={couser} title='Co User' /></button> */}
                                             <NavLink className='mr-4 xl:mr-2' to={`/wallet/co-user/${item.id}`}><img src={couser} title='Co User' /></NavLink>
                                             <NavLink className='mr-4 xl:mr-2' to={`/wallet/deposit/${item.id}`}><img src={wallet} title='Deposit' /> </NavLink>
                                            {/* <button className='mr-4 self-center xl:mr-2' onClick={() => handleDeposit(item)}><img src={wallet} alt='Deposit' title='Deposit' /></button> */}
                                            <button className='mr-4 self-center xl:mr-2' onClick={() => handleWithdrawClick(item)}><img src={withdrawal} alt='Withdrawal' title='Withdrawal' /></button>
                                            <NavLink className='mr-4 xl:mr-2' to={`/wallet/activity-log/${item.id}`}><img src={log} title='Log' /> </NavLink>
                                            {/* <button className='mr-4 self-center xl:mr-2' onClick={() => handleActivityLog(item)}><img src={log} alt='Log' title='Log' /></button> */}
                                            {/* <NavLink to={`/swap-coins/${item.id}`} className='mr-4 self-center xl:mr-2'><img src={swap} alt='Swap' title='Swap' /></NavLink> */}

                                        </td>
                                    </tr>
                                )
                            })
                            :
                            <>
                                <tr className={(mode === 'light' ? 'bg-white' : 'bg-tableblue text-fontblue') + " flex flex-row justify-around items-center my-5 py-4 rounded-xl shadow-lg"}>
                                    <td colSpan="6">{ loading ? 'Loading...' : 'No Records Found' }</td>
                                </tr>
                            </>
                        }
                    </tbody>
                </table>
            </div>
            {(multiSignatureWalletList.length > 0) && 
                <>
                    <Pagination 
                        totalPages={totalPages}
                        onPageClick={(page) => setPage(page + 1)}
                        key={entries}
                    />
                </>
            }
        </>
    );
};

export default MultiSignatureWallet;