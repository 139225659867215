import './App.css';
import React, {createContext, useState} from "react";
import 'react-toastify/dist/ReactToastify.css';
import 'react-confirm-alert/src/react-confirm-alert.css'; // Import css
import PublicRoute from './routes/PublicRoute';
import PrivateRoute from './routes/Private';

const SetTheme = createContext();
const Theme = createContext();
const NotificationCount = createContext();
const SetNotificationCount = createContext();

function App() {
  let currMode = localStorage.getItem('mode')
  const token = localStorage.getItem('token');
  const g2f = JSON.parse(localStorage.getItem('g2f'))


  const [mode,setMode]=useState(currMode);
  const [notification,setNotification]=useState(0);


  return (
    <SetTheme.Provider value={setMode}>
      <Theme.Provider value={mode}>
        <SetNotificationCount.Provider value={setNotification}> 
          <NotificationCount.Provider value={notification}>
          {(!token || !g2f ) ? <PublicRoute /> : <PrivateRoute />}
          </NotificationCount.Provider>
        </SetNotificationCount.Provider>
      </Theme.Provider>
    </SetTheme.Provider>
  );
}

export default App;
export {Theme,SetTheme,NotificationCount,SetNotificationCount}
