import React, { useEffect, useState } from 'react'
import phoneIcon from "../../../Images/phone-icon.png";
import check from "../../../Images/check.png";
import { APP_URLS } from "../../../../api/url";
import API from "../../../../api/axios";
import { toast, ToastContainer } from 'react-toastify';
import { useFormik } from 'formik';
import * as Yup from 'yup';




const PhoneVerification = (props) => {

    const [otpSent,setOtpSent] = useState(false)
    const [loading, setLoading] = useState(false);

    const [timeLeft, setTimeLeft] = useState(null);

    useEffect(() => {
      if(timeLeft === 0){
         setTimeLeft(null)
      }
  
      // exit early when we reach 0
      if (!timeLeft) return;
  
      // save intervalId to clear the interval when the
      // component re-renders
      const intervalId = setInterval(() => {
  
        setTimeLeft(timeLeft - 1);
      }, 1000);
  
      // clear interval on re-render to avoid memory leaks
      return () => clearInterval(intervalId);
      // add timeLeft as a dependency to re-rerun the effect
      // when we update it
    }, [timeLeft]);




    const formik = useFormik({
        initialValues: {
            otp:''
        },
        validationSchema: Yup.object({
            otp: Yup.string().required('OTP is Required'),
        }),
        onSubmit: values => {
          if(!loading) { 
          setLoading(true)
          var formdata = new FormData();
          formdata.append("code", values.otp);
    
          API.post(APP_URLS.VERIFY_VERIFICATION_CODE, formdata)
          .then(res => { 
            setLoading(false)
              formik.resetForm()
              if (res.data.success === true) {
                props.setUpdated(true)
                toast.success('Phone number has been verified successfully.', {
                  position: toast.POSITION.TOP_RIGHT
                })
              } else {
                toast.error(res.data.message, {
                  position: toast.POSITION.TOP_RIGHT
                })        
              }
            })
            .catch((error) => {
              setLoading(false)
              const resp = error.response;
              if (resp) {
                
                  let error_message = '';
                  if (resp.data.errors !== undefined) {
                      {Object.keys(resp.data.errors).map((error, index) => (
                          error_message = resp.data.errors[error][0]
                      ))}
                  } else if (resp.data.data.error !== undefined) {
                      error_message =  resp.data.data.error;
                  } else {
                      error_message =  resp.data.error
                  }
      
                  toast.error(error_message, {
                      position: toast.POSITION.TOP_RIGHT
                  })
              }
            })      
        }
    }
      });

    const sendOTP =() =>{
        API.get(APP_URLS.SEND_VERIFICATION_CODE)
        .then(res => { 
            if (res.data.success === true) {
              setTimeLeft(30)
              setOtpSent(true)
              toast.success('OTP has been sent successfully.', {
                position: toast.POSITION.TOP_RIGHT
              })
            } else {
              toast.error(res.data.message, {
                position: toast.POSITION.TOP_RIGHT
              })        
            }
          })
          .catch((error) => {
            const resp = error.response;
            if (resp) {
              
                let error_message = '';
                if (resp.data.errors !== undefined) {
                    {Object.keys(resp.data.errors).map((error, index) => (
                        error_message = resp.data.errors[error][0]
                    ))}
                } else if (resp.data.data.error !== undefined) {
                    error_message =  resp.data.data.error;
                } else {
                    error_message =  resp.data.error
                }
    
                toast.error(error_message, {
                    position: toast.POSITION.TOP_RIGHT
                })
            }
          })   
    }
  return (
    <>
    <ToastContainer />
    {props.userDetails&&(
    <div className={(props.mode==='light'?'bg-orange':'bg-elemblue2')+' w-full w-full px-10 py-10 mt-5 rounded-lg mb-10 md:px-3'}>
        <div className="mb-2">
          <p className={(props.mode==='light'?'text-darkblue':'text-white')+' text-lg mb-10 sm:mb-5'}>Phone Verification </p>
          <div className="flex">
            <img className="w-wp60 xs:hidden" src={phoneIcon} />
            <div className="self-center px-5 xs:px-0">
              
              <p
                className={
                  (props.mode === "light" ? 'text-darkblue' : 'text-white') + " text-lg mb-1 sm:text-sm"
                }
              >
                {props.userDetails.user.phone}
              </p>
              <p
                className={
                  (props.mode === "light" ? 'text-lightblue' : 'text-white opacity-50') +
                  " text-sm mb-1 sm:text-xs"
                }
              >
                Keep your primary phone number up-to-date
              </p>
            </div>
          </div>
        </div>
        {props.userDetails.user.phone_verified==false?
        <form onSubmit={formik.handleSubmit}>
        <div className="w-full flex flex-row gap-5 md:block gap-5">
          <div className="w-w50 inline-block mb-2 pl-24 md:w-w100 md:pl-0">
            <div>
              <input
                type="text"
                placeholder="Enter OTP"
                name="otp"
                value={formik.values.otp}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                disabled={!otpSent}
                className={
                  (props.mode === "light"
                    ? "bg-white"
                    : "bg-inputblue1 text-white") +
                  " w-full outline-none h-10 text-md p-1 border-btnblue border rounded-3xl h-h45 px-4 md:h-h40 text-sm"
                }
              />
              {formik.touched.otp && formik.errors.otp ? (
                      <div className='text-left text-red text-xs pt-2'>{formik.errors.otp}</div>):null}
            </div>
          </div>
          <div className="w-w50 md:w-w100 flex self-center mb-2 lg:ml-2 sm:ml-0">
            { timeLeft === null ?
            
              <button className="text-sm text-lightblue underline" id="OTP"onClick={(e)=>{
                e.preventDefault();
                sendOTP();
                }}>
                Send SMS with code
              </button>
            :
              <div className={(props.mode==='light'?'text-lightblue':'text-white')+' text-sm'} id="OTPsent">
                Please wait for 00:{timeLeft >= 10 ? timeLeft : '0'+timeLeft} Seconds
              </div>
            }
          </div>
        </div>
        <div className="w-full flex flex-row mt-2 pl-24 md:block md:pl-0">
          <button type="submit" className={(props.mode==='light'?'bg-darkorange border-darkorange':'bg-bgblue border-btnblue ')+' border rounded-3xl w-auto text-white px-8 h-h45 b-0 md:h-h40 text-sm sm:w-full mt-3 disabled:opacity-50'} disabled={!otpSent}>
            Verify
          </button>
        </div>
        </form>
        :              
        <div className="flex">
            <img className="mr-2" src={check} />
            <span className={(props.mode==='light'?'text-darkblue':'text-white')+' text-md self-center'} >
            {" "}
            Phone Verified
            </span>
        </div>}
      </div>
    )}

    </>
  )
}

export default PhoneVerification