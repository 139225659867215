import React,{useState} from 'react'
import {
    faArrowUpFromBracket,
    faXmarkCircle,
  } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { APP_URLS } from "../../../../api/url";
import API from "../../../../api/axios";
import { toast, ToastContainer } from 'react-toastify';
import { faYelp } from '@fortawesome/free-brands-svg-icons';

const Popup = (props) => {

  const FILE_SIZE = 3072 * 1024;
  const SUPPORTED_FORMATS = [
    "image/jpg",
    "image/jpeg",
    "image/png"
  ];

    const [loading, setLoading] = useState(false);
    const [type,setType]=useState(props.idType)
    const [display,setDisplay] = useState(1);

    const uploadNID = ()=>{
        var formdata = new FormData();
        var imagedata = document.querySelectorAll('input[type="file"]');
        let frnt = imagedata[0].files[0]
        let back = imagedata[1].files[0]
        formdata.append("front_image", frnt);
        formdata.append("back_image", back);
            API.post(APP_URLS.SUBMIT_NID_PHOTO, formdata)
            .then(res => { 
              if (res.data.success === true) {
                  props.setOpen('')
                  props.setIdUpdated(true)
                  setLoading(false)
                  toast.success('National ID Card has been uploaded successfully.', {
                    position: toast.POSITION.TOP_RIGHT
                  })
                } else {
                  toast.error(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                  })        
                }
              })
              .catch((error) => {
                const resp = error.response;
                if (resp) {
                  
                    let error_message = '';
                    if (resp.data.errors !== undefined) {
                        {Object.keys(resp.data.errors).map((error, index) => (
                            error_message = resp.data.errors[error][0]
                        ))}
                    } else if (resp.data.data.error !== undefined) {
                        error_message =  resp.data.data.error;
                    } else {
                        error_message =  resp.data.error
                    }
        
                    toast.error(error_message, {
                        position: toast.POSITION.TOP_RIGHT
                    })
                }
              }) 
    }

    const uploadPassport = ()=>{
        var formdata = new FormData();
        var imagedata = document.querySelectorAll('input[type="file"]');
        let frnt = imagedata[0].files[0]
        let back = imagedata[1].files[0]
        formdata.append("front_image", frnt);
        formdata.append("back_image", back);
            API.post(APP_URLS.SUBMIT_PASSPORT_PHOTO, formdata)
            .then(res => { 
              if (res.data.success === true) {
                  props.setOpen('')
                  props.setIdUpdated(true)
                  setLoading(false)
                  toast.success('Passport has been uploaded successfully.', {
                    position: toast.POSITION.TOP_RIGHT
                  })
                } else {
                  toast.error(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                  })        
                }
              })
              .catch((error) => {
                const resp = error.response;
                if (resp) {
                  
                    let error_message = '';
                    if (resp.data.errors !== undefined) {
                        {Object.keys(resp.data.errors).map((error, index) => (
                            error_message = resp.data.errors[error][0]
                        ))}
                    } else if (resp.data.data.error !== undefined) {
                        error_message =  resp.data.data.error;
                    } else {
                        error_message =  resp.data.error
                    }
        
                    toast.error(error_message, {
                        position: toast.POSITION.TOP_RIGHT
                    })
                }
              }) 
    }

    const uploadDL = () =>{
        var formdata = new FormData();
        var imagedata = document.querySelectorAll('input[type="file"]');
        let frnt = imagedata[0].files[0]
        let back = imagedata[1].files[0]
        formdata.append("front_image", frnt);
        formdata.append("back_image", back);
            API.post(APP_URLS.SUBMIT_DL_PHOTO, formdata)
            .then(res => { 
              if (res.data.success === true) {
                  props.setOpen('')
                  props.setIdUpdated(true)
                  setLoading(false)
                  toast.success('Driving License has been uploaded successfully.', {
                    position: toast.POSITION.TOP_RIGHT
                  })
                } else {
                  toast.error(res.data.message, {
                    position: toast.POSITION.TOP_RIGHT
                  })        
                }
              })
              .catch((error) => {
                const resp = error.response;
                if (resp) {
                  
                    let error_message = '';
                    if (resp.data.errors !== undefined) {
                        {Object.keys(resp.data.errors).map((error, index) => (
                            error_message = resp.data.errors[error][0]
                        ))}
                    } else if (resp.data.data.error !== undefined) {
                        error_message =  resp.data.data.error;
                    } else {
                        error_message =  resp.data.error
                    }
        
                    toast.error(error_message, {
                        position: toast.POSITION.TOP_RIGHT
                    })
                }
              }) 
    }

    
    const formik = useFormik({
        initialValues: {
            front_doc:'',
            back_doc:''
        },
        validationSchema: Yup.object({
            front_doc: Yup.mixed().required('Front page of document is required')
              .test(
                'fileFormat',
                'Unsupported format type for front document, Only images are accepted',
                (value) => value === null || (value && SUPPORTED_FORMATS.includes(document.querySelectorAll('input[type="file"]')[0].files[0].type))
              )
              .test('fileSize', 'File too large, Max Size: 1 MB', (value) => !value || (value && document.querySelectorAll('input[type="file"]')[0].files[0].size <= FILE_SIZE)),
            back_doc: Yup.mixed().required('Back page of document is required')
              .test(
                'fileFormat',
                'Unsupported format type for front document, Only images are accepted',
                (value) => value === null || (value && SUPPORTED_FORMATS.includes(document.querySelectorAll('input[type="file"]')[1].files[0].type))
              )
              .test('fileSize', 'File too large, Max Size: 1 MB', (value) => !value || (value && document.querySelectorAll('input[type="file"]')[1].files[0].size <= FILE_SIZE))
        }),
        onSubmit: values => {
          if(!loading) { 
          setLoading(true)
          if(type=='NID'){
            uploadNID()
          }
          else if(type=='Passport'){
            uploadPassport()
          }
          else if(type=='Dl'){
            uploadDL()
          }
        
        }
    }
      });

    return(

     <div className={(props.mode==='light'?'bg-darkblue':'bg-darkblue')+" flex flex-center bg-opacity-80 w-full h-screen fixed z-20 grid place-items-center h-screen top-0 left-0"}>
      <div className="relative max-h-hv90 max-w-md z-30 right-0 md:left-0 md:w-w50 sm:w-w90">
        <div
            className={
              (props.mode === "light"
                ? "bg-orange text-darkblue border-darktblue"
                : "bg-bgblue border-lightblue") +
              " relative block mx-auto border border-8 rounded-2xl text-center px-8 py-10 w-full sm:p-2"
            }
          >
          <div className='max-h-hv70 h-auto overflow-y-auto scrollbar-hide'>
            <button
              className="absolute -right-5 -top-5"
              onClick={() => props.setOpen('')}
            >
              {" "}
              <FontAwesomeIcon
                className="text-elemblue text-3xl bg-white rounded-full"
                icon={faXmarkCircle}
              />{" "}
            </button>
            <div className={(props.mode==='light'?'border-darkblue':'border-white')+' flex w-full items-center border-b-2 border-opacity-20 mb-7'} >
              <button className={(props.mode==='light'?display===1?'text-darkblue border-darkorange border-b-2':'text-darkblue':display===1?'text-lightblue border-lightblue border-b-2':'text-lightblue')+' block text-wrap text-md pb-3 text-left w-w50 mb-mb2 xs:pl-1 xs:text-xs'} onClick={()=>setDisplay(1)}>
                <span className={(props.mode==='light'?'text-darkblue border-darkblue':'text-white border-white')+' inline-block align-top h-8 w-8 border border-dashed rounded-full mr-2 text-xl text-center'} >
                  1
                </span>
                <span className="inline-block w-w70 text-sm text-left leading-tight sm:text-xs sm:w-w60">
                  Upload document front page
                </span>
              </button>
              <button className={(props.mode==='light'?display===2?'text-darkblue border-darkorange border-b-2':'text-darkblue':display===2?'text-lightblue border-lightblue border-b-2':'text-lightblue')+' block text-wrap text-md pb-3 text-left w-w50 mb-mb2 xs:pl-1 xs:text-xs'} onClick={()=>setDisplay(2)}>
                <span className={(props.mode==='light'?'text-darkblue border-darkblue':'text-white border-white')+' inline-block align-top h-8 w-8 border border-dashed rounded-full mr-2 text-xl text-center'} >
                  2
                </span>
                <span className="inline-block w-w70 text-sm text-left leading-tight sm:text-xs sm:w-w60">
                  Upload document back page
                </span>
              </button>
            </div>
            <form className={(props.mode==='light'?'border-darkorange':'border-lightblue')+' border-dashed border py-5 px-3 rounded-xl'} onSubmit={formik.handleSubmit}>
              <div className='relative'>
              <FontAwesomeIcon
                className={(props.mode==='light'?'text-darkorange':'text-lightblue')+' text-5xl'}
                icon={faArrowUpFromBracket}
              />
                <input className={'w-full top-12 -top-0 -left-0 mx-auto form-control cursor-pointer border rounded-md mt-4 p-3'+(props.mode=='light'?' border-darkorange text-darkblue':' border-lightblue text-lightblue') +(display===1?' block':' hidden')} type="file" 
                value={formik.values.front_doc}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                id="front_doc"
                name="front_doc"
                accept="image/*"/>
                <input className={'w-full top-12 -top-0 -left-0 mx-auto form-control cursor-pointer border rounded-md mt-4 p-3'+(props.mode=='light'?' border-darkorange text-darkblue':' border-lightblue text-lightblue') +(display===2?' block':' hidden')} type="file" 
                value={formik.values.back_doc}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur} 
                id="back_doc"
                name="back_doc"
                accept="image/*"/>
                </div>
                {formik.touched.front_doc && formik.errors.front_doc ? (<div className='text-auto text-red text-xs mt-2 pl-2'>{formik.errors.front_doc}</div>) : null}
                {formik.touched.back_doc && formik.errors.back_doc ? (<div className='text-auto text-red text-xs mt-2 pl-2'>{formik.errors.back_doc}</div>) : null}
              <p className={(props.mode==='light'?'text-darkblue':'text-white')+' text-sm font-light mt-2'}>
                Please upload a copy if your valid identification in PNG, JPEG
                or JPG format, no longer than 3mb in size
              </p>
              <button type="submit" className={(props.mode==='light'?'bg-darkorange border-darkorange':'bg-bgblue border-btnblue')+' text-white float-right border rounded-full b-0 text-md px-10 py-2 mx-auto float-none mt-5 '}>
                {loading?'Uploading':'Upload'}
              </button>
            </form>
          </div>
        </div>
      </div>
   </div>
    )
  }

  export default Popup