import React, { useState, useContext } from "react";
import { MainLayout } from '../../components/Layouts/MainLayout'
import {Theme} from "../../App"
import Mode from '../../components/Mode/Mode';


const ColdStoragePage = (props) => {

    const mode = useContext(Theme);

    return (
        <MainLayout>
            <Mode />
            <div className='min-h-screen w-w95 mt-5'>
                <h1 className={(mode==='light'?'text-darkblue':'text-white')+' text-2xl mt-10 md:mt-14 sm:mt-7'}>Coming Soon..</h1>
            </div>
        </MainLayout>
    )
}

export default ColdStoragePage