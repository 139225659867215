import React, { useState, useContext } from "react";
import { MainLayout } from '../../components/Layouts/MainLayout'
import {Theme} from "../../App"
import Mode from '../../components/Mode/Mode';
import WithdrawDefaultCoins from "./Components/WithdrawDefaultCoins";
import WithdrawCoinHistory from "./Components/WithdrawCoinHistory";


const WithdrawCoinsPage = (props) => {

    const mode = useContext(Theme);

    const [rerender, setRerender] = useState();

    const handleRerender = () => {
      setRerender(Math.random());
    }

  return (
    <MainLayout>
        <Mode />
        <div className='min-h-screen'>
        {/* <div className="flex flex-row justify-start mt-8 mb-5 w-full items-center sm:mt-0">
            <h1 className={(mode==='light'?"text-darkblue":"text-white")+" text-medium"}>Withdraw Coins</h1>
        </div>
        <WithdrawDefaultCoins mode={mode} rerender={() => handleRerender()}/>

        <hr className={(props.mode==='light'?'border-darkblue opacity-20':'border-white opacity-10')+' mt-16 mb-10 mb-1'}></hr> */}

        <WithdrawCoinHistory mode={mode} key={rerender} rerender={rerender}/>
        </div>
    </MainLayout>
  )
}

export default WithdrawCoinsPage