import React, { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeftLong, faArrowRightLong, faClone, faCropSimple } from "@fortawesome/free-solid-svg-icons";
import qrcode from '../../../components/Images/qr-code.jpeg';
import generate from '../../../components/Images/generate.png';
import { Theme } from "../../../App";
import API from "../../../api/axios";
import { APP_URLS } from "../../../api/url";
import QRCode from "react-qr-code";
import { toast } from "react-toastify";
import Pagination from "../../../components/Shared/Pagination";

const Deposit = (props) => {
    const mode = useContext(Theme);
    const [wallet,] = useState(props.wallet)
    const [loading, setLoading] = useState(false);
    const [loadingAddressList, setLoadingAddressList] = useState(false);
    const [defaultWallet, setDefaultWallet] = useState(false);
    const [walletAddress, setWalletAddress] = useState('');
    const [walletAddressList, setWalletAddressList] = useState(false);
   
    //Setting Pagination and Search
    const [page, setPage] = useState(1);
    const [totalPages, setTotalPages] = useState(0);
    const [entries,] = useState(10)

    useEffect(() => {
        window.scrollTo(0,0);

        if (wallet.coin_type === 'Default') {
            setDefaultWallet(true)
        } else {
            getAddress();
            fetchAddressList();
        }
    }, [walletAddress]);

    const fetchAddressList = async () => {
        setLoadingAddressList(true)
        await API.get(APP_URLS.ADDRESS_LIST + `?wallet_id=${wallet.id}&limit=${entries}&page=${page}`)
            .then((result) => {
                const resp = result.data.data.addresses.data;
                setTotalPages(result.data.data.addresses.last_page)
                setWalletAddressList(resp);
                setLoadingAddressList(false)
            }) 
            .catch((error) => {
                setLoadingAddressList(false)
            })
    }

    function handleClick() {
        props.backToWallet()
    }

    const getAddress = async () => {
        await API.get(APP_URLS.GET_WALLET_ADDRESS + `?wallet_id=${wallet.id}`)
            .then((result) => {
                if (result.data.data.address !== '' && result.data.data.address !== undefined && result.data.data.address !== null) {
                    setWalletAddress(result.data.data.address)
                    
                }
            })
            .catch((error) => {})
    }

    const cloneWalletAddress = () =>{
        navigator.clipboard.writeText(walletAddress)
        let textCopied = document.getElementById('HashCopied');
        textCopied.style.display = 'block';
        setTimeout(() => {
          const textCopied = document.getElementById('HashCopied');
          textCopied.style.display = 'none';
        }, 1000);
    }

    const generateNewAddress = async () => {
        setLoading(true);
        await API.post(APP_URLS.GENERATE_NEW_WALLET_ADDRESS,{'wallet_id': wallet.id, 'coin_type': wallet.coin_type})
            .then((result) => {
                setLoading(false);
                if (result.data.data.address !== '' && result.data.data.address !== undefined && result.data.data.address !== null) {
                    setWalletAddress(result.data.data.address)
                    toast.success('New code generated successfully.', {
                        position: toast.POSITION.TOP_RIGHT
                    }) 
                }
            })
            .catch((error) => {
                setLoading(false);
            })
    }

    const cloneHash = (index) =>{
        let copyText  = document.getElementById("copyHash"+index).innerText;
        navigator.clipboard.writeText(copyText)
        let textCopied = document.getElementById('HashCopied'+index);
        textCopied.style.display = 'block';
        setTimeout(() => {
          const textCopied = document.getElementById('HashCopied'+index);
          textCopied.style.display = 'none';
        }, 1000);
    }

    
    return (
        <>
            <div className='pb-5 mt-14 sm:mt-7'>
                <div className='flex flex-row w-full justify-between mb-3'>
                    <h1 className={(mode === 'light' ? 'text-darkblue ' : 'justify-start text-white text-md ') + ""}>Deposit</h1>
                    <button className={(mode === 'light' ? 'text-darkblue ' : 'text-white  text-md') + ""} onClick={() => handleClick()} ><FontAwesomeIcon className='mr-2' icon={faArrowLeftLong} /> Back to Wallet </button>
                </div>
                {/* {defaultWallet === true ? 
                ''
                :
                    <> */}
                        <div className={(mode === 'light' ? 'bg-orange' : 'bg-elemblue3') + ' w-full flex flex-row justify-between rounded-lg p-5 md:block'}>
                            <div className='w-w70 flex md:lw-ful xs:block md:w-full'>
                                <img className='w-wp60 h-h60 mr-2 xs:mx-auto' src={generate} />
                                <div className='w-full flex flex-row gap-5 lg:block gap-3'>
                                    <div className='w-w100 inline-block '>
                                        <div className='flex flex-row items-center my-2 mt-5'>
                                            <p className={(mode === 'light' ? 'text-darkblue' : 'text-white') + ' text-small ml-2 xl:text-xs w-full'}>Generate a new address</p>
                                        </div>
                                        <div>
                                            <input
                                                type="text"
                                                name="coin-amount"
                                                disabled
                                                value={walletAddress}
                                                className={(mode === 'light' ? 'bg-white' : 'bg-inputblue1 text-white') + " w-full outline-none h-10 text-xsmall p-1 border-btnblue border rounded-3xl h-h45 px-4"} />
                                            <button type="button" 
                                                    onClick={() => generateNewAddress()}
                                                    className={(mode==='light'?'bg-darkorange':'bg-gradient-to-r from-lightblue to-blue ')+' outline-none text-white text-xsmall rounded-3xl h-h40 px-8 mt-6 float-right xs:w-full'}>{ loading ? 'Generating...' : 'Generate a new address' }</button>
                                        </div>
                                    </div> 
                                </div>
                                <div className='w-full pt-14 pl-10 xs:pt-5'>
                                    <span className='text-sm text-blue cursor-pointer underline' onClick={() => cloneWalletAddress()} id={"copyHash"}><FontAwesomeIcon icon={faClone} /> Click here to copy code</span>
                                    <p id={"HashCopied"} className={"ml-1 text-green text-xsmall hidden"}>Text Copied To Clipboard</p>

                                </div>
                            </div>
                            <div className='w-w30 text-center px-5 md:w-full border-opacity-0 mt-10 sm:px-0'>
                                <span className=' relative rounded-full bg-green py-2 px-4 text-white text-sm'>QR code scan</span>
                                {/* <div className="border border-2 border-white"> */}
                                {walletAddress !== '' && <QRCode className="mx-auto w-auto h-auto border border-8 border-white xxxs:-ml-4" value={walletAddress} /> }
                                {/* </div> */}
                            </div>
                        </div>
                        <div>
                            <h2 className='text-white text-medium mt-10 mb-5'>Address List</h2>
                            <div className='w-full flex flex-row justify-between rounded-lg gap-8 sm:gap-2'>
                                <div className={(mode === 'light' ? 'bg-orange text-darkblue' : 'bg-tableblue text-white') + ' p-6 rounded-l-xl text-xl w-w50 self-center sm:p-3'}>Address</div>
                                <div className={(mode === 'light' ? 'bg-orange text-darkblue' : 'bg-tableblue text-white ') + ' p-6 rounded-r-xl text-xl text-center w-w50 sm:p-3'}>Created At</div>
                            </div>
                            {walletAddressList.length > 0 ?
                                walletAddressList.map((item, index) => {
                                    return <div className='w-full flex flex-row justify-between rounded-lg mt-5 gap-8 sm:gap-2' key={index}>
                                        <div className={(mode === 'light' ? 'bg-orange text-darkblue' : 'bg-tableblue text-white ') + ' justify-left px-6 py-8 rounded-l-xl text-sm text-opacity-50 w-w50 item-center self-center sm:py-5 sm:px-2'}>
                                            <div className="flex justify-start w-full break-all">
                                                <span id={"copyHash"+index} className={(mode === 'light' ? 'text-darkblue' : 'text-white') + ' overflow-hidden whitespace-nowrap overflow-ellipsis'} >{item.address}</span>
                                                <span className="cursor-pointer"  onClick={() => cloneHash(index)} id={"copyHash" + index}> <FontAwesomeIcon icon={faClone} className={(mode === 'light' ? 'text-darkblue' : 'text-white') + " text-medium ml-1"} /> </span>
                                            </div>
                                            <p id={"HashCopied"+index} className={"ml-1 text-green text-xsmall self-center hidden"}>Text Copied To Clipboard</p>
                                        </div>
                                        <div className={(mode === 'light' ? 'bg-orange text-darkblue' : ' flex bg-tableblue text-white ') + ' px-6 py-4 rounded-r-xl text-sm text-opacity-50 w-w50 sm:py-1 sm:px-2'}>
                                            <div className='block mx-auto text-center py-2 self-center'>
                                                <span className={(mode === 'light' ? 'text-darkblue' : 'text-white') + ' block'}>{item.created_at}</span>
                                            </div>
                                        </div>
                                    </div>
                                })
                                : 
                                <>
                                    <div className={(mode === 'light' ? 'text-darkblue' : 'text-white') + ' w-full block justify-between rounded-lg mt-5 text-center gap-8 sm:gap-2'}>
                                        {loadingAddressList === true ? 'Loading...' : 'No Addressesss Found' }
                                    </div>
                                </>
                            }
                            
                            {(walletAddressList.length > 0) && 
                                <>
                                    <Pagination 
                                        totalPages={totalPages}
                                        onPageClick={(page) => setPage(page + 1)}
                                        key={entries}
                                    />
                                </>
                            }
                        </div>
                    {/* </>
                } */}
            </div>
        </>
    );
};

export default Deposit;