import React,{useState,useEffect} from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faClone, faArrowLeftLong, faArrowRightLong, faMinus, faPlus, faCirclePlus } from "@fortawesome/free-solid-svg-icons";
import { APP_URLS } from "../../../api/url";
import API from "../../../api/axios";
import { toast, ToastContainer } from "react-toastify";
import PageLength from '../../../components/Shared/PageLength';
import SearchBar from '../../../components/Shared/SearchBar';
import Pagination from '../../../components/Shared/Pagination';
import DownloadPDF from './Popup/DownloadPDF';


const WithdrawCoinHistory = (props) => {

  //Seting data for coin history API
  const[entries,setEntries] =useState(10)
  const[search,setSearch] = useState('');
  const[data,setData] = useState([]);
  const[loading, setLoading] = useState(false);
  const[rerender,] = useState(props.rerender);
  
  //Setting Pagination
  const[page,setPage] = useState(1);
  const[totalPages,setTotalPages] = useState(0);

  //USERID
  const [id,setId] = useState(JSON.parse(localStorage.getItem('user_info')).id)

  //Popup
  const [popup,setPopup] = useState(false)
  const [totalEntries,setTotalEntries] = useState(0)
  
  
  const withdrawCoinHistory =()=>{
    setLoading(true);
    API.get(APP_URLS.TRANSACTION_HISTORY+`?type=all&search=${search}&length=${entries}&page=${page}`)
    .then(res => { 
        if (res.data.success === true) {
          setData(res.data.data.data)
          setTotalPages(res.data.data.last_page)
          setTotalEntries(res.data.data.total)
          setLoading(false);
        }
      })
      .catch((error) => {
        setLoading(false)
        const resp = error.response;
        if (resp) {
          
            let error_message = '';
            if (resp.data.errors !== undefined) {
                {Object.keys(resp.data.errors).map((error, index) => (
                    error_message = resp.data.errors[error][0]
                ))}
            } else if (resp.data.data.error !== undefined) {
                error_message =  resp.data.data.error;
            } else {
                error_message =  resp.data.error
            }

            toast.error(error_message, {
                position: toast.POSITION.TOP_RIGHT
            })
        }
      })  
  }


  const cloneAddress = (index) =>{
    let copyText  = document.getElementById("copyText"+index).previousSibling.innerText;
    navigator.clipboard.writeText(copyText)
    let textCopied = document.getElementById('textCopied'+index);
    textCopied.style.display = 'block';
    setTimeout(() => {
      const textCopied = document.getElementById('textCopied'+index);
      textCopied.style.display = 'none';
    }, 1000);
  }

  const cloneHash = (index) =>{
    let copyText  = document.getElementById("Hash"+index).innerText;
    navigator.clipboard.writeText(copyText)
    let textCopied = document.getElementById('HashCopied'+index);
    textCopied.style.display = 'block';
    setTimeout(() => {
      const textCopied = document.getElementById('HashCopied'+index);
      textCopied.style.display = 'none';
    }, 1000);
  }

  useEffect(() => {
    withdrawCoinHistory()
  }, [search,entries,page,rerender])

  

  const handleLengthChange = (length) => {
    setEntries(length)
    setPage(0)
  }

  const handleSearchChange = (search) => {
    setSearch(search)
    setPage(0)
  }

  return (
    <>
      {popup?<DownloadPDF setPopup={setPopup} mode={props.mode} limit={totalEntries}/>:''}
      <div className="flex flex-row w-w100 justify-between mt-14 sm:mt-7">
        <div className="flex flex-row items-center">
            <h2 className={(props.mode==='light'?'text-darkblue':'text-white')+' block text-left w-w100 text-medium mb-2 sm:mt-0'}>
            Transaction History
            </h2>
        </div>
        <div className="w-auto flex flex-row justify-between pl-2 sm:mt-0">
            <button
            type="button" 
            onClick={()=>setPopup(true)}
            className={(props.mode === "light"? "rounded-3xl w-auto px-3 bg-darkblue text-white outline-none": "bg-gradient-to-r from-purple to-pink text-white rounded-3xl w-auto px-3 outline-none")+"  text-small xxs:text-xsmall disabled:opacity-50"}>
            Export History
            </button>
        </div> 
      </div>

      <div className='mt-5'>
        <div className="flex flex-row w-w100 justify-between h-h40">
          <PageLength onPageLengthChange={(length) => handleLengthChange(length)} />
          <div className="sm:w-w40">
              <SearchBar onSearchClick={(search) => handleSearchChange(search)} />
          </div>
        </div>

        <div className="w-full table-auto overflow-x-auto">
        {/* {dataLoaded&&( */}
          <table className="w-full mt-2 table-auto relative md:w-maxWidth">
            <thead>
            <tr className={(props.mode==='light'?'bg-white':'bg-tableblue text-white')+" flex flex-row justify-around mt-4 py-8 rounded-xl shadow-lg"}>
              <th className="flex justify-start w-w25 px-10">Address</th>
              <th className="flex justify-start w-w20">Coin Amount</th>
              <th className="flex justify-start w-w20">Transaction Hash</th>
              <th className="flex justify-start w-w20">Created At</th>
              <th className="flex justify-center w-w15">Status</th>
            </tr>
            </thead>
            <tbody>
            {data.length>0?
              data.map((item,index)=>{
                return <tr className={(props.mode==='light'?'bg-white text-dark blue':'bg-tableblue text-white')+" flex flex-row justify-around items-center my-5 py-4 rounded-xl shadow-lg"} key={index}>
                <td className={(props.mode==='light'?'text-darkblue':'text-white')+' flex flex-col justify-start w-w25 px-10 break-all'}>
                      <div className="flex justify-start w-full break-all">
                      {item.address?
                      <span className="overflow-hidden whitespace-nowrap overflow-ellipsis w-52 ">{item.address}</span>:
                      <span className="overflow-hidden whitespace-nowrap overflow-ellipsis w-52 ">{item.email}</span>}
                      {item.address==="iHodl Bonus"?'':<button className="cursor-pointer" onClick={()=>cloneAddress(index)} id={"copyText"+index}> <FontAwesomeIcon icon={faClone} className={(props.mode==='light'?'text-darkblue':'text-white')+" text-medium"} /> </button>}
                      </div>
                      <p id={"textCopied"+index} className={"ml-1 text-green text-xsmall hidden"}>Text Copied To Clipboard</p>

                </td>
                <td className={(props.mode==='light'?'text-darkblue':'text-white')+' flex justify-start w-w20'} >
                    {(item.transaction_type==='withdraw'||id===item.sender_user_id)?
                      <FontAwesomeIcon icon={faMinus} className={"text-red text-xsmall self-center mr-1"} />:
                      <FontAwesomeIcon icon={faPlus} className={"text-green text-xsmall self-center mr-1"} />}
                  <span>{parseFloat(item.amount).toFixed(2)} {item.coin_type}</span>
                </td>
                {item.transaction_type=='deposite'?
                <td className={(props.mode==='light'?'text-darkblue':'text-white')+' flex flex-col justify-left w-w20'}>
                      <div className="w-4/5 flex flex-row break-all">
                      {item.transaction_id?
                      <span className="overflow-hidden whitespace-nowrap overflow-ellipsis w-52" id={"Hash"+index}>{item.transaction_id}</span>:
                      <span className="overflow-hidden whitespace-nowrap overflow-ellipsis w-52" id={"Hash"+index}>NA</span>}
                      <button className="cursor-pointer ml-3" onClick={()=>cloneHash(index)} id={"copyHash"+index}> 
                        <FontAwesomeIcon icon={faClone} className={(props.mode==='light'?'text-darkblue':'text-white')+" text-medium"} /> 
                      </button>
                      </div>
                      <p id={"HashCopied"+index} className={"ml-1 text-green text-xsmall hidden"}>Text Copied To Clipboard</p>
                    </td>:
                    <td className={(props.mode==='light'?'text-darkblue':'text-white')+' flex flex-col justify-left w-w20'}>
                      <div className="w-4/5 flex flex-row justify-between break-all">
                      {item.transaction_hash?
                      <>
                      <span className="overflow-hidden whitespace-nowrap overflow-ellipsis w-52" id={"Hash"+index}>{item.transaction_hash}</span>
                      <button className="cursor-pointer" onClick={()=>cloneHash(index)} id={"copyHash"+index}> 
                        <FontAwesomeIcon icon={faClone} className={(props.mode==='light'?'text-darkblue':'text-white')+" text-medium"} /> 
                      </button>
                      </>:
                      <span className="overflow-hidden whitespace-nowrap overflow-ellipsis w-52" id={"Hash"+index}>NA</span>}
                      </div>
                      <p id={"HashCopied"+index} className={"ml-1 text-green text-xsmall hidden"}>Text Copied To Clipboard</p>
                    </td>}
                <td className={(props.mode==='light'?'text-darkblue':'text-white')+' block justify-center w-w20'} >
                  <span className="block">{item.created_at}</span>
                  {/* <span className="text-xs opacity-50 block pr-12">05:34</span> */}
                </td>
                <td className="flex justify-center w-w15 text-white text-sm">
                    {item.status===1||item.status==='1'?
                        <div className="px-3 h-8 self-center flex justify-center items-center bg-greenlight rounded-lg">Accepted</div>:
                        item.status===0||item.status==='0'?
                        <div className="px-3 h-8 self-center flex justify-center items-center bg-darkgrey2fade rounded-lg">Pending</div>:
                        <div className="px-3 h-8 self-center flex justify-center items-center bg-redLight rounded-lg">Rejected</div>
                      }
                    </td>
              </tr>
              }):
              <tr className={(props.mode === 'light' ? 'bg-white' : 'bg-tableblue text-fontblue') + " flex flex-row justify-around items-center my-5 py-4 rounded-xl shadow-lg"}>
                  <td colSpan="5"> { loading ? 'Loading...' : 'No Records Found' }</td>
              </tr>
              }
            </tbody>


          </table>        
            {/* )} */}
        </div>
        {(data.length > 0) && 
              <>
                  <Pagination 
                    totalPages={totalPages}
                    onPageClick={(page) => setPage(page + 1)}
                    key={entries}
                  />
              </>
          }
      </div>
    </>
  )
}

export default WithdrawCoinHistory