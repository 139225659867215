import React, { useState } from 'react'
import {
    faCheck, faEye, faEyeSlash,
  } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as Yup from 'yup';
import { useFormik } from 'formik';
import { APP_URLS } from "../../../../api/url";
import API from "../../../../api/axios";
import { toast, ToastContainer } from 'react-toastify';

const ChangePasswordForm = (props) => {
  
  const passwordRegExp = /^((?=.*\d)(?=.*[A-Z])(?=.*[a-z]).{8,})$/;

  const [showPassword,setShowPassword] = useState(false)
  const [showConfirmPassword,setShowConfirmPassword] = useState(false)
  const [showCurrentPassword,setShowCurrentPassword] = useState(false)

  const handlePassword = () =>{
    var temp = document.getElementById("password");
    if(showPassword){
      setShowPassword(false)
      temp.type = "password"
    }
    else{
      setShowPassword(true)
      temp.type = "text"
    }
  }

  const handleConfirmPassword = () =>{
    var temp = document.getElementById("confirmPassword");
    if(showConfirmPassword){
      setShowConfirmPassword(false)
      temp.type = "password"
    }
    else{
      setShowConfirmPassword(true)
      temp.type = "text"
    }
  }

  const handleCurrentPassword = () =>{
    var temp = document.getElementById("currentPassword");
    if(showCurrentPassword){
      setShowCurrentPassword(false)
      temp.type = "password"
    }
    else{
      setShowCurrentPassword(true)
      temp.type = "text"
    }
  }

  const formik = useFormik({
    initialValues: {
      current_password:'',
      new_password:'',
      password_confirmation:'',
    },
    validationSchema: Yup.object({
      new_password: Yup.string().required('Password is Required').matches(passwordRegExp, 'Password must be consist of one uppercase, one lowercase, one number and minimum length at least 8 characters.'),
      password_confirmation: Yup.string().oneOf([Yup.ref('new_password'), null], 'Passwords must match'),
      current_password: Yup.mixed().required('Current Password is Required'),
    }),
    onSubmit: values => {
      formik.resetForm()
      var formdata = new FormData();
      formdata.append("password", values.new_password);
      formdata.append("current_password", values.current_password);
      formdata.append("password_confirmation", values.password_confirmation);
      API.post(APP_URLS.CHANGE_PASSWORD, formdata)
      .then(res => { 
          if (res.data.success === true) {
            toast.success('Password has been Updated successfully.', {
              position: toast.POSITION.TOP_RIGHT
            })
          } else {
            toast.error(res.data.message, {
              position: toast.POSITION.TOP_RIGHT
            })        
          }
        })
        .catch((error) => {
          const resp = error.response;
          if (resp) {
            
              let error_message = '';
              if (resp.data.errors !== undefined) {
                  {Object.keys(resp.data.errors).map((error, index) => (
                      error_message = resp.data.errors[error][0]
                  ))}
              } else if (resp.data.data.error !== undefined) {
                  error_message =  resp.data.data.error;
              } else {
                  error_message =  resp.data.error
              }
  
              toast.error(error_message, {
                  position: toast.POSITION.TOP_RIGHT
              })
          }
        })      
    },
  });


  return (
    <>
        <form className={(props.mode==='light'?'bg-orange':' bg-elemblue3 ')+' w-full py-7 px-5 rounded-lg md:w-full sm:w-full'} onSubmit={formik.handleSubmit}>
        <ToastContainer/>
              <div className="flex justify-between">
                <h2 className={(props.mode==='light'?'text-darkblue':'text-white')+' text-medium mb-1 sm:text-sm'}>
                  Change Password
                </h2>
                <button type="submit" className="float-right text-lightblue text-md font-light sm:text-sm">
                  {" "}
                  <FontAwesomeIcon className="mr-2" icon={faCheck} />
                  Save Password
                </button>
              </div>
              <div>
                <div className="flex flex-row w-w80 lg:w-w100 inline-block gap-5 pl-10 mt-5 md:pl-0 sm:block">
                  <div className="w-w33 sm:w-full md:mb-5 lg:mr-2 sm:mr-0">
                    <p className={(props.mode==='light'?'text-darkblue':'text-white')+' text-small ml-2 mb-1 xl:text-xs'}>
                      Current Password
                    </p>
                    <div className={
                        (props.mode === "light"
                          ? "bg-white"
                          : "bg-inputblue1 text-white") +
                        " flex w-full outline-none h-10 text-sm p-1 border-btnblue border rounded-3xl h-h45 px-4 shadow-lg"
                      }>
                    <input
                      className={(props.mode === "light"
                      ? "bg-white"
                      : "bg-inputblue1 text-white") +' w-[90%] h-full focus:outline-0'}
                      id="currentPassword"
                      type="password"
                      name="current_password"
                      placeholder="**********"
                      value={formik.values.current_password}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      autoComplete="off"
                    />
                      <FontAwesomeIcon icon={showCurrentPassword?faEye:faEyeSlash} className={"text-small self-center cursor-pointer "} onClick={handleCurrentPassword}/>
                    </div>
                    {formik.touched.current_password && formik.errors.current_password ? (<div className='text-left text-red text-xs mt-2 pl-2'>{formik.errors.current_password}</div>) : null}
                  </div>
                  <div className="w-w33 sm:w-full md:mb-5 lg:mr-2 sm:mr-0">
                    <p className={(props.mode==='light'?'text-darkblue':'text-white')+' text-small ml-2 mb-1 xl:text-xs'}>
                      New Password
                    </p>
                    <div className={
                        (props.mode === "light"
                          ? "bg-white"
                          : "bg-inputblue1 text-white") +
                        " flex w-full outline-none h-10 text-sm p-1 border-btnblue border rounded-3xl h-h45 px-4 shadow-lg"
                      }>
                    <input
                      className={(props.mode === "light"
                      ? "bg-white"
                      : "bg-inputblue1 text-white") +' w-[90%] h-full focus:outline-0'}
                      id="password"
                      type="password"
                      name="new_password"
                      placeholder="**********"
                      value={formik.values.new_password}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      autoComplete="off"

                    />
                      <FontAwesomeIcon icon={showPassword?faEye:faEyeSlash} className={"text-small self-center cursor-pointer"} onClick={handlePassword}/>
                    </div>
                    {formik.touched.new_password && formik.errors.new_password ? (<div className='text-left text-red text-xs mt-2 pl-2'>{formik.errors.new_password}</div>) : null}

                  </div>
                  <div className="w-w33 sm:w-full md:mb-5">
                    <p className={(props.mode==='light'?'text-darkblue':'text-white')+' text-small ml-2 mb-1 xl:text-xs'}>
                      Confirm Password
                    </p>
                    <div className={
                        (props.mode === "light"
                          ? "bg-white"
                          : "bg-inputblue1 text-white") +
                        " flex w-full outline-none h-10 text-sm p-1 border-btnblue border rounded-3xl h-h45 px-4 shadow-lg"
                      }>
                    <input
                      className={(props.mode === "light"
                      ? "bg-white"
                      : "bg-inputblue1 text-white") +' w-[90%] h-full focus:outline-0'}
                      id="confirmPassword"
                      type="password"
                      name="password_confirmation"
                      placeholder="**********"
                      autoComplete="off"
                      value={formik.values.password_confirmation}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    <FontAwesomeIcon icon={showConfirmPassword?faEye:faEyeSlash} className={"text-small self-center cursor-pointer"} onClick={handleConfirmPassword}/>
                    </div>
                    {formik.touched.password_confirmation && formik.errors.password_confirmation ? (<div className='text-left text-red text-xs mt-2 pl-2'>{formik.errors.password_confirmation}</div>) : null}
                  </div>
                </div>
              </div>
            </form>
    </>
  )
}

export default ChangePasswordForm