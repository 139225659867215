import React, {useState, useEffect} from "react";
import {
  LineChart,
  Line,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer
} from "recharts";

const monthly_data = [
  {
    name: "Jan",
    Coin: 0,
  },
  {
    name: "Feb",
    Coin: 0,
  },
  {
    name: "Mar",
    Coin: 0,
  },
  {
    name: "Apr",
    Coin: 0,
  },
  {
    name: "May",
    Coin: 0,
  },
  {
    name: "Jun",
    Coin: 0,
  },  
  {
    name: "Jul",
    Coin: 0,
  },  
  {
    name: "Aug",
    Coin: 0,
  },  
  {
    name: "Sep",
    Coin: 0,
  },
  {
    name: "Oct",
    Coin: 0,
  },
  {
    name: "Nov",
    Coin: 0,
  },
  {
    name: "Dec",
    Coin: 0,
  }
];
const weekly_data = [
  {
    name: "Mon",
    Coin: 0,
  },
  {
    name: "Tue",
    Coin: 0,
  },
  {
    name: "Wed",
    Coin: 0,
  },
  {
    name: "Thu",
    Coin: 0,
  },
  {
    name: "Fri",
    Coin: 0,
  },
  {
    name: "Sat",
    Coin: 0,
  },  
  {
    name: "Sun",
    Coin: 0,
  }
];

const BuyCoinReport = (props) => {

  const [range,setRange] = useState('monthly')
  const [weeklyvalues,setWeeklyValues] = useState({})
  const [monthlyvalues,setMontlhyValues] = useState({})
  const [weeklyDataLoaded,setWeeklyDataLoaded] = useState(false)
  const [monthlyDataLoaded,setMonthlyDataLoaded] = useState(false)

  useEffect(() => {
    let authToken = localStorage.getItem("token");
    let access_type = localStorage.getItem("access_type");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", access_type + " " + authToken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
    };
    fetch(process.env.REACT_APP_API_URL+`user-dashboard-app?buycoin_type=weekly`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        let data = JSON.parse(result);
        setWeeklyValues(data);
        setWeeklyDataLoaded(true);
      })
      .catch((error) => console.log("error", error));
    fetch(process.env.REACT_APP_API_URL+`user-dashboard-app?buycoin_type=monthly`, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        let data = JSON.parse(result);
        setMontlhyValues(data);
        setMonthlyDataLoaded(true);
      })
      .catch((error) => console.log("error", error));
  }, []);

  if(range=='monthly'&&monthlyDataLoaded){
    
    let values_coin = monthlyvalues.data.monthly_buy_coin
    monthly_data.map((element,index) => {
        element.Coin=parseFloat(values_coin[index])
     });
  }
  if(range=='weekly'&&weeklyDataLoaded){
    let values_coin = weeklyvalues.data.weekly_buy_coin
    weekly_data.map((element,index) => {
        element.Coin= parseFloat(values_coin[index])
      });
  }

  return (
    <>
      <div className={(props.mode==='light'?'bg-white':'bg-elemblue2')+" shadow-lg flex flex-col justify-between items-center w-w485 mt-2 sm:w-w100 sm:mt-6"}>
        <div className="flex flex-row w-full justify-between">
          <div>
            <div className="flex flex-row items-center justify-between p-5">
            <h1 className={(props.mode==='light'?'':'text-white')+" text-medium"}>Buy Coin Report</h1>
            </div>
          </div>
          <div className="p-5 text-xsmall">
          <select          
          className={(props.mode==='light'?'bg-white':'bg-elemblue2 text-white')+" w-full outline-none text-xsmall p-1 rounded-lg  bg-no-repeat bg-right"} onChange={e => setRange(e.target.value)}>
              <option value="monthly">Monthly</option>
              <option value="weekly">Weekly</option>
            </select>
          </div>
        </div>
        <div className="w-11/12 -ml-14">
          
        {range=='monthly'&&monthlyDataLoaded?
        <ResponsiveContainer width="100%" height={250}>
          <LineChart data={monthly_data} margin={{ top: 2, right: 20, left: 20, bottom: 20 }}>
            <CartesianGrid strokeWidth={0.3} vertical={false}/>
            <XAxis strokeWidth={2.5} dataKey="name" />
            <YAxis strokeWidth={2.5}/>
            <Tooltip />
            <filter id="shadow" height="500%">
              <feDropShadow dx="5" dy="20" stdDeviation="8"/>
            </filter>
            <Line type="monotone" strokeWidth={4}
              filter="url(#shadow)"
              dataKey="Coin"
              stroke="#228efe"
              dot={{ stroke: '#228efe',fill:'#228efe',r: 3,filter:"none"}}
            />
          </LineChart>
          </ResponsiveContainer>:
          <ResponsiveContainer width="100%" height={250}>
          <LineChart data={weekly_data} margin={{ top: 2, right: 20, left: 20, bottom: 20 }}>
            <CartesianGrid strokeWidth={0.3} vertical={false}/>
            <XAxis strokeWidth={2.5} dataKey="name" />
            <YAxis strokeWidth={2.5}/>
            <Tooltip />
            <filter id="shadow" height="500%">
              <feDropShadow dx="5" dy="20" stdDeviation="8"/>
            </filter>
            <Line type="monotone" strokeWidth={4}
              filter="url(#shadow)"
              dataKey="Coin"
              stroke="#228efe"
              dot={{ stroke: '#228efe',fill:'#228efe',r: 3,filter:"none"}}
            />
          </LineChart>
          </ResponsiveContainer>
          }
          
        </div>
      </div>
    </>
  );
};

export default BuyCoinReport;
