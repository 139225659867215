import React,{useState,useContext} from "react";
import {NotificationCount} from "../../App"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faWifi,
  faBell,
  faBars,
  faEllipsisVertical,
} from "@fortawesome/free-solid-svg-icons";
import { NavLink } from "react-router-dom";


const Navbar = (props) => {

  const [status,setStatus] = useState(false)

  const notification = useContext(NotificationCount); 

  const [show, setShow] = useState(false)
  let values = props.data
  let balance = Object.keys(values).length ? parseFloat(values?.data?.balance).toFixed(2) : '';
  let balance_usd = values?.data?.balance_usd



  const handleToggle = () => setShow(prevShow => !prevShow);
  return (
    <>
    <div className={"flex flex-col truncate fixed items-center w-w81 l-l20 z-10 sm:w-full overflow-x-hidden pt-3 md:-left-0 md:w-w100 sm:px-5  " +(props.mode==='light'?'bg-grey':'bg-bgblue') }>
      <div className="flex flex-row h-16 items-center justify-center pb-3 w-w100 lg:w-wc100 sm:w-w100 md:h-10 md:pb-3 md:w-w100">
        <div className="flex flex-row items-center justify-between w-w95 sm:w-w100 block md:block">
          <div className="flex flex-row justify-between h-8 2xl:h-h40 sm:w-w100 md:w-w100">
            <div className="hidden flex items-center self-center md:block">
              <button onClick={()=>props.setMenu(true)}>
                <FontAwesomeIcon icon={faBars} className={(props.mode==='light'?"text-darkblue":"text-white")+ " text-xmedium "} />
              </button>
            </div>
            {status===false?
            <button type="button" className="bg-gradient-to-r from-lightblue to-blue outline-none text-white text-xsmall rounded-3xl xxs:w-auto px-6 2xl:h-h40 xsmall lg:text-xs lg:px-4 " >CONNECT NFTs</button>:
            <button type="button" className="bg-gradient-to-r from-lightblue to-blue outline-none text-white text-xsmall rounded-3xl xxs:w-auto px-6 2xl:h-h40 xsmall lg:text-xs lg:px-4 " >Disconnect</button>}
            <FontAwesomeIcon icon={faEllipsisVertical} onClick={handleToggle} className={(props.mode==='light'?'text-darkblue':'text-white')+' flex text-2xl self-center cursor-pointer hidden md:block'} />
          </div>
          
          {show === true ?<div className="fixed right-6 z-40 border border-1 border-lightblue rounded-lg min-h-8 bg-white shadow-lg float-right px-5 py-3 mt-2 xs:min-w-64">
            <div className="sm:block my-3 sm:mb-2">
              <div className="">
                <p className={(props.mode==='light'?'text-darkblue':'text-darkblue')+" text-md mb-2 ml-2"}>Available Balance</p>
                <div className="">
                  <button type="button" className={(props.mode==='light'?'bg-darkblue':'bg-darkpink')+" text-white block w-full h-7 mb-2 text-sm rounded-3xl outline-none px-3 cursor-default"} >{props.data?balance:''} HDL</button>
                  <button type="button" className={(props.mode==='light'?"bg-darkorange":'bg-elemblue')+" text-white block w-full h-7 text-sm rounded-3xl outline-none px-3 cursor-default"}>{balance_usd} USD</button>
                </div>
              </div>
              <hr className="border-opacity-20 border-darkblue mt-5"></hr>
              <div className="flex justify-between mt-4">
                <button type="button" className="outline-none large text-3xl mr-10">              
                  <FontAwesomeIcon className={props.mode==='light'?'text-darkblue opacity-80':'text-greyblue'} icon={faWifi} />
                </button>
                <NavLink to="/notifications" className="relative outline-none text-3xl lg-text-2xl">
                  {notification>0?
                  <span className="absolute z-10 bg-lightblue text-xs mr-2 px-2 py-0.5 rounded-full h-5 w-auto text-white text-center -left-3">{notification}</span>
                    :null}
                  <FontAwesomeIcon className={props.mode==='light'?'text-darkblue opacity-80':'text-greyblue'} icon={faBell} />
                </NavLink>
              </div>
            </div>
          </div> : ''}

          <div className="flex justify-between sm:hidden md:hidden sm:block my-3">
            <div className="flex flex-row items-center">
              <p className={(props.mode==='light'?'text-darkblue':'text-white')+" text-xsmall 2xl:text-lg xl:text-base"}>Available Balance</p>
              <div className="flex justify-evenly xxs:w-wp190 justify-start">
                <button type="button" className={(props.mode==='light'?'bg-darkblue':'bg-darkpink')+" text-white h-8 text-xsmall rounded-3xl outline-none xxs:w-auto px-3 2xl:h-h40 ml-3 mr-1 2xl:text-xsmall xs:px-1 cursor-default"} >{balance} HDL</button>
                <button type="button" className={(props.mode==='light'?"bg-darkorange":'bg-elemblue')+" text-white h-8 text-xsmall rounded-3xl outline-none xxs:w-auto px-3 2xl:h-h40 ml-0 mr-5 2xl:text-xsmall xs:px-1 cursor-default"}>{balance_usd} USD</button>
              </div>
            </div>
            <div className="flex justify-between sm:mt-2">
              <button type="button" className="outline-none large self-center text-2xl xl:text-2xl mr-10">              
                <FontAwesomeIcon className={props.mode==='light'?'text-darkblue opacity-80':'text-greyblue'} icon={faWifi} />
              </button>
              {/* <button type="button" className="relative outline-none 2xl:text-4xl xl:text-2xl mr-10">
                <span className="absolute z-10 bg-darkpink text-xs mr-2 px-2 py-0.5 rounded-full h-5 w-auto text-white text-center -left-4 lg:-left-5">2</span>
                <FontAwesomeIcon className={props.mode==='light'?'text-darkblue opacity-80':'text-greyblue'} icon={faEnvelope} />
              </button> */}
              <NavLink to="/notifications" className="relative outline-none self-center text-2xl xl:text-2xl lg-text-2xl">
              {notification>0?
                <span className="absolute z-10 bg-lightblue text-xs mr-2 px-2 py-0.5 rounded-full h-5 w-auto text-white text-center -left-4 lg:-left-5">{notification}</span>
                :null}
                <FontAwesomeIcon className={props.mode==='light'?'text-darkblue opacity-80':'text-greyblue'} icon={faBell} />
              </NavLink>
            </div>
          </div>
        </div>
      </div>
      <div className="w-w100 flex justify-center lg:w-w100 flex justify-center ">
      <hr className={"w-w95 sm:w-full "+(props.mode==='light'?'border-lightblue opacity-20':'border-elemblue')}></hr>
      </div>
      {/* {show==true?<pop />:''} */}
      </div>
    </>
  );
};

export default Navbar;
