import React, { useState, useEffect, useContext } from "react";
import Mode from "../../components/Mode/Mode";
import { MainLayout } from "../../components/Layouts/MainLayout";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmarkCircle} from "@fortawesome/free-solid-svg-icons";
import {Theme} from "../../App"
import { APP_URLS } from "../../api/url";
import API from "../../api/axios";
import { toast, ToastContainer } from "react-toastify";
import Popup from './Components/Popup'
import Pagination from "../../components/Shared/Pagination";


const NotificationPage = () => {

  const mode = useContext(Theme);
  const [data,setData] =useState();
  const [dataLoaded,setDataLoaded] =useState(false);
  const [read,setRead] = useState(false) 

  const [page,setPage] = useState(1) 
  const [totalPage,setTotalPage] = useState() 

  const [open,setOpen] = useState()

  const getNotifications =()=>{
    API.get(APP_URLS.GET_NOTIFICATION+`?page=${page}`)
    .then(res => { 
        if (res.data) {
          setTotalPage(res.data.last_page)
          setData(res.data.data)
          setDataLoaded(true)
        }
      })
      .catch((error) => {
        const resp = error.response;
        if (resp) {
          
            let error_message = '';
            if (resp.data.errors !== undefined) {
                {Object.keys(resp.data.errors).map((error, index) => (
                    error_message = resp.data.errors[error][0]
                ))}
            } else if (resp.data.data.error !== undefined) {
                error_message =  resp.data.data.error;
            } else {
                error_message =  resp.data.error
            }

            toast.error(error_message, {
                position: toast.POSITION.TOP_RIGHT
            })
        }
      })  
  }

  useEffect(() => {
    getNotifications()
    setRead(false)
  }, [read,page])

  
  
  return (
    <>
      <MainLayout>
        <Mode />
          <div className='min-h-screen w-full mt-5'>
        <h1 className={(mode==='light'?'text-darkblue':'text-white')+' text-2xl mt-10 lg:mt-14 sm:mt-2'}>Notifications</h1>
        {dataLoaded&&(
        <div>
          {data.length>0?
            data.map((item,index)=>{
              return (
              <div className={(item.status===0?'bg-orange':'bg-white')+' flex flex-col bg-white text-darkblue text-sm rounded-md w-full my-3 shadow-lg cursor-pointer'} role='alert' key={index} onClick={()=>setOpen(item.id)}>
                <div>
                  <p className="mr-4 py-3 px-5">
                    {item.title} 
                    <em className='block opacity-60 text-xxsmall underline'>{item.updated_at}</em>
                  </p>
                </div>
              </div>
            )
            })
            :
            <div className="flex h-hv80">
              <div className={(mode==='light'?'text-darkblue opacity-40':'text-white  opacity-60')+' text-lg flex items-center self-center block mx-auto'}>No Notifications Found!</div>  
            </div>
          }
          {totalPage>1?
            <Pagination 
              totalPages={totalPage}
              onPageClick={(page) => setPage(page + 1)}
            />:null}   
        </div>
        )}
  
     </div> 
     {open!==''&&open!=null?<Popup mode={mode} data={data} setOpen={setOpen} id={open} setRead={setRead}/>:null}
      </MainLayout>      
    </>
  );
};

export default NotificationPage;