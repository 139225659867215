import React, { useState,useEffect,useContext } from 'react'
import {SetTheme} from "../../../App"
import { APP_URLS } from "../../../api/url";
import API from "../../../api/axios";
import { toast, ToastContainer } from "react-toastify";

const PreferenceSettings = (props) => {

  const setMode = useContext(SetTheme);

  //Language SetUp
  const [dropdownValues, setDropdownValues] = useState([]);
  const [language, setLanguage] = useState();
   
  const handleLight =()=>{
    setMode('light')
    localStorage.setItem('mode','light')
  }
  const handleDark =()=>{
    setMode('dark')
    localStorage.setItem('mode','dark')
  }

  //Fetch Languages from API
  const getLanguages = () => {
    API.get(APP_URLS.GENERAL_SETTINGS)
    .then(res => { 
        if (res.data.success === true) {
          let languages = res.data.data.languages
          //Setting DropDown Values
          let newDropdownValues = []
          for (const key in languages) {
            newDropdownValues.push(<option value={key} key={key}>{languages[key]}</option>)
        }
        setDropdownValues(newDropdownValues)
        }
      })
      .catch((error) => {
        const resp = error.response;
        if (resp) {
          
            let error_message = '';
            if (resp.data.errors !== undefined) {
                {Object.keys(resp.data.errors).map((error, index) => (
                    error_message = resp.data.errors[error][0]
                ))}
            } else if (resp.data.data.error !== undefined) {
                error_message =  resp.data.data.error;
            } else {
                error_message =  resp.data.error
            }

            toast.error(error_message, {
                position: toast.POSITION.TOP_RIGHT
            })
        }
      })
  };

  //Update Language through API
  const updateLanguages = () => {
    let formdata = new FormData();
    formdata.append("lang", language);
    API.post(APP_URLS.SAVE_LANGUAGE,formdata)
    .then(res => { 
        if (res.data.success === true) {
          toast.success('Language has been updated successfully.', {
            position: toast.POSITION.TOP_RIGHT
          })
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT
          })        
        }
      })
      .catch((error) => {
        const resp = error.response;
        if (resp) {
          
            let error_message = '';
            if (resp.data.errors !== undefined) {
                {Object.keys(resp.data.errors).map((error, index) => (
                    error_message = resp.data.errors[error][0]
                ))}
            } else if (resp.data.data.error !== undefined) {
                error_message =  resp.data.data.error;
            } else {
                error_message =  resp.data.error
            }

            toast.error(error_message, {
                position: toast.POSITION.TOP_RIGHT
            })
        }
      })
  };
  
  useEffect(() => {
    getLanguages()
  }, [])


  return (
    <>
        <ToastContainer />
        <div
          className={(props.mode === "light"? "bg-orange text-darkblue": "bg-elemblue2 ") +
            " w-full flex-col w-full px-10 py-10 mt-5 rounded-lg mb-10 md:px-3"
          }>
          <div className="flex flex-col mb-5">
            <p
              className={(props.mode === "light" ? "" : "text-white") + " text-lg mb-1"} >
              Preference Settings
            </p>
          </div>
          {/* <div className="w-full flex flex-row gap-5 lg:block gap-7 hidden">
            <div className="w-w70 lg:w-w100 inline-block mb-2">
              <div className="flex flex-row my-2">
                <p className={(props.mode === "light" ? "text-darkblue" : "text-white") +" text-small xl:text-xs"}>
                  Select Language
                </p>
              </div>
              <div>
                <select className={(props.mode === "light" ? "bg-white": "bg-inputblue1 text-white") +
                    " w-full outline-none h-10 text-md p-1 border-btnblue border rounded-3xl h-h45 px-4 md:h-h40 text-sm appearance-none"} onChange={e => setLanguage(e.target.value)}>
                  {dropdownValues }
                </select>
              </div>
            </div>
            <div className="w-w30 lg:w-w100 flex self-end mb-2">
              <button
                className={(props.mode === "light"? "bg-lightblue border-lightblue": "bg-bgblue text-white border-btnblue") +
                  " border rounded-3xl w-auto text-white px-10 h-h45 b-0 md:h-h40 text-sm sm:w-full mt-3"} onClick={()=>updateLanguages()}>
                Update Language
              </button>
            </div>
          </div> */}
          <div className="w-full flex flex-row mt-10 mb-20 md:block">
            <p
              className={(props.mode === "light" ? "text-darkblue" : "text-white") +" text-small xl:text-xs self-center"}>  
              Choose Theme Type
            </p>
             <div className='inline-flex rounded-3xl bg-greylight ml-5 md:mt-2 md:ml-0"role="group'>
              <div className={(props.mode==='light'?'bg-btnblue text-white':'')+' flex flex-row justify-center items-center w-24 h-h50 rounded-full cursor-pointer sm:w-28'}  onClick={handleLight}>
                  <p className='text-white'>Light</p>
              </div>
              <div className={(props.mode==='dark'?'bg-btnblue':' border-btnblue  text-white')+' flex flex-row justify-center items-center w-24 h-h50  rounded-full cursor-pointer pt-2 pb-2 border-b-0 sm:w-28'} onClick={handleDark}>
                  <p className='text-white'>Dark</p>
              </div>
            </div>
          </div>
        </div>
    </>
  )
}

export default PreferenceSettings