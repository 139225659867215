import React from 'react';
import { Route, Navigate, Routes } from 'react-router-dom';
import DashboardPage from '../Pages/DashboardPage';
import BuyCoinPage from '../Pages/BuyCoins';
import SendReceiveCoinPage from '../Pages/SendReceiveCoin';
import WalletPage from '../Pages/Wallet';
import SwapCoinPage from '../Pages/SwapCoin';
import WithdrawCoinsPage from '../Pages/WithdrawCoins';
import ColdStoragePage from '../Pages/ColdStorage';
import SettingPage from '../Pages/Settings';
import ProfilePage from '../Pages/ProfilePage';
import NotificationPage from '../Pages/Notification';
import AddressBookPage from '../Pages/AddressBook';
import OffersPage from '../Pages/Offers';
import CoUserPage from '../Pages/CoUserList';
import DepositPage from '../Pages/Deposit';
import ActivityLogPage from '../Pages/ActivityLog';
import FaqPage from '../Pages/FAQ';
import ScheduleTransactionsPage from '../Pages/ScheduleTransactions';

const PrivateRoute = () => (
    <>
        <Routes>
            <Route path="/" element={<Navigate replace to="/dashboard" />} />
            <Route exact path="/dashboard" element={<DashboardPage />} />
            <Route exact path="/buy-coins" element={<BuyCoinPage />} />
            <Route exact path="/send-receive-coin" element={<SendReceiveCoinPage />} />
            <Route exact path="/schedule-transactions" element={<ScheduleTransactionsPage />} />
            <Route exact path="/wallet" element={<WalletPage />} />
            <Route exact path="/wallet/co-user/:coin" element={<CoUserPage />} />
            <Route exact path="/wallet/deposit/:coin" element={<DepositPage />} />
            <Route exact path="/wallet/activity-log/:coin" element={<ActivityLogPage />} />

            <Route exact path="/swap-coins" element={<SwapCoinPage />}>
                <Route path=":coinType" element={<SwapCoinPage />} />
            </Route>
            {/* <Route exact path="/myreferral" element={<MyReferralPage />} /> */}
            <Route exact path="/cold-storage" element={<ColdStoragePage />} />
            <Route exact path="/transaction-history" element={<WithdrawCoinsPage />} />
            <Route exact path="/address-book" element={<AddressBookPage />} />
            <Route exact path="/offers" element={<OffersPage />} />
            <Route exact path="/faqs" element={<FaqPage />} />
            <Route exact path="/settings" element={<SettingPage />} />
            <Route exact path="/profile" element={<ProfilePage />} />
            <Route exact path="/notifications" element={<NotificationPage />} />
            <Route exact path="*" element={<Navigate replace to="/dashboard" />} />

        </Routes>
    </>
)

export default PrivateRoute;