import React, { useState, useEffect, useContext, createContext } from "react";
import Navbar from "../components/Navbar/Navbar";
import Menu from "../components/Menu/Menu";
import { useNavigate } from 'react-router-dom';
import Switch from "react-switch";
import {Theme} from "../App"
import ProfileUser from "../components/MainContent/ProfileUser";

const Update = createContext();

const ProfilePage = () => {

  const mode = useContext(Theme);
  
  const navigate = useNavigate()
  const [values, setValues] = useState([]);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [menu,setMenu]=useState(false);

  const[profileUpdated,setProfileUpdated]=useState(false);


  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token == '' || token === null) {
      navigate('/login')
    }
  }, []);

  useEffect(() => {
    let authToken = localStorage.getItem("token");
    let access_type = localStorage.getItem("access_type");
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");
    myHeaders.append("Authorization", access_type + " " + authToken);

    var requestOptions = {
      method: "GET",
      headers: myHeaders,
    };

    fetch(process.env.REACT_APP_API_URL+"user-dashboard-app", requestOptions)
      .then((response) => response.text())
      .then((result) => {
        let data = JSON.parse(result);
        setValues(data);
        setDataLoaded(true);
      })
      .catch((error) => console.log("error", error));
  }, []);


  
  return (
    <>

      <div className={"flex flex-row " + (mode==='light' ? 'bg-grey' : 'bg-bgblue')}>
        <div className={"w-w17 " +(menu?'md:absolute sm:absolute ':'md:hidden sm:hidden')}>
          <Menu mode={mode} setMenu={setMenu} menu={menu} setProfileUpdated={setProfileUpdated} profileUpdated={profileUpdated}/>
        </div>
        <div className="w-w83 md:w-w100 sm:w-w100 sm:overflow-x-hidden px-3">
            <Navbar mode={mode} setMenu={setMenu} data={values}/>
          {dataLoaded?
          <div className="mt-10 flex flex-col items-center lg:mt-5 sm:mt-14">
            <Update.Provider value={setProfileUpdated}>
              <ProfileUser mode={mode} data={values}/>
            </Update.Provider>
          </div>:
          <div className="flex flex-row justify-between my-8 gap-6 md:block sm:block xxs:mt-6 h-screen">
              <div className="absolute top-[50%] left-[55%] md:top-[50%] md:left-[45%] xs:left-[38%]">
                  <span className='text-fontblue '>Loading...</span>
              </div>
          </div>
          }
        </div>
      </div>
      
    </>
  );
};

export default ProfilePage;
export {Update}

