import React from 'react';
import { Route, Navigate, Routes } from 'react-router-dom';
import Login from '../Pages/Login';
import Register from '../Pages/Register';
import VerifyAccount from '../Pages/VerifyAccount';
import ForgotPassword from '../Pages/ForgotPassword';
import ResetPassword from '../Pages/ResetPassword';
import GoogleAuthentication from '../Pages/GoogleAuthentication';

const PublicRoute = (props) => {
    const token = localStorage.getItem('token');
    return (
        <>
            <Routes>
                <Route path="/login" element={token?<Navigate replace to="/g2f-checked" />:<Login setToken={props.setToken} />} />
                <Route path="/register" element={<Register />} />
                <Route path="/forget-password" element={<ForgotPassword />} />
                <Route path="/account-verification" element={<VerifyAccount />} />
                <Route path="/reset-password" element={<ResetPassword />} />
                <Route path="/reset-password" element={<ResetPassword />} />
                <Route path="/g2f-checked" element={<GoogleAuthentication />} />
                <Route exact path="*" element={<Navigate replace to="/login" />} />
            </Routes>
        </>
    )}


export default PublicRoute;