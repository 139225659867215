import React,{useState,useEffect} from "react";
import logo from "../components/Images/iHodl-3.png";
import { Link, useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik } from "formik";
import { APP_URLS } from "../api/url";
import { toast, ToastContainer } from 'react-toastify';
import API from "../api/axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

const Register = () => {
  const navigate = useNavigate()
  const nameRegExp = /^([a-zA-Z0-9\_\-]+)$/;
  const emailRegExp = /^([a-z0-9\_\-]+)(\.[a-z0-9\+_\-]+)*@([a-z0-9\-]+\.)+[a-z]{2,6}$/;
  const passwordRegExp = /^((?=.*\d)(?=.*[A-Z])(?=.*[a-z]).{8,})$/;
  const [loading, setLoading] = useState(false);

  const [showPassword,setShowPassword] = useState(false)
  const [showConfirmPassword,setConfirmShowPassword] = useState(false)

  const handlePassword = () =>{
    var temp = document.getElementById("password");
    if(showPassword){
      setShowPassword(false)
      temp.type = "password"
    }
    else{
      setShowPassword(true)
      temp.type = "text"
    }
  }

  const handleConfirmPassword = () =>{
    var temp = document.getElementById("ConfirmPassword");
    if(showConfirmPassword){
      setConfirmShowPassword(false)
      temp.type = "password"
    }
    else{
      setConfirmShowPassword(true)
      temp.type = "text"
    }
  }
  
  useEffect(() => {
    const token = localStorage.getItem('token');

    if(token != '' && token !== null) {
        navigate('/dashboard')
    }
  }, []);
  

  const formik = useFormik({
    initialValues: {
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        password_confirmation: ''
    },
    validationSchema: Yup.object({
        first_name: Yup.string().required('First Name is Required').max(20, 'The "First Name" field may not be greater than 20 characters').matches(nameRegExp, 'First Name should be string only'),
        last_name: Yup.string().required('Last Name is Required').max(20, 'The "Last Name" field may not be greater than 20 characters').matches(nameRegExp, 'Last Name should be string only'),
        email: Yup.string().required('Email is Required').matches(emailRegExp, 'The "Email" field format is invalid'),
        password: Yup.string().required('Password is Required').matches(passwordRegExp, 'Password must be consist of one uppercase, one lowercase, one number and minimum length at least 8 characters.'),
        password_confirmation: Yup.string().oneOf([Yup.ref('password'), null], 'Passwords must match'),
    }),
    onSubmit: values => {
        if(!loading) {
            setLoading(true)
            API.post(APP_URLS.REGISTER, values)
            .then(res => {
                const resp = res.data;
                setLoading(false)
                localStorage.setItem('register_success_message', resp.message);
                navigate('/account-verification?verification_email='+values.email)
            })
            .catch(function (error) {  
                const resp = error.response;
                setLoading(false)
                if (resp) {
                  
                    let error_message = '';
                    if (resp.data.errors !== undefined) {
                        {Object.keys(resp.data.errors).map((error, index) => (
                            error_message = resp.data.errors[error][0]
                        ))}
                    } else if (resp.data.data.error !== undefined) {
                        error_message =  resp.data.data.error;
                    } else {
                        error_message =  resp.data.error
                    }

                    toast.error(error_message, {
                        position: toast.POSITION.TOP_RIGHT
                    })
                }
            });         
        }
    },
  });

  return (
    <>
      <ToastContainer />
      <div className="w-full flex w-full bg-bgblue px-4 py-10 min-h-screen">
          <div className="shadow-lg self-center w-full max-w-lg m-auto bg-elemblue2 rounded p-7 border border-btnblue">   
            <header className="text-center">
            <img src={logo} className="w-2/6 flex mx-auto" />
            <h1 className="text-center text-white text-bold text-lg mb-2">Create an Account</h1>
            </header>   
            <form onSubmit={formik.handleSubmit} className="">
              <div className="flex gap-5">
                <div className="w-w50">
                  <label className="block mb-1 text-white" htmlFor="first_name">First Name</label>
                  <input 
                      className={"w-full p-2 text-darkblue border-b-2 border-indigo-500 outline-none bg-bluelight text-white rounded-3xl h-h50 px-4" + (formik.touched.first_name && formik.errors.first_name ? ' is-invalid' : '')} 
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      type="text" name="first_name"
                      value={formik.values.first_name}
                      placeholder="First Name" />
                      {formik.touched.first_name && formik.errors.first_name ? (
                            <div className='text-left text-red text-xs pt-2'>{formik.errors.first_name}</div>
                        ) : null}
                </div>
                <div  className="w-w50">
                  <label className="block mb-1 text-white" htmlFor="firstname">Last Name</label>
                  <input 
                      className={"w-full p-2 text-darkblue border-b-2 border-indigo-500 outline-none bg-bluelight text-white rounded-3xl h-h50 px-4" + (formik.touched.last_name && formik.errors.last_name ? ' is-invalid' : '')} 
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      type="text" name="last_name"
                      value={formik.values.last_name}
                      placeholder="Last Name" />
                    {formik.touched.last_name && formik.errors.last_name ? (
                                  <div className='text-left text-red text-xs pt-2'>{formik.errors.last_name}</div>
                              ) : null}
                </div>
              </div>
              <div className="col-span-2">
                <label className="block mb-1 mt-6 text-white" htmlFor="username">Email address</label>
                <input 
                  type="text" 
                   name="email" 
                  className={"w-full  p-2 text-darkblue border-b-2 border-indigo-500 outline-none bg-bluelight text-white rounded-3xl h-h50 px-4" + (formik.touched.email && formik.errors.email ? ' is-invalid' : '')}   
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  placeholder="Email Address"
                  autoComplete='off' />

                  {formik.touched.email && formik.errors.email ? (
                        <div className='text-left text-red text-xs pt-2'>{formik.errors.email}</div>
                    ) : null}
              </div>
              <div>
                <label className="block mb-1 mt-6 text-white" htmlFor="password">Password</label>
                <div className={"flex w-full p-2  text-darkblue border-b-2 border-indigo-500 outline-none bg-bluelight text-white rounded-3xl h-h50 px-4" + (formik.touched.password && formik.errors.password ? ' is-invalid' : '')}>
                <input type="password" name="password"  onChange={formik.handleChange} onBlur={formik.handleBlur}
                      id="password"
                      className="w-w94 h-full focus:outline-0 bg-bluelight"
                      value={formik.values.password} 
                      placeholder="Password"
                      autoComplete='off' />
                      <FontAwesomeIcon icon={showPassword?faEye:faEyeSlash} className={"text-small self-center cursor-pointer"} onClick={handlePassword}/>
                      </div>
                {formik.touched.password && formik.errors.password ? (
                      <div className='text-left text-red text-xs pt-2'>{formik.errors.password}</div>
                  ) : null}
              </div>
              <div>
                <label className="block mb-1 mt-6 text-white" htmlFor="password_confirmation">Password Confirmation</label>
                <div className={"flex w-full p-2 text-darkblue border-b-2 border-indigo-500 outline-none bg-bluelight text-white rounded-3xl h-h50 px-4" + (formik.touched.password && formik.errors.password ? ' is-invalid' : '')}>
                <input type="password" name="password_confirmation"  onChange={formik.handleChange} onBlur={formik.handleBlur}
                      id="ConfirmPassword"
                      className="w-w94 h-full focus:outline-0 bg-bluelight"
                      value={formik.values.password_confirmation} 
                      placeholder="Password Confirmation"
                      autoComplete='off' />
                      <FontAwesomeIcon icon={showConfirmPassword?faEye:faEyeSlash} className={"text-small self-center cursor-pointer"} onClick={handleConfirmPassword}/>
                      </div>
                  {formik.touched.password_confirmation && formik.errors.password_confirmation ? (
                        <div className='text-left text-red text-xs pt-2'>{formik.errors.password_confirmation}</div>
                    ) : null}
              </div>
            <div>          
                <button className="w-full mt-9 bg-btnblue hover:bg-pink-700 font-bold py-2 px-4 rounded-3xl h-h50 text-white" type="submit" disabled={loading}>{ loading ? 'Signing Up...' : 'Sign up' }</button>
              </div>       
            </form>  
            <footer className="float-right mt-5 text-white">
              Already have an account? <Link className="text-btnblue text-lg" to="/login">Login Here</Link>
            </footer>   
          </div>
        </div>
    </>
  )
}

export default Register