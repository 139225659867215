import React,{useState} from 'react'
import notrunning from "../../../../components/Images/not-running.png";
import BuyOurCoin from './BuyOurCoin';


const SaleNotAvailable = (props) => {
  const[paymentmethods,setPaymentmethods]=useState(Object.values(props.data.payment_methods))

  return (
    <>
    <div className="flex flex-row justify-between my-8 gap-6 md:block sm:block xxs:mt-6">
    <BuyOurCoin mode={props.mode} data={props.data}/>

      <div className={(props.mode==='light'?'bg-orange':'bg-elemblue2')+" shadow-lg flex flex-col p-7 rounded-lg justify-center items-center md:w-full w-w50 mt-2 sm:w-w100"}>
        <div className="self-center">
          <h3 className={(props.mode==='light'?'text-darkblue':'text-white')+" pb-5 opacity-70"}>
            <em>ICO PHASE IS NOT RUNNING...</em>
          </h3>
          <img className="w-auto" src={notrunning} />
        </div>
      </div>
    </div>
  </>
  )
}

export default SaleNotAvailable