import React, { useEffect, useState, createContext } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import BalanceChart from "../Charts/BalanceChart";
import AvailableCoins from "../Charts/AvailableCoins";
import Watchlist from "../Charts/Watchlist";
import TotalBuyCoins from "../Charts/TotalBuyCoins";
import DepositWithdraw from "../Charts/DepositWithdraw";
import BuyCoinReport from "../Charts/BuyCoinReport";
import AllDeposit from "../Charts/AllDeposit";
import Mode from "../Mode/Mode";
import { faXmarkCircle } from "@fortawesome/free-solid-svg-icons";
import { APP_URLS } from "../../api/url";
import API from "../../api/axios";
import { toast, ToastContainer } from "react-toastify";
import dropdown from '../../components/Images/dropdown.png';


const weekday = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
const month = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "June",
  "July",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];
let coins = [];


const SetPopUp = createContext();

const Dashboard = (props) => {
  const [displayPopUp, setdisplayPopUp] = useState(false);
  const [coinList, setCoinList] = useState([]);
  const [userWatchList, setUserWatchList] = useState([]);
  const [userShortWatchList, setUserShortWatchList] = useState([]);
  const [coin, setCoin] = useState();
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState([]);
  const [valuesLoaded, setValuesLoaded] = useState(false);



  useEffect(() => {
    fetchWatchList();
    userDashboardApp();
  }, []);

  const fetchWatchList = async () => {
    setLoading(true);
    await API.get(APP_URLS.WATCH_LIST+'?interval=1d,7d,30d,365d,ytd&convert=USD')
      .then((result) => { 
        const resp = result.data.data;
        setUserWatchList(resp.data);
        setUserShortWatchList(resp.coins);

        const allCoinList = resp.all_coins;
        setCoinList(allCoinList);

        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      })
  }

  const userDashboardApp = async () => {
    await API.get(APP_URLS.USER_DASHBOARD_DATA+'?buycoin_type=weekly')
      .then((result) => { 
        const resp = result.data;
        setValues(resp);
        setValuesLoaded(true)
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
      })

  }

  const handleEntries = (e) => {
    setCoin(e.target.value)
  };

  const deleteCoinHandle = (deletedCoin) => {
      updateWatchList(deletedCoin);
  }

  const updateWatchList = (selectedCoin) => {
    setLoading(true);
    API.post(APP_URLS.UPDATE_WATCH_LIST, {'coin_id': selectedCoin})
    .then(res => { 
        if (res.data.success === true) {
          if(selectedCoin===undefined||selectedCoin===''){
            toast.warning('Please select a coin for adding to the Watch List.', {
              position: toast.POSITION.TOP_RIGHT
            })
          }
          else{
          toast.success('Watch List has been updated successfully.', {
            position: toast.POSITION.TOP_RIGHT
          })}
        } else {
          toast.error(res.data.message, {
            position: toast.POSITION.TOP_RIGHT
          })        
        }
        setLoading(false);
        setCoin('')
        setdisplayPopUp(false)
        fetchWatchList()
      })
      .catch((error) => {
        const resp = error.response;
        setLoading(false)
        if (resp) {
          
            let error_message = '';
            if (resp.data.errors !== undefined) {
                {Object.keys(resp.data.errors).map((error, index) => (
                    error_message = resp.data.errors[error][0]
                ))}
            } else if (resp.data.data.error !== undefined) {
                error_message =  resp.data.data.error;
            } else {
                error_message =  resp.data.error
            }

            toast.error(error_message, {
                position: toast.POSITION.TOP_RIGHT
            })
        }
        setdisplayPopUp(false)
      })
  };


 
  const d = new Date();
  let dateName = d.getDate();
  let monthName = month[d.getMonth()];
  let dayName = weekday[d.getDay()];
  let date = `${dateName} ${monthName}, ${dayName}`;

  return ( 
    <>
      <Mode /> 
      <ToastContainer />
      <div className="min-h-screen w-w95 mt-10 lg:mt-5">
      <div className="flex flex-col w-full relative">
        {displayPopUp ? ( 
          <div className={(props.mode==='light'?'bg-darkblue':'bg-darkblue')+" flex flex-center bg-opacity-80 w-full h-screen fixed z-20 grid place-items-center h-screen top-0 left-0"}>
            <div className="relative w-2/6 z-30 right-0 md:left-0 md:w-w50 sm:w-w90">
              <div
                className={
                  (props.mode === "light"
                    ? "bg-orange text-darkblue border-darktblue"
                    : "bg-bgblue border-lightblue") +
                  " relative block mx-auto border border-8 rounded-2xl text-center px-8 py-10 w-full sm:p-2"
                }
              >
                <button
                  className="absolute -right-5 -top-5"
                  onClick={() => setdisplayPopUp(false)}
                >
                  {" "}
                  <FontAwesomeIcon
                    className="text-elemblue text-3xl bg-white rounded-full"
                    icon={faXmarkCircle}
                  />{" "}
                </button>
                <form className="text-start">
                  <label htmlFor="coins" className={(props.mode==='light'?'text-darkblue':'text-white')+' flex my-3'}>
                    Select Coin
                  </label>
                  <div className={(props.mode==='light'?'bg-white text-darkblue':'bg-inputblue')+' text-white w-full outline-none text-small px-3 rounded-3xl h-12 my-3 shadow-lg'}>
                  <select
                    style={{backgroundImage: `url(${dropdown})`}} 
                    className={(props.mode==='light'?'bg-white text-darkblue':'bg-inputblue')+' text-white w-full outline-none text-small pr-3 rounded-3xl h-12 bg-no-repeat bg-right appearance-none'}
                    name="coins"
                    id="coins"
                    onChange={(e) => handleEntries(e)}
                  >
                    <option >Select Coin</option>
                    {coinList && coinList.map((item, index) => {
                      if (item !== 'Default' && item !== null && !userShortWatchList.includes(item)) {
                        return <option key={index} value={item}>{item}</option>;
                      }
                    })}
                  </select>
                  </div>
                </form>
                <button
                  type="button"
                  onClick={() => updateWatchList(coin)}
                  className={
                    (props.mode === "light"
                      ? "bg-darkorange"
                      : "bg-gradient-to-r from-purple to-pink") +
                    " text-white rounded-3xl w-3/6 px-3 outline-none text-small h-10 my-3 px-5 xxs:text-small"
                  }
                >
                  Add Coins
                </button>
              </div>
            </div>
          </div>
        ) : (
          ""
        )}
        <div className="flex flex-row justify-start w-full items-center mt-14 sm:mt-0">
          <h1
            className={
              (props.mode === "light" ? "text-darkblue" : "text-white") + " text-medium"
            }
          >
            Dashboard
          </h1>
          <p
            className={
              (props.mode === "light" ? "text-darkgrey2" : "text-fontpurple") +
              " ml-8 flex items-center"
            }
          >
            {date}
          </p>
        </div>
        {valuesLoaded?
        <div className={(props.mode==='dark'?'bg-bgblue':'')+" min-h-screen"}>
          <div className="flex flex-row justify-between mt-5 sm:block">
            <div className="w-1/5 shadow-lg sm:w-w100 sm:mb-2 h-hp47 xxs:my-2">
              {valuesLoaded && (<BalanceChart mode={props.mode} values={values} key={values} />) }
            </div>
            <div className="flex flex-col w-w35 justify-between h-hp47 sm:w-w100 sm:mb-3">
              <div className="flex flex-row justify-around lg:block md:block sm:block ">
              {valuesLoaded && (<AvailableCoins mode={props.mode} values={values} key={values} />) }
                {/* <BlockedCoins mode={props.mode} values={values} key={values} /> */}
              </div>
              <div className="w-full flex justify-center md:bg-elemblue3 sm:mt-6 xl:mt-6">
                {valuesLoaded && (<TotalBuyCoins mode={props.mode} values={values} key={values} />)}
              </div>
            </div>
            <div className="w-5/12 shadow-lg h-hp47 sm:w-w100 sm:mt-6 lg:mt-0">
              {valuesLoaded && 
                (<SetPopUp.Provider value={setdisplayPopUp}>
                  <Watchlist
                    mode={props.mode}
                    values={values} key={values}
                    onDeleteCoin={deleteCoinHandle}
                    watchList={userWatchList}
                    loading={loading}
                  />
                </SetPopUp.Provider>) 
              }
            </div>
          </div>
          <div className="flex flex-row justify-between mt-8 sm:block xxs:mt-6">
            {valuesLoaded  && 
              <>
                <DepositWithdraw mode={props.mode} />
                <BuyCoinReport mode={props.mode} values={values} key={values} />
                </>
            }
          </div>
          <div className="flex flex-row justify-center mt-5">
            {valuesLoaded  && <AllDeposit mode={props.mode} /> }
          </div>
        </div>:
        <div className={(props.mode==='dark'?'bg-bgblue':'')+" min-h-screen "}>
            <div className="absolute top-[40%] left-[45%]">
                <span className="text-fontblue">Loading...</span>
            </div>
        </div>
        }
      </div>
      </div>
    </>
  );
};

export default Dashboard;
export { SetPopUp };
